// src/components/admin/FundProgramData/FundProgramData.tsx

import React from 'react';

const FundProgramData: React.FC = () => {
  return (
    <div className="p-4 border rounded">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border text-sm">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              <th className="py-2 px-4 border-b">Participant ID</th>
              <th className="py-2 px-4 border-b">Participant Name</th>
              <th className="py-2 px-4 border-b">Invested Amount</th>
              <th className="py-2 px-4 border-b">Program Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={4} className="text-center py-8">No participants available</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FundProgramData;
