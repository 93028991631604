// src/hooks/AdminDashboard/useSubData.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';
import useUserDashboard from '../Dashboard/useUserDashboard';  

export interface Product {
  id?: string;
  productId: string;
  productName: string;
  productDescription: string;
  productImg: string | File | null; 
  productStatus: string;
}

export interface Plan {
  id?: string;
  planId: string;
  LinkProductId: string;
  planName: string;
  planDescription: string;
  planFeatures: string | string[];
  planAmount: number;
  planCurrency: string;
  planPeriod: number;
  planAccount: string;
  planStatus: string;
}

interface Trial {
  id?: string;
  trialId: string;
  trialLinkPlanId: string;
  trialName: string;
  trialPeriod: number;
  trialMembersType: string | null;  
  trialGroupName: string | null;  
  trialGroupMember: string | null; 
  trialStatus: string;
}

interface Voucher {
  id?: string;
  voucherId: string;
  VoucherLinkPlanID: string; 
  voucherName: string; 
  voucherCode: string; 
  voucherType: string;    
  voucherAmount?: number;     
  voucherPercentage?: number; 
  voucherPeriod: Date;
  voucherDays: number;
  voucherRedeemCount: number;
  voucherMemberType: string;
  voucherGroupName: string;
  voucherGroupMember: string;
  voucherStatus: string;
}

export interface SubUser {
  id?: string;
  subOrderId: string;
  subProductId: string;
  subPlanId: string;
  subUserName: string;
  subUserEmail: string;
  subPlanType: string;
  subPlanAmount: number;
  subPlanPeriod: number;
  subDateSubmit: Date;
  subExpiredDate: Date;
  subExpiredDays: number;
  subStatus: string;
  subAccountType?: string;
  subAccountServer?: string;
  subAccountNumber?: string;
  subAccountPassword?: string;
  subTrialCheck: number;
  subVoucherCheck: number;
  subDemoCheck: number;
  subPaymentMethod: string;
  subPaymentReceipts: string;
  subNotes: string;
  subRemark: string;
  subTermAgreed: string;
}

export interface AlgoMatrixData{
  id?: string;
  subOrderId: string;
  subProductId: string;
  subPlanId: string;
  subUserName: string;
  subUserEmail: string;
  subPlanType: string;
  subPlanAmount: number;
  subPlanPeriod: number;
  subDateSubmit: Date;
  subExpiredDate: Date;
  subExpiredDays: number;
  subStatus: string;
  subAccountType?: string;
  subAccountServer?: string;
  subAccountNumber?: string;
  subAccountPassword?: string;
  subTrialCheck: number;
  subVoucherCheck: number;
  subDemoCheck: number;
  subPaymentMethod: string;
  subPaymentReceipts: string;
  subNotes: string;
  subRemark: string;
  subTermAgreed: string;
}

export interface TraderHubData{
  id?: string;
  subOrderId: string;
  subProductId: string;
  subPlanId: string;
  subUserName: string;
  subUserEmail: string;
  subPlanType: string;
  subPlanAmount: number;
  subPlanPeriod: number;
  subDateSubmit: Date;
  subExpiredDate: Date;
  subExpiredDays: number;
  subStatus: string;
  subAccountType?: string;
  subAccountServer?: string;
  subAccountNumber?: string;
  subAccountPassword?: string;
  subTrialCheck: number;
  subVoucherCheck: number;
  subDemoCheck: number;
  subPaymentMethod: string;
  subPaymentReceipts: string;
  subNotes: string;
  subRemark: string;
  subTermAgreed: string;
}

const getStatusStyle = (status: string) => {
  switch (status) {
    case 'Active':
      return 'bg-green-600 text-white';
    case 'Pending':
      return 'bg-yellow-300 text-black'
    case 'Expire':
      return 'bg-rose-500 text-white';
    default:
      return '';
  }
};

export const useSubData = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [trials, setTrials] = useState<Trial[]>([]);
  const [vouchers, setVouchers] = useState<Voucher[]>([]);
  const [pendingSubscriptions, setPendingSubscriptions] = useState<SubUser[]>([]);
  const [algoMatrixSubscriptions, setAlgoMatrixSubscriptions] = useState<AlgoMatrixData[]>([]);
  const [traderHubSubscriptions, setTraderHubSubscriptions] = useState<TraderHubData[]>([]);
  const [versionNumber, setVersionNumber] = useState<string>('');
  const [loading, setLoading] = useState(true);
  // import hook and context state
  const { apiUrl } = useEnv();
  const { user } = useUserDashboard();

  //------------------------ Product -------------------------//
  const fetchProducts = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/sub/products`);
      const data = await response.json();
      setProducts(data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  //------------------------ Plan -------------------------//
  const fetchPlan = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/sub/plans`);
      const data = await response.json();
      setPlans(data);

      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch plans', error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchPlan();
  }, [fetchPlan]);

  //------------------------ Trial set -------------------------//
  const fetchTrial = useCallback(async () => {
    try {
      const email = localStorage.getItem('email'); 
      const response = await fetch(`${apiUrl}/sub/trials?memberEmail=${email}`);
      const data = await response.json();
      setTrials(data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch trials', error);
    }
  }, [apiUrl]);

  // Function to get trial period by plan ID
  const getTrialPeriodByPlanId = (planId: string) => {
    const trial = trials.find(trial => trial.trialLinkPlanId === planId);
    return trial ? trial.trialPeriod : null;
  };

  useEffect(() => {
    fetchTrial();
  }, [fetchTrial]);

  //------------------------ Product -------------------------//
  const fetchVoucher = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/sub/vouchers`);
      const data = await response.json();
      setVouchers(data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch voucher', error);
    }
  }, [apiUrl]);

  // Function to get voucher code by plan ID
  const getVoucherByCodeAndPlanId = (voucherCode: string, planId: string) => {
    const voucher = vouchers.find(
      voucher => voucher.voucherCode === voucherCode && voucher.VoucherLinkPlanID === planId
    );
    return voucher;
  };
  useEffect(() => {
    fetchVoucher();
  }, [fetchVoucher]);

  //------------------------ subscription Register -------------------------//
  const fetchVersionNumber = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/coretable/version`);
      const data = await response.json();
      if (data && data.VersionNumber) {
        setVersionNumber(data.VersionNumber);
      }
    } catch (error) {
      console.error('Failed to fetch VersionNumber', error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchVersionNumber();
  }, [fetchVersionNumber]);

  // Define updateExpiredDays before fetchSubscriptions
  const updateExpiredDays = useCallback(async (subOrderId: string, email: string) => {
    try {
      const response = await fetch(`${apiUrl}/sub/updateExpiredDays`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subOrderId, email }),
      });

      if (!response.ok) throw new Error("Failed to update subExpiredDays");

      const result = await response.json();
      return result.subExpiredDays;
    } catch (error) {
      console.error("Error updating subExpiredDays:", error);
      throw error;
    }
  }, [apiUrl]);

  // Update fetchSubscriptions to reference updateExpiredDays without error
  const fetchSubscriptions = useCallback(async () => {
    const email = user?.email;
    if (!email) return;

    try {
      const response = await fetch(`${apiUrl}/sub/subscriptions?email=${email}`);
      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();

      // Store each subscription type in its own state
      setPendingSubscriptions(data.pendingSubscriptions);
      setAlgoMatrixSubscriptions(data.algoMatrixSubscriptions);
      setTraderHubSubscriptions(data.traderHubSubscriptions);

      // Auto-update subExpiredDays for all fetched subscriptions
      await Promise.all(
        [...data.algoMatrixSubscriptions, ...data.traderHubSubscriptions].map(async (sub) => {
          const updatedDays = await updateExpiredDays(sub.subOrderId, email);
          sub.subExpiredDays = updatedDays;

          // Update status if expired
          if (updatedDays < 0) {
            sub.subStatus = 'Expire';
          }
        })
      );

    } catch (error) {
      console.error('Failed to fetch subscriptions data:', error);
      setPendingSubscriptions([]);
      setAlgoMatrixSubscriptions([]);
      setTraderHubSubscriptions([]);
    }
  }, [apiUrl, user?.email, updateExpiredDays]);

  // UseEffect to fetch subscriptions when component loads
  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  const registerSubscription = async (subscriptionData: SubUser, paymentReceipt: File | null) => {
    try {
      const formData = new FormData();

      // Append subscription data to FormData
      Object.entries(subscriptionData).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          formData.append(key, value.toString());
        }
      });

      // Append file if available
      if (paymentReceipt) {
        formData.append("subPaymentReceipts", paymentReceipt);
      }

      // Make API call
      const response = await fetch(`${apiUrl}/sub/register`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to register subscription");
      }

      return await response.json();
    } catch (error) {
      console.error("Error during subscription registration:", error);
      throw error;
    }
  };

  // Modify the updateDemoAccount function to accept email as an additional parameter
  const updateDemoAccount = async (
    subOrderId: string, subAccountNumber: number, subAccountPassword: string, email: string
  ) => {

    try {
      const response = await fetch(`${apiUrl}/sub/updateDemoAccount`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subOrderId, subAccountNumber, subAccountPassword, email }),
      });
  
      if (!response.ok) {
        console.error("Failed to update demo account, response status:", response.status);
        throw new Error('Failed to update demo account');
      }
  
      const result = await response.json();
  
      return result;
    } catch (error) {
      console.error("Error updating demo account:", error);
      throw error;
    }
  }; 
  
  // ------------------------------------ handle file download ------------------------------------ //
  const getDownloadFile = async (type: string, app?: string): Promise<void> => {
    try {
      const userEmail = localStorage.getItem('email') || (user && user.email);
  
      if (!userEmail) {
        throw new Error('User email is required for download but was not found.');
      }
  
      // Add `app` to the query parameters if present
      const endpoint = `${apiUrl}/download?email=${userEmail}&type=${type}${app ? `&app=${app}` : ''}`;
  
      const response = await fetch(endpoint);
  
      if (!response.ok) {
        throw new Error(`Failed to fetch download token. Status: ${response.status}`);
      }
  
      const { downloadUrl } = await response.json();
  
      // Determine the download file name based on `type`
      let fileName = '';
      if (type === 'algomatrix') {
        fileName = 'AlgoMatrix.ex4';
      } else if (type === 'mt4') {
        fileName = `${app || 'file'}.exe`;
      } else if (type === 'installation-guide') {
        fileName = 'AlgoMatrix_Guide_Book.pdf';
      } else {
        throw new Error(`Unsupported download type: ${type}`);
      }
  
      // Fetch and download the file using the download URL
      const fileResponse = await fetch(`${apiUrl}${downloadUrl}`);
      if (!fileResponse.ok) {
        throw new Error('Failed to fetch the file from the server');
      }
  
      const blob = await fileResponse.blob();
      const downloadLink = window.URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = downloadLink;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadLink);
  
    } catch (error) {
      console.error('Error during file download:', error);
      alert('Failed to download the file. Please ensure you are logged in and try again.');
    }
  };    

  return { 
    products, plans, trials, vouchers, versionNumber, loading,
    registerSubscription,
    fetchSubscriptions,
    updateDemoAccount, 
    updateExpiredDays,
    getTrialPeriodByPlanId,
    getVoucherByCodeAndPlanId,
    getStatusStyle,
    getDownloadFile,
    pendingSubscriptions,
    algoMatrixSubscriptions,
    traderHubSubscriptions,
  };
};

// // udpate code --> 24 nov 2024
// // src/hooks/AdminDashboard/useSubData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import useUserDashboard from '../Dashboard/useUserDashboard';  

// export interface Product {
//   id?: string;
//   productId: string;
//   productName: string;
//   productDescription: string;
//   productImg: string | File | null; 
//   productStatus: string;
// }

// export interface Plan {
//   id?: string;
//   planId: string;
//   LinkProductId: string;
//   planName: string;
//   planDescription: string;
//   planFeatures: string | string[];
//   planAmount: number;
//   planCurrency: string;
//   planPeriod: number;
//   planAccount: string;
//   planStatus: string;
// }

// interface Trial {
//   id?: string;
//   trialId: string;
//   trialLinkPlanId: string;
//   trialName: string;
//   trialPeriod: number;
//   trialMembersType: string | null;  
//   trialGroupName: string | null;  
//   trialGroupMember: string | null; 
//   trialStatus: string;
// }

// interface Voucher {
//   id?: string;
//   voucherId: string;
//   VoucherLinkPlanID: string; 
//   voucherName: string; 
//   voucherCode: string; 
//   voucherType: string;    
//   voucherAmount?: number;     
//   voucherPercentage?: number; 
//   voucherPeriod: Date;
//   voucherDays: number;
//   voucherRedeemCount: number;
//   voucherMemberType: string;
//   voucherGroupName: string;
//   voucherGroupMember: string;
//   voucherStatus: string;
// }

// export interface SubUser {
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// export interface AlgoMatrixData{
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// export interface TraderHubData{
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// const getStatusStyle = (status: string) => {
//   switch (status) {
//     case 'Active':
//       return 'bg-green-600 text-white';
//     case 'Pending':
//       return 'bg-yellow-300 text-black'
//     case 'Expire':
//       return 'bg-rose-500 text-white';
//     default:
//       return '';
//   }
// };

// export const useSubData = () => {
//   const [products, setProducts] = useState<Product[]>([]);
//   const [plans, setPlans] = useState<Plan[]>([]);
//   const [trials, setTrials] = useState<Trial[]>([]);
//   const [vouchers, setVouchers] = useState<Voucher[]>([]);
//   const [pendingSubscriptions, setPendingSubscriptions] = useState<SubUser[]>([]);
//   const [algoMatrixSubscriptions, setAlgoMatrixSubscriptions] = useState<AlgoMatrixData[]>([]);
//   const [traderHubSubscriptions, setTraderHubSubscriptions] = useState<TraderHubData[]>([]);
//   const [versionNumber, setVersionNumber] = useState<string>('');
//   const [loading, setLoading] = useState(true);
//   // import hook and context state
//   const { apiUrl } = useEnv();
//   const { user } = useUserDashboard();

//   //------------------------ Product -------------------------//
//   const fetchProducts = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/products`);
//       const data = await response.json();
//       setProducts(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch products', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   //------------------------ Plan -------------------------//
//   const fetchPlan = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/plans`);
//       const data = await response.json();
//       setPlans(data);

//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch plans', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchPlan();
//   }, [fetchPlan]);

//   //------------------------ Trial set -------------------------//
//   const fetchTrial = useCallback(async () => {
//     try {
//       const email = localStorage.getItem('email'); 
//       const response = await fetch(`${apiUrl}/sub/trials?memberEmail=${email}`);
//       const data = await response.json();
//       setTrials(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch trials', error);
//     }
//   }, [apiUrl]);

//   // Function to get trial period by plan ID
//   const getTrialPeriodByPlanId = (planId: string) => {
//     const trial = trials.find(trial => trial.trialLinkPlanId === planId);
//     return trial ? trial.trialPeriod : null;
//   };

//   useEffect(() => {
//     fetchTrial();
//   }, [fetchTrial]);

//   //------------------------ Product -------------------------//
//   const fetchVoucher = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/vouchers`);
//       const data = await response.json();
//       setVouchers(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch voucher', error);
//     }
//   }, [apiUrl]);

//   // Function to get voucher code by plan ID
//   const getVoucherByCodeAndPlanId = (voucherCode: string, planId: string) => {
//     const voucher = vouchers.find(
//       voucher => voucher.voucherCode === voucherCode && voucher.VoucherLinkPlanID === planId
//     );
//     return voucher;
//   };
//   useEffect(() => {
//     fetchVoucher();
//   }, [fetchVoucher]);

//   //------------------------ subscription Register -------------------------//
//   const fetchVersionNumber = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/coretable/version`);
//       const data = await response.json();
//       if (data && data.VersionNumber) {
//         setVersionNumber(data.VersionNumber);
//       }
//     } catch (error) {
//       console.error('Failed to fetch VersionNumber', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchVersionNumber();
//   }, [fetchVersionNumber]);

//   // Define updateExpiredDays before fetchSubscriptions
//   const updateExpiredDays = useCallback(async (subOrderId: string, email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/updateExpiredDays`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ subOrderId, email }),
//       });

//       if (!response.ok) throw new Error("Failed to update subExpiredDays");

//       const result = await response.json();
//       return result.subExpiredDays;
//     } catch (error) {
//       console.error("Error updating subExpiredDays:", error);
//       throw error;
//     }
//   }, [apiUrl]);

//   // Update fetchSubscriptions to reference updateExpiredDays without error
//   const fetchSubscriptions = useCallback(async () => {
//     const email = user?.email;
//     if (!email) return;

//     try {
//       const response = await fetch(`${apiUrl}/sub/subscriptions?email=${email}`);
//       if (!response.ok) throw new Error("Network response was not ok");

//       const data = await response.json();

//       // Store each subscription type in its own state
//       setPendingSubscriptions(data.pendingSubscriptions);
//       setAlgoMatrixSubscriptions(data.algoMatrixSubscriptions);
//       setTraderHubSubscriptions(data.traderHubSubscriptions);

//       // Auto-update subExpiredDays for all fetched subscriptions
//       await Promise.all(
//         [...data.algoMatrixSubscriptions, ...data.traderHubSubscriptions].map(async (sub) => {
//           const updatedDays = await updateExpiredDays(sub.subOrderId, email);
//           sub.subExpiredDays = updatedDays;

//           // Update status if expired
//           if (updatedDays < 0) {
//             sub.subStatus = 'Expire';
//           }
//         })
//       );

//     } catch (error) {
//       console.error('Failed to fetch subscriptions data:', error);
//       setPendingSubscriptions([]);
//       setAlgoMatrixSubscriptions([]);
//       setTraderHubSubscriptions([]);
//     }
//   }, [apiUrl, user?.email, updateExpiredDays]);

//   // UseEffect to fetch subscriptions when component loads
//   useEffect(() => {
//     fetchSubscriptions();
//   }, [fetchSubscriptions]);

//   const registerSubscription = async (subscriptionData: SubUser, paymentReceipt: File | null) => {
//     try {
//       const formData = new FormData();

//       // Append subscription data to FormData
//       Object.entries(subscriptionData).forEach(([key, value]) => {
//         if (value !== undefined && value !== null) {
//           formData.append(key, value.toString());
//         }
//       });

//       // Append file if available
//       if (paymentReceipt) {
//         formData.append("subPaymentReceipts", paymentReceipt);
//       }

//       // Make API call
//       const response = await fetch(`${apiUrl}/sub/register`, {
//         method: "POST",
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error("Failed to register subscription");
//       }

//       return await response.json();
//     } catch (error) {
//       console.error("Error during subscription registration:", error);
//       throw error;
//     }
//   };

//   // Modify the updateDemoAccount function to accept email as an additional parameter
//   const updateDemoAccount = async (
//     subOrderId: string, subAccountNumber: number, subAccountPassword: string, email: string
//   ) => {

//     try {
//       const response = await fetch(`${apiUrl}/sub/updateDemoAccount`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ subOrderId, subAccountNumber, subAccountPassword, email }),
//       });
  
//       if (!response.ok) {
//         console.error("Failed to update demo account, response status:", response.status);
//         throw new Error('Failed to update demo account');
//       }
  
//       const result = await response.json();
  
//       return result;
//     } catch (error) {
//       console.error("Error updating demo account:", error);
//       throw error;
//     }
//   }; 
  
//   // ------------------------------------ handle file download ------------------------------------ //
//   const getDownloadFile = async (type: string, app?: string): Promise<void> => {
//     try {
//       const userEmail = localStorage.getItem('email') || (user && user.email);
  
//       if (!userEmail) {
//         throw new Error('User email is required for download but was not found.');
//       }
  
//       // Add `app` to the query parameters if present
//       const endpoint = `${apiUrl}/download?email=${userEmail}&type=${type}${app ? `&app=${app}` : ''}`;

//       const response = await fetch(endpoint);
  
//       if (!response.ok) {
//         throw new Error(`Failed to fetch download token. Status: ${response.status}`);
//       }
  
//       const { downloadUrl } = await response.json();
  
//       // Fetch and download the file using the download URL
//       const fileResponse = await fetch(`${apiUrl}${downloadUrl}`);
//       if (!fileResponse.ok) {
//         throw new Error('Failed to fetch the file from the server');
//       }
  
//       const blob = await fileResponse.blob();
//       const downloadLink = window.URL.createObjectURL(blob);
  
//       const a = document.createElement('a');
//       a.style.display = 'none';
//       a.href = downloadLink;
//       a.download = type === 'algomatrix' ? 'AlgoMatrix.ex4' : `${app || 'file'}.exe`;
//       document.body.appendChild(a);
//       a.click();
//       window.URL.revokeObjectURL(downloadLink);

//     } catch (error) {
//       console.error('Error during file download:', error);
//       alert('Failed to download the file. Please ensure you are logged in and try again.');
//     }
//   };  

//   return { 
//     products, plans, trials, vouchers, versionNumber, loading,
//     registerSubscription,
//     fetchSubscriptions,
//     updateDemoAccount, 
//     updateExpiredDays,
//     getTrialPeriodByPlanId,
//     getVoucherByCodeAndPlanId,
//     getStatusStyle,
//     getDownloadFile,
//     pendingSubscriptions,
//     algoMatrixSubscriptions,
//     traderHubSubscriptions,
//   };
// };

// // update code --> 23 nov 2024
// // src/hooks/AdminDashboard/useSubData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import useUserDashboard from '../Dashboard/useUserDashboard';  

// export interface Product {
//   id?: string;
//   productId: string;
//   productName: string;
//   productDescription: string;
//   productImg: string | File | null; 
//   productStatus: string;
// }

// export interface Plan {
//   id?: string;
//   planId: string;
//   LinkProductId: string;
//   planName: string;
//   planDescription: string;
//   planFeatures: string | string[];
//   planAmount: number;
//   planCurrency: string;
//   planPeriod: number;
//   planAccount: string;
//   planStatus: string;
// }

// interface Trial {
//   id?: string;
//   trialId: string;
//   trialLinkPlanId: string;
//   trialName: string;
//   trialPeriod: number;
//   trialMembersType: string | null;  
//   trialGroupName: string | null;  
//   trialGroupMember: string | null; 
//   trialStatus: string;
// }

// interface Voucher {
//   id?: string;
//   voucherId: string;
//   VoucherLinkPlanID: string; 
//   voucherName: string; 
//   voucherCode: string; 
//   voucherType: string;    
//   voucherAmount?: number;     
//   voucherPercentage?: number; 
//   voucherPeriod: Date;
//   voucherDays: number;
//   voucherRedeemCount: number;
//   voucherMemberType: string;
//   voucherGroupName: string;
//   voucherGroupMember: string;
//   voucherStatus: string;
// }

// export interface SubUser {
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// export interface AlgoMatrixData{
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// export interface TraderHubData{
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// const getStatusStyle = (status: string) => {
//   switch (status) {
//     case 'Active':
//       return 'bg-green-600 text-white';
//     case 'Pending':
//       return 'bg-yellow-300 text-black'
//     case 'Expire':
//       return 'bg-rose-500 text-white';
//     default:
//       return '';
//   }
// };

// export const useSubData = () => {
//   const [products, setProducts] = useState<Product[]>([]);
//   const [plans, setPlans] = useState<Plan[]>([]);
//   const [trials, setTrials] = useState<Trial[]>([]);
//   const [vouchers, setVouchers] = useState<Voucher[]>([]);
//   const [pendingSubscriptions, setPendingSubscriptions] = useState<SubUser[]>([]);
//   const [algoMatrixSubscriptions, setAlgoMatrixSubscriptions] = useState<AlgoMatrixData[]>([]);
//   const [traderHubSubscriptions, setTraderHubSubscriptions] = useState<TraderHubData[]>([]);
//   const [versionNumber, setVersionNumber] = useState<string>('');
//   const [loading, setLoading] = useState(true);
//   // import hook and context state
//   const { apiUrl } = useEnv();
//   const { user } = useUserDashboard();

//   //------------------------ Product -------------------------//
//   const fetchProducts = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/products`);
//       const data = await response.json();
//       setProducts(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch products', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   //------------------------ Plan -------------------------//
//   const fetchPlan = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/plans`);
//       const data = await response.json();
//       setPlans(data);

//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch plans', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchPlan();
//   }, [fetchPlan]);

//   //------------------------ Trial set -------------------------//
//   const fetchTrial = useCallback(async () => {
//     try {
//       const email = localStorage.getItem('email'); 
//       const response = await fetch(`${apiUrl}/sub/trials?memberEmail=${email}`);
//       const data = await response.json();
//       setTrials(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch trials', error);
//     }
//   }, [apiUrl]);

//   // Function to get trial period by plan ID
//   const getTrialPeriodByPlanId = (planId: string) => {
//     const trial = trials.find(trial => trial.trialLinkPlanId === planId);
//     return trial ? trial.trialPeriod : null;
//   };

//   useEffect(() => {
//     fetchTrial();
//   }, [fetchTrial]);

//   //------------------------ Product -------------------------//
//   const fetchVoucher = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/vouchers`);
//       const data = await response.json();
//       setVouchers(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch voucher', error);
//     }
//   }, [apiUrl]);

//   // Function to get voucher code by plan ID
//   const getVoucherByCodeAndPlanId = (voucherCode: string, planId: string) => {
//     const voucher = vouchers.find(
//       voucher => voucher.voucherCode === voucherCode && voucher.VoucherLinkPlanID === planId
//     );
//     return voucher;
//   };
//   useEffect(() => {
//     fetchVoucher();
//   }, [fetchVoucher]);

//   //------------------------ subscription Register -------------------------//
//   const fetchVersionNumber = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/coretable/version`);
//       const data = await response.json();
//       if (data && data.VersionNumber) {
//         setVersionNumber(data.VersionNumber);
//       }
//     } catch (error) {
//       console.error('Failed to fetch VersionNumber', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchVersionNumber();
//   }, [fetchVersionNumber]);

//   // Define updateExpiredDays before fetchSubscriptions
//   const updateExpiredDays = useCallback(async (subOrderId: string, email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/updateExpiredDays`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ subOrderId, email }),
//       });

//       if (!response.ok) throw new Error("Failed to update subExpiredDays");

//       const result = await response.json();
//       return result.subExpiredDays;
//     } catch (error) {
//       console.error("Error updating subExpiredDays:", error);
//       throw error;
//     }
//   }, [apiUrl]);

//   // Update fetchSubscriptions to reference updateExpiredDays without error
//   const fetchSubscriptions = useCallback(async () => {
//     const email = user?.email;
//     if (!email) return;

//     try {
//       const response = await fetch(`${apiUrl}/sub/subscriptions?email=${email}`);
//       if (!response.ok) throw new Error("Network response was not ok");

//       const data = await response.json();

//       // Store each subscription type in its own state
//       setPendingSubscriptions(data.pendingSubscriptions);
//       setAlgoMatrixSubscriptions(data.algoMatrixSubscriptions);
//       setTraderHubSubscriptions(data.traderHubSubscriptions);

//       // Auto-update subExpiredDays for all fetched subscriptions
//       await Promise.all(
//         [...data.algoMatrixSubscriptions, ...data.traderHubSubscriptions].map(async (sub) => {
//           const updatedDays = await updateExpiredDays(sub.subOrderId, email);
//           sub.subExpiredDays = updatedDays;

//           // Update status if expired
//           if (updatedDays < 0) {
//             sub.subStatus = 'Expire';
//           }
//         })
//       );

//     } catch (error) {
//       console.error('Failed to fetch subscriptions data:', error);
//       setPendingSubscriptions([]);
//       setAlgoMatrixSubscriptions([]);
//       setTraderHubSubscriptions([]);
//     }
//   }, [apiUrl, user?.email, updateExpiredDays]);

//   // UseEffect to fetch subscriptions when component loads
//   useEffect(() => {
//     fetchSubscriptions();
//   }, [fetchSubscriptions]);

//   const registerSubscription = async (subscriptionData: SubUser) => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/register`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(subscriptionData),
//       });
//       return response.json();
//     } catch (error) {
//       console.error('Failed to register subscription:', error);
//       throw error;
//     }
//   };

//   // Modify the updateDemoAccount function to accept email as an additional parameter
//   const updateDemoAccount = async (
//     subOrderId: string, subAccountNumber: number, subAccountPassword: string, email: string
//   ) => {

//     try {
//       const response = await fetch(`${apiUrl}/sub/updateDemoAccount`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ subOrderId, subAccountNumber, subAccountPassword, email }),
//       });
  
//       if (!response.ok) {
//         console.error("Failed to update demo account, response status:", response.status);
//         throw new Error('Failed to update demo account');
//       }
  
//       const result = await response.json();
  
//       return result;
//     } catch (error) {
//       console.error("Error updating demo account:", error);
//       throw error;
//     }
//   };  

//   return { 
//     products, plans, trials, vouchers, versionNumber, loading,
//     registerSubscription,
//     fetchSubscriptions,
//     updateDemoAccount, 
//     updateExpiredDays,
//     getTrialPeriodByPlanId,
//     getVoucherByCodeAndPlanId,
//     getStatusStyle,
//     pendingSubscriptions,
//     algoMatrixSubscriptions,
//     traderHubSubscriptions,
//   };
// };

// // udpatre code --> 19 nov 2024
// // src/hooks/AdminDashboard/useSubData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import useUserDashboard from '../Dashboard/useUserDashboard';  

// export interface Product {
//   id?: string;
//   productId: string;
//   productName: string;
//   productDescription: string;
//   productImg: string | File | null; 
//   productStatus: string;
// }

// export interface Plan {
//   id?: string;
//   planId: string;
//   LinkProductId: string;
//   planName: string;
//   planDescription: string;
//   planFeatures: string | string[];
//   planAmount: number;
//   planCurrency: string;
//   planPeriod: number;
//   planAccount: string;
//   planStatus: string;
// }

// interface Trial {
//   id?: string;
//   trialId: string;
//   trialLinkPlanId: string;
//   trialName: string;
//   trialPeriod: number;
//   trialMembersType: string | null;  
//   trialGroupName: string | null;  
//   trialGroupMember: string | null; 
//   trialStatus: string;
// }

// interface Voucher {
//   id?: string;
//   voucherId: string;
//   VoucherLinkPlanID: string; 
//   voucherName: string; 
//   voucherCode: string; 
//   voucherType: string;    
//   voucherAmount?: number;     
//   voucherPercentage?: number; 
//   voucherPeriod: Date;
//   voucherDays: number;
//   voucherRedeemCount: number;
//   voucherMemberType: string;
//   voucherGroupName: string;
//   voucherGroupMember: string;
//   voucherStatus: string;
// }

// export interface SubUser {
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// export interface AlgoMatrixData{
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// export interface TraderHubData{
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// const getStatusStyle = (status: string) => {
//   switch (status) {
//     case 'Active':
//       return 'bg-green-600 text-white';
//     case 'Pending':
//       return 'bg-yellow-300 text-black'
//     case 'Expire':
//       return 'bg-rose-500 text-white';
//     default:
//       return '';
//   }
// };

// export const useSubData = () => {
//   const [products, setProducts] = useState<Product[]>([]);
//   const [plans, setPlans] = useState<Plan[]>([]);
//   const [trials, setTrials] = useState<Trial[]>([]);
//   const [vouchers, setVouchers] = useState<Voucher[]>([]);
//   const [pendingSubscriptions, setPendingSubscriptions] = useState<SubUser[]>([]);
//   const [algoMatrixSubscriptions, setAlgoMatrixSubscriptions] = useState<AlgoMatrixData[]>([]);
//   const [traderHubSubscriptions, setTraderHubSubscriptions] = useState<TraderHubData[]>([]);
//   const [versionNumber, setVersionNumber] = useState<string>('');
//   const [loading, setLoading] = useState(true);
//   // import hook and context state
//   const { apiUrl } = useEnv();
//   const { user } = useUserDashboard();

//   //------------------------ Product -------------------------//
//   const fetchProducts = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/products`);
//       const data = await response.json();
//       setProducts(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch products', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   //------------------------ Plan -------------------------//
//   const fetchPlan = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/plans`);
//       const data = await response.json();
//       setPlans(data);

//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch plans', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchPlan();
//   }, [fetchPlan]);

//   //------------------------ Trial set -------------------------//
//   const fetchTrial = useCallback(async () => {
//     try {
//       const email = localStorage.getItem('email'); 
//       const response = await fetch(`${apiUrl}/sub/trials?memberEmail=${email}`);
//       const data = await response.json();
//       setTrials(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch trials', error);
//     }
//   }, [apiUrl]);

//   // Function to get trial period by plan ID
//   const getTrialPeriodByPlanId = (planId: string) => {
//     const trial = trials.find(trial => trial.trialLinkPlanId === planId);
//     return trial ? trial.trialPeriod : null;
//   };

//   useEffect(() => {
//     fetchTrial();
//   }, [fetchTrial]);

//   //------------------------ Product -------------------------//
//   const fetchVoucher = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/vouchers`);
//       const data = await response.json();
//       setVouchers(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch voucher', error);
//     }
//   }, [apiUrl]);

//   // Function to get voucher code by plan ID
//   const getVoucherByCodeAndPlanId = (voucherCode: string, planId: string) => {
//     const voucher = vouchers.find(
//       voucher => voucher.voucherCode === voucherCode && voucher.VoucherLinkPlanID === planId
//     );
//     return voucher;
//   };
//   useEffect(() => {
//     fetchVoucher();
//   }, [fetchVoucher]);

//   //------------------------ subscription Register -------------------------//
//   const fetchVersionNumber = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/coretable/version`);
//       const data = await response.json();
//       if (data && data.VersionNumber) {
//         setVersionNumber(data.VersionNumber);
//       }
//     } catch (error) {
//       console.error('Failed to fetch VersionNumber', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchVersionNumber();
//   }, [fetchVersionNumber]);

//   // Define updateExpiredDays before fetchSubscriptions
//   const updateExpiredDays = useCallback(async (subOrderId: string, email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/updateExpiredDays`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ subOrderId, email }),
//       });

//       if (!response.ok) throw new Error("Failed to update subExpiredDays");

//       const result = await response.json();
//       return result.subExpiredDays;
//     } catch (error) {
//       console.error("Error updating subExpiredDays:", error);
//       throw error;
//     }
//   }, [apiUrl]);

//   // Update fetchSubscriptions to reference updateExpiredDays without error
//   const fetchSubscriptions = useCallback(async () => {
//     const email = user?.email;
//     if (!email) return;

//     try {
//       const response = await fetch(`${apiUrl}/sub/subscriptions?email=${email}`);
//       if (!response.ok) throw new Error("Network response was not ok");

//       const data = await response.json();

//       // Store each subscription type in its own state
//       setPendingSubscriptions(data.pendingSubscriptions);
//       setAlgoMatrixSubscriptions(data.algoMatrixSubscriptions);
//       setTraderHubSubscriptions(data.traderHubSubscriptions);

//       // Auto-update subExpiredDays for all fetched subscriptions
//       await Promise.all(
//         [...data.algoMatrixSubscriptions, ...data.traderHubSubscriptions].map(async (sub) => {
//           const updatedDays = await updateExpiredDays(sub.subOrderId, email);
//           sub.subExpiredDays = updatedDays;

//           // Update status if expired
//           if (updatedDays < 0) {
//             sub.subStatus = 'Expire';
//           }
//         })
//       );

//     } catch (error) {
//       console.error('Failed to fetch subscriptions data:', error);
//       setPendingSubscriptions([]);
//       setAlgoMatrixSubscriptions([]);
//       setTraderHubSubscriptions([]);
//     }
//   }, [apiUrl, user?.email, updateExpiredDays]);

//   // UseEffect to fetch subscriptions when component loads
//   useEffect(() => {
//     fetchSubscriptions();
//   }, [fetchSubscriptions]);

//   const registerSubscription = async (subscriptionData: SubUser) => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/register`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(subscriptionData),
//       });
//       return response.json();
//     } catch (error) {
//       console.error('Failed to register subscription:', error);
//       throw error;
//     }
//   };

//   // Modify the updateDemoAccount function to accept email as an additional parameter
//   const updateDemoAccount = async (
//     subOrderId: string, subAccountNumber: number, subAccountPassword: string, email: string
//   ) => {

//     try {
//       const response = await fetch(`${apiUrl}/sub/updateDemoAccount`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ subOrderId, subAccountNumber, subAccountPassword, email }),
//       });
  
//       if (!response.ok) {
//         console.error("Failed to update demo account, response status:", response.status);
//         throw new Error('Failed to update demo account');
//       }
  
//       const result = await response.json();
  
//       return result;
//     } catch (error) {
//       console.error("Error updating demo account:", error);
//       throw error;
//     }
//   };  

//   return { 
//     products, plans, trials, vouchers, versionNumber, loading,
//     registerSubscription,
//     fetchSubscriptions,
//     updateDemoAccount, 
//     updateExpiredDays,
//     getTrialPeriodByPlanId,
//     getVoucherByCodeAndPlanId,
//     getStatusStyle,
//     pendingSubscriptions,
//     algoMatrixSubscriptions,
//     traderHubSubscriptions,
//   };
// };

// // update code --> 18 nov 2024
// // src/hooks/AdminDashboard/useSubData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import useUserDashboard from '../Dashboard/useUserDashboard';  

// export interface Product {
//   id?: string;
//   productId: string;
//   productName: string;
//   productDescription: string;
//   productImg: string | File | null; 
//   productStatus: string;
// }

// export interface Plan {
//   id?: string;
//   planId: string;
//   LinkProductId: string;
//   planName: string;
//   planDescription: string;
//   planFeatures: string | string[];
//   planAmount: number;
//   planCurrency: string;
//   planPeriod: number;
//   planAccount: string;
//   planStatus: string;
// }

// interface Trial {
//   id?: string;
//   trialId: string;
//   trialLinkPlanId: string;
//   trialName: string;
//   trialPeriod: number;
//   trialMembersType: string | null;  
//   trialGroupName: string | null;  
//   trialGroupMember: string | null; 
//   trialStatus: string;
// }

// interface Voucher {
//   id?: string;
//   voucherId: string;
//   VoucherLinkPlanID: string; 
//   voucherName: string; 
//   voucherCode: string; 
//   voucherType: string;    
//   voucherAmount?: number;     
//   voucherPercentage?: number; 
//   voucherPeriod: Date;
//   voucherDays: number;
//   voucherRedeemCount: number;
//   voucherMemberType: string;
//   voucherGroupName: string;
//   voucherGroupMember: string;
//   voucherStatus: string;
// }

// export interface SubUser {
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// export interface AlgoMatrixData{
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// export interface TraderHubData{
//   id?: string;
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: number;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType?: string;
//   subAccountServer?: string;
//   subAccountNumber?: string;
//   subAccountPassword?: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subDemoCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// const getStatusStyle = (status: string) => {
//   switch (status) {
//     case 'Active':
//       return 'bg-green-600 text-white';
//     case 'Pending':
//       return 'bg-yellow-300 text-black'
//     case 'Expire':
//       return 'bg-rose-500 text-white';
//     default:
//       return '';
//   }
// };

// export const useSubData = () => {
//   const [products, setProducts] = useState<Product[]>([]);
//   const [plans, setPlans] = useState<Plan[]>([]);
//   const [trials, setTrials] = useState<Trial[]>([]);
//   const [vouchers, setVouchers] = useState<Voucher[]>([]);
//   const [pendingSubscriptions, setPendingSubscriptions] = useState<SubUser[]>([]);
//   const [algoMatrixSubscriptions, setAlgoMatrixSubscriptions] = useState<AlgoMatrixData[]>([]);
//   const [traderHubSubscriptions, setTraderHubSubscriptions] = useState<TraderHubData[]>([]);
//   const [versionNumber, setVersionNumber] = useState<string>('');
//   const [loading, setLoading] = useState(true);
//   // import hook and context state
//   const { apiUrl } = useEnv();
//   const { user } = useUserDashboard();

//   //------------------------ Product -------------------------//
//   const fetchProducts = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/products`);
//       const data = await response.json();
//       setProducts(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch products', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   //------------------------ Plan -------------------------//
//   const fetchPlan = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/plans`);
//       const data = await response.json();
//       setPlans(data);

//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch plans', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchPlan();
//   }, [fetchPlan]);

//   //------------------------ Trial set -------------------------//
//   const fetchTrial = useCallback(async () => {
//     try {
//       const email = localStorage.getItem('email'); 
//       const response = await fetch(`${apiUrl}/sub/trials?memberEmail=${email}`);
//       const data = await response.json();
//       setTrials(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch trials', error);
//     }
//   }, [apiUrl]);

//   // Function to get trial period by plan ID
//   const getTrialPeriodByPlanId = (planId: string) => {
//     const trial = trials.find(trial => trial.trialLinkPlanId === planId);
//     return trial ? trial.trialPeriod : null;
//   };

//   useEffect(() => {
//     fetchTrial();
//   }, [fetchTrial]);

//   //------------------------ Product -------------------------//
//   const fetchVoucher = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/vouchers`);
//       const data = await response.json();
//       setVouchers(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch voucher', error);
//     }
//   }, [apiUrl]);

//   // Function to get voucher code by plan ID
//   const getVoucherByCodeAndPlanId = (voucherCode: string, planId: string) => {
//     const voucher = vouchers.find(
//       voucher => voucher.voucherCode === voucherCode && voucher.VoucherLinkPlanID === planId
//     );
//     return voucher;
//   };
//   useEffect(() => {
//     fetchVoucher();
//   }, [fetchVoucher]);

//   //------------------------ subscription Register -------------------------//
//   const fetchVersionNumber = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/coretable/version`);
//       const data = await response.json();
//       if (data && data.VersionNumber) {
//         setVersionNumber(data.VersionNumber);
//       }
//     } catch (error) {
//       console.error('Failed to fetch VersionNumber', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchVersionNumber();
//   }, [fetchVersionNumber]);

//   // Define updateExpiredDays before fetchSubscriptions
//   const updateExpiredDays = useCallback(async (subOrderId: string, email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/updateExpiredDays`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ subOrderId, email }),
//       });

//       if (!response.ok) throw new Error("Failed to update subExpiredDays");

//       const result = await response.json();
//       return result.subExpiredDays;
//     } catch (error) {
//       console.error("Error updating subExpiredDays:", error);
//       throw error;
//     }
//   }, [apiUrl]);

//   // Update fetchSubscriptions to reference updateExpiredDays without error
//   const fetchSubscriptions = useCallback(async () => {
//     const email = user?.email;
//     if (!email) return;

//     try {
//       const response = await fetch(`${apiUrl}/sub/subscriptions?email=${email}`);
//       if (!response.ok) throw new Error("Network response was not ok");

//       const data = await response.json();

//       // Store each subscription type in its own state
//       setPendingSubscriptions(data.pendingSubscriptions);
//       setAlgoMatrixSubscriptions(data.algoMatrixSubscriptions);
//       setTraderHubSubscriptions(data.traderHubSubscriptions);

//       // Auto-update subExpiredDays for all fetched subscriptions
//       await Promise.all(
//         [...data.algoMatrixSubscriptions, ...data.traderHubSubscriptions].map(async (sub) => {
//           const updatedDays = await updateExpiredDays(sub.subOrderId, email);
//           sub.subExpiredDays = updatedDays;

//           // Update status if expired
//           if (updatedDays < 0) {
//             sub.subStatus = 'Expire';
//           }
//         })
//       );

//     } catch (error) {
//       console.error('Failed to fetch subscriptions data:', error);
//       setPendingSubscriptions([]);
//       setAlgoMatrixSubscriptions([]);
//       setTraderHubSubscriptions([]);
//     }
//   }, [apiUrl, user?.email, updateExpiredDays]);

//   // UseEffect to fetch subscriptions when component loads
//   useEffect(() => {
//     fetchSubscriptions();
//   }, [fetchSubscriptions]);

//   const registerSubscription = async (subscriptionData: SubUser) => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/register`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(subscriptionData),
//       });
//       return response.json();
//     } catch (error) {
//       console.error('Failed to register subscription:', error);
//       throw error;
//     }
//   };

//   const renewSubscription = async (renewalData: any) => {
//     try {
//       const response = await fetch(`${apiUrl}/sub/renew`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(renewalData),
//       });
  
//       if (!response.ok) {
//         throw new Error("Failed to renew subscription");
//       }
  
//       const result = await response.json();
//       return result;
//     } catch (error) {
//       console.error("Error renewing subscription:", error);
//       throw error;
//     }
//   }; 

//   // Modify the updateDemoAccount function to accept email as an additional parameter
//   const updateDemoAccount = async (
//     subOrderId: string, subAccountNumber: number, subAccountPassword: string, email: string
//   ) => {

//     try {
//       const response = await fetch(`${apiUrl}/sub/updateDemoAccount`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ subOrderId, subAccountNumber, subAccountPassword, email }),
//       });
  
//       if (!response.ok) {
//         console.error("Failed to update demo account, response status:", response.status);
//         throw new Error('Failed to update demo account');
//       }
  
//       const result = await response.json();
  
//       return result;
//     } catch (error) {
//       console.error("Error updating demo account:", error);
//       throw error;
//     }
//   };  

//   return { 
//     products, plans, trials, vouchers, versionNumber, loading,
//     renewSubscription,
//     registerSubscription,
//     fetchSubscriptions,
//     updateDemoAccount, 
//     updateExpiredDays,
//     getTrialPeriodByPlanId,
//     getVoucherByCodeAndPlanId,
//     getStatusStyle,
//     pendingSubscriptions,
//     algoMatrixSubscriptions,
//     traderHubSubscriptions,
//   };
// };