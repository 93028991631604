// src/components/client/MiniApp/App03_MySubPlan.tsx

import React from 'react';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { FaSquareCheck } from "react-icons/fa6";
import { useSubData, Product, Plan } from '../../../hooks/Subscription/useSubData';

interface SubscriptionPlanProps {
  selectedProduct: Product;
  onSelectPlan: (plan: Plan) => void; 
  onBackToProducts: () => void;
}

const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({ selectedProduct, onSelectPlan, onBackToProducts }) => {
  const { plans, loading, getTrialPeriodByPlanId } = useSubData();

  // Filter plans based on the selected product's productId
  const filteredPlans = plans
    .filter(plan => plan.LinkProductId === selectedProduct.productId && plan.planStatus === "Active");

  if (loading) {
    return <p>Loading plans...</p>;
  }

  return (
    <>
      {filteredPlans && filteredPlans.length > 0 ? (
        <div className="flex flex-wrap flex-row-reverse justify-center gap-4 mt-4 
              sm:flex-col sm:mt-0 
              xs:flex-col xs:mt-0"
        >
          {filteredPlans.map((plan, index) => {
            const trialPeriod = getTrialPeriodByPlanId(plan.planId); 

            return (
              <div
                key={index}
                className="flex-1 max-w-xs text-center p-4 border rounded shadow-sm flex flex-col justify-between py-4"
              >
                <div>
                  <h4 className="font-semibold 
                        sm:text-xl 
                        xs:text-xl"
                  >
                    {plan.planName}
                  </h4>
                  <p className="text-muted-foreground my-2 
                      sm:text-sm 
                      xs:text-xs"
                  >
                    {plan.planDescription}
                  </p>
                  <div className="flex justify-center items-center m-4">
                    <ul className="list-none pl-0 text-left 
                          sm:text-sm 
                          xs:text-xs"
                    >
                      {(typeof plan.planFeatures === 'string' ? plan.planFeatures.split(',') : plan.planFeatures).map(
                        (planFeature: string, i: number) => (
                          <li key={i} className="flex items-start mb-1">
                            <span className="mr-2 mt-1"><FaSquareCheck /></span>
                            <span>{planFeature}</span>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div>
                  <p className="flex justify-center items-center text-sm mt-4">
                    Trial info
                    <div className="relative group">
                      <BsExclamationCircleFill
                        className="w-4 h-4 ml-2 text-cyan-800 hover:text-gray-900 cursor-pointer
                          sm:w-3 
                          xs:w-3"
                        aria-hidden="true"
                      />
                      <div className="absolute top-full mt-2 hidden z-150 w-[160px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block bg-cyan-800 text-white border-2 border-white
                            sm:text-xs" 
                      >
                        {trialPeriod ? 
                          `Trial period ${trialPeriod} days with criteria` : 
                          'No trial available.'
                        }
                      </div>
                    </div>
                  </p>
                  <p className="font-semibold mt-0">
                  {`${plan.planAmount} ${plan.planCurrency} / ${
                    plan.planPeriod === 365 ? 'Annually' : plan.planPeriod === 30 ? 'Monthly' : `${plan.planPeriod} Days`
                  }`}
                  </p>
                </div>
                <div className="text-center mt-2">
                  <span
                    className="px-4 py-2 bg-cyan-800 text-white hover:bg-cyan-900 rounded cursor-pointer 
                      sm:text-sm 
                      xs:text-sm"
                    onClick={() => onSelectPlan(plan)}
                  >
                    Subscribe
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-center items-center p-4 border-dashed border-4 border-gray-300 rounded-lg">
          <p className="text-muted-foreground text-center 
              sm:text-sm 
              xs:text-sm"
          >
            No plans available for this product.
          </p>
        </div>
      )}
      <div className="flex justify-center items-center">
        <button
          className="flex justify-between items-center px-4 py-2 bg-gray-200 text-gray-800 rounded
            sm:text-xs
            xs:text-xs"
          onClick={onBackToProducts}
        >
          <FaArrowCircleLeft className="mr-2" />
          Back to Products
        </button>
      </div>
    </>
  );
};

export default SubscriptionPlan;



// // udpate code --> 05 nov 2024 
// // src/components/client/MiniApp/App03_MySubPlan.tsx

// import React from 'react';
// import { BsExclamationCircleFill } from 'react-icons/bs';
// import { FaArrowCircleLeft } from 'react-icons/fa';
// import { FaSquareCheck } from "react-icons/fa6";
// import { useSubData, Product, Plan } from '../../../hooks/Subscription/useSubData';

// interface SubscriptionPlanProps {
//   selectedProduct: Product;
//   onSelectPlan: (plan: Plan) => void; 
//   onBackToProducts: () => void;
// }

// const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({ selectedProduct, onSelectPlan , onBackToProducts }) => {
//   const { plans, loading } = useSubData();

//   // Filter plans based on the selected product's productId
//   const filteredPlans = plans.filter(plan => plan.LinkProductId === selectedProduct.productId);

//   if (loading) {
//     return <p>Loading plans...</p>;
//   }

//   return (
//     <>
//       {filteredPlans && filteredPlans.length > 0 ? (
//         <div className="flex flex-wrap justify-center gap-4 mt-4 sm:flex-col xs:flex-col sm:mt-0 xs:mt-0">
//           {filteredPlans.map((plan, index) => (
//             <div
//               key={index}
//               className="flex-1 max-w-xs text-center p-4 border rounded shadow-sm flex flex-col justify-between py-4">
//               <div>
//                 <h4 className="font-semibold sm:text-lg xs:text-lg">{plan.planName}</h4>
//                 <p className="text-muted-foreground my-2 sm:text-sm xs:text-xs">{plan.planDescription}</p>
//                 <div className="flex justify-center items-center m-4">
//                   <ul className="list-none pl-0 text-left sm:text-sm xs:text-xs">
//                     {(typeof plan.planFeatures === 'string' ? plan.planFeatures.split(',') : plan.planFeatures).map(
//                       (planFeature: string, i: number) => (
//                         <li key={i} className="flex items-start mb-1">
//                           <span className="mr-2 mt-1"><FaSquareCheck /></span>
//                           <span>{planFeature}</span>
//                         </li>
//                       )
//                     )}
//                   </ul>
//                 </div>
//               </div>
//               <div>
//                 <p className="flex justify-center items-center text-sm mt-4">
//                   Trial info
//                   <div className="relative group">
//                     <BsExclamationCircleFill
//                       className="w-4 h-4 ml-2 sm:w-3 xs:w-3 text-cyan-800 hover:text-gray-900 cursor-pointer"
//                       aria-hidden="true"
//                     />
//                     <div className="absolute top-full mt-2 hidden z-150 w-[160px] px-4 py-2 text-sm sm:text-xs rounded-lg shadow-lg group-hover:block bg-cyan-800 text-white border-2 border-white">
//                       Trial information goes here.
//                     </div>
//                   </div>
//                 </p>
//                 <p className="font-semibold mt-0">
//                   {`${plan.planAmount} ${plan.planCurrency} / ${plan.planPeriod}`}
//                 </p>
//               </div>
//               <div className="text-center mt-2">
//                 <span
//                   className="px-4 py-2 bg-cyan-800 text-white hover:bg-cyan-900 rounded cursor-pointer sm:text-sm xs:text-sm"
//                   onClick={() => onSelectPlan(plan)}
//                 >
//                   Subscribe
//                 </span>
//               </div>
//             </div>
//           ))}
//         </div>
//       ) : (
//         <div className="flex justify-center items-center p-4 border-dashed border-4 border-gray-300 rounded-lg">
//           <p className="text-muted-foreground text-center sm:text-sm xs:text-sm">No plans available for this product.</p>
//         </div>
//       )}
//       <div className="flex justify-center items-center py-4">
//         <button
//           className="flex justify-between items-center px-4 py-2 bg-gray-200 text-gray-800 rounded"
//           onClick={onBackToProducts}
//         >
//           <FaArrowCircleLeft className="mr-2" />
//             Back to Products
//         </button>
//       </div>
//     </>
//   );
// };

// export default SubscriptionPlan;
