// src/hooks/useFetchLogs.tsx

import { useState, useEffect } from 'react';
import { useEnv } from '../../context/EnvContext';

interface FetchLogsParams {
  broker: string;
  accountNumber: string;
  startDate: string;
  endDate: string;
  keywords: string[];
}

interface LogEntry {
  fileName: string;
  content: string;
}

export const useFetchLogs = () => {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [brokers, setBrokers] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { apiUrl } = useEnv();

  useEffect(() => {
    const fetchBrokers = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${apiUrl}/api/logs`);
        // console.log('Raw response:', response);
        if (!response.ok) {
          throw new Error(`Failed to fetch brokers: ${response.statusText}`);
        }
        const data = await response.json();
        // console.log('Parsed brokers:', data);
        setBrokers(data.brokers);
      } catch (err) {
        console.error('[ERROR] Fetching brokers:', err);
        setError((err as Error).message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBrokers();
  }, [apiUrl]);

  const fetchLogs = async (params: FetchLogsParams) => {
    try {
        setIsLoading(true);
        setError(null);

        const response = await fetch(`${apiUrl}/api/logs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch logs: ${response.statusText}`);
        }

        const data = await response.json();
        // console.log('Logs fetched:', data);
        setLogs(data.logs);
    } catch (err) {
        console.error('[ERROR] Fetching logs:', err);
        setError((err as Error).message);
    } finally {
        setIsLoading(false);
    }
  };

  return { logs, brokers, isLoading, error, fetchLogs };
};


// // udpatae code --> 12 dec 2042
// // src/hooks/useFetchLogs.tsx

// import { useState, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface FetchLogsParams {
//   broker: string;
//   accountNumber: string;
//   startDate: string;
//   endDate: string;
//   keywords: string[];
// }

// interface LogEntry {
//   fileName: string;
//   content: string;
// }

// export const useFetchLogs = () => {
//   const [logs, setLogs] = useState<LogEntry[]>([]);
//   const [brokers, setBrokers] = useState<string[]>([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const { apiUrl } = useEnv();

//   useEffect(() => {
//     const fetchBrokers = async () => {
//       try {
//         setIsLoading(true);
//         const response = await fetch(`${apiUrl}/api/logs`);
//         console.log('Raw response:', response);
//         if (!response.ok) {
//           throw new Error(`Failed to fetch brokers: ${response.statusText}`);
//         }
//         const data = await response.json();
//         console.log('Parsed brokers:', data);
//         setBrokers(data.brokers);
//       } catch (err) {
//         console.error('[ERROR] Fetching brokers:', err);
//         setError((err as Error).message);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchBrokers();
//   }, [apiUrl]);

//   const fetchLogs = async (params: FetchLogsParams) => {
//     try {
//       setIsLoading(true);
//       setError(null);

//       const response = await fetch(`${apiUrl}/api/logs`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(params),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch logs: ${response.statusText}`);
//       }

//       const data = await response.json();
//       console.log('Logs fetched:', data);
//       setLogs(data.logs);
//     } catch (err) {
//       console.error('[ERROR] Fetching logs:', err);
//       setError((err as Error).message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return { logs, brokers, isLoading, error, fetchLogs };
// };
