// src/components/admin/SubProductSetting/AddSubUserModal.tsx

import React, { useState, useEffect } from 'react';
import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';
import { useSubUserData, SubPending } from '../../../hooks/AdminDashboard/useSubUserData';

interface AddSubUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  newData: { [key: string]: any };
  handleNewInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  isPendingTable: boolean;
}

const AddSubUserModal: React.FC<AddSubUserModalProps> = ({
  isOpen,
  onClose,
  onSave,
  newData,
  handleNewInputChange,
  isPendingTable,
}) => {
  // import custom hook
  const { products, plans } = useSubSetting();
  const { addSubPending, addSubProduct } = useSubUserData();
  // custom state
  const [expireDays, setExpireDays] = useState<number>(0);
  const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null);
  const [receiptPreview, setReceiptPreview] = useState<string | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [generatedOrderId, setGeneratedOrderId] = useState<string | null>(null);
  const [isOrderIdGenerated, setIsOrderIdGenerated] = useState<boolean>(false);
  // enable/disable section field
  const [isAccountFieldsEnabled, setIsAccountFieldsEnabled] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); 

  // Function to generate order ID based on product name
  const generateOrderId = (productName: string) => {
    const prefix = productName.replace(/\s+/g, '-').substring(0, 3).toUpperCase();
    const randomLetters = Math.random().toString(36).substring(2, 6).toUpperCase();
    const randomDigits = String(Math.floor(1000 + Math.random() * 9000));
    return `sub-${prefix}-${randomLetters}${randomDigits}`;
  };

  useEffect(() => {
    if (selectedProduct && !isOrderIdGenerated) {
      const product = products.find((p) => p.productId === selectedProduct);
      if (product) {
        const orderId = generateOrderId(product.productName);
        setGeneratedOrderId(orderId);
        setIsOrderIdGenerated(true);

        handleNewInputChange({
          target: { name: 'subOrderId', value: orderId },
        } as React.ChangeEvent<HTMLInputElement>);
      }
    }
  }, [selectedProduct, products, isOrderIdGenerated, handleNewInputChange]);

  useEffect(() => {
    if (newData.subExpiredDate) {
      const today = new Date();
      const expireDate = new Date(newData.subExpiredDate);
      const differenceInTime = expireDate.getTime() - today.getTime();
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
      setExpireDays(differenceInDays);
    }
  }, [newData.subExpiredDate]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setPaymentReceipt(file);
      setReceiptPreview(URL.createObjectURL(file));
    }
  };

  const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPlanId = e.target.value;
    setSelectedPlan(selectedPlanId);

    const plan = plans.find((p) => p.planId === selectedPlanId);
    if (plan) {
      handleNewInputChange({
        target: { name: 'subPlanId', value: selectedPlanId },
      } as React.ChangeEvent<HTMLInputElement>);
      handleNewInputChange({
        target: { name: 'subPlanPeriod', value: plan.planPeriod.toString() },
      } as React.ChangeEvent<HTMLInputElement>);
      handleNewInputChange({
        target: { name: 'subPlanAmount', value: plan.planAmount.toString() },
      } as React.ChangeEvent<HTMLInputElement>);
      setIsAccountFieldsEnabled(plan.planAccount !== 'Disable');
    }
  };

  const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProduct(e.target.value);
    setSelectedPlan(null);
    setIsOrderIdGenerated(false);
    handleNewInputChange({
      target: { name: 'subProductId', value: e.target.value },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const resetState = () => {
    setExpireDays(0);
    setPaymentReceipt(null);
    setReceiptPreview(null);
    setSelectedProduct(null);
    setSelectedPlan(null);
    setGeneratedOrderId(null);
    setIsOrderIdGenerated(false);
    setIsAccountFieldsEnabled(true);
    setIsSubmitting(false);
  };

  const handleAdd = async () => {
    if (isSubmitting) return; 
    setIsSubmitting(true);
  
    try {
      if (!selectedProduct || !selectedPlan || !newData.subUserName || !newData.subUserEmail) {
        alert("Please fill in all required fields.");
        return;
      }
  
      // Handle Order ID generation
      if (!isOrderIdGenerated) {
        const product = products.find((p) => p.productId === selectedProduct);
        if (!product) {
          console.error("Product not found for selectedProductId:", selectedProduct);
          alert("Please select a valid product.");
          return;
        }
        const newOrderId = generateOrderId(product.productName);
        setGeneratedOrderId(newOrderId);
        setIsOrderIdGenerated(true); 
      }

      // Construct formData with all required fields
      const formData: SubPending = {
        ...newData,
        subOrderId: generatedOrderId || "",
        subProductId: selectedProduct || "",
        subPlanId: selectedPlan || "",
        subProductName: products.find((p) => p.productId === selectedProduct)?.productName || "",
        subExpiredDays: expireDays,
        subPaymentReceipts: "",
        subTermAgreed: newData.subTermAgreed || "Yes",
        subUserName: newData.subUserName || "",
        subUserEmail: newData.subUserEmail || "",
        subPlanType: newData.subPlanType || "",
        subPlanAmount: newData.subPlanAmount || 0,
        subPlanPeriod: newData.subPlanPeriod || "",
        subDateSubmit: new Date(newData.subDateSubmit) || new Date(),
        subExpiredDate: new Date(newData.subExpiredDate) || new Date(),
        subStatus: newData.subStatus || "",
        subAccountType: newData.subAccountType || "",
        subAccountServer: newData.subAccountServer || "",
        subAccountNumber: newData.subAccountNumber || "",
        subAccountPassword: newData.subAccountPassword || "",
        subTrialCheck: newData.subTrialCheck || 0,
        subVoucherCheck: newData.subVoucherCheck || 0,
        subDemoCheck: newData.subDemoCheck || 0,
        subPaymentMethod: newData.subPaymentMethod || "",
        subNotes: newData.subNotes || "",
        subRemark: newData.subRemark || "",
      };
  
      if (newData.subStatus === "Pending") {
        await addSubPending(formData, paymentReceipt || undefined);
      } else if (newData.subStatus === "Active" || newData.subStatus === "Expire") {
        if (generatedOrderId && generatedOrderId.startsWith("sub-ALG")) {
          await addSubProduct("AlgoMatrix", formData);
        } else if (generatedOrderId && generatedOrderId.startsWith("sub-TRA")) {
          await addSubProduct("TraderHub", formData);
        }
      }

      onSave(formData);
      resetState();
      onClose();
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };       

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg">
        <h2 className="text-lg font-semibold mb-4 text-cyan-800 mx-4">Add New Subscription</h2>
        <div className="flex flex-col text-sm border p-4 rounded overflow-y-auto max-h-[500px]">
          
          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Order Id</label>
            <select
              name="subOrderId"
              value={selectedProduct || ''}
              onChange={handleProductChange}
              className="border rounded px-2 py-1 w-full"
            >
              <option value="">Select a Product</option>
              {products.map((product) => (
                <option key={product.productId} value={product.productId}>
                  {product.productName}
                </option>
              ))}
            </select>
            {generatedOrderId && (
              <span className="text-rose-600 text-xs ml-2 font-bold">Generated Order ID: {generatedOrderId}</span>
            )}
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Product Name</label>
            <select
              name="subProductId"
              value={selectedProduct || ''}
              onChange={handleProductChange}
              className="border rounded px-2 py-1 w-full bg-gray-50"
              disabled
            >
              <option value="">Select a Product</option>
              {products.map((product) => (
                <option key={product.productId} value={product.productId}>
                  {product.productName}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Plan Name</label>
            <select
              name="subPlanId"
              value={selectedPlan || ''}
              onChange={handlePlanChange}
              className="border rounded px-2 py-1 w-full"
            >
              <option value="">Select a Plan</option>
              {plans
                .filter((plan) => plan.LinkProductId === selectedProduct)
                .map((plan) => (
                  <option key={plan.planId} value={plan.planId}>
                    {`${plan.planId} - ${plan.planName}`}
                  </option>
                ))}
            </select>
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Subscription Status</label>
            <select
              name="subStatus"
              value={newData.subStatus || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
            >
              <option value="">Select a Status</option>
              {isPendingTable ? (
                <option value="Pending">Pending</option>
              ) : (
                <>
                  <option value="Active">Active</option>
                  <option value="Expire">Expire</option>
                </>
              )}
            </select>
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Requested By</label>
            <input
              type="text"
              name="subUserName"
              placeholder="User Name"
              value={newData.subUserName || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
            />
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Email</label>
            <input
              type="email"
              name="subUserEmail"
              placeholder="User Email"
              value={newData.subUserEmail || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
            />
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Plan Type</label>
            <select
              name="subPlanType"
              value={newData.subPlanType || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
            >
              <option value="">Select a plan type</option>
              <option value="Subscribe">Subscribe</option>
              <option value="Trial">Trial</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Plan Period</label>
            <input
              type="number"
              name="subPlanPeriod"
              placeholder="Plan Period"
              value={newData.subPlanPeriod || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
              disabled
            />
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Plan Amount</label>
            <input
              type="number"
              name="subPlanAmount"
              placeholder="Plan Amount"
              value={newData.subPlanAmount || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
              disabled
            />
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Submit Date</label>
            <input
              type="date"
              name="subDateSubmit"
              placeholder="Date of Submit"
              value={newData.subDateSubmit || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
            />
          </div>

          <div className="mb-3">
            <div className="flex justify-between items-center">
              <label className="mx-2 font-semibold text-cyan-800">Expire Date</label>
              <span className="text-red-500 font-bold">{expireDays} Days</span>
            </div>
            <input
              type="date"
              name="subExpiredDate"
              placeholder="expired date of submit"
              value={newData.subExpiredDate || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
            />
          </div>

          <div className="mb-3">
            <label 
              className={`mx-2 font-semibold ${isAccountFieldsEnabled ? "text-cyan-800" : "text-gray-400"}`}
            >
              Account Type
            </label>
            <select
              name="subAccountType"
              value={newData.subAccountType || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
              disabled={!isAccountFieldsEnabled}
            >
              <option value="">Select an Account Type</option>
              <option value="Live">Live</option>
              <option value="Demo">Demo</option>
            </select>
          </div>

          <div className="mb-3">
            <label 
              className={`mx-2 font-semibold ${isAccountFieldsEnabled ? "text-cyan-800" : "text-gray-400"}`}
            >
              Account Server
            </label>
            <select
              name="subAccountServer"
              value={newData.subAccountServer || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
              disabled={!isAccountFieldsEnabled}
            >
              <option value="">Select an Account Server</option>
              <option value="ForturnPrimeGlobal-Live">FPG Live</option>
              <option value="ForturnPrimeGlobal-Demo">FPG Demo</option>
              <option value="LirunexLimited-Live">Lirunex Live</option>
              <option value="LirunexLimited-Demo">Lirunex Demo</option>
            </select>
          </div>

          <div className="mb-3">
            <label 
              className={`mx-2 font-semibold ${isAccountFieldsEnabled ? "text-cyan-800" : "text-gray-400"}`}
            >
              Account Number
            </label>
            <input
              type="number"
              name="subAccountNumber"
              placeholder="enter account number"
              value={newData.subAccountNumber || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
              disabled={!isAccountFieldsEnabled}
            />
          </div>

          <div className="mb-3">
            <label 
              className={`mx-2 font-semibold ${isAccountFieldsEnabled ? "text-cyan-800" : "text-gray-400"}`}
            >
              Account Password
            </label>
            <input
              type="text"
              name="subAccountPassword"
              placeholder="enter account password"
              value={newData.subAccountPassword || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
              disabled={!isAccountFieldsEnabled}
            />
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Trial Check</label>
            <select
              name="subTrialCheck"
              value={newData.subTrialCheck || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
            >
              <option value="">Select trial status</option>
              <option value="1">Enable</option>
              <option value="0">Disable</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Voucher Check</label>
            <select
              name="subVoucherCheck"
              value={newData.subVoucherCheck || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
            >
              <option value="">Select voucher status</option>
              <option value="1">Enable</option>
              <option value="0">Disable</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Payment Method</label>
            <select
              name="subPaymentMethod"
              value={newData.subPaymentMethod || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
            >
              <option value="">Select voucher status</option>
              <option value="Bank Transfer">Bank Transfer</option>
              <option value="USDT">USDT</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Payment Receipts</label>
            <input
              type="file"
              name="subPaymentReceipts"
              accept="image/*"
              onChange={handleFileChange}
              className="border rounded px-2 py-1 w-full"
            />
            {receiptPreview && (
              <img src={receiptPreview} alt="Receipt Preview" className="mt-2 w-32 h-auto rounded" />
            )}
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Notes</label>
            <textarea
              name="subNotes"
              placeholder="enter user notes..."
              value={newData.subNotes || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
            />
          </div>

          <div className="mb-3">
            <label className="mx-2 font-semibold text-cyan-800">Remark (for admin use)</label>
            <textarea
              name="subRemark"
              placeholder="enter user remark from admin..."
              value={newData.subRemark || ''}
              onChange={handleNewInputChange}
              className="border rounded px-2 py-1 w-full"
            />
          </div>
        </div>

        <div className="flex justify-end space-x-2 mt-4">
          <button
            onClick={onClose}
            className="bg-gray-200 text-gray-600 px-6 py-1 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleAdd}
            className="bg-cyan-800 text-white px-4 py-1 rounded"
          >
            Add
          </button>
        </div>
      
      </div>
    </div>
  );
};

export default AddSubUserModal;

// // updaet code --> 24 nov 2024
// // src/components/admin/SubProductSetting/AddSubUserModal.tsx

// import React, { useState, useEffect } from 'react';
// import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';
// import { useSubUserData, SubPending } from '../../../hooks/AdminDashboard/useSubUserData';

// interface AddSubUserModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   onSave: (data: any) => void;
//   newData: { [key: string]: any };
//   handleNewInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
//   isPendingTable: boolean;
// }

// const AddSubUserModal: React.FC<AddSubUserModalProps> = ({
//   isOpen,
//   onClose,
//   onSave,
//   newData,
//   handleNewInputChange,
//   isPendingTable,
// }) => {
//   // import custom hook
//   const { products, plans } = useSubSetting();
//   const { addSubPending, addSubProduct } = useSubUserData();
//   // custom state
//   const [expireDays, setExpireDays] = useState<number>(0);
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null);
//   const [receiptPreview, setReceiptPreview] = useState<string | null>(null);
//   const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
//   const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
//   const [generatedOrderId, setGeneratedOrderId] = useState<string | null>(null);
//   const [isOrderIdGenerated, setIsOrderIdGenerated] = useState<boolean>(false);
//   // enable/disable section field
//   const [isAccountFieldsEnabled, setIsAccountFieldsEnabled] = useState<boolean>(true); 

//   // Function to generate order ID based on product name
//   const generateOrderId = (productName: string) => {
//     const prefix = productName.replace(/\s+/g, '-').substring(0, 3).toUpperCase();
//     const randomLetters = Math.random().toString(36).substring(2, 6).toUpperCase();
//     const randomDigits = String(Math.floor(1000 + Math.random() * 9000));
//     return `sub-${prefix}-${randomLetters}${randomDigits}`;
//   };

//   useEffect(() => {
//     if (selectedProduct && !isOrderIdGenerated) {
//       const product = products.find((p) => p.productId === selectedProduct);
//       if (product) {
//         const orderId = generateOrderId(product.productName);
//         setGeneratedOrderId(orderId);
//         setIsOrderIdGenerated(true);

//         handleNewInputChange({
//           target: { name: 'subOrderId', value: orderId },
//         } as React.ChangeEvent<HTMLInputElement>);
//       }
//     }
//   }, [selectedProduct, products, isOrderIdGenerated, handleNewInputChange]);

//   useEffect(() => {
//     if (newData.subExpiredDate) {
//       const today = new Date();
//       const expireDate = new Date(newData.subExpiredDate);
//       const differenceInTime = expireDate.getTime() - today.getTime();
//       const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
//       setExpireDays(differenceInDays);
//     }
//   }, [newData.subExpiredDate]);

//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       setPaymentReceipt(file);
//       setReceiptPreview(URL.createObjectURL(file));
//     }
//   };

//   const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedPlanId = e.target.value;
//     setSelectedPlan(selectedPlanId);

//     const plan = plans.find((p) => p.planId === selectedPlanId);
//     if (plan) {
//       handleNewInputChange({
//         target: { name: 'subPlanId', value: selectedPlanId },
//       } as React.ChangeEvent<HTMLInputElement>);
//       handleNewInputChange({
//         target: { name: 'subPlanPeriod', value: plan.planPeriod.toString() },
//       } as React.ChangeEvent<HTMLInputElement>);
//       handleNewInputChange({
//         target: { name: 'subPlanAmount', value: plan.planAmount.toString() },
//       } as React.ChangeEvent<HTMLInputElement>);
//       setIsAccountFieldsEnabled(plan.planAccount !== 'Disable');
//     }
//   };

//   const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     setSelectedProduct(e.target.value);
//     setSelectedPlan(null);
//     setIsOrderIdGenerated(false);
//     handleNewInputChange({
//       target: { name: 'subProductId', value: e.target.value },
//     } as React.ChangeEvent<HTMLInputElement>);
//   };

//   const resetState = () => {
//     setExpireDays(0);
//     setPaymentReceipt(null);
//     setReceiptPreview(null);
//     setSelectedProduct(null);
//     setSelectedPlan(null);
//     setGeneratedOrderId(null);
//     setIsOrderIdGenerated(false);
//     setIsAccountFieldsEnabled(true);
//   };

//   const handleSave = async () => {
//     if (isOrderIdGenerated) return;

//     // Generate an order ID only if it hasn't been generated
//     if (!generatedOrderId) {
//       const newOrderId = generateOrderId(
//         products.find((p) => p.productId === selectedProduct)?.productName || ""
//       );
//       setGeneratedOrderId(newOrderId);
//     }
  
//     const paymentReceiptUrl = paymentReceipt ? URL.createObjectURL(paymentReceipt) : "";
  
//     // Construct formData with all required fields
//     const formData: SubPending = {
//       ...newData,
//       subOrderId: generatedOrderId || "",
//       subProductId: selectedProduct || "",
//       subPlanId: selectedPlan || "",
//       subProductName: products.find((p) => p.productId === selectedProduct)?.productName || "",
//       subExpiredDays: expireDays,
//       subPaymentReceipts: paymentReceiptUrl,
//       subTermAgreed: newData.subTermAgreed || "Yes",
//       subUserName: newData.subUserName || "",
//       subUserEmail: newData.subUserEmail || "",
//       subPlanType: newData.subPlanType || "",
//       subPlanAmount: newData.subPlanAmount || 0,
//       subPlanPeriod: newData.subPlanPeriod || "",
//       subDateSubmit: new Date(newData.subDateSubmit) || new Date(),
//       subExpiredDate: new Date(newData.subExpiredDate) || new Date(),
//       subStatus: newData.subStatus || "",
//       subAccountType: newData.subAccountType || "",
//       subAccountServer: newData.subAccountServer || "",
//       subAccountNumber: newData.subAccountNumber || "",
//       subAccountPassword: newData.subAccountPassword || "",
//       subTrialCheck: newData.subTrialCheck || 0,
//       subVoucherCheck: newData.subVoucherCheck || 0,
//       subPaymentMethod: newData.subPaymentMethod || "",
//       subNotes: newData.subNotes || "",
//       subRemark: newData.subRemark || "",
//     };
  
//     try {
//       setIsOrderIdGenerated(true); 
  
//       if (newData.subStatus === "Pending") {
//         await addSubPending(formData);
//       } else if (newData.subStatus === "Active" || newData.subStatus === "Expire") {
//         if (generatedOrderId && generatedOrderId.startsWith("sub-ALG")) {
//           await addSubProduct("AlgoMatrix", formData);
//         } else if (generatedOrderId && generatedOrderId.startsWith("sub-TRA")) {
//           await addSubProduct("TraderHub", formData);
//         }
//       }
  
//       onSave(formData);
//       resetState();
//       onClose();
//     } catch (error) {
//       console.error("Error saving data:", error);
//     } finally {
//       setIsOrderIdGenerated(false);
//     }
//   };       

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg">
//         <h2 className="text-lg font-semibold mb-4 text-cyan-800 mx-4">Add New Subscription</h2>
//         <div className="flex flex-col text-sm border p-4 rounded overflow-y-auto max-h-[500px]">
          
//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Order Id</label>
//             <select
//               name="subOrderId"
//               value={selectedProduct || ''}
//               onChange={handleProductChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a Product</option>
//               {products.map((product) => (
//                 <option key={product.productId} value={product.productId}>
//                   {product.productName}
//                 </option>
//               ))}
//             </select>
//             {generatedOrderId && (
//               <span className="text-rose-600 text-xs ml-2 font-bold">Generated Order ID: {generatedOrderId}</span>
//             )}
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Product Name</label>
//             <select
//               name="subProductId"
//               value={selectedProduct || ''}
//               onChange={handleProductChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a Product</option>
//               {products.map((product) => (
//                 <option key={product.productId} value={product.productId}>
//                   {product.productName}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Plan Name</label>
//             <select
//               name="subPlanId"
//               value={selectedPlan || ''}
//               onChange={handlePlanChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a Plan</option>
//               {plans
//                 .filter((plan) => plan.LinkProductId === selectedProduct)
//                 .map((plan) => (
//                   <option key={plan.planId} value={plan.planId}>
//                     {`${plan.planId} - ${plan.planName}`}
//                   </option>
//                 ))}
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Subscription Status</label>
//             <select
//               name="subStatus"
//               value={newData.subStatus || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a Status</option>
//               {isPendingTable ? (
//                 <option value="Pending">Pending</option>
//               ) : (
//                 <>
//                   <option value="Active">Active</option>
//                   <option value="Expire">Expire</option>
//                 </>
//               )}
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Requested By</label>
//             <input
//               type="text"
//               name="subUserName"
//               placeholder="User Name"
//               value={newData.subUserName || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Email</label>
//             <input
//               type="email"
//               name="subUserEmail"
//               placeholder="User Email"
//               value={newData.subUserEmail || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Plan Type</label>
//             <select
//               name="subPlanType"
//               value={newData.subPlanType || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a plan type</option>
//               <option value="Subscribe">Subscribe</option>
//               <option value="Trial">Trial</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Plan Period</label>
//             <input
//               type="number"
//               name="subPlanPeriod"
//               placeholder="Plan Period"
//               value={newData.subPlanPeriod || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//               disabled
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Plan Amount</label>
//             <input
//               type="number"
//               name="subPlanAmount"
//               placeholder="Plan Amount"
//               value={newData.subPlanAmount || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//               disabled
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Submit Date</label>
//             <input
//               type="date"
//               name="subDateSubmit"
//               placeholder="Date of Submit"
//               value={newData.subDateSubmit || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <div className="flex justify-between items-center">
//               <label className="mx-2 font-semibold text-cyan-800">Expire Date</label>
//               <span className="text-red-500 font-bold">{expireDays} Days</span>
//             </div>
//             <input
//               type="date"
//               name="subExpiredDate"
//               placeholder="expired date of submit"
//               value={newData.subExpiredDate || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label 
//               className={`mx-2 font-semibold ${isAccountFieldsEnabled ? "text-cyan-800" : "text-gray-400"}`}
//             >
//               Account Type
//             </label>
//             <select
//               name="subAccountType"
//               value={newData.subAccountType || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//               disabled={!isAccountFieldsEnabled}
//             >
//               <option value="">Select an Account Type</option>
//               <option value="Live">Live</option>
//               <option value="Demo">Demo</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label 
//               className={`mx-2 font-semibold ${isAccountFieldsEnabled ? "text-cyan-800" : "text-gray-400"}`}
//             >
//               Account Server
//             </label>
//             <select
//               name="subAccountServer"
//               value={newData.subAccountServer || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//               disabled={!isAccountFieldsEnabled}
//             >
//               <option value="">Select an Account Server</option>
//               <option value="ForturnPrimeGlobal-Live">FPG Live</option>
//               <option value="ForturnPrimeGlobal-Demo">FPG Demo</option>
//               <option value="LirunexLimited-Live">Lirunex Live</option>
//               <option value="LirunexLimited-Demo">Lirunex Demo</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label 
//               className={`mx-2 font-semibold ${isAccountFieldsEnabled ? "text-cyan-800" : "text-gray-400"}`}
//             >
//               Account Number
//             </label>
//             <input
//               type="number"
//               name="subAccountNumber"
//               placeholder="enter account number"
//               value={newData.subAccountNumber || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//               disabled={!isAccountFieldsEnabled}
//             />
//           </div>

//           <div className="mb-3">
//             <label 
//               className={`mx-2 font-semibold ${isAccountFieldsEnabled ? "text-cyan-800" : "text-gray-400"}`}
//             >
//               Account Password
//             </label>
//             <input
//               type="text"
//               name="subAccountPassword"
//               placeholder="enter account password"
//               value={newData.subAccountPassword || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//               disabled={!isAccountFieldsEnabled}
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Trial Check</label>
//             <select
//               name="subTrialCheck"
//               value={newData.subTrialCheck || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select trial status</option>
//               <option value="1">Enable</option>
//               <option value="0">Disable</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Voucher Check</label>
//             <select
//               name="subVoucherCheck"
//               value={newData.subVoucherCheck || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select voucher status</option>
//               <option value="1">Enable</option>
//               <option value="0">Disable</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Payment Method</label>
//             <select
//               name="subPaymentMethod"
//               value={newData.subPaymentMethod || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select voucher status</option>
//               <option value="Bank Transfer">Bank Transfer</option>
//               <option value="USDT">USDT</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Payment Receipts</label>
//             <input
//               type="file"
//               name="subPaymentReceipts"
//               accept="image/*"
//               onChange={handleFileChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//             {receiptPreview && (
//               <img src={receiptPreview} alt="Receipt Preview" className="mt-2 w-32 h-auto rounded" />
//             )}
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Notes</label>
//             <textarea
//               name="subNotes"
//               placeholder="enter user notes..."
//               value={newData.subNotes || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Remark (for admin use)</label>
//             <textarea
//               name="subRemark"
//               placeholder="enter user remark from admin..."
//               value={newData.subRemark || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>
//         </div>

//         <div className="flex justify-end space-x-2 mt-4">
//           <button
//             onClick={onClose}
//             className="bg-gray-200 text-gray-600 px-6 py-1 rounded"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleSave}
//             className="bg-cyan-800 text-white px-4 py-1 rounded"
//           >
//             Save
//           </button>
//         </div>
      
//       </div>
//     </div>
//   );
// };

// export default AddSubUserModal;

// // update code --> 11 nov 2024
// // src/components/admin/SubProductSetting/AddSubUserModal.tsx

// import React, { useState, useEffect } from 'react';
// import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';
// import { useSubUserData, SubPending } from '../../../hooks/AdminDashboard/useSubUserData';

// interface AddSubUserModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   onSave: (data: any) => void;
//   newData: { [key: string]: any };
//   handleNewInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
// }

// const AddSubUserModal: React.FC<AddSubUserModalProps> = ({
//   isOpen,
//   onClose,
//   onSave,
//   newData,
//   handleNewInputChange,
// }) => {
//   // import custom hook
//   const { products, plans } = useSubSetting();
//   const { addSubPending, addSubProduct } = useSubUserData();
//   // custom state
//   const [expireDays, setExpireDays] = useState<number>(0);
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null);
//   const [receiptPreview, setReceiptPreview] = useState<string | null>(null);
//   const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
//   const [generatedOrderId, setGeneratedOrderId] = useState<string | null>(null);
//   const [isOrderIdGenerated, setIsOrderIdGenerated] = useState<boolean>(false);
//   // enable/disable section field
//   const [isTradingSectionEnabled, setIsTradingSectionEnabled] = useState(
//     plans.some((plan) => plan.planAccount === 'Enable')
//   );  

//   useEffect(() => {
//     // Enable or disable the trading section if any plan has `planAccount` set to 'Enable'
//     setIsTradingSectionEnabled(plans.some((plan) => plan.planAccount === 'Enable'));
//   }, [plans]);  

//   // Function to generate order ID based on product name
//   const generateOrderId = (productName: string) => {
//     const prefix = productName.replace(/\s+/g, '-').substring(0, 3).toUpperCase();
//     const randomLetters = Math.random().toString(36).substring(2, 6).toUpperCase();
//     const randomDigits = String(Math.floor(1000 + Math.random() * 9000));
//     return `sub-${prefix}-${randomLetters}${randomDigits}`;
//   };

//   useEffect(() => {
//     if (selectedProduct && !isOrderIdGenerated) {
//       const product = products.find((p) => p.productId === selectedProduct);
//       if (product) {
//         const orderId = generateOrderId(product.productName);
//         setGeneratedOrderId(orderId);
//         setIsOrderIdGenerated(true);

//         const relatedPlan = plans.find((plan) => plan.LinkProductId === selectedProduct);
//         if (relatedPlan) {
//           handleNewInputChange({
//             target: { name: 'subPlanPeriod', value: relatedPlan.planPeriod.toString() },
//           } as React.ChangeEvent<HTMLInputElement>);
//           handleNewInputChange({
//             target: { name: 'subPlanAmount', value: relatedPlan.planAmount.toString() },
//           } as React.ChangeEvent<HTMLInputElement>);
//         }
//       }
//     }
//   }, [selectedProduct, products, plans, handleNewInputChange, isOrderIdGenerated]);

//   useEffect(() => {
//     if (newData.subExpiredDate) {
//       const today = new Date();
//       const expireDate = new Date(newData.subExpiredDate);
//       const differenceInTime = expireDate.getTime() - today.getTime();
//       const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
//       setExpireDays(differenceInDays);
//     }
//   }, [newData.subExpiredDate]);

//   // Handle file input change for payment receipt
//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       setPaymentReceipt(file);
//       setReceiptPreview(URL.createObjectURL(file));
//     }
//   };

//   const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     setSelectedProduct(e.target.value);
//     setIsOrderIdGenerated(false);
//   };

//   const handleSave = async () => {
//     // Convert paymentReceipt (File) to a string (URL) or set as an empty string if null
//     const paymentReceiptUrl = paymentReceipt ? URL.createObjectURL(paymentReceipt) : '';

//     // Construct formData with all required fields
//     const formData: SubPending = {
//       ...newData,
//       subOrderId: generatedOrderId || '',
//       subProductId: selectedProduct || '',
//       subProductName: products.find((p) => p.productId === selectedProduct)?.productName || '',
//       subPlanId: plans.find((plan) => plan.LinkProductId === selectedProduct)?.planId || '',
//       subExpiredDays: expireDays,
//       subPaymentReceipts: paymentReceiptUrl, 
//       subTermAgreed: newData.subTermAgreed || '',
//       subUserName: newData.subUserName || '',
//       subUserEmail: newData.subUserEmail || '',
//       subPlanType: newData.subPlanType || '',
//       subPlanAmount: newData.subPlanAmount || 0,
//       subPlanPeriod: newData.subPlanPeriod || '',
//       subDateSubmit: new Date(newData.subDateSubmit) || new Date(),
//       subExpiredDate: new Date(newData.subExpiredDate) || new Date(),
//       subStatus: newData.subStatus || '',
//       subAccountType: newData.subAccountType || '',
//       subAccountServer: newData.subAccountServer || '',
//       subAccountNumber: newData.subAccountNumber || '',
//       subAccountPassword: newData.subAccountPassword || '',
//       subTrialCheck: newData.subTrialCheck || 0,
//       subVoucherCheck: newData.subVoucherCheck || 0,
//       subPaymentMethod: newData.subPaymentMethod || '',
//       subNotes: newData.subNotes || '',
//       subRemark: newData.subRemark || ''
//     };
  
//     try {
//       if (newData.subStatus === "Pending") {
//         await addSubPending(formData);
//       } else if (newData.subStatus === "Active" || newData.subStatus === "Expire") {
//         if (generatedOrderId && generatedOrderId.startsWith("sub-ALG")) {
//           await addSubProduct("AlgoMatrix", formData);
//         } else if (generatedOrderId && generatedOrderId.startsWith("sub-TRA")) {
//           await addSubProduct("TraderHub", formData);
//         }
//       }
  
//       onSave(formData);
//       onClose();
//     } catch (error) {
//       console.error('Error saving data:', error);
//     }
//   };      

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg">
//         <h2 className="text-lg font-semibold mb-4 text-cyan-800 mx-4">Add New Subscription</h2>
//         <div className="flex flex-col text-sm border p-4 rounded overflow-y-auto max-h-[500px]">
          
//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Order Id</label>
//             <select
//               name="subOrderId"
//               value={selectedProduct || ''}
//               onChange={handleProductChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a Product</option>
//               {products.map((product) => (
//                 <option key={product.productId} value={product.productId}>
//                   {product.productName}
//                 </option>
//               ))}
//             </select>
//             {generatedOrderId && (
//               <span className="text-rose-600 text-xs ml-2 font-bold">Generated Order ID: {generatedOrderId}</span>
//             )}
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Product Name</label>
//             <select
//               name="subProductId"
//               value={newData.subProductId || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a product</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Plan Name</label>
//             <select
//               name="subPlanId"
//               value={newData.subPlanId || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a plan</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Subscription Status</label>
//             <select
//               name="subStatus"
//               value={newData.subStatus || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a Status</option>
//               <option value="Active">Active</option>
//               <option value="Pending">Pending</option>
//               <option value="Expire">Expire</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Requested By</label>
//             <input
//               type="text"
//               name="subUserName"
//               placeholder="User Name"
//               value={newData.subUserName || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Email</label>
//             <input
//               type="email"
//               name="subUserEmail"
//               placeholder="User Email"
//               value={newData.subUserEmail || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Plan Type</label>
//             <select
//               name="subPlanType"
//               value={newData.subPlanType || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a plan type</option>
//               <option value="Subscribe">Subscribe</option>
//               <option value="Trial">Trial</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Plan Period</label>
//             <input
//               type="number"
//               name="subPlanPeriod"
//               placeholder="Plan Period"
//               value={newData.subPlanPeriod || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//               disabled
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Plan Amount</label>
//             <input
//               type="number"
//               name="subPlanAmount"
//               placeholder="Plan Amount"
//               value={newData.subPlanAmount || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//               disabled
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Submit Date</label>
//             <input
//               type="date"
//               name="subDateSubmit"
//               placeholder="Date of Submit"
//               value={newData.subDateSubmit || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <div className="flex justify-between items-center">
//               <label className="mx-2 font-semibold text-cyan-800">Expire Date</label>
//               <span className="text-red-500 font-bold">{expireDays} Days</span>
//             </div>
//             <input
//               type="date"
//               name="subExpiredDate"
//               placeholder="expired date of submit"
//               value={newData.subExpiredDate || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label 
//               className={`mx-2 font-semibold text-cyan-800 ${isTradingSectionEnabled ? "text-gray-300 font-bold" : "text-cyan-800 font-bold" }`}
//             >
//               Account Type
//             </label>
//             <select
//               name="subAccountType"
//               value={newData.subAccountType || ''}
//               onChange={handleNewInputChange}
//               className={`border rounded px-2 py-1 w-full ${isTradingSectionEnabled ? "text-gray-300" : "text-cyan-800"}`}
//               disabled={isTradingSectionEnabled}
//             >
//               <option value="">Select a account type</option>
//               <option value="Live">Live</option>
//               <option value="Demo">Demo</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Account Server</label>
//             <select
//               name="subAccountServer"
//               value={newData.subAccountServer || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a account server</option>
//               <option value="ForturnPrimeGlobal-Live">FPG Live</option>
//               <option value="ForturnPrimeGlobal-Demo">FPG Demo</option>
//               <option value="LirunexLimited-Live">Lirunex Live</option>
//               <option value="LirunexLimited-Demo">Lirunex Demo</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Account Number</label>
//             <input
//               type="number"
//               name="subAccountNumber"
//               placeholder="enter account number"
//               value={newData.subAccountNumber || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Account Password</label>
//             <input
//               type="text"
//               name="subAccountPassword"
//               placeholder="enter account password"
//               value={newData.subAccountPassword || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Trial Check</label>
//             <select
//               name="subTrialCheck"
//               value={newData.subTrialCheck || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select trial status</option>
//               <option value="1">Enable</option>
//               <option value="0">Disable</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Voucher Check</label>
//             <select
//               name="subVoucherCheck"
//               value={newData.subVoucherCheck || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select voucher status</option>
//               <option value="1">Enable</option>
//               <option value="0">Disable</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Payment Method</label>
//             <select
//               name="subPaymentMethod"
//               value={newData.subPaymentMethod || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select voucher status</option>
//               <option value="Bank Transfer">Bank Transfer</option>
//               <option value="USDT">USDT</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Payment Receipts</label>
//             <input
//               type="file"
//               name="subPaymentReceipts"
//               accept="image/*"
//               onChange={handleFileChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//             {receiptPreview && (
//               <img src={receiptPreview} alt="Receipt Preview" className="mt-2 w-32 h-auto rounded" />
//             )}
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Notes</label>
//             <textarea
//               name="subNotes"
//               placeholder="enter user notes..."
//               value={newData.subNotes || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Remark (for admin use)</label>
//             <textarea
//               name="subRemark"
//               placeholder="enter user remark from admin..."
//               value={newData.subRemark || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>
//         </div>

//         <div className="flex justify-end space-x-2 mt-4">
//           <button
//             onClick={onClose}
//             className="bg-gray-200 text-gray-600 px-6 py-1 rounded"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleSave}
//             className="bg-cyan-800 text-white px-4 py-1 rounded"
//           >
//             Save
//           </button>
//         </div>
      
//       </div>
//     </div>
//   );
// };

// export default AddSubUserModal;


// // udpate code --> 10 nov 2024
// // src/components/admin/SubProductSetting/AddSubUserModal.tsx

// import React, { useState, useEffect } from 'react';

// interface AddSubUserModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   onSave: (data: any) => void;
//   newData: { [key: string]: any };
//   handleNewInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
// }

// const AddSubUserModal: React.FC<AddSubUserModalProps> = ({
//   isOpen,
//   onClose,
//   onSave,
//   newData,
//   handleNewInputChange,
// }) => {
//   const [expireDays, setExpireDays] = useState<number>(0);
//   const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null);
//   const [receiptPreview, setReceiptPreview] = useState<string | null>(null);

//   useEffect(() => {
//     if (newData.subExpiredDate) {
//       const today = new Date();
//       const expireDate = new Date(newData.subExpiredDate);
//       const differenceInTime = expireDate.getTime() - today.getTime();
//       const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
//       setExpireDays(differenceInDays);
//     }
//   }, [newData.subExpiredDate]);

//   // Handle file input change for payment receipt
//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       setPaymentReceipt(file);
//       setReceiptPreview(URL.createObjectURL(file));
//     }
//   };

//   const handleSave = () => {
//     // Include paymentReceipt in onSave if required
//     const formData = { ...newData, paymentReceipt };
//     onSave(formData);
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg">
//         <h2 className="text-lg font-semibold mb-4">Add New Subscription</h2>
//         <div className="flex flex-col text-sm border p-4 rounded overflow-y-auto max-h-[500px]">
          
//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Order Id</label>
//             <select
//               name="subOrderId"
//               value={newData.subOrderId || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a Order</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Subscription Status</label>
//             <select
//               name="subStatus"
//               value={newData.subStatus || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a Status</option>
//               <option value="Active">Active</option>
//               <option value="Pending">Pending</option>
//               <option value="Expire">Expire</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Requested By</label>
//             <input
//               type="text"
//               name="subUserName"
//               placeholder="User Name"
//               value={newData.subUserName || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Email</label>
//             <input
//               type="email"
//               name="subUserEmail"
//               placeholder="User Email"
//               value={newData.subUserEmail || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Plan Type</label>
//             <select
//               name="subPlanType"
//               value={newData.subPlanType || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a plan type</option>
//               <option value="Trial">Trial</option>
//               <option value="Subscribe">Subscribe</option>
//               <option value="Renew">Renew</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Plan Amount</label>
//             <input
//               type="number"
//               name="subPlanAmount"
//               placeholder="Plan Amount"
//               value={newData.subPlanAmount || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Submit Date</label>
//             <input
//               type="date"
//               name="subDateSubmit"
//               placeholder="Date of Submit"
//               value={newData.subDateSubmit || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <div className="flex justify-between items-center">
//               <label className="mx-2 font-semibold text-cyan-800">Expire Date</label>
//               <span className="text-red-500 font-bold">{expireDays} Days</span>
//             </div>
//             <input
//               type="date"
//               name="subExpiredDate"
//               placeholder="expired date of submit"
//               value={newData.subExpiredDate || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Account Type</label>
//             <select
//               name="subAccountType"
//               value={newData.subAccountType || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a account type</option>
//               <option value="Demo">Demo</option>
//               <option value="Live">Live</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Account Server</label>
//             <select
//               name="subAccountServer"
//               value={newData.subAccountServer || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select a account server</option>
//               <option value="ForturnPrimeGlobal-Live">FPG Live</option>
//               <option value="ForturnPrimeGlobal-Demo">FPG Demo</option>
//               <option value="LirunexLimited-Live">Lirunex Live</option>
//               <option value="LirunexLimited-Demo">Lirunex Demo</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Account Number</label>
//             <input
//               type="number"
//               name="subAccountNumber"
//               placeholder="enter account number"
//               value={newData.subAccountNumber || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Account Password</label>
//             <input
//               type="text"
//               name="subAccountPassword"
//               placeholder="enter account password"
//               value={newData.subAccountPassword || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Trial Check</label>
//             <select
//               name="subTrialCheck"
//               value={newData.subTrialCheck || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select trial status</option>
//               <option value="Enable">Enable</option>
//               <option value="Disable">Disable</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Voucher Check</label>
//             <select
//               name="subVoucherCheck"
//               value={newData.subVoucherCheck || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select voucher status</option>
//               <option value="Enable">Enable</option>
//               <option value="Disable">Disable</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Payment Method</label>
//             <select
//               name="subPaymentMethod"
//               value={newData.subPaymentMethod || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             >
//               <option value="">Select voucher status</option>
//               <option value="Bank Transfer">Bank Transfer</option>
//               <option value="USDT">USDT</option>
//             </select>
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Payment Receipts</label>
//             <input
//               type="file"
//               name="subPaymentReceipts"
//               accept="image/*"
//               onChange={handleFileChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//             {receiptPreview && (
//               <img src={receiptPreview} alt="Receipt Preview" className="mt-2 w-32 h-auto rounded" />
//             )}
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Notes</label>
//             <textarea
//               name="subPaymentReceipts"
//               placeholder="enter user notes..."
//               value={newData.subNotes || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>

//           <div className="mb-3">
//             <label className="mx-2 font-semibold text-cyan-800">Remark (for admin use)</label>
//             <textarea
//               name="subRemark"
//               placeholder="enter user remark from admin..."
//               value={newData.subRemark || ''}
//               onChange={handleNewInputChange}
//               className="border rounded px-2 py-1 w-full"
//             />
//           </div>
//         </div>

//         <div className="flex justify-end space-x-2 mt-4">
//           <button
//             onClick={onClose}
//             className="bg-gray-200 text-gray-600 px-6 py-1 rounded"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleSave}
//             className="bg-cyan-800 text-white px-4 py-1 rounded"
//           >
//             Save
//           </button>
//         </div>
      
//       </div>
//     </div>
//   );
// };

// export default AddSubUserModal;