// src/components/login/SignUpAlert.tsx

import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimes, FaExclamationCircle } from 'react-icons/fa';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';

interface SignUpAlertProps {
  visible: boolean;
  onClose: () => void;
  success: boolean;
  errors?: Record<string, string>;
}

const SignUpAlert: React.FC<SignUpAlertProps> = ({ visible, onClose, success, errors }) => {
  const { theme } = useTheme();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (visible) {
      setShowModal(true);
      if (success) {
        const timer = setTimeout(() => {
          onClose();
          navigate('/login');
        }, 3000);
        return () => clearTimeout(timer);
      }
    } else {
      setShowModal(false);
    }
  }, [visible, success, navigate, onClose]);

  if (!visible) return null;

  return (
    <div className={`fixed inset-x-0 top-0 transition-transform transform ${showModal ? 'translate-y-0' : '-translate-y-full'} z-50 px-4`}
      style={{ fontFamily: theme.fontFamily }}>
      <div className={`relative flex flex-col p-4 mt-4 mb-4 text-sm ${success ? 'text-green-800 bg-green-100 border-green-500' : 'text-red-800 bg-red-100 border-red-500'} rounded-lg max-w-lg mx-auto md:max-w-2xl`}
        role="alert">
        <div className="flex items-center">
          {success ? 
            <FaCheckCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" /> : 
            <FaExclamationCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" />
          }
          <span className="font-medium" style={{ color: theme.textColor }}>
            {success ? 'Sign Up Successful!' : 'Sign Up Failed'}
          </span>
        </div>
        <div className={`mt-2 ${success ? 'text-green-700' : 'text-red-700'} md:ml-4`}>
          {success ? (
            'You have successfully signed up. Please check your email for verification.'
          ) : (
            <>
              <p>There was an error during the sign-up process. Please check the following:</p>
              <ul className="list-disc pl-5">
                {errors &&
                  Object.entries(errors).map(([field, message]) => (
                    <li key={field} className="text-red-700">
                      <strong>{field}:</strong> {message}
                    </li>
                  ))}
              </ul>
            </>
          )}
        </div>
        <button 
          onClick={onClose} 
          className={`absolute top-2 right-2 ${success ? 'text-green-800 hover:text-green-600' : 'text-red-800 hover:text-red-600'} focus:outline-none`}
        >
          <FaTimes className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default SignUpAlert;


// // udpte code --> 26 nov 2024
// // src/components/login/SignUpAlert.tsx

// import React, { useEffect, useState } from 'react';
// import { FaCheckCircle, FaTimes, FaExclamationCircle } from 'react-icons/fa';
// import { useTheme } from '../../context/ThemeContext';
// import { useNavigate } from 'react-router-dom';

// interface SignUpAlertProps {
//   visible: boolean;
//   onClose: () => void;
//   success: boolean;
// }

// const SignUpAlert: React.FC<SignUpAlertProps> = ({ visible, onClose, success }) => {
//   const { theme } = useTheme();
//   const [showModal, setShowModal] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (visible) {
//       setShowModal(true);
//       if (success) {
//         const timer = setTimeout(() => {
//           onClose();
//           navigate('/login');
//         }, 3000);
//         return () => clearTimeout(timer);
//       }
//     } else {
//       setShowModal(false);
//     }
//   }, [visible, success, navigate, onClose]);

//   if (!visible) return null;

//   return (
//     <div className={`fixed inset-x-0 top-0 transition-transform transform ${showModal ? 'translate-y-0' : '-translate-y-full'} z-50 px-4`}
//       style={{ fontFamily: theme.fontFamily }}>
//       <div className={`relative flex flex-col p-4 mt-4 mb-4 text-sm ${success ? 'text-green-800 bg-green-100 border-green-500' : 'text-red-800 bg-red-100 border-red-500'} rounded-lg max-w-lg mx-auto md:max-w-2xl`}
//         role="alert">
//         <div className="flex items-center">
//           {success ? <FaCheckCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" /> : <FaExclamationCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" />}
//           <span className="font-medium" style={{ color: theme.textColor }}>
//             {success ? 'Sign Up Successful!' : 'Sign Up Failed'}
//           </span>
//         </div>
//         <div className={`mt-2 ${success ? 'text-green-700' : 'text-red-700'} md:ml-4`}>
//           {success 
//             ? 'You have successfully signed up. Please check your email for verfication.'
//             : 'There was some error during the sign-up process. Please contact administration or email:general@r1trader.com'}
//         </div>
//         <button onClick={onClose} className={`absolute top-2 right-2 ${success ? 'text-green-800 hover:text-green-600' : 'text-red-800 hover:text-red-600'} focus:outline-none`}>
//           <FaTimes className="w-4 h-4" />
//         </button>
//       </div>
//     </div>
//   );
// };

// export default SignUpAlert;
