// src/components/admin/SubProductSetting/SubProductModal.tsx

import React, { useState, useEffect } from 'react';
import { MdOutlineLibraryAdd } from "react-icons/md";
import { CiSquareMinus } from "react-icons/ci";
import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';

interface SubPlanModalProps {
  onClose: () => void;
  onSave: (
    plan: { 
      planId: string; 
      LinkProductId: string;
      planName: string;
      planDescription: string;
      planFeatures: string;
      planAmount: number;
      planCurrency: string;
      planPeriod: number;
      planAccount: string;
      planStatus: string;
    }) => void;
    editMode?: boolean;
    initialData?: { 
      planId: string; 
      LinkProductId: string;
      planName: string;
      planDescription: string;
      planFeatures: string;
      planAmount: number;
      planCurrency: string;
      planPeriod: number;
      planAccount: string;
      planStatus: string;
    };
  }

const SubPlanModal: React.FC<SubPlanModalProps> = ({ onClose, onSave, editMode = false, initialData }) => {
  // import custom hook
  const { products } = useSubSetting();

  // custom states
  const [baseAmount, setBaseAmount] = useState<number>(Number(initialData?.planAmount) || 0);
  const [customAnnualAmount, setCustomAnnualAmount] = useState<number | null>(null);
  const [frequency, setFrequency] = useState("monthly");
  const [planFeatures, setPlanFeatures] = useState<string[]>(
    typeof initialData?.planFeatures === "string" 
      ? initialData.planFeatures.split(", ") 
      : initialData?.planFeatures || [""]
  );  
  
  const [planData, setPlanData] = useState({
    planId: initialData?.planId || '',
    LinkProductId: initialData?.LinkProductId || '',
    planName: initialData?.planName || '',
    planDescription: initialData?.planDescription || '',
    planFeatures: initialData?.planFeatures || '',
    planAmount: Number(initialData?.planAmount) || 0,
    planCurrency: initialData?.planCurrency || '',
    planPeriod: Number(initialData?.planPeriod) || 30, 
    planAccount: initialData?.planAccount || 'Enable',
    planStatus: initialData?.planStatus || 'Active',
  });

  useEffect(() => {
    if (initialData) {
      setPlanData({
        planId: initialData.planId,
        LinkProductId: initialData.LinkProductId,
        planName: initialData.planName,
        planDescription: initialData.planDescription,
        planFeatures: initialData.planFeatures,
        planAmount: initialData.planAmount,
        planCurrency: initialData.planCurrency,
        planPeriod: initialData.planPeriod,
        planAccount: initialData.planAccount,
        planStatus: initialData.planStatus,
      });
    }
  }, [initialData]);

  // Update handleChange to enforce planPeriod based on dropdown selection for monthly and annual periods
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    setPlanData((prevData) => ({
      ...prevData,
      [name]: name === 'planAmount' ? Number(value) : value,
      planPeriod: name === 'planPeriod' && value === "30" ? 30 : value === "365" ? 365 : prevData.planPeriod,
    }));

    // If changing planPeriod dropdown to monthly or annual, ensure planPeriod updates accordingly
    if (name === 'planPeriod') {
      setPlanData((prevData) => ({
        ...prevData,
        planPeriod: value === "30" ? 30 : value === "365" ? 365 : prevData.planPeriod,
      }));
    }
  }; 

  const handleAdd = () => {
    const formattedFeatures = planFeatures.map((feature) => feature.trim());
    const newPlanData = {
      ...planData,
      planFeatures: formattedFeatures.join(", "),
      planAmount: Number(planData.planAmount),
      planPeriod: Number(planData.planPeriod),
    };
    
    onSave(newPlanData); // Pass data back to parent for handling
    onClose();
  };
  
  
  const handleAddFeature = () => {
    setPlanFeatures([...planFeatures, ""]);
  };
  
  const handleRemoveFeature = (index: number) => {
    if (planFeatures.length > 1) {
      setPlanFeatures(planFeatures.filter((_, i) => i !== index));
    }
  };  
  
  const handleFeatureChange = (index: number, value: string) => {
    const updatedFeatures = [...planFeatures];
    updatedFeatures[index] = value;
    setPlanFeatures(updatedFeatures);
  };

  const handleFrequencyChange = (value: string) => {
    setFrequency(value);
    setPlanData((prevData) => ({
      ...prevData,
      planAmount: value === "annual" ? baseAmount * 12 : baseAmount, 
    }));
  };  

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = Number(e.target.value);
    setBaseAmount(amount);  // Update base amount independently
  
    setPlanData((prevData) => ({
      ...prevData,
      planAmount: frequency === "annual" ? amount * 12 : amount,  
    }));
  };  

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const period = Number(e.target.value);
    setPlanData((prevData) => ({
      ...prevData,
      planPeriod: period, 
    }));
  };  

  const handleCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const customAmount = Number(e.target.value);
    setCustomAnnualAmount(customAmount); 
    setPlanData((prevData) => ({
      ...prevData,
      planAmount: customAmount,
    }));
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-md shadow-md w-[400px]">
        <h2 className="text-xl font-semibold mb-4 text-cyan-800">
          {editMode ? "Edit Plan" : "Add New Plan"}
        </h2>
        
        <div className="overflow-y-auto h-auto max-h-[550px]">
          {/* Plan id field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Plan Id</label>
            <input
              type="text"
              name="planId"
              placeholder="id auto generated..."
              value={planData.planId}
              onChange={handleChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
              disabled
            />
          </div>

          {/* Plan Name field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Plan Name</label>
            <input
              type="text"
              name="planName"
              placeholder="enter plan name..."
              value={planData.planName}
              onChange={handleChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
            />
          </div>

          {/* Link Product Id field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Link Product Id</label>
            <select
              name="LinkProductId"
              value={planData.LinkProductId}
              onChange={handleChange}
              className="border px-4 py-1 rounded w-full text-sm"
            >
              <option value="">Select a status</option>
              {products.map((product) => (
                <option key={product.productId} value={product.productId}>
                  {product.productName}
                </option>
              ))}
            </select>
          </div>

          {/* Plan Description field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Plan Description</label>
            <textarea
              name="planDescription"
              placeholder="enter plan description..."
              value={planData.planDescription}
              onChange={handleChange}
              className="border px-4 py-1 rounded w-full text-sm"
            />
          </div>

          {/* Plan Features field */}
          <div className="my-4">
            <div className="flex justify-between items-center">
              <label className="mx-4 font-semibold text-cyan-800">Plan Features</label>
              <button 
                type="button" 
                onClick={handleAddFeature}>
                <MdOutlineLibraryAdd />
              </button>
            </div>
            <div className="flex flex-col space-y-2 mt-2">
              {planFeatures.map((feature, index) => (
                <div key={index} className="flex items-center">
                  <button 
                    type="button" 
                    onClick={() => handleRemoveFeature(index)}>
                    <CiSquareMinus className="text-rose-600 mr-2" />
                  </button>
                  <input
                    value={feature}
                    onChange={(e) => handleFeatureChange(index, e.target.value)}
                    className="border px-4 py-1 rounded w-full text-sm"
                    placeholder={`Feature ${index + 1}`}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Plan Amount field */}
          <div className="my-4">
            <div className="flex justify-between items-center">
              <label className="mx-4 font-semibold text-cyan-800">Plan Amount</label>
              <div className="space-x-2 text-sm">
                <label>
                  <input
                    type="radio"
                    name="frequency"
                    value="monthly"
                    className="mr-1 accent-cyan-800"
                    checked={frequency === "monthly"}
                    onChange={() => handleFrequencyChange("monthly")}
                  />
                  Monthly
                </label>
                <label className="ml-4">
                  <input
                    type="radio"
                    name="frequency"
                    value="annual"
                    className="mr-1 accent-cyan-800"
                    checked={frequency === "annual"}
                    onChange={() => handleFrequencyChange("annual")}
                  />
                  Annual
                </label>
              </div>
            </div>
            <div className="flex items-center">
              <input
                type="number"
                name="planAmount"
                placeholder="Enter amount..."
                value={frequency === "annual" ? customAnnualAmount ?? planData.planAmount : planData.planAmount}
                onChange={frequency === "annual" ? handleCustomAmountChange : handleAmountChange}
                className="border px-4 py-1 rounded w-full text-sm"
              />
              <select
                name="planCurrency"
                value={planData.planCurrency}
                onChange={handleChange}
                className="border px-4 py-1 rounded w-[200px] text-sm text-center"
              >
                <option value="">Currency</option>
                <option value="USD">USD</option>
                <option value="MYR">MYR</option>
              </select>
            </div>
          </div>

          {/* Plan Period field */}
          <div className="my-4">
            <div className="flex justify-between items-center">
              <label className="mx-4 font-semibold text-cyan-800">Plan Period</label>
              <select
                name="planPeriod"
                value={planData.planPeriod}
                onChange={handlePeriodChange}  
                className="px-4 py-1 rounded w-auto text-sm"
              >
                <option value="">Select a Period</option>
                <option value="30">Monthly</option>
                <option value="365">Annual</option>
              </select>
            </div>
            <input
              name="planPeriod"
              placeholder="enter plan period..."
              value={planData.planPeriod}
              onChange={handleChange}
              className="border px-4 py-1 rounded w-full text-sm"
            />
          </div>

          {/* Plan Account field */}
          <div className="my-2">
            <label className="mx-4 font-semibold text-cyan-800">Plan Account</label>
            <select
              name="planAccount"
              value={planData.planAccount}
              onChange={handleChange}
              className="border px-4 py-1 rounded w-full text-sm"
            >
              <option value="Enable">Enable</option>
              <option value="Disable">Disable</option>
            </select>
          </div>

          {/* Plan Status field */}
          <div className="my-2">
            <label className="mx-4 font-semibold text-cyan-800">Plan Status</label>
            <select
              name="planStatus"
              value={planData.planStatus}
              onChange={handleChange}
              className="border px-4 py-1 rounded w-full text-sm"
            >
              <option value="">Select a status</option>
              <option value="Active">Active</option>
              <option value="Stopped">Stopped</option>
            </select>
          </div>
        </div>


        <div className="flex justify-end space-x-2 mt-4">
          <button onClick={onClose} className="bg-gray-500 text-white px-4 py-1 rounded">Cancel</button>
          <button onClick={handleAdd} className="bg-cyan-800 text-white px-6 py-1 rounded">
            {editMode ? "Update" : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubPlanModal;
