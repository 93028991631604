// src/components/admin/VideoSetting/VideoGroupModal.tsx

import React, { useState, useEffect } from 'react';
import useVideoUpload from '../../../hooks/AdminDashboard/useVideoUpload'; // Import the hook here

interface VideoGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddGroup: (groupName: string, emailList: string[]) => void; // Function to pass group name and email list
}

const VideoGroupModal: React.FC<VideoGroupModalProps> = ({ isOpen, onClose, handleAddGroup }) => {
  const [groupName, setGroupName] = useState('');
  const [filteredEmailList, setFilteredEmailList] = useState<string[]>([]); 
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1); 
  const emailsPerPage = 10;

  // Fetch user emails from the hook
  const { userEmails } = useVideoUpload(); 

  useEffect(() => {
    setFilteredEmailList(userEmails);  
  }, [userEmails]);

  // Handle searching emails
  useEffect(() => {
    const filtered = userEmails.filter(email =>
      email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredEmailList(filtered);
    setCurrentPage(1); 
  }, [searchTerm, userEmails]);

  const handleSelectEmail = (email: string) => {
    setSelectedEmails([...selectedEmails, email]);
    setFilteredEmailList(filteredEmailList.filter(e => e !== email)); 
  };

  const handleRemoveEmail = (email: string) => {
    setFilteredEmailList([...filteredEmailList, email]);
    setSelectedEmails(selectedEmails.filter(e => e !== email));
  };

  const handleSubmit = () => {
    if (groupName && selectedEmails.length > 0) {
      handleAddGroup(groupName, selectedEmails); 
      onClose(); 
    } else {
      alert("Please enter a group name and add at least one email.");
    }
  };

  // Paginate emails - show 10 at a time
  const paginatedEmails = filteredEmailList.slice(0, currentPage * emailsPerPage);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
      <div className="bg-white w-full max-w-lg h-auto p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-cyan-800 mb-4">Add Custom Group</h2>

        {/* Group name input */}
        <div className="mb-4">
          <label className="block text-cyan-800 font-semibold mb-2">Group Name</label>
          <input
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
            placeholder="Enter group name"
          />
        </div>

        {/* Email selection */}
        <div className="flex flex-col">
          <label className="block text-cyan-800 font-semibold mb-2">Select a Email</label>
          <div className="flex justify-between space-x-4">
            <div className="w-1/2">
                <h3 className="text-cyan-800 font-semibold text-xs mb-2 mx-4">Available Users</h3>
                {/* Search bar */}
                <div className="mb-2">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="block w-full px-4 py-1 text-sm text-gray-700 border border-gray-200 rounded"
                        placeholder="Search emails..."
                    />
                </div>
                
                <ul className="border p-2 h-40 overflow-y-auto">
                    {paginatedEmails.map((email) => (
                        <li
                            key={email}
                            className="text-sm text-gray-700 hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleSelectEmail(email)}
                        >
                            {email}
                        </li>
                    ))}
                </ul>

                {/* Load more button */}
                {filteredEmailList.length > currentPage * emailsPerPage && (
                <button
                    onClick={handleLoadMore}
                    className="mt-2 text-xs text-white bg-cyan-800 px-4 py-1 rounded"
                >
                    Load More
                </button>
                )}
            </div>

            <div className="w-1/2">
                <h3 className="text-cyan-800 font-semibold text-xs mb-2 mx-4">Selected Emails</h3>
                <ul className="border p-2 h-[198px] overflow-y-auto">
                    {selectedEmails.map((email) => (
                        <li
                            key={email}
                            className="text-sm text-gray-700 hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleRemoveEmail(email)}
                        >
                            {email}
                        </li>
                    ))}
                </ul>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-4 space-x-2">
          <button
            onClick={onClose}
            className="text-cyan-800 px-6 py-2 rounded-lg border border-cyan-800 hover:bg-cyan-800 hover:text-white"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-cyan-800 text-white px-6 py-2 rounded-lg shadow"
          >
            Add Group
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoGroupModal;

