// src/components/admin/EventSetting/ProgramFilter.tsx

import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { FaCirclePlay, FaCirclePause } from "react-icons/fa6";
import { BiReset } from "react-icons/bi";

interface SearchFilterProps {
  onSearch: (searchText: string) => void;
  onFilter: (startDate: string, endDate: string, status: 'all' | 'running' | 'stopped') => void;
}

const SearchFilter: React.FC<SearchFilterProps> = ({ onSearch, onFilter }) => {
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterStatus, setFilterStatus] = useState<'all' | 'running' | 'stopped'>('all');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    onSearch(e.target.value);
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    onFilter(e.target.value, endDate, filterStatus);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    onFilter(startDate, e.target.value, filterStatus);
  };

  const handleResetFilters = () => {
    setSearchText('');
    setStartDate('');
    setEndDate('');
    setFilterStatus('all');
    onSearch('');
    onFilter('', '', 'all');
  };

  const toggleFilterStatus = () => {
    const newStatus = filterStatus === 'all' ? 'running' : filterStatus === 'running' ? 'stopped' : 'all';
    setFilterStatus(newStatus);
    onFilter(startDate, endDate, newStatus);
  };

  return (
    <div className="flex items-center space-x-4 p-4 rounded-full">
      <div className="relative flex-grow">
        <input
          type="text"
          placeholder="Search title"
          value={searchText}
          onChange={handleSearchChange}
          className="w-full p-2 pr-8 border border-gray-300 rounded"
        />
        <FaSearch className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
      </div>
      <div className="flex flex-col">
        <input
          id="startDate"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          className="p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="flex flex-col">
        <input
          id="endDate"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          className="p-2 border border-gray-300 rounded"
        />
      </div>
      <button
        onClick={toggleFilterStatus}
        className="flex items-center space-x-1 px-3 py-2 text-gray-700 rounded"
        style={{ width: '110px' }}
      >
        {filterStatus === 'all' ? (
          <>
            <FaCirclePlay />
            <span>All</span>
          </>
        ) : filterStatus === 'running' ? (
          <>
            <FaCirclePlay />
            <span>Running</span>
          </>
        ) : (
          <>
            <FaCirclePause />
            <span>Stopped</span>
          </>
        )}
      </button>
      <button
        onClick={handleResetFilters}
        className="flex items-center space-x-1 px-3 py-2 text-gray-700 rounded"
      >
        <BiReset />
        <span>Reset</span>
      </button>
    </div>
  );
};

export default SearchFilter;





// // udpate code --> 20 june 2024
// // src/components/admin/EventSetting/ProgramFilter.tsx

// import React, { useState } from 'react';

// interface SearchFilterProps {
//   onSearch: (searchText: string) => void;
//   onFilter: (startDate: string, endDate: string) => void;
// }

// const SearchFilter: React.FC<SearchFilterProps> = ({ onSearch, onFilter }) => {
//   const [searchText, setSearchText] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchText(e.target.value);
//     onSearch(e.target.value);
//   };

//   const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setStartDate(e.target.value);
//     onFilter(e.target.value, endDate);
//   };

//   const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setEndDate(e.target.value);
//     onFilter(startDate, e.target.value);
//   };

//   return (
//     <div className="flex space-x-4">
//       <input
//         type="text"
//         placeholder="Search title"
//         value={searchText}
//         onChange={handleSearchChange}
//         className="p-2 border border-gray-300 rounded"
//       />
//       <input
//         type="date"
//         value={startDate}
//         onChange={handleStartDateChange}
//         className="p-2 border border-gray-300 rounded"
//       />
//       <input
//         type="date"
//         value={endDate}
//         onChange={handleEndDateChange}
//         className="p-2 border border-gray-300 rounded"
//       />
//     </div>
//   );
// };

// export default SearchFilter;
