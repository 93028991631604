// src/components/admin/SubProductSetting/subProductSetting.tsx

// import react component
import React, { useState } from 'react';
// import react icon
import { IoIosCheckbox, IoIosCheckboxOutline  } from "react-icons/io";
// import custom components
import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';
import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
import { useAuth } from '../../../context/AuthContext';   
import { useEnv } from '../../../context/EnvContext'; 
import SubProductModal from './subProductModal';

const SubProductSetting: React.FC = () => {
  // import custom hook
  const { products, loading, addProduct, updateProduct, deleteProduct } = useSubSetting();
  const { apiUrl } = useEnv();
  const { user } = useAuth();
  const { permissions } = useRoleAccess(user?.email || '');    
  // add/edit product modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isEditMode, setIsEditMode] = useState(false); 
  const [currentProduct, setCurrentProduct] = useState<any>(null);

  const handleAddProduct = (
    product: { 
      productId: string; 
      productName: string; 
      productDescription: string; 
      productStatus: string;
      productImg: string | File | null; 
    }) => {
    if (isEditMode && currentProduct) {
      updateProduct(currentProduct.productId, product); 
    } else {
      addProduct(product); 
    }
    setIsModalOpen(false);
    setCurrentProduct(null);
    setIsEditMode(false);
  };

  const handleSelectRow = (productId: string) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(productId)
        ? prevSelectedRows.filter(id => id !== productId) 
        : [...prevSelectedRows, productId]
    );
  };

  const handleDeleteSelected = async () => {
    for (const productId of selectedRows) {
      await deleteProduct(productId); 
    }
    setSelectedRows([]); 
  };

  // Handle row double-click to enter edit mode
  const handleEditProduct = (product: any) => {
    setCurrentProduct(product); 
    setIsEditMode(true);
    setIsModalOpen(true); 
  };

  return (
    <div className="p-4 border rounded">
      <div className="flex justify-end mb-2 space-x-2">
        {/* Toggle Multi-Select Mode */}
        <button 
          className={`text-xl mr-2 
            ${permissions?.productSubscription?.addSub !== 'enable'
              ? 'text-gray-400 cursor-not-allowed' 
              : 'text-cyan-800 '
            }`}
          onClick={() => setIsMultiSelectEnabled(!isMultiSelectEnabled)}
          disabled={permissions?.productSubscription?.editSub !== 'enable'}
        >
          {isMultiSelectEnabled ? <IoIosCheckbox /> : <IoIosCheckboxOutline />}
        </button>

        {/* Add Product Button */}
        <button 
          className={`px-6 py-1 rounded 
            ${permissions?.productSubscription?.addSub !== 'enable'
              ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
              : 'bg-cyan-800 text-white'
            }`}
          onClick={() => {
            setIsEditMode(false);
            setCurrentProduct(null);
            setIsModalOpen(true);
          }}
          disabled={permissions?.productSubscription?.addSub !== 'enable'}
        >
          Add Product
        </button>

        {/* Remove Selected Products Button */}
        <button
          className={`px-6 py-1 rounded 
            ${permissions?.productSubscription?.addSub !== 'enable'
              ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
              : 'bg-cyan-800 text-white'
            }`}
          onClick={handleDeleteSelected}
          disabled={
            !selectedRows.length ||
            permissions?.productSubscription?.deleteSub !== 'enable'
          }
        >
          Remove Product
        </button>
      </div>

      {/* Product Table */}
      <div className="overflow-x-auto">
        <table className="bg-white border text-sm">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              {isMultiSelectEnabled && 
                <th className="py-2 px-4 border-b">Select</th>
              }
              <th className="py-2 px-4 border-b text-nowrap">Product ID</th>
              <th className="py-2 px-4 border-b text-nowrap">Product Name</th>
              <th className="py-2 px-4 border-b text-nowrap">Description</th>
              <th className="py-2 px-4 border-b text-nowrap">Image</th>
              <th className="py-2 px-4 border-b text-nowrap">Status</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} className="text-center py-8">Loading...</td>
              </tr>
            ) : products.length > 0 ? (
              products.map((product) => (
                <tr 
                  key={product.productId} 
                  className={`text-center border-b hover:bg-gray-50 ${
                    permissions?.productSubscription?.editSub === 'enable' ? '' : 'cursor-not-allowed pointer-events-none'
                  }`}
                  onDoubleClick={() => {
                    if (permissions?.productSubscription?.editSub === 'enable') {
                      handleEditProduct(product);
                    }
                  }}
                >
                  {isMultiSelectEnabled && (
                    <td className="py-2 px-4">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(product.productId)}
                        onChange={() => handleSelectRow(product.productId)}
                      />
                    </td>
                  )}
                  <td className="py-2 px-4">{product.productId}</td>
                  <td className="py-2 px-4">{product.productName}</td>
                  <td className="py-2 px-4 text-center text-wrap">{product.productDescription}</td>
                  <td className="py-2 px-4">
                    {typeof product.productImg === 'string' && product.productImg ? (
                      <img 
                        src={`${apiUrl}${product.productImg}`} 
                        alt="Product" 
                        className="w-[100px] h-auto mx-auto" 
                      />
                    ) : (
                      <span>No Image</span>
                    )}
                  </td>
                  <td className={`py-2 px-4 text-wrap ${
                    product.productStatus === 'Active' ? 'text-green-500 font-bold' : 'text-rose-500 font-bold'
                  }`}>
                    {product.productStatus}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center py-8">No products available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal for Adding Product */}
      {isModalOpen && (
        <SubProductModal
          onClose={() => setIsModalOpen(false)}
          onSave={handleAddProduct}
          editMode={isEditMode}
          initialData={currentProduct}
        />
      )}
    </div>
  );
};

export default SubProductSetting;