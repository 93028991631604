
// src/components/client/MiniApp/App03_MySubProduct.tsx

import React, { useState } from 'react';
import { MdOutlineSubscriptions } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import custom file
import { useEnv } from '../../../context/EnvContext'; 
import { useSubData, Product, Plan } from '../../../hooks/Subscription/useSubData';
import SubscriptionItem from './App03_MySubItem';
import SubscriptionPlan from './App03_MySubPlan';
import MySubRegistration from './App03_MySubRegistration';

const MySubProduct: React.FC = () => {
  const [activeTab, setActiveTab] = useState('products');
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [liveOrderId, setLiveOrderId] = useState<string>('');
  const [demoOrderId, setDemoOrderId] = useState<string>('');
  // handle custom state
  const { products, pendingSubscriptions, algoMatrixSubscriptions, traderHubSubscriptions, versionNumber, loading } = useSubData();
  const { apiUrl } = useEnv();

  // Function to generate order ID based on product name
  const generateOrderId = (productName: string) => {
    const prefix = productName.replace(/\s+/g, '-').substring(0, 3).toUpperCase();
    const randomLetters = Math.random().toString(36).substring(2, 6).toUpperCase();
    const randomDigits = String(Math.floor(1000 + Math.random() * 9000));
    return `sub-${prefix}-${randomLetters}${randomDigits}`;
  };

  const handleSelectProduct = (product: Product) => {
    setSelectedProduct(product);
    const newLiveOrderId = generateOrderId(product.productName);
    const newDemoOrderId = generateOrderId(product.productName);
    setLiveOrderId(newLiveOrderId);
    setDemoOrderId(newDemoOrderId);
  };

  const renderSubscriptionList = () => {
    if (!pendingSubscriptions.length && !algoMatrixSubscriptions.length && !traderHubSubscriptions.length) {
      return (
        <div className="text-center p-20 border-dashed border-4 text-xl font-semibold border-gray-300 text-gray-300 border-opacity-60 rounded-lg">
          You have not subscribed to any products yet
        </div>
      );
    }
    
    return (
      <div className="flex flex-col">
        {products.map((product) => (
          <SubscriptionItem 
            key={product.productId} 
            product={product}
            pendingSubscriptions={pendingSubscriptions.filter(sub => sub.subProductId === product.productId)}
            algoMatrixSubscriptions={algoMatrixSubscriptions.filter(sub => sub.subProductId === product.productId)}
            traderHubSubscriptions={traderHubSubscriptions.filter(sub => sub.subProductId === product.productId)}
            versionNumber={versionNumber}
          />
        ))}
      </div>
    );
  };  

  const renderProductList = () => (
    <div className="flex flex-col space-y-4">
      {loading ? (
        <div className="flex flex-row items-center rounded-lg p-2 bg-gray-100">
          <Skeleton width={150} height={150} className="flex w-[150px] h-[150px] rounded-lg" />
          <div className="flex-1 ml-4">
            <Skeleton height={24} width="80%" className="mb-2" />
            <Skeleton height={20} width="90%" className="mb-2" />
            <Skeleton height={20} width="70%" />
          </div>
        </div>
      ) : (
        products
          .filter((product) => product.productStatus === "Active")
          .map((product) => (
            <div 
              key={product.productId} 
              className="flex flex-row justify-between items-center rounded-lg p-2 bg-gray-100
                sm:flex-col
                xs:flex-col"
            >
              <img
                src={product.productImg ? `${apiUrl}${product.productImg}` : '/placeholder.jpg'}
                alt={product.productName}
                className="w-[150px] h-[150px] rounded-lg
                  sm:my-4
                  xs:my-4"
              />
              <div className="flex-1 mx-6">
                <h3 className="text-xl font-semibold
                      sm:text-lg sm:text-center
                      xs:text-base xs:text-center"
                >
                  {product.productName}
                </h3>
                <p className="text-sm text-gray-600
                    sm:text-xs sm:my-2 sm:text-center
                    xs:text-xs xs:my-2 xs:text-center"
                >
                  {product.productDescription}
                </p>
              </div>
              <button
                className="px-2 py-2 mr-4 text-sm bg-cyan-800 text-white text-wrap w-[80px] rounded 
                  sm:mr-0 sm:text-xs sm:text-nowrap sm:my-2
                  xs:mr-0 xs:text-xs xs:text-nowrap xs:my-2"
                onClick={() => handleSelectProduct(product)}
              >
                More Details
              </button>
            </div>
          ))
        )}
      </div>
    );

    return (
      <div className="w-auto h-auto p-8 rounded-lg sm:p-4 xs:p-2">
        <h2 className="flex justify-center items-center text-2xl font-bold mb-4 text-center 
              md:text-xl 
              sm:text-lg 
              xs:text-base"
        >
          <MdOutlineSubscriptions className="mr-2 sm:mr-0 xs:mr-2" />
          R One Subscription Products
        </h2>
        <p className="text-center 
            mb-4 md:text-sm 
            sm:mb-2 sm:text-xs 
            xs:mb-2 xs:text-xs"
        >
          Effortlessly master R One skills and strategies with our R One Product. Our user-friendly subscription
          platform enables you to quickly achieve mastery and excel with ease.
        </p>
        <div className="flex justify-center 
              mb-2 tablet-portrait-min:text-sm
              sm:text-xs sm:mb-4 
              xs:text-xs xs:mb-6"
        >
          <button
            className={`px-9 py-2 ${
              activeTab === 'products' ? 'bg-cyan-800 text-white rounded-l-xl' : 'bg-gray-200 text-black rounded-l-xl'
            }`}
            onClick={() => setActiveTab('products')}
          >
            Products List
          </button>
          <button
            className={`px-4 py-2 ${
              activeTab === 'subscriptions' ? 'bg-cyan-800 text-white rounded-r-xl' : 'bg-gray-200 text-black rounded-r-xl'
            }`}
            onClick={() => setActiveTab('subscriptions')}
          >
            My Subscriptions
          </button>
        </div>
        <div className="overflow-y-auto h-auto max-h-[600px] space-y-4 
              xl:h-[400px] 
              lg:h-[380px]
              tablet-portrait-max:h-[600px]
              tablet-landscape-max:h-[460px] 
              md:h-[380px]
              tablet-portrait-min:h-[500px]
              sm:h-[350px] 
              xs:h-[315px]"
        >
          {activeTab === 'products' && !selectedProduct && !selectedPlan && renderProductList()}
          {activeTab === 'subscriptions' && renderSubscriptionList()}
          
          {activeTab === 'products' && selectedProduct && !selectedPlan && (
            <SubscriptionPlan
              selectedProduct={selectedProduct}  
              onBackToProducts={() => setSelectedProduct(null)}
              onSelectPlan={setSelectedPlan} 
            />
          )}

          {activeTab === 'products' && selectedProduct && selectedPlan ? (
            <MySubRegistration
              plan={selectedPlan}
              selectedProduct={selectedProduct} 
              liveOrderId={liveOrderId} 
              demoOrderId={demoOrderId} 
              onBack={() => setSelectedPlan(null)} 
            />
          ) : null}

        </div>
      </div>
    );
  };

export default MySubProduct;

// // udpate code --> 24 nov 2024
// // src/components/client/MiniApp/App03_MySubProduct.tsx

// import React, { useState } from 'react';
// import { MdOutlineSubscriptions } from 'react-icons/md';
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';
// // import custom file
// import { useEnv } from '../../../context/EnvContext'; 
// import { useSubData, Product, Plan } from '../../../hooks/Subscription/useSubData';
// import SubscriptionItem from './App03_MySubItem';
// import SubscriptionPlan from './App03_MySubPlan';
// import MySubRegistration from './App03_MySubRegistration';

// const MySubProduct: React.FC = () => {
//   const [activeTab, setActiveTab] = useState('products');
//   const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
//   const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
//   const [liveOrderId, setLiveOrderId] = useState<string>('');
//   const [demoOrderId, setDemoOrderId] = useState<string>('');
//   // import state
//   const { products, pendingSubscriptions, algoMatrixSubscriptions, traderHubSubscriptions, versionNumber, loading } = useSubData();
//   const { apiUrl } = useEnv();

//   // Function to generate order ID based on product name
//   const generateOrderId = (productName: string) => {
//     const prefix = productName.replace(/\s+/g, '-').substring(0, 3).toUpperCase();
//     const randomLetters = Math.random().toString(36).substring(2, 6).toUpperCase();
//     const randomDigits = String(Math.floor(1000 + Math.random() * 9000));
//     return `sub-${prefix}-${randomLetters}${randomDigits}`;
//   };

//   const handleSelectProduct = (product: Product) => {
//     setSelectedProduct(product);
//     const newLiveOrderId = generateOrderId(product.productName);
//     const newDemoOrderId = generateOrderId(product.productName);
//     setLiveOrderId(newLiveOrderId);
//     setDemoOrderId(newDemoOrderId);
//   };

//   const renderSubscriptionList = () => {
//     if (!pendingSubscriptions.length && !algoMatrixSubscriptions.length && !traderHubSubscriptions.length) {
//       return (
//         <div className="text-center p-20 border-dashed border-4 text-xl font-semibold border-gray-300 text-gray-300 border-opacity-60 rounded-lg">
//           You have not subscribed to any products yet
//         </div>
//       );
//     }
    
//     return (
//       <div className="flex flex-col">
//         {products.map((product) => (
//           <SubscriptionItem 
//             key={product.productId} 
//             product={product}
//             pendingSubscriptions={pendingSubscriptions.filter(sub => sub.subProductId === product.productId)}
//             algoMatrixSubscriptions={algoMatrixSubscriptions.filter(sub => sub.subProductId === product.productId)}
//             traderHubSubscriptions={traderHubSubscriptions.filter(sub => sub.subProductId === product.productId)}
//             versionNumber={versionNumber}
//           />
//         ))}
//       </div>
//     );
//   };  

//   const renderProductList = () => (
//     <div className="flex flex-col space-y-4">
//       {loading ? (
//         <div className="flex flex-row items-center rounded-lg p-2 bg-gray-100">
//           <Skeleton width={150} height={150} className="flex w-[150px] h-[150px] rounded-lg" />
//           <div className="flex-1 ml-4">
//             <Skeleton height={24} width="80%" className="mb-2" />
//             <Skeleton height={20} width="90%" className="mb-2" />
//             <Skeleton height={20} width="70%" />
//           </div>
//         </div>
//       ) : (
//         products
//           .filter((product) => product.productStatus === "Active")
//           .map((product) => (
//             <div 
//               key={product.productId} 
//               className="flex flex-row justify-between items-center rounded-lg p-2 bg-gray-100"
//             >
//               <img
//                 src={product.productImg ? `${apiUrl}${product.productImg}` : '/placeholder.jpg'}
//                 alt={product.productName}
//                 className="w-[150px] h-[150px] rounded-lg"
//               />
//               <div className="flex-1 mx-6">
//                 <h3 className="text-xl font-semibold">{product.productName}</h3>
//                 <p className="text-sm text-gray-600 ">{product.productDescription}</p>
//               </div>
//               <button
//                 className="px-2 py-2 mr-4 text-sm bg-cyan-800 text-white text-wrap w-[80px] rounded sm:mr-0 xs:mr-0 xs:text-xs"
//                 onClick={() => handleSelectProduct(product)}
//               >
//                 More Details
//               </button>
//             </div>
//           ))
//         )}
//       </div>
//     );

//     return (
//       <div className="w-auto h-auto p-8 rounded-lg sm:p-4 xs:p-2">
//         <h2 className="flex justify-center items-center text-2xl font-bold mb-4 text-center sm:text-xl xs:text-base">
//           <MdOutlineSubscriptions className="mr-2 sm:mr-0 xs:mr-2" />
//           R One Subscription Products
//         </h2>
//         <p className="text-center mb-4 sm:mb-2 xs:mb-2 sm:text-sm xs:text-xs">
//           Effortlessly master R One skills and strategies with our R One Product. Our user-friendly subscription
//           platform enables you to quickly achieve mastery and excel with ease.
//         </p>
//         <div className="flex justify-center mb-2 sm:text-sm sm:mb-4 xs:text-xs">
//           <button
//             className={`px-9 py-2 ${
//               activeTab === 'products' ? 'bg-cyan-800 text-white rounded-l-xl' : 'bg-gray-200 text-black rounded-l-xl'
//             }`}
//             onClick={() => setActiveTab('products')}
//           >
//             Products List
//           </button>
//           <button
//             className={`px-4 py-2 ${
//               activeTab === 'subscriptions' ? 'bg-cyan-800 text-white rounded-r-xl' : 'bg-gray-200 text-black rounded-r-xl'
//             }`}
//             onClick={() => setActiveTab('subscriptions')}
//           >
//             My Subscriptions
//           </button>
//         </div>
//         <div className="overflow-y-auto h-auto max-h-screen space-y-4 xl:h-[380px] lg:h-[380px] md:h-[370px] sm:h-[325px] xs:h-[315px]">
//           {activeTab === 'products' && !selectedProduct && !selectedPlan && renderProductList()}
//           {activeTab === 'subscriptions' && renderSubscriptionList()}
          
//           {selectedProduct && !selectedPlan && (
//             <SubscriptionPlan
//               selectedProduct={selectedProduct}  
//               onBackToProducts={() => setSelectedProduct(null)}
//               onSelectPlan={setSelectedPlan} 
//             />
//           )}

//           {selectedProduct && selectedPlan ? (
//             <MySubRegistration
//               plan={selectedPlan}
//               selectedProduct={selectedProduct} 
//               liveOrderId={liveOrderId} 
//               demoOrderId={demoOrderId} 
//               onBack={() => setSelectedPlan(null)} 
//             />
//           ) : null}

//         </div>
//       </div>
//     );
//   };

// export default MySubProduct;