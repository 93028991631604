// src/components/client/MiniApp/App03_MySubItem.tsx

import React, { useState, useEffect } from 'react';
// import react icon
import { AiOutlineDownload, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FaRegEdit } from "react-icons/fa";
import { TbLayoutBottombarExpand, TbLayoutNavbarExpandFilled } from "react-icons/tb";
// import custom file
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
import { Product, useSubData, SubUser, AlgoMatrixData, TraderHubData } from '../../../hooks/Subscription/useSubData';
import { useEnv } from '../../../context/EnvContext';
import MySubRegistration from './App03_MySubRegistration';
import MyTicketAlert from './App_AlertMessage';

interface SubscriptionProps {
  product: Product;
  pendingSubscriptions: SubUser[];
  algoMatrixSubscriptions: AlgoMatrixData[];
  traderHubSubscriptions: TraderHubData[];
  versionNumber: string;
}

const SubscriptionItem: React.FC<SubscriptionProps> = ({ product, pendingSubscriptions, algoMatrixSubscriptions, traderHubSubscriptions, versionNumber }) => {
  // State for custom hook
  const { apiUrl } = useEnv();
  const { getStatusStyle, getDownloadFile, updateDemoAccount, updateExpiredDays, fetchSubscriptions, plans, products } = useSubData();
  const { user } = useUserDashboard();
  // State for tab selection
  const [activeTab, setActiveTab] = useState<'algomatrix' | 'mt4'>('algomatrix');
  const [selectedMt4App, setSelectedMt4App] = useState<string>('');
  const [expandedDetails, setExpandedDetails] = useState<boolean[]>(
    Array(pendingSubscriptions.length + algoMatrixSubscriptions.length + traderHubSubscriptions.length).fill(false)
  );
  const [showPassword, setShowPassword] = useState<boolean[]>(
    Array(pendingSubscriptions.length + algoMatrixSubscriptions.length + traderHubSubscriptions.length).fill(false)
  );
  // state for modifying account information
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedAlgoMatrixSubscriptions, setUpdatedAlgoMatrixSubscriptions] = useState(algoMatrixSubscriptions);
  const [updatedTraderHubSubscriptions, setUpdatedTraderHubSubscriptions] = useState(traderHubSubscriptions);
  const [newAccountData, setNewAccountData] = useState<{
    subOrderId: string;
    subAccountNumber: string;
    subAccountPassword: string;
  }>({
    subOrderId: '',
    subAccountNumber: '',
    subAccountPassword: '',
  }); 
  // State for show registration date and renewal date
  const [showRegistration, setShowRegistration] = useState(false);
  const [renewalData, setRenewalData] = useState<Partial<SubUser> | null>(null);
  // State for managing alert messages
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertType, setAlertType] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const updateSubscriptionsExpiredDays = async () => {
      if (user) {
        // Update AlgoMatrix subscriptions
        const updatedAlgoMatrix = await Promise.all(
          algoMatrixSubscriptions.map(async (subData) => {
            const updatedDays = await updateExpiredDays(subData.subOrderId, user.email);
            return { ...subData, subExpiredDays: updatedDays };
          })
        );
  
        // Update TraderHub subscriptions
        const updatedTraderHub = await Promise.all(
          traderHubSubscriptions.map(async (subData) => {
            const updatedDays = await updateExpiredDays(subData.subOrderId, user.email);
            return { ...subData, subExpiredDays: updatedDays };
          })
        );
  
        setUpdatedAlgoMatrixSubscriptions(updatedAlgoMatrix);
        setUpdatedTraderHubSubscriptions(updatedTraderHub);
      }
    };
  
    updateSubscriptionsExpiredDays();
  }, [user, algoMatrixSubscriptions, traderHubSubscriptions, updateExpiredDays]);  

  const getPlanName = (planId: string) => {
    const plan = plans.find(p => p.planId === planId);
    return plan ? plan.planName : 'Unknown Plan';
  };

  const toggleAccountDetails = (index: number) => {
    setExpandedDetails(prevState => {
      const newExpandedDetails = [...prevState];
      newExpandedDetails[index] = !newExpandedDetails[index];
      return newExpandedDetails;
    });
  };

  const togglePasswordVisibility = (index: number) => {
    setShowPassword(prevState => {
      const newShowPassword = [...prevState];
      newShowPassword[index] = !newShowPassword[index];
      return newShowPassword;
    });
  };

  const renderPassword = (password: string, index: number) => {
    return showPassword[index] ? password : `*****${password.slice(-4)}`;
  };

  const handleOpenModal = (
    subOrderId: string,
    subAccountNumber: string = '',
    subAccountPassword: string = ''
  ) => {
    setNewAccountData({ subOrderId, subAccountNumber, subAccountPassword });
    setIsModalOpen(true);
  };
  
  const handleSaveAccountData = async () => {
    const userEmail = user?.email;
    if (!newAccountData.subOrderId || !userEmail) {
      console.error("Required data missing. Either subOrderId or userEmail is undefined.");
      return;
    }
  
    // Find the current subscription from the updatedAlgoMatrixSubscriptions
    const currentSubscription = updatedAlgoMatrixSubscriptions.find(
      (sub) => sub.subOrderId === newAccountData.subOrderId
    );
  
    // Check if `subDemoCheck` is 0 and show an error message on the front end
    if (currentSubscription && currentSubscription.subDemoCheck <= 0) {
      setAlertType('error');
      setAlertMessage(
        'You have reached the maximum allowed changes for this demo account. No further modifications are permitted.'
      );
      setAlertVisible(true);
      return;
    }
  
    try {
      // Update the account data using the API
      await updateDemoAccount(
        newAccountData.subOrderId,
        Number(newAccountData.subAccountNumber),
        newAccountData.subAccountPassword,
        userEmail
      );
  
      // Refetch all subscriptions to update the UI
      await fetchSubscriptions();
  
      setIsModalOpen(false);
      setAlertType('success');
      setAlertMessage('Your account has been successfully updated. Please refresh your browser to view the changes.');
      setAlertVisible(true);
    } catch (error) {
      console.error("Failed to update account data:", error);
      setAlertType('error');
      setAlertMessage('Failed to update account. Please try again later.');
      setAlertVisible(true);
    }
  };  

  const handleRenew = (subData: SubUser) => {
    setRenewalData({
      subOrderId: subData.subOrderId,
      subProductId: subData.subProductId,
      subPlanId: subData.subPlanId,
      subUserName: subData.subUserName,
      subUserEmail: subData.subUserEmail,
      subAccountType: subData.subAccountType,
      subAccountNumber: subData.subAccountNumber || '',
      subAccountPassword: subData.subAccountPassword || '',
      subAccountServer: subData.subAccountServer || '',
      subPlanType: subData.subPlanType,
      subPaymentMethod: subData.subPaymentMethod || '',
      subVoucherCheck: subData.subVoucherCheck || 0,
      subDemoCheck: subData.subDemoCheck || 0,
    });    
    setShowRegistration(true);
  };
  
  const handleDownload = async (type: string, app?: string) => {
    try {
      await getDownloadFile(type, app);
      // Show success alert
      setAlertType('success');
      setAlertMessage('Download successful! Your file is now ready.');
      setAlertVisible(true);
    } catch (error) {
      console.error('Error during download:', error);
      // Show error alert
      setAlertType('error');
      setAlertMessage('Download failed. Please try again later.');
      setAlertVisible(true);
    }
  };     
    
  const renderSubscriptions = (
    subscriptions: (SubUser | AlgoMatrixData | TraderHubData)[], 
    indexOffset: number
  ) => {
    return subscriptions.map((subData, index) => {
      const currentDate = new Date();
      const expiredDate = new Date(subData.subExpiredDate);
      const daysUntilExpiration = Math.ceil((expiredDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
      
      // Determine if subscription is expired
      const isExpired = expiredDate < currentDate;
      
      return (
      <div key={subData.subOrderId} className="rounded-lg p-4 bg-gray-100 my-2 w-full">
        <div className="flex flex-row justify-between items-center mx-6
              sm:flex-col
              xs:flex-col"
        >
          <div className="flex flex-row items-center  
                sm:flex-col
                xs:flex-col"
          >
            <img
              src={product ? `${apiUrl}${product.productImg}` : '/placeholder.jpg'}
              alt={product?.productName || 'Product Image'}
              className="w-[150px] h-auto rounded-lg object-cover 
                md:w-[150px] 
                sm:w-[120px]
                xs:w-[120px]"
            />
            <div className="ml-4">
              <div className="my-2">
                <div className="flex items-center
                      sm:justify-center
                      xs:justify-center"
                >
                  <div className="text-xl font-bold 
                        md:text-lg 
                        sm:text-lg 
                        xs:text-base"
                  >
                    {product?.productName}
                  </div>
                  <div className={
                    `${getStatusStyle(subData.subStatus)} text-[10px] ml-2 px-4 py-0 rounded-full 
                        md:text-[12px] 
                        sm:text-[10px] 
                        xs:text-[8px]`
                      }
                  >
                    {subData.subStatus}
                  </div>
                </div>
              </div>

              <div className="flex flex-row space-x-4">
                <div className="text-sm font-semibold 
                      md:text-xs 
                      sm:text-xs 
                      xs:text-xs"
                >
                  <div>Plan:</div>
                  <div>Period:</div>
                  <div>Expired On:</div>
                </div>
                <div>
                  <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
                    {getPlanName(subData.subPlanId)} 
                  </div>
                  <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
                    {subData.subPlanType} with {subData.subPlanPeriod} days
                  </div>
                  <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
                    {new Date(subData.subExpiredDate).toLocaleDateString('en-CA')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-normal items-center">
            {/* Display Renew button if subscription is expired */}
            {isExpired ? (
              <button
                className={`bg-cyan-800 text-white rounded-xl px-4 py-2 text-center text-lg font-semibold 
                  ${subData.subStatus === 'Pending' ? 'bg-gray-400 cursor-not-allowed' : ''
                }`}
                onClick={() => { 
                  if (subData.subStatus !== "Pending") {
                    handleRenew(subData);
                  }
                }}
                disabled={subData.subStatus === 'Pending'}
              >
                Renew
              </button>
            ) : (
              <div
                className="flex items-center bg-cyan-800 text-white text-center rounded-xl font-semibold text-2xl px-4 py-2 cursor-pointer
                  tablet-portrait-min:text-base
                  tablet-landscape-min::text-xl
                  sm:text-base sm:my-2
                  xs:text-sm xs:my-2"
                onClick={() => {
                  if (daysUntilExpiration <= 30 && daysUntilExpiration > 0 && subData.subStatus !== 'Pending') {
                    handleRenew(subData);
                  }
                }}
              >
                {subData.subExpiredDays} <span className="text-sm ml-2">Days</span>
              </div>
            )}

            <div
              className={`ml-2 text-2xl ${['Pending', 'Expire'].includes(subData.subStatus) ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer'}`}
              onClick={
                !['Pending', 'Expire'].includes(subData.subStatus) ? () => toggleAccountDetails(index + indexOffset) : undefined
              }
            >
              {expandedDetails[index + indexOffset] ? <TbLayoutNavbarExpandFilled /> : <TbLayoutBottombarExpand />}
            </div>
          </div>
        </div>

        {/* Alert Text for Expiration */}
        {daysUntilExpiration <= 30 && (
          <div
            className={`text-xs mt-2 text-center ${
              subData.subStatus === 'Pending'
                ? 'text-yellow-600'
                : isExpired
                ? 'text-red-600'
                : daysUntilExpiration <= 14
                ? 'text-red-600'
                : 'text-yellow-600'
            }`}
          >
            {subData.subStatus === 'Pending'
              ? 'Your subscription is pending approval. Please wait for it to be approved.'
              : isExpired
              ? 'Your subscription has expired. Please click "Renew" to renew your subscription.'
              : daysUntilExpiration <= 14
              ? 'Your subscription is expiring soon. Click the day count to renew.'
              : 'Your subscription expires in less than 30 days. Renew by clicking the day count.'}
          </div>
        )}

        {/* Expanded Details */}
        {expandedDetails[index + indexOffset] && (
          <div className="mt-2 bg-white p-4 rounded-lg shadow-lg w-full 
                sm:mt-2 
                xs:mt-2"
          >
            {product.productName === "TraderHub" ? (
              <div className="text-center">
                <p className="text-sm font-semibold mb-2 text-rose-600
                    sm:text-xs
                    xs:text-xs">
                  Please use this link to join the TraderHub Telegram group:
                </p>
                <a 
                  href="https://t.me/ROneTraderHubCS6bot" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-cyan-800 underline text-base
                    sm:text-sm
                    xs:text-sm"
                >
                  https://t.me/ROneTraderHubCS6bot
                </a>
              </div>
            ) : (
              <div className="flex flex-row justify-between items-center
                    sm:flex-col
                    xs:flex-col"
              >   
                <div className="mx-4">
                  <div className="flex justify-normal items-center">
                    <label className="font-semibold border-b border-cyan-800 text-cyan-800 text-sm">
                      Account Info:
                    </label>
                    {subData.subAccountType === 'DEMO' && (
                      <button 
                        onClick={() => handleOpenModal(subData.subOrderId, subData.subAccountNumber || '', subData.subAccountPassword || '')}
                      >
                        <FaRegEdit className="ml-2"/>
                      </button>
                    )}
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-col justify-start mr-2">
                      <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Type:</strong></div>
                      <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Number:</strong></div>
                      <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Password:</strong></div>
                      <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Server:</strong></div>
                    </div>
                    <div className="flex flex-col justify-start">
                      <div className="text-sm md:text-xs sm:text-xs xs:text-xs">{subData.subAccountType}</div>
                      <div className="text-sm md:text-xs sm:text-xs xs:text-xs">{subData.subAccountNumber}</div>
                      <div className="flex items-center text-sm md:text-xs sm:text-xs xs:text-xs">
                        {renderPassword(subData.subAccountPassword || '', index + indexOffset)}
                        <button onClick={() => togglePasswordVisibility(index + indexOffset)} className="ml-2 text-cyan-800">
                          {showPassword[index + indexOffset] ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                        </button>
                      </div>
                      <div className="text-sm md:text-xs sm:text-xs xs:text-xs">{subData.subAccountServer}</div>
                    </div>
                  </div>
                </div>

                {/* Modal for updating demo account information */}
                {isModalOpen && (
                  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-auto max-w-xl
                          sm:max-w-sm
                          xs:max-w-xs"
                    >
                      <h2 className="text-xl font-semibold
                            sm:text-base
                            xs:text-sm"
                      >
                        Update Account Information
                      </h2>
                      <div className="text-rose-600 text-wrap text-left p-4
                            sm:text-sm
                            xs:text-xs"
                      >
                        Warning: This demo account can only be changed <span className="font-bold ml-1 text-rose-800">{subData.subDemoCheck}</span> times. After reaching this limit, 
                        you will no longer be able to change the account number and password.
                      </div>

                      {/* Demo Account Number */}
                      <label className="block mb-2 font-semibold mx-4
                              sm:text-base
                              xs:text-sm"
                      >
                        New Account Number:
                      </label>
                      <input 
                        type="number" 
                        className="w-full p-2 border rounded mb-4"
                        placeholder='Enter your new account number...'
                        value={newAccountData.subAccountNumber} 
                        onChange={(e) => setNewAccountData({ ...newAccountData, subAccountNumber: e.target.value })}
                      />
                      {/* Demo Account Password */}
                      <label className="block mb-2 font-semibold mx-4
                              sm:text-base
                              xs:text-sm"
                      >
                        New Account Password:
                      </label>
                      <div className="relative">
                        <input 
                          type={showPassword[0] ? "text" : "password"} 
                          className="w-full p-2 border rounded mb-4"
                          placeholder="Enter your new account password..."
                          value={newAccountData.subAccountPassword} 
                          onChange={(e) => setNewAccountData({ ...newAccountData, subAccountPassword: e.target.value })}
                        />
                        <button
                          type="button"
                          className="absolute right-3 top-2 text-gray-600"
                          onClick={() => setShowPassword([!showPassword[0], ...showPassword.slice(1)])}
                        >
                          {showPassword[0] ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                        </button>
                      </div>
                      {/* modal execution button */}
                      <div className="flex justify-end space-x-4">
                        <button 
                          onClick={() => setIsModalOpen(false)} 
                          className="px-4 py-2 bg-gray-300 rounded-md"
                        >
                          Cancel
                        </button>
                        <button 
                          onClick={handleSaveAccountData} 
                          className="px-4 py-2 bg-cyan-800 text-white rounded-md"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="w-auto max-w-[800px] mx-2">
                  <div className="flex justify-normal items-center text-xs mb-2
                        sm:my-2
                        xs:my-2"
                  >
                    <button 
                      className={
                        `px-4 py-2 w-full rounded-l-md ${activeTab === 'algomatrix' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}
                        sm:px-2
                        xs:px-1 xs:ml-2`
                      }
                      onClick={() => setActiveTab('algomatrix')}
                    >
                      AlgoMatrix
                    </button>
                    <button 
                      className={
                        `px-4 py-2 w-full rounded-r-md ${activeTab === 'mt4' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}
                        sm:px-2
                        xs:px-1 xs:mr-2`
                      }
                      onClick={() => setActiveTab('mt4')}
                    >
                      MT4 Terminal
                    </button>
                  </div>

                  {activeTab === 'algomatrix' ? (
                    <div>
                      <div className="text-sm flex justify-center items-center py-1 space-x-4
                            sm:text-xs
                            xs:text-xs"
                      >
                        <span>Current Version: <strong>{versionNumber}</strong></span>
                        <button
                          className="border-cyan-800 text-cyan-800 border rounded-full px-4 py-0.5 flex items-center justify-center"
                          onClick={() => handleDownload('algomatrix')}
                        >
                          Download <AiOutlineDownload className="ml-2" />
                        </button>
                      </div>
                      <div className="text-red-600 text-xs text-center">
                        <span>Only support MT4, installation guide click </span>
                        <button
                          className="text-rose-600 underline hover:text-cyan-800 px-1"
                          onClick={() => handleDownload('installation-guide')}
                        >
                          here
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center">
                      <div className="flex justify-normal items-center">
                        <label className="text-sm w-full mx-6 font-semibold
                                sm:text-xs sm:text-nowrap 
                                xs:text-xs xs:text-nowrap xs:mx-4"
                        >
                          Select MT4:
                        </label>
                        <select
                          name="mt4AppDropdown"
                          className="border border-cyan-800 rounded py-1 px-4 w-auto text-sm text-center
                            xs:text-xs"
                          value={selectedMt4App}
                          onChange={(e) => {
                            const selectedApp = e.target.value;
                            setSelectedMt4App(selectedApp);
                            if (selectedApp) {
                              handleDownload('mt4', selectedApp); 
                            }
                          }}
                        >
                          <option value="">Select an MT4</option>
                          <option value="fortuneprimeglobal4setup">Fortune Prime</option>
                          <option value="lirunexlimited4setup">Lirunex</option>
                        </select>
                      </div>
                      <span className="text-red-600 text-xs text-center"> 
                        Select a MT4 Terminal installer to download
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      );
    });
  };

   // Conditional rendering for registration
   if (showRegistration && renewalData) {
    return (
      <MySubRegistration
        plan={plans.find(plan => plan.planId === renewalData.subPlanId) || plans[0]} 
        selectedProduct={products.find(p => p.productId === renewalData.subProductId) || product}
        liveOrderId={renewalData.subOrderId || ''}
        demoOrderId={''} 
        onBack={() => {
          setShowRegistration(false);
          setRenewalData(null);
        }}
        renewalData={renewalData}
        isRenew={true}
      />
    );
  }

  return (
    <>
      {/* Alert Message */}
      <MyTicketAlert
        type={alertType}
        message={alertMessage}
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
      />

      {showRegistration && renewalData ? (
        <MySubRegistration
          plan={plans.find(plan => plan.planId === renewalData.subPlanId) || plans[0]} 
          selectedProduct={products.find(p => p.productId === renewalData.subProductId) || product}
          liveOrderId={renewalData.subOrderId || ''}
          demoOrderId={''} 
          onBack={() => {
            setShowRegistration(false);
            setRenewalData(null);
          }}
          renewalData={renewalData}
          isRenew={true}
        />
      ) : (
        <div className="flex flex-col items-center" key={product.productId}>
          {pendingSubscriptions.length > 0 &&
            renderSubscriptions(pendingSubscriptions, 0)}
        
          {updatedAlgoMatrixSubscriptions.length > 0 &&
            renderSubscriptions(
              updatedAlgoMatrixSubscriptions,
              pendingSubscriptions.length
            )}
        
          {updatedTraderHubSubscriptions.length > 0 &&
            renderSubscriptions(
              updatedTraderHubSubscriptions,
              pendingSubscriptions.length + algoMatrixSubscriptions.length
            )}
        </div>
      )}
    </>
  );    
};

export default SubscriptionItem;

// // udpate code --> 26 nov 2024
// // src/components/client/MiniApp/App03_MySubItem.tsx

// import React, { useState, useEffect } from 'react';
// // import react icon
// import { AiOutlineDownload, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
// import { FaRegEdit } from "react-icons/fa";
// import { TbLayoutBottombarExpand, TbLayoutNavbarExpandFilled } from "react-icons/tb";
// // import custom file
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import { Product, useSubData, SubUser, AlgoMatrixData, TraderHubData } from '../../../hooks/Subscription/useSubData';
// import { useEnv } from '../../../context/EnvContext';
// import MySubRegistration from './App03_MySubRegistration';
// import MyTicketAlert from './App_AlertMessage';

// interface SubscriptionProps {
//   product: Product;
//   pendingSubscriptions: SubUser[];
//   algoMatrixSubscriptions: AlgoMatrixData[];
//   traderHubSubscriptions: TraderHubData[];
//   versionNumber: string;
// }

// const SubscriptionItem: React.FC<SubscriptionProps> = ({ product, pendingSubscriptions, algoMatrixSubscriptions, traderHubSubscriptions, versionNumber }) => {
//   // State for custom hook
//   const { apiUrl } = useEnv();
//   const { getStatusStyle, getDownloadFile, updateDemoAccount, updateExpiredDays, plans, products } = useSubData();
//   const { user } = useUserDashboard();
//   // State for tab selection
//   const [activeTab, setActiveTab] = useState<'algomatrix' | 'mt4'>('algomatrix');
//   const [selectedMt4App, setSelectedMt4App] = useState<string>('');
//   const [expandedDetails, setExpandedDetails] = useState<boolean[]>(
//     Array(pendingSubscriptions.length + algoMatrixSubscriptions.length + traderHubSubscriptions.length).fill(false)
//   );
//   const [showPassword, setShowPassword] = useState<boolean[]>(
//     Array(pendingSubscriptions.length + algoMatrixSubscriptions.length + traderHubSubscriptions.length).fill(false)
//   );
//   // state for modifying account information
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [updatedAlgoMatrixSubscriptions, setUpdatedAlgoMatrixSubscriptions] = useState(algoMatrixSubscriptions);
//   const [updatedTraderHubSubscriptions, setUpdatedTraderHubSubscriptions] = useState(traderHubSubscriptions);
//   const [newAccountData, setNewAccountData] = useState<{
//     subOrderId: string;
//     subAccountNumber: string;
//     subAccountPassword: string;
//   }>({
//     subOrderId: '',
//     subAccountNumber: '',
//     subAccountPassword: '',
//   }); 
//   // State for show registration date and renewal date
//   const [showRegistration, setShowRegistration] = useState(false);
//   const [renewalData, setRenewalData] = useState<Partial<SubUser> | null>(null);
//   // State for managing alert messages
//   const [alertVisible, setAlertVisible] = useState(false);
//   const [alertMessage, setAlertMessage] = useState<string>('');
//   const [alertType, setAlertType] = useState<'success' | 'error'>('success');

//   useEffect(() => {
//     const updateSubscriptionsExpiredDays = async () => {
//       if (user) {
//         // Update AlgoMatrix subscriptions
//         const updatedAlgoMatrix = await Promise.all(
//           algoMatrixSubscriptions.map(async (subData) => {
//             const updatedDays = await updateExpiredDays(subData.subOrderId, user.email);
//             return { ...subData, subExpiredDays: updatedDays };
//           })
//         );
  
//         // Update TraderHub subscriptions
//         const updatedTraderHub = await Promise.all(
//           traderHubSubscriptions.map(async (subData) => {
//             const updatedDays = await updateExpiredDays(subData.subOrderId, user.email);
//             return { ...subData, subExpiredDays: updatedDays };
//           })
//         );
  
//         setUpdatedAlgoMatrixSubscriptions(updatedAlgoMatrix);
//         setUpdatedTraderHubSubscriptions(updatedTraderHub);
//       }
//     };
  
//     updateSubscriptionsExpiredDays();
//   }, [user, algoMatrixSubscriptions, traderHubSubscriptions, updateExpiredDays]);  

//   const getPlanName = (planId: string) => {
//     const plan = plans.find(p => p.planId === planId);
//     return plan ? plan.planName : 'Unknown Plan';
//   };

//   const toggleAccountDetails = (index: number) => {
//     setExpandedDetails(prevState => {
//       const newExpandedDetails = [...prevState];
//       newExpandedDetails[index] = !newExpandedDetails[index];
//       return newExpandedDetails;
//     });
//   };

//   const togglePasswordVisibility = (index: number) => {
//     setShowPassword(prevState => {
//       const newShowPassword = [...prevState];
//       newShowPassword[index] = !newShowPassword[index];
//       return newShowPassword;
//     });
//   };

//   const renderPassword = (password: string, index: number) => {
//     return showPassword[index] ? password : `*****${password.slice(-4)}`;
//   };

//   const handleOpenModal = (
//     subOrderId: string,
//     subAccountNumber: string = '',
//     subAccountPassword: string = ''
//   ) => {
//     setNewAccountData({ subOrderId, subAccountNumber, subAccountPassword });
//     setIsModalOpen(true);
//   };
  
//   const handleSaveAccountData = async () => {
//     const userEmail = user?.email;
  
//     if (!newAccountData.subOrderId || !userEmail) {
//       console.error("Required data missing. Either subOrderId or userEmail is undefined.");
//       return;
//     }
  
//     try {
//       // Proceed with updating the account details
//       await updateDemoAccount(
//         newAccountData.subOrderId,
//         Number(newAccountData.subAccountNumber),
//         newAccountData.subAccountPassword,
//         userEmail
//       );
  
//       setIsModalOpen(false);
//     } catch (error) {
//       console.error("Failed to update account data:", error);
//     }
//   };

//   const handleRenew = (subData: SubUser) => {
//     setRenewalData({
//       subOrderId: subData.subOrderId,
//       subProductId: subData.subProductId,
//       subPlanId: subData.subPlanId,
//       subUserName: subData.subUserName,
//       subUserEmail: subData.subUserEmail,
//       subAccountType: subData.subAccountType,
//       subAccountNumber: subData.subAccountNumber || '',
//       subAccountPassword: subData.subAccountPassword || '',
//       subAccountServer: subData.subAccountServer || '',
//       subPlanType: subData.subPlanType,
//       subPaymentMethod: subData.subPaymentMethod || '',
//       subVoucherCheck: subData.subVoucherCheck || 0,
//       subDemoCheck: subData.subDemoCheck || 0,
//     });    
//     setShowRegistration(true);
//   };
  
//   const handleDownload = async (type: string, app?: string) => {
//     try {
//       await getDownloadFile(type, app);
//       // Show success alert
//       setAlertType('success');
//       setAlertMessage('Download successful! Your file is now ready.');
//       setAlertVisible(true);
//     } catch (error) {
//       console.error('Error during download:', error);
//       // Show error alert
//       setAlertType('error');
//       setAlertMessage('Download failed. Please try again later.');
//       setAlertVisible(true);
//     }
//   };     
    
//   const renderSubscriptions = (
//     subscriptions: (SubUser | AlgoMatrixData | TraderHubData)[], 
//     indexOffset: number
//   ) => {
//     return subscriptions.map((subData, index) => {
//       const currentDate = new Date();
//       const expiredDate = new Date(subData.subExpiredDate);
//       const daysUntilExpiration = Math.ceil((expiredDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
      
//       // Determine if subscription is expired
//       const isExpired = expiredDate < currentDate;
      
//       return (
//       <div key={subData.subOrderId} className="rounded-lg p-4 bg-gray-100 my-2 w-full">
//         <div className="flex flex-row justify-between items-center mx-6
//               sm:flex-col
//               xs:flex-col"
//         >
//           <div className="flex flex-row items-center  
//                 sm:flex-col
//                 xs:flex-col"
//           >
//             <img
//               src={product ? `${apiUrl}${product.productImg}` : '/placeholder.jpg'}
//               alt={product?.productName || 'Product Image'}
//               className="w-[150px] h-auto rounded-lg object-cover 
//                 md:w-[150px] 
//                 sm:w-[120px]
//                 xs:w-[120px]"
//             />
//             <div className="ml-4">
//               <div className="my-2">
//                 <div className="flex items-center
//                       sm:justify-center
//                       xs:justify-center"
//                 >
//                   <div className="text-xl font-bold 
//                         md:text-lg 
//                         sm:text-lg 
//                         xs:text-base"
//                   >
//                     {product?.productName}
//                   </div>
//                   <div className={
//                     `${getStatusStyle(subData.subStatus)} text-[10px] ml-2 px-4 py-0 rounded-full 
//                         md:text-[12px] 
//                         sm:text-[10px] 
//                         xs:text-[8px]`
//                       }
//                   >
//                     {subData.subStatus}
//                   </div>
//                 </div>
//               </div>

//               <div className="flex flex-row space-x-4">
//                 <div className="text-sm font-semibold 
//                       md:text-xs 
//                       sm:text-xs 
//                       xs:text-xs"
//                 >
//                   <div>Plan:</div>
//                   <div>Period:</div>
//                   <div>Expired On:</div>
//                 </div>
//                 <div>
//                   <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
//                     {getPlanName(subData.subPlanId)} 
//                   </div>
//                   <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
//                     {subData.subPlanType} with {subData.subPlanPeriod} days
//                   </div>
//                   <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
//                     {new Date(subData.subExpiredDate).toLocaleDateString('en-CA')}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="flex justify-normal items-center">
//             {/* Display Renew button if subscription is expired */}
//             {isExpired ? (
//               <button
//                 className={`bg-cyan-800 text-white rounded-xl px-4 py-2 text-center text-lg font-semibold 
//                   ${subData.subStatus === 'Pending' ? 'bg-gray-400 cursor-not-allowed' : ''
//                 }`}
//                 onClick={() => { 
//                   if (subData.subStatus !== "Pending") {
//                     handleRenew(subData);
//                   }
//                 }}
//                 disabled={subData.subStatus === 'Pending'}
//               >
//                 Renew
//               </button>
//             ) : (
//               <div
//                 className="flex items-center bg-cyan-800 text-white text-center rounded-xl font-semibold text-2xl px-4 py-2 cursor-pointer
//                   tablet-portrait-min:text-base
//                   tablet-landscape-min::text-xl
//                   sm:text-base sm:my-2
//                   xs:text-sm xs:my-2"
//                 onClick={() => {
//                   if (daysUntilExpiration <= 30 && daysUntilExpiration > 0 && subData.subStatus !== 'Pending') {
//                     handleRenew(subData);
//                   }
//                 }}
//               >
//                 {subData.subExpiredDays} <span className="text-sm ml-2">Days</span>
//               </div>
//             )}

//             <div
//               className={`ml-2 text-2xl ${['Pending', 'Expire'].includes(subData.subStatus) ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer'}`}
//               onClick={
//                 !['Pending', 'Expire'].includes(subData.subStatus) ? () => toggleAccountDetails(index + indexOffset) : undefined
//               }
//             >
//               {expandedDetails[index + indexOffset] ? <TbLayoutNavbarExpandFilled /> : <TbLayoutBottombarExpand />}
//             </div>
//           </div>
//         </div>

//         {/* Alert Text for Expiration */}
//         {daysUntilExpiration <= 30 && (
//           <div
//             className={`text-xs mt-2 text-center ${
//               subData.subStatus === 'Pending'
//                 ? 'text-yellow-600'
//                 : isExpired
//                 ? 'text-red-600'
//                 : daysUntilExpiration <= 14
//                 ? 'text-red-600'
//                 : 'text-yellow-600'
//             }`}
//           >
//             {subData.subStatus === 'Pending'
//               ? 'Your subscription is pending approval. Please wait for it to be approved.'
//               : isExpired
//               ? 'Your subscription has expired. Please click "Renew" to renew your subscription.'
//               : daysUntilExpiration <= 14
//               ? 'Your subscription is expiring soon. Click the day count to renew.'
//               : 'Your subscription expires in less than 30 days. Renew by clicking the day count.'}
//           </div>
//         )}

//         {/* Expanded Details */}
//         {expandedDetails[index + indexOffset] && (
//           <div className="mt-2 bg-white p-4 rounded-lg shadow-lg w-full 
//                 sm:mt-2 
//                 xs:mt-2"
//           >
//             {product.productName === "TraderHub" ? (
//               <div className="text-center">
//                 <p className="text-sm font-semibold mb-2 text-rose-600
//                     sm:text-xs
//                     xs:text-xs">
//                   Please use this link to join the TraderHub Telegram group:
//                 </p>
//                 <a 
//                   href="https://t.me/ROneTraderHubCS6bot" 
//                   target="_blank" 
//                   rel="noopener noreferrer"
//                   className="text-cyan-800 underline text-base
//                     sm:text-sm
//                     xs:text-sm"
//                 >
//                   https://t.me/ROneTraderHubCS6bot
//                 </a>
//               </div>
//             ) : (
//               <div className="flex flex-row justify-between items-center
//                     sm:flex-col
//                     xs:flex-col"
//               >   
//                 <div className="mx-4">
//                   <div className="flex justify-normal items-center">
//                     <label className="font-semibold border-b border-cyan-800 text-cyan-800 text-sm">
//                       Account Info:
//                     </label>
//                     {subData.subAccountType === 'DEMO' && (
//                       <button 
//                         onClick={() => handleOpenModal(subData.subOrderId, subData.subAccountNumber || '', subData.subAccountPassword || '')}
//                       >
//                         <FaRegEdit className="ml-2"/>
//                       </button>
//                     )}
//                   </div>
//                   <div className="flex flex-row">
//                     <div className="flex flex-col justify-start mr-2">
//                       <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Type:</strong></div>
//                       <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Number:</strong></div>
//                       <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Password:</strong></div>
//                       <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Server:</strong></div>
//                     </div>
//                     <div className="flex flex-col justify-start">
//                       <div className="text-sm md:text-xs sm:text-xs xs:text-xs">{subData.subAccountType}</div>
//                       <div className="text-sm md:text-xs sm:text-xs xs:text-xs">{subData.subAccountNumber}</div>
//                       <div className="flex items-center text-sm md:text-xs sm:text-xs xs:text-xs">
//                         {renderPassword(subData.subAccountPassword || '', index + indexOffset)}
//                         <button onClick={() => togglePasswordVisibility(index + indexOffset)} className="ml-2 text-cyan-800">
//                           {showPassword[index + indexOffset] ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
//                         </button>
//                       </div>
//                       <div className="text-sm md:text-xs sm:text-xs xs:text-xs">{subData.subAccountServer}</div>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Modal for updating demo account information */}
//                 {isModalOpen && (
//                   <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//                     <div className="bg-white p-6 rounded-lg shadow-lg w-auto max-w-xl
//                           sm:max-w-sm
//                           xs:max-w-xs"
//                     >
//                       <h2 className="text-xl font-semibold
//                             sm:text-base
//                             xs:text-sm"
//                       >
//                         Update Account Information
//                       </h2>
//                       <div className="text-rose-600 text-wrap text-left p-4
//                             sm:text-sm
//                             xs:text-xs"
//                       >
//                         Warning: This demo account can only be changed <span className="font-bold ml-1 text-rose-800">{subData.subDemoCheck}</span> times. After reaching this limit, 
//                         you will no longer be able to change the account number and password.
//                       </div>
//                       <label className="block mb-2 font-semibold mx-4
//                               sm:text-base
//                               xs:text-sm"
//                       >
//                         New Account Number:
//                       </label>
//                       <input 
//                         type="number" 
//                         className="w-full p-2 border rounded mb-4"
//                         placeholder='Enter your new account number...'
//                         value={newAccountData.subAccountNumber} 
//                         onChange={(e) => setNewAccountData({ ...newAccountData, subAccountNumber: e.target.value })}
//                       />
//                       <label className="block mb-2 font-semibold mx-4
//                               sm:text-base
//                               xs:text-sm"
//                       >
//                         New Account Password:
//                       </label>
//                       <input 
//                         type="password" 
//                         className="w-full p-2 border rounded mb-4"
//                         placeholder='Enter your new account password...'
//                         value={newAccountData.subAccountPassword} 
//                         onChange={(e) => setNewAccountData({ ...newAccountData, subAccountPassword: e.target.value })}
//                       />
//                       <div className="flex justify-end space-x-4">
//                         <button 
//                           onClick={() => setIsModalOpen(false)} 
//                           className="px-4 py-2 bg-gray-300 rounded-md"
//                         >
//                           Cancel
//                         </button>
//                         <button 
//                           onClick={handleSaveAccountData} 
//                           className="px-4 py-2 bg-cyan-800 text-white rounded-md"
//                         >
//                           Save
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 )}

//                 <div className="w-auto max-w-[800px] mx-2">
//                   <div className="flex justify-normal items-center text-xs mb-2
//                         sm:my-2
//                         xs:my-2"
//                   >
//                     <button 
//                       className={
//                         `px-4 py-2 w-full rounded-l-md ${activeTab === 'algomatrix' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}
//                         sm:px-2
//                         xs:px-1 xs:ml-2`
//                       }
//                       onClick={() => setActiveTab('algomatrix')}
//                     >
//                       AlgoMatrix
//                     </button>
//                     <button 
//                       className={
//                         `px-4 py-2 w-full rounded-r-md ${activeTab === 'mt4' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}
//                         sm:px-2
//                         xs:px-1 xs:mr-2`
//                       }
//                       onClick={() => setActiveTab('mt4')}
//                     >
//                       MT4 Terminal
//                     </button>
//                   </div>

//                   {activeTab === 'algomatrix' ? (
//                     <div>
//                       <div className="text-sm flex justify-center items-center py-1 space-x-4
//                             sm:text-xs
//                             xs:text-xs"
//                       >
//                         <span>Current Version: <strong>{versionNumber}</strong></span>
//                         <button
//                           className="border-cyan-800 text-cyan-800 border rounded-full px-4 py-0.5 flex items-center justify-center"
//                           onClick={() => handleDownload('algomatrix')}
//                         >
//                           Download <AiOutlineDownload className="ml-2" />
//                         </button>
//                       </div>
//                       <div className="text-red-600 text-xs text-center">
//                         <span>Only support MT4, installation guide click </span>
//                         <button
//                           className="text-rose-600 underline hover:text-cyan-800 px-1"
//                           onClick={() => handleDownload('installation-guide')}
//                         >
//                           here
//                         </button>
//                       </div>
//                     </div>
//                   ) : (
//                     <div className="text-center">
//                       <div className="flex justify-normal items-center">
//                         <label className="text-sm w-full mx-6 font-semibold
//                                 sm:text-xs sm:text-nowrap 
//                                 xs:text-xs xs:text-nowrap xs:mx-4"
//                         >
//                           Select MT4:
//                         </label>
//                         <select
//                           name="mt4AppDropdown"
//                           className="border border-cyan-800 rounded py-1 px-4 w-auto text-sm text-center
//                             xs:text-xs"
//                           value={selectedMt4App}
//                           onChange={(e) => {
//                             const selectedApp = e.target.value;
//                             setSelectedMt4App(selectedApp);
//                             if (selectedApp) {
//                               handleDownload('mt4', selectedApp); 
//                             }
//                           }}
//                         >
//                           <option value="">Select an MT4</option>
//                           <option value="fortuneprimeglobal4setup">Fortune Prime</option>
//                           <option value="lirunexlimited4setup">Lirunex</option>
//                         </select>
//                       </div>
//                       <span className="text-red-600 text-xs text-center"> 
//                         Select a MT4 Terminal installer to download
//                       </span>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//       );
//     });
//   };

//    // Conditional rendering for registration
//    if (showRegistration && renewalData) {
//     return (
//       <MySubRegistration
//         plan={plans.find(plan => plan.planId === renewalData.subPlanId) || plans[0]} 
//         selectedProduct={products.find(p => p.productId === renewalData.subProductId) || product}
//         liveOrderId={renewalData.subOrderId || ''}
//         demoOrderId={''} 
//         onBack={() => {
//           setShowRegistration(false);
//           setRenewalData(null);
//         }}
//         renewalData={renewalData}
//         isRenew={true}
//       />
//     );
//   }

//   return (
//     <>
//       {/* Alert Message */}
//       <MyTicketAlert
//         type={alertType}
//         message={alertMessage}
//         visible={alertVisible}
//         onClose={() => setAlertVisible(false)}
//       />

//       {showRegistration && renewalData ? (
//         <MySubRegistration
//           plan={plans.find(plan => plan.planId === renewalData.subPlanId) || plans[0]} 
//           selectedProduct={products.find(p => p.productId === renewalData.subProductId) || product}
//           liveOrderId={renewalData.subOrderId || ''}
//           demoOrderId={''} 
//           onBack={() => {
//             setShowRegistration(false);
//             setRenewalData(null);
//           }}
//           renewalData={renewalData}
//           isRenew={true}
//         />
//       ) : (
//         <div className="flex flex-col items-center" key={product.productId}>
//           {pendingSubscriptions.length > 0 &&
//             renderSubscriptions(pendingSubscriptions, 0)}
        
//           {updatedAlgoMatrixSubscriptions.length > 0 &&
//             renderSubscriptions(
//               updatedAlgoMatrixSubscriptions,
//               pendingSubscriptions.length
//             )}
        
//           {updatedTraderHubSubscriptions.length > 0 &&
//             renderSubscriptions(
//               updatedTraderHubSubscriptions,
//               pendingSubscriptions.length + algoMatrixSubscriptions.length
//             )}
//         </div>
//       )}
//     </>
//   );    
// };

// export default SubscriptionItem;