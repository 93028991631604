// src/components/admin/SubProductSettiong/subRejectModal.tsx

import React, { useState } from 'react';

// Rejection Modal Component
const RejectModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSend: (reason: string) => void;
}> = ({ isOpen, onClose, onSend }) => {
  const [reason, setReason] = useState('');

  const handleSend = () => {
    onSend(reason);
    setReason('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded shadow-lg max-w-md w-full">
        <h2 className="text-lg font-semibold mb-4">Reject Subscription</h2>
        <textarea
          className="w-full border rounded p-2 mb-4"
          placeholder="Enter reason for rejection..."
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          rows={6}
        ></textarea>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="bg-gray-200 text-gray-600 px-4 py-2 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleSend}
            className="bg-red-600 text-white px-4 py-2 rounded"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectModal;