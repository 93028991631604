// src/components/admin/SubProductSetting/subUserDataStat.tsx

import React, { useState, useEffect } from 'react';
import { useSubUserData } from '../../../hooks/AdminDashboard/useSubUserData';

const SubUserDataStat: React.FC = () => {
  const { statistics, loadingStats, fetchStatistics } = useSubUserData();
  const [selectedProductId, setSelectedProductId] = useState<string | 'All'>('All');

  // Fetch statistics when the selected product changes
  useEffect(() => {
    fetchStatistics(selectedProductId);
  }, [selectedProductId, fetchStatistics]);

  const getProductTitle = () => {
    if (selectedProductId === 'All') return 'All Products';
    if (selectedProductId === 'sub-ALG') return 'AlgoMatrix';
    if (selectedProductId === 'sub-TRA') return 'Trader Hub';
    return 'Unknown Product';
  };

  return (
    <div className="mt-4 p-4 border-t border-gray-200">
      <div className="flex flex-col">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <h2 className="text-lg font-semibold text-cyan-800">Subscription Stats:</h2>
            <h2 className="text-lg font-semibold text-cyan-800 ml-2">
              {loadingStats ? 'Loading...' : getProductTitle()}
            </h2>
          </div>

          <select
            value={selectedProductId}
            onChange={(e) => setSelectedProductId(e.target.value)}
            className="border border-gray-300 rounded px-2 py-1 text-sm"
          >
            <option value="All">All Products</option>
            <option value="sub-ALG">AlgoMatrix</option>
            <option value="sub-TRA">Trader Hub</option>
          </select>
        </div>

        <div className="flex flex-row justify-center items-center space-x-4 mt-2 border p-4 rounded bg-gray-50">
          <div className="flex flex-col border px-4 py-2 rounded-md bg-white shadow-sm w-full">
            <div className="font-bold">Total Subscriptions:</div>
            <span className="text-lg text-end">{statistics.totalSubscriptions}</span>
          </div>
          <div className="flex flex-col border px-4 py-2 rounded-md bg-white shadow-sm w-full">
            <div className="font-bold">Active:</div>
            <span className="text-lg text-end">{statistics.totalActive}</span>
          </div>
          <div className="flex flex-col border px-4 py-2 rounded-md bg-white shadow-sm w-full">
            <div className="font-bold">Pending:</div>
            <span className="text-lg text-end">{statistics.totalPending}</span>
          </div>
          <div className="flex flex-col border px-4 py-2 rounded-md bg-white shadow-sm w-full">
            <div className="font-bold">Expired:</div>
            <span className="text-lg text-end">{statistics.totalExpired}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubUserDataStat;





// // udpateg code --> 26 nov 2024
// // src/components/admin/SubProductSetting/subUserDataStat.tsx

// import React, { useMemo, useState, useEffect } from 'react';
// import { useSubUserData, SubPending } from '../../../hooks/AdminDashboard/useSubUserData';
// import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';

// const SubUserDataStat: React.FC = () => {
//   // Use hooks to fetch subscription and product data
//   const { algoMatrixData, traderHubData, fetchSubProductData, fetchSubPending, pendingUserCount } = useSubUserData();
//   const { products } = useSubSetting();
//   const [selectedProductId, setSelectedProductId] = useState<string | "All">("All");

//   // State to hold combined data
//   const [combinedData, setCombinedData] = useState<SubPending[]>([]);

//   // Fetch data on mount
//   useEffect(() => {
//     const fetchData = async () => {
//       await fetchSubProductData('All');
//       await fetchSubPending(); 
//       setCombinedData([...algoMatrixData, ...traderHubData]); 
//     };
//     fetchData();
//   }, [fetchSubProductData, fetchSubPending, algoMatrixData, traderHubData]);

//   // Calculate subscription stats based on selected product ID
//   const subscriptionStats = useMemo(() => {
//     const stats = { Active: 0, Pending: pendingUserCount, Expired: 0, Total: 0 };
  
//     // Count active, expired, and total from combinedData
//     combinedData.forEach((user: SubPending) => {
//       if (selectedProductId === "All" || user.subProductId === selectedProductId) {
//         stats.Total++;
//         if (user.subStatus === 'Active') stats.Active++;
//         else if (user.subStatus === 'Expire') stats.Expired++;
//       }
//     });
  
//     return stats;
//   }, [combinedData, selectedProductId, pendingUserCount]);  

//   const productName = selectedProductId === "All"
//     ? "Total Product"
//     : products.find(product => product.productId === selectedProductId)?.productName || selectedProductId;

//   return (
//     <div className="mt-4 p-4 border-t border-gray-200">
//       <div className="flex flex-col">
//         <div className="flex justify-between items-center">
//           <div className="flex items-center">
//             <h2 className="text-lg font-semibold text-cyan-800">Subscription Stats:</h2>
//             <h2 className="text-lg font-semibold text-cyan-800 ml-2">{productName}</h2>
//           </div>
          
//           {/* Dropdown to filter by product */}
//           <select
//             value={selectedProductId}
//             onChange={(e) => setSelectedProductId(e.target.value)}
//             className="border border-gray-300 rounded px-2 py-1 text-sm"
//           >
//             <option value="All">All Products</option>
//             {products.map(({ productId, productName }) => (
//               <option key={productId} value={productId}>
//                 {productName} ({productId})
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="flex flex-row justify-center items-center space-x-4 mt-2 border p-4 rounded bg-gray-50">
//           <div className="flex flex-col border px-4 py-2 rounded-md bg-white shadow-sm w-full">
//             <div className="font-bold ">Total Subscriptions:</div>
//             <span className="text-lg text-end">{subscriptionStats.Total}</span>
//           </div>
//           <div className="flex flex-col border px-4 py-2 rounded-md bg-white shadow-sm w-full">
//             <div className="font-bold">Active:</div>
//             <span className="text-lg text-end">{subscriptionStats.Active}</span>
//           </div>
//           <div className="flex flex-col border px-4 py-2 rounded-md bg-white shadow-sm w-full">
//             <div className="font-bold">Pending:</div>
//             <span className="text-lg text-end">{subscriptionStats.Pending}</span>
//           </div>
//           <div className="flex flex-col border px-4 py-2 rounded-md bg-white shadow-sm w-full">
//             <div className="font-bold">Expired:</div>
//             <span className="text-lg text-end">{subscriptionStats.Expired}</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SubUserDataStat;

