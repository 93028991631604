// src/components/admin/SubProductSetting/subPlanSetting.tsx

// import react component
import React, { useState } from 'react';
// import react icon
import { IoIosCheckbox, IoIosCheckboxOutline  } from "react-icons/io";
// import custom components
import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';
import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
import { useAuth } from '../../../context/AuthContext';   
import SubTrialModal from './subTrialModal';

const SubTrialSetting: React.FC = () => {
  // import custom hook
  const { user } = useAuth();
  const { permissions } = useRoleAccess(user?.email || '');
  const { trials, products, plans, loading, addTrial, updateTrial, deleteTrial } = useSubSetting();
  // add/edit product modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isEditMode, setIsEditMode] = useState(false); 
  const [currentTrial, setCurrentTrial] = useState<any>(null);
  // display custom group email list
  const [isEmailListModalOpen, setIsEmailListModalOpen] = useState(false);
  const [emailList, setEmailList] = useState<string[]>([]);


  const handleAddTrialSet = async (trial: { 
    trialId: string;
    trialLinkPlanId: string; 
    trialName: string; 
    trialPeriod: number; 
    trialMembersType: string | null;    
    trialGroupName: string | null;     
    trialGroupMember: string | null; 
    trialStatus: string;
  }) => {
    try {
      if (isEditMode && currentTrial) {
        // Update the existing plan
        await updateTrial(currentTrial.trialId, trial);
      } else {
        // Add a new plan
        await addTrial(trial);
      }
    } catch (error) {
      console.error("Failed to save plan:", error);
    } finally {
      // Reset modal state and editing state
      setIsModalOpen(false);
      setCurrentTrial(null);
      setIsEditMode(false);
    }
  };
  
  const handleSelectRow = (trialId: string) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(trialId)
        ? prevSelectedRows.filter(id => id !== trialId) 
        : [...prevSelectedRows, trialId]
    );
  };

  const handleDeleteSelected = async () => {
    for (const trialId of selectedRows) {
      await deleteTrial(trialId); 
    }
    setSelectedRows([]); 
  };

  // Handle row double-click to enter edit mode
  const handleEditTrial = (trial: any) => {
    setCurrentTrial(trial);
    setIsEditMode(true); 
    setIsModalOpen(true); 
  };

  const handleShowEmailList = (emails: string | null) => {
    setEmailList(emails ? emails.split(',') : []);
    setIsEmailListModalOpen(true);
  };  

  return (
    <div className="p-4 border rounded">

      <div className="flex justify-end mb-2 space-x-2">
        {/* Toggle Multi-Select Mode */}
        <button 
          className={`text-xl mr-2 
            ${permissions?.productSubscription?.editSub !== 'enable'
              ? 'text-gray-400 cursor-not-allowed' 
              : 'text-cyan-800 '
            }`}
          onClick={() => setIsMultiSelectEnabled(!isMultiSelectEnabled)}
          disabled={permissions?.productSubscription?.editSub !== 'enable'}
        >
          {isMultiSelectEnabled ? <IoIosCheckbox /> : <IoIosCheckboxOutline />}
        </button>

        {/* Add Product Button */}
        <button 
          className={`px-6 py-1 rounded 
            ${permissions?.productSubscription?.addSub !== 'enable'
              ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
              : 'bg-cyan-800 text-white'
            }`}
          onClick={() => {
            setIsEditMode(false);
            setCurrentTrial(null);
            setIsModalOpen(true);
          }}
          disabled={permissions?.productSubscription?.addSub !== 'enable'}
        >
          Add Trial Sets
        </button>

        {/* Remove Selected Products Button */}
        <button
          className={`px-6 py-1 rounded 
            ${permissions?.productSubscription?.deleteSub !== 'enable'
              ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
              : 'bg-cyan-800 text-white'
            }`}
          onClick={handleDeleteSelected}
          disabled={!selectedRows.length || permissions?.productSubscription?.deleteSub !== 'enable'}
        >
          Remove Trial Sets
        </button>
      </div>

      {/* Plan Table */}
      <div className="overflow-x-auto w-auto">
        <table className="min-w-full bg-white border text-sm">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              {isMultiSelectEnabled && <th className="py-2 px-4 border-b">Select</th>}
              <th className="py-2 px-4 border-b text-nowrap">Trial Id</th>
              <th className="py-2 px-4 border-b text-nowrap">Linked Plan</th>
              <th className="py-2 px-4 border-b text-nowrap">Trial Name</th>
              <th className="py-2 px-4 border-b text-nowrap">Trial Period</th>
              <th className="py-2 px-4 border-b text-nowrap">Trial Custom Group</th>
              <th className="py-2 px-4 border-b text-nowrap">Trial Custom Group List</th>
              <th className="py-2 px-4 border-b text-nowrap">Trial Status</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} className="text-center py-8">Loading...</td>
              </tr>
            ) : trials.length > 0 ? (
              trials.map((trial) => (
                <tr 
                  key={trial.trialId} 
                  className={`text-center border-b hover:bg-gray-50 ${
                    permissions?.productSubscription?.editSub === 'enable' ? '' : 'cursor-not-allowed pointer-events-none'
                  }`}
                  onDoubleClick={() => {
                    if (permissions?.productSubscription?.editSub === 'enable') {
                      handleEditTrial(trial);
                    }
                  }}
                >
                  {isMultiSelectEnabled && (
                    <td className="py-2 px-4">
                      <input
                        type="checkbox"
                        className="accent-cyan-800"
                        checked={selectedRows.includes(trial.trialId)}
                        onChange={() => handleSelectRow(trial.trialId)}
                      />
                    </td>
                  )}
                  <td className="py-2 px-4 text-nowrap">{trial.trialId}</td>
                  <td className="py-2 px-4 text-nowrap">
                    {trial.trialLinkPlanId
                      .split(',')
                      .map(planId => {
                        const plan = plans.find(p => p.planId === planId);
                        const product = products.find(p => p.productId === plan?.LinkProductId);
                        return plan && product ? `${product.productName} - ${plan.planName}` : 'Unknown';
                      })
                      .join(', ')}
                  </td>
                  <td className="py-2 px-4 text-nowrap">{trial.trialName}</td>
                  <td className="py-2 px-4 text-nowrap">{trial.trialPeriod}</td>
                  <td className="py-2 px-4 text-nowrap">
                    <span className={trial.trialMembersType ? "" : "text-gray-300"}>
                      {trial.trialMembersType || "No set member type"}
                    </span>
                  </td>
                  <td 
                    className="py-2 px-4 text-nowrap"
                    onClick={() => handleShowEmailList(trial.trialGroupMember)}
                  >
                    <span className={trial.trialGroupName ? "" : "text-gray-300"}>
                      {trial.trialGroupName || "No set custom group"}
                    </span>
                  </td>
                  <td className={`py-2 px-4 text-nowrap ${
                    trial.trialStatus === 'Active' ? 'text-green-500 font-bold' : 'text-rose-500 font-bold'
                  }`}>
                    {trial.trialStatus}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center py-8">No trial set available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal for Adding Product */}
      {isModalOpen && (
        <SubTrialModal
          onClose={() => setIsModalOpen(false)}
          onSave={handleAddTrialSet}
          editMode={isEditMode}
          initialData={currentTrial}
        />
      )}

      {/* Modal for to show custom trial group email list */}
      {isEmailListModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-semibold text-center mb-4 text-cyan-800">Email List</h2>
            <ul className="text-sm list-disc pl-5 space-y-2">
              {emailList.length > 0 ? (
                emailList.map((email, index) => <li key={index}>{email}</li>)
              ) : (
                <li>No emails available</li>
              )}
            </ul>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setIsEmailListModalOpen(false)}
                className="bg-cyan-800 text-white px-4 py-1 rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default SubTrialSetting;

// // uidpate code --> 01 Nov 2024 
// // src/components/admin/SubProductSetting/subPlanSetting.tsx

// // import react component
// import React, { useState } from 'react';
// // import react icon
// import { IoIosCheckbox, IoIosCheckboxOutline  } from "react-icons/io";
// // import custom components
// import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';
// import SubTrialModal from './subTrialModal';

// const SubTrialSetting: React.FC = () => {
//   // import custom hook
//   const { trials, products, plans, loading, addTrial, updateTrial, deleteTrial } = useSubSetting();
//   // add/edit product modal state
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   const [isEditMode, setIsEditMode] = useState(false); 
//   const [currentTrial, setCurrentTrial] = useState<any>(null);

//   const handleAddTrialSet = async (trial: { 
//     trialId: string;
//     trialLinkPlanId: string; 
//     trialName: string; 
//     trialPeriod: number; 
//     trialMembersType: string | null;    
//     trialGroupName: string | null;     
//     trialGroupMember: string | null; 
//     trialStatus: string;
//   }) => {
//     try {
//       if (isEditMode && currentTrial) {
//         // Update the existing plan
//         await updateTrial(currentTrial.trialId, trial);
//       } else {
//         // Add a new plan
//         await addTrial(trial);
//       }
//     } catch (error) {
//       console.error("Failed to save plan:", error);
//     } finally {
//       // Reset modal state and editing state
//       setIsModalOpen(false);
//       setCurrentTrial(null);
//       setIsEditMode(false);
//     }
//   };
  
//   const handleSelectRow = (trialId: string) => {
//     setSelectedRows(prevSelectedRows =>
//       prevSelectedRows.includes(trialId)
//         ? prevSelectedRows.filter(id => id !== trialId) 
//         : [...prevSelectedRows, trialId]
//     );
//   };

//   const handleDeleteSelected = async () => {
//     for (const trialId of selectedRows) {
//       await deleteTrial(trialId); 
//     }
//     setSelectedRows([]); 
//   };

//   // Handle row double-click to enter edit mode
//   const handleEditTrial = (trial: any) => {
//     setCurrentTrial(trial);
//     setIsEditMode(true); 
//     setIsModalOpen(true); 
//   };

//   return (
//     <div className="p-4 border rounded">

//       <div className="flex justify-end mb-2 space-x-2">
//         {/* Toggle Multi-Select Mode */}
//         <button 
//           className="text-cyan-800 text-xl mr-2"
//           onClick={() => setIsMultiSelectEnabled(!isMultiSelectEnabled)}
//         >
//           {isMultiSelectEnabled ? <IoIosCheckbox /> : <IoIosCheckboxOutline />}
//         </button>

//         {/* Add Product Button */}
//         <button 
//           className="bg-cyan-800 text-white px-6 py-1 rounded"
//           onClick={() => {
//             setIsEditMode(false);
//             setCurrentTrial(null);
//             setIsModalOpen(true);
//           }}
//         >
//           Add Trial Sets
//         </button>

//         {/* Remove Selected Products Button */}
//         <button
//           className="bg-cyan-800 text-white px-6 py-1 rounded"
//           onClick={handleDeleteSelected}
//           disabled={!selectedRows.length}
//         >
//           Remove Trial Sets
//         </button>
//       </div>

//       {/* Plan Table */}
//       <div className="overflow-x-auto w-auto">
//         <table className="min-w-full bg-white border text-sm">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold">
//               {isMultiSelectEnabled && <th className="py-2 px-4 border-b">Select</th>}
//               <th className="py-2 px-4 border-b text-nowrap">Trial Id</th>
//               <th className="py-2 px-4 border-b text-nowrap">Linked Plan</th>
//               <th className="py-2 px-4 border-b text-nowrap">Trial Name</th>
//               <th className="py-2 px-4 border-b text-nowrap">Trial Period</th>
//               <th className="py-2 px-4 border-b text-nowrap">Trial Custom Group</th>
//               <th className="py-2 px-4 border-b text-nowrap">Trial Custom Group List</th>
//               <th className="py-2 px-4 border-b text-nowrap">Trial Status</th>
//             </tr>
//           </thead>
//           <tbody>
//             {loading ? (
//               <tr>
//                 <td colSpan={5} className="text-center py-8">Loading...</td>
//               </tr>
//             ) : trials.length > 0 ? (
//               trials.map((trial) => (
//                 <tr 
//                   key={trial.trialId} 
//                   className="text-center border-b hover:bg-gray-50"
//                   onDoubleClick={() => handleEditTrial(trial)}
//                 >
//                   {isMultiSelectEnabled && (
//                     <td className="py-2 px-4">
//                       <input
//                         type="checkbox"
//                         className="accent-cyan-800"
//                         checked={selectedRows.includes(trial.trialId)}
//                         onChange={() => handleSelectRow(trial.trialId)}
//                       />
//                     </td>
//                   )}
//                   <td className="py-2 px-4 text-nowrap">{trial.trialId}</td>
//                   <td className="py-2 px-4 text-nowrap">
//                     {trial.trialLinkPlanId
//                       .split(',')
//                       .map(planId => {
//                         const plan = plans.find(p => p.planId === planId);
//                         const product = products.find(p => p.productId === plan?.LinkProductId);
//                         return plan && product ? `${product.productName} - ${plan.planName}` : 'Unknown';
//                       })
//                       .join(', ')}
//                   </td>
//                   <td className="py-2 px-4 text-nowrap">{trial.trialName}</td>
//                   <td className="py-2 px-4 text-nowrap">{trial.trialPeriod}</td>
//                   <td className="py-2 px-4 text-nowrap">{trial.trialMembersType}</td>
//                   <td className="py-2 px-4 text-nowrap">{trial.trialGroupName}</td>
//                   <td className={`py-2 px-4 text-nowrap ${
//                     trial.trialStatus === 'Active' ? 'text-green-500 font-bold' : 'text-rose-500 font-bold'
//                   }`}>
//                     {trial.trialStatus}
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={8} className="text-center py-8">No trial set available</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       {/* Modal for Adding Product */}
//       {isModalOpen && (
//         <SubTrialModal
//           onClose={() => setIsModalOpen(false)}
//           onSave={handleAddTrialSet}
//           editMode={isEditMode}
//           initialData={currentTrial}
//         />
//       )}
//     </div>
//   );
// };

// export default SubTrialSetting;