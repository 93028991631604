// src/hooks/AdminDashboard/useRoleAccess.tsx

import { useState, useEffect } from 'react';
import { useEnv } from '../../context/EnvContext';

interface RolePermissions {
  fullAccess: string,
  overview: string,
  userData: {
    enable: string;
    addData: string;
    editData: string;
    deleteData: string;
  };
  emailSchedule: {
    enable: string;
    addLogic: string;
    editLogic: string;
    deleteLogic: string;
    addEmail: string;
    editEmail: string;
    deleteEmail: string;
    changeStatus: string;
  },
  eventSetting: {
    enable: string;
    addEvent: string,
    editEvent: string,
    deleteEvent: string,
  },
  eventNotification: {
    enable: string;
    deleteEventTicket: string,
    rejectEventTicket: string,
    approveEventTicket: string,
  },
  productSubscription: {
    enable: string;
    addSub: string,
    editSub: string,
    deleteSub: string,
    expandDataSub: string,
    expandProductSub: string,
    pendingSub: string,
    allDataSub: string,
    productPlanSub: string,
    trialVoucherSub: string,
    approveSub: string,
    rejectSub: string,
  },
  fundedProgram: string,
  videoManage: {
    enable: string;
    uploadVideo: string,
    removeVideo: string,
    editVideo: string,
    addPlaylist: string,
    editPlaylist: string,
    removePlaylist: string,
  },
  logsViewer: string,
  adminSetting: string,
}

// Helper function to set all permissions to "enable"
const enableAllPermissions = (): RolePermissions => ({
  fullAccess: 'enable',
  overview: 'enable',
  userData: { enable: 'enable', addData: 'enable', editData: 'enable', deleteData: 'enable' },
  emailSchedule: {
    enable: 'enable', addLogic: 'enable', editLogic: 'enable', deleteLogic: 'enable',
    addEmail: 'enable', editEmail: 'enable', deleteEmail: 'enable', changeStatus: 'enable',
  },
  eventSetting: { 
    enable:'enable', addEvent: 'enable', editEvent: 'enable', deleteEvent: 'enable',
  },
  eventNotification: {
    enable: 'enable', deleteEventTicket: 'enable', rejectEventTicket: 'enable', approveEventTicket: 'enable',
  },
  productSubscription: {
    enable: 'enable', addSub: 'enable', editSub: 'enable', deleteSub: 'enable', approveSub: 'enable', rejectSub: 'enable',
    expandDataSub: 'enable', expandProductSub:'enable', pendingSub: 'enable', allDataSub: 'enable', productPlanSub: 'enable', trialVoucherSub: 'enable',
  },
  fundedProgram: 'enable',
  videoManage: {    
    enable: 'enable', uploadVideo: 'enable', removeVideo: 'enable', editVideo: 'enable',
    addPlaylist: 'enable', editPlaylist: 'enable', removePlaylist: 'enable'
  },
  logsViewer: 'enable',
  adminSetting: 'enable',
});

export const useRoleAccess = (email: string) => {
  const { apiUrl } = useEnv();
  const [permissions, setPermissions] = useState<RolePermissions | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!email) {
      setPermissions(null);
      setLoading(false);
      return;
    }

    const fetchPermissions = async () => {
      try {
        const response = await fetch(`${apiUrl}/admin-users/${email}/permissions`);
        if (!response.ok) {
          throw new Error('Failed to fetch permissions');
        }
        const data = await response.json();

        // Check if fullAccess is enabled; if so, enable all permissions
        if (data.rolePermission?.fullAccess === 'enable') {
          setPermissions(enableAllPermissions());
        } else {
          setPermissions(data.rolePermission);
        }
      } catch (err: any) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, [apiUrl, email]);

  return { permissions, loading, error };
};