// src/components/admin/SubProductSetting/subUserData.tsx

import React, { useState, useMemo } from 'react';
import { RiRefreshFill, RiFilter2Line } from "react-icons/ri";
import { FaRegEdit, FaTrashAlt, FaSave } from "react-icons/fa";
import { MdAddToPhotos } from "react-icons/md";
// import custom file
import { useEnv } from '../../../context/EnvContext'; 
import { useAuth } from '../../../context/AuthContext';   
import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
import { useSubUserData, SubPending } from '../../../hooks/AdminDashboard/useSubUserData';
import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting'; 
import AddSubUserModal from './subAddUserModal';  

const SubUserData: React.FC = () => {
  const { apiUrl } = useEnv();
  const { user } = useAuth();
  const { permissions } = useRoleAccess(user?.email || '');    
  const { 
    algoMatrixData, traderHubData, fetchSubProductData, addSubProduct, updateSubProduct, deleteSubProduct
  } = useSubUserData();
  const { products } = useSubSetting(); 
  // state for expanded user data row
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  // state for receipts
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [receiptUrl, setReceiptUrl] = useState<string | null>(null);
  // state for search to filter data
  const [searchQuery, setSearchQuery] = useState("");
  // state to add data modal
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newData, setNewData] = useState<Partial<any>>({});
  // state to add filter table
  const [selectedPlanType, setSelectedPlanType] = useState<string | "All">("All");
  const [selectedProductId, setSelectedProductId] = useState<string | "All">("All");
  const [selectedStatusIndex, setSelectedStatusIndex] = useState(0);
  const statuses = ["All", "Active", "Expire"];
  const selectedStatus = statuses[selectedStatusIndex];
  // state to edit/modify date
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const [editedData, setEditedData] = useState<Partial<any>>({});
  // state load 20 per data
  const [rowsPerPage, setRowsPerPage] = useState(20);

  // Function to handle refetching the data
  const handleRefetch = () => {
    fetchSubProductData('All'); 
  };

  const handleRowClick = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleViewReceipt = (url: string) => {
    setReceiptUrl(url);
    setIsReceiptModalOpen(true);
  };
  
  const handleEditClick = (user: any) => {
    setIsEditing(user.subOrderId);
    setEditedData(user);
  };

  // Save the edited data
  const handleSaveClick = async (user: any) => {
    const productType = user.subOrderId.startsWith("sub-ALG") ? "AlgoMatrix" : "TraderHub";
    await updateSubProduct(productType, user.subOrderId, editedData);
    setIsEditing(null);
  };

  // delete the data
  const handleDelete = async (orderId: string) => {
    await deleteSubProduct(orderId); 
    fetchSubProductData();
  };
  
  // Update edited data in state
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };  
  
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; 

  const handleAddNewClick = () => {
    setIsAddModalOpen(true);
    setNewData({});
  };

  const handleAddNewSave = async () => {
    const productType = selectedProductId === 'AlgoMatrix' ? 'AlgoMatrix' : 'TraderHub';
    await addSubProduct(productType, newData as SubPending);
    setIsAddModalOpen(false);
    fetchSubProductData();
  };

  const handleNewInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setNewData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };  

  // Filter data based on the selected product
  const combinedData = useMemo(() => {
    if (selectedProductId === 'AlgoMatrix') return algoMatrixData;
    if (selectedProductId === 'TraderHub') return traderHubData;
    return [...algoMatrixData, ...traderHubData];
  }, [algoMatrixData, traderHubData, selectedProductId]);

  // Cycle through statuses on icon click
  const handleStatusToggle = () => {
    setSelectedStatusIndex((prevIndex) => (prevIndex + 1) % statuses.length);
  };

  // Filter subscriptions based on the selected product and search query
  const filteredData = useMemo(() => {
    return combinedData.filter(user => {
      const matchesSearchQuery = 
        (user.subOrderId?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
        (user.subPlanId?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
        (user.subUserName?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
        (user.subUserEmail?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
        (user.subPlanType?.toLowerCase() || "").includes(searchQuery.toLowerCase());
  
      const matchesProductId = selectedProductId === "All" || user.subProductId === selectedProductId;
      const matchesPlanType = selectedPlanType === "All" || user.subPlanType === selectedPlanType;
      const matchesStatus = selectedStatus === "All" || user.subStatus === selectedStatus;
  
      return matchesSearchQuery && matchesProductId && matchesPlanType && matchesStatus;
    })
    .slice(0, rowsPerPage); 
  }, [combinedData, searchQuery, selectedProductId, selectedPlanType, selectedStatus, rowsPerPage]);  

  // Function to load more rows
  const handleLoadMore = () => {
    setRowsPerPage((prev) => prev + 20);
  };

  return (
    <div className="p-4 border rounded">
      <div className="flex justify-end">
        <div className="flex items-center py-2 space-x-4">
          {/* Dropdown to filter by product */}
          <select
            value={selectedProductId}
            onChange={(e) => setSelectedProductId(e.target.value)}
            className="border border-gray-300 rounded px-2 py-1 text-sm"
          >
            <option value="All">All Products</option>
            {products.map(({ productId, productName }) => (
              <option key={productId} value={productId}>
                {productName} ({productId})
              </option>
            ))}
          </select>

          {/* Reset Button */}
          <button 
            className="text-lg text-cyan-800"
            onClick={handleRefetch}
          >
            <RiRefreshFill />
          </button>

          {/* Add Button */}
          <button 
            className="text-gray-400 text-lg cursor-not-allowed" 
            onClick={handleAddNewClick}
            disabled
          >
            <MdAddToPhotos />
          </button>

          {/* Status Filter Toggle Icon */}
          <button                 
            className="flex flex-row justify-normal items-center space-x-2 px-4 text-cyan-800 border border-cyan-800 rounded-full"
            onClick={handleStatusToggle}
          >
            <RiFilter2Line/>
            <span className={`ml-1 ${
              selectedStatus === "Active" ? "font-bold" : selectedStatus === "Expire" ? "font-bold" : "" }`}>
              {selectedStatus}
            </span>
          </button>

          {/* Radio buttons for Plan Type filter */}
          <div className="flex items-center space-x-2 accent-cyan-800 px-4 border-x-cyan-800 border-x">
            <label>
              <input
                type="radio"
                name="planType"
                value="All"
                checked={selectedPlanType === "All"}
                onChange={() => setSelectedPlanType("All")}
              />
              <span className="ml-1">All</span>
            </label>
            <label>
              <input
                type="radio"
                name="planType"
                value="Trial"
                checked={selectedPlanType === "Trial"}
                onChange={() => setSelectedPlanType("Trial")}
              />
              <span className="ml-1">Trial</span>
            </label>
            <label>
              <input
                type="radio"
                name="planType"
                value="Subscribe"
                checked={selectedPlanType === "Subscribe"}
                onChange={() => setSelectedPlanType("Subscribe")}
              />
              <span className="ml-1">Subscribe</span>
            </label>
          </div>

          {/* Search Bar */}
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded px-2 py-1 text-sm w-[250px]"
          />
        </div>
      </div>

      {/* Product Table */}
      <div className="overflow-x-auto max-h-[380px] overflow-y-scroll">
        <table className="min-w-full bg-white border text-sm">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              <th className="py-2 px-4 border-b text-nowrap">Order Id</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan</th>
              <th className="py-2 px-4 border-b text-nowrap">User Name</th>
              <th className="py-2 px-4 border-b text-nowrap">User Email</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Type</th>
              <th className="py-2 px-4 border-b text-nowrap">Account Type</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Period</th>
              <th className="py-2 px-4 border-b text-nowrap">Status</th>
              <th className="py-2 px-4 border-b text-nowrap">Expire Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((user, index) => (
                <React.Fragment key={index}>
                  <tr 
                    onClick={() => handleRowClick(index)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subOrderId}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanId}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subUserName}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subUserEmail}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanType}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subAccountType}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanPeriod}</td>
                    <td className={`border-b text-center font-bold text-sm ${
                      user.subStatus === 'Pending' ? 'text-yellow-500' :
                      user.subStatus === 'Active' ? 'text-green-500' :
                      user.subStatus === 'Expire' ? 'text-red-500' : ''
                    }`}>
                      {user.subStatus}
                    </td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">
                      {new Date(user.subExpiredDate).toLocaleDateString()}
                    </td>
                  </tr>

                  {/* Expanded Row for More Info */}
                  {expandedRowIndex === index && (
                    <tr className="bg-gray-50 border border-gray-300">
                      <td colSpan={9} className="px-8 py-4">
                        <div className="flex flex-col space-y-2">
                          <div className="flex justify-center items-center bg-gray-200 py-2 rounded-lg space-x-4">
                            <div className="flex items-center">
                              <div className="w-[80px] text-left pr-2"><strong>Status:</strong></div>
                              <div className="flex-grow">
                                {isEditing ? (
                                  <select
                                    name="subStatus"
                                    value={editedData.subStatus || ''}
                                    onChange={handleSelectChange}
                                    className="border rounded px-2 py-1 w-full"
                                  >
                                    <option value="Active">Active</option>
                                    <option value="Expire">Expire</option>
                                  </select>
                                ) : (
                                  <span
                                    className={`rounded-full text-white text-xs px-4 py-1 ${
                                      user.subStatus === 'Pending' ? 'bg-yellow-500' :
                                      user.subStatus === 'Active' ? 'bg-green-500' :
                                      user.subStatus === 'Expire' ? 'bg-red-500' : ''
                                    }`}
                                  >
                                    {user.subStatus}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="flex justify-between items-center ">
                              <span className="font-semibold mr-1 text-nowrap">Expired Date:</span>
                              {isEditing ? (
                                <input
                                  type="date"
                                  name="subExpiredDate"
                                  value={
                                    editedData.subExpiredDate
                                      ? new Date(editedData.subExpiredDate).toISOString().split('T')[0]
                                      : ''
                                  }
                                  onChange={handleInputChange}
                                  className="border rounded px-2 py-1 w-full mx-2"
                                />
                              ) : (
                                <span className="mr-2">{new Date(user.subExpiredDate).toLocaleDateString()}</span>
                              )}
                              <span className="font-semibold text-nowrap">({user.subExpiredDays} Days)</span> 
                            </div>
                            {isEditing === user.subOrderId ? (
                              <FaSave 
                                onClick={() => handleSaveClick(user)} 
                                className="cursor-pointer text-cyan-800" 
                              />
                            ) : (
                              <FaRegEdit 
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  if (permissions?.productSubscription?.editSub === 'enable') {
                                    handleEditClick(user);
                                  }
                                }}
                                className={`${
                                  permissions?.productSubscription?.editSub === 'enable' ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed'
                                }`} 
                              />
                            )}
                            <FaTrashAlt 
                              onClick={(e) => {
                                e.stopPropagation();
                                if (permissions?.productSubscription?.deleteSub === 'enable') {
                                  handleDelete(user.subOrderId);
                                }
                              }} 
                              className={`${
                                permissions?.productSubscription?.editSub === 'enable' ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed' 
                              }`}
                            />
                          </div>
                          
                          {/* User info Section */}
                          <div className="flex flex-row justify-between px-4 text-xs">
                            <div className="flex flex-row justify-between">
                              <div className="flex flex-col mt-4">
                                <label className="font-bold underline mb-2">User Info</label>
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left"><strong>Order Id:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        readOnly
                                        disabled
                                        name="subOrderId"
                                        value={editedData.subOrderId}
                                        className="border rounded px-2 py-1 w-full bg-slate-100"
                                      />
                                    ) : (
                                      user.subOrderId
                                    )}
                                  </div>
                                </div>
                                
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left"><strong>Plan Id:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        readOnly
                                        disabled
                                        name="subPlanId"
                                        value={editedData.subPlanId}
                                        className="border rounded px-2 py-1 w-full bg-slate-100"
                                      />
                                    ) : (
                                      user.subPlanId
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left"><strong>Request By:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        readOnly
                                        disabled
                                        name="subUserName"
                                        value={editedData.subUserName}
                                        className="border rounded px-2 py-1 w-full bg-slate-100"
                                      />
                                    ) : (
                                      user.subUserName
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left"><strong>Email:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        readOnly
                                        disabled
                                        name="subUserEmail"
                                        value={editedData.subUserEmail}
                                        className="border rounded px-2 py-1 w-full bg-slate-100"
                                      />
                                    ) : (
                                      user.subUserEmail
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Plan Section */}
                            <div className="flex flex-row justify-between">
                              <div className="flex flex-col mt-4">
                                <label className="font-bold underline mb-2">Plan Info</label>
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Type:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <select
                                        name="subPlanType"
                                        value={editedData.subPlanType || ''}
                                        onChange={handleSelectChange}
                                        className="border rounded px-2 py-1 w-full"
                                      >
                                        <option value="Trial">Trial</option>
                                        <option value="Subscribe">Subscribe</option>
                                        <option value="Renew">Renew</option>
                                      </select>
                                    ) : (
                                      user.subPlanType
                                    )}
                                  </div>
                                </div>
                                
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Amount:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="number"
                                        name="subPlanAmount"
                                        value={editedData.subPlanAmount || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subPlanAmount
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Period:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="number"
                                        name="subPlanPeriod"
                                        value={editedData.subPlanPeriod || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subPlanPeriod
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Apply Date:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="date"
                                        name="subDateSubmit"
                                        value={
                                          editedData.subDateSubmit
                                            ? new Date(editedData.subDateSubmit).toISOString().split('T')[0]
                                            : ''
                                        }
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      new Date(user.subDateSubmit).toLocaleDateString()
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Account Section */}
                            <div className="flex flex-row justify-between">
                              <div className="flex flex-col mt-4">
                                <label className="font-bold underline mb-2">Account Info</label>
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Type:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <select
                                        name="subAccountType"
                                        value={editedData.subAccountType || ''}
                                        onChange={handleSelectChange}
                                        className="border rounded px-2 py-1 w-full"
                                      >
                                        <option value="DEMO">Demo</option>
                                        <option value="LIVE">Live</option>
                                      </select>
                                    ) : (
                                      user.subAccountType
                                    )}
                                  </div>
                                </div>
                                
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Number ID:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="number"
                                        name="subAccountNumber"
                                        value={editedData.subAccountNumber || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subAccountNumber
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Password:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="text"
                                        name="subAccountPassword"
                                        value={editedData.subAccountPassword || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subAccountPassword
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Server:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <select
                                        name="subAccountServer"
                                        value={editedData.subAccountServer || ''}
                                        onChange={handleSelectChange}
                                        className="border rounded px-2 py-1 w-full"
                                      >
                                        <option value="LirunexLimited-Live">Lirunex Live</option>
                                        <option value="LirunexLimited-Demo">Lirunex Demo</option>
                                        <option value="FortunePrimeGlobal-Live">FPG Live</option>
                                        <option value="FortunePrimeGlobal-Demo">FPG Demo</option>
                                      </select>
                                    ) : (
                                      user.subAccountServer
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Account Section */}
                            <div className="flex flex-row justify-between">
                              <div className="flex flex-col mt-4">
                                <label className="font-bold underline mb-2">Payment Info</label>
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Method:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <select
                                        name="subPaymentMethod"
                                        value={editedData.subPaymentMethod || ''}
                                        onChange={handleSelectChange}
                                        className="border rounded px-2 py-1 w-full"
                                      >
                                        <option value="Bank Transfer">Bank Transfer</option>
                                        <option value="USDT">USDT</option>
                                      </select>
                                    ) : (
                                      user.subPaymentMethod
                                    )}
                                  </div>
                                </div>
                                
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Receipts:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <span
                                        className="text-blue-600 underline cursor-pointer"
                                        onClick={() => handleViewReceipt(user.subPaymentReceipts)}
                                      >
                                        View Receipt
                                      </span>
                                    ) : (
                                      <span
                                        className="text-blue-600 underline cursor-pointer"
                                        onClick={() => handleViewReceipt(user.subPaymentReceipts)}
                                      >
                                        View Receipt
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Trial:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="number"
                                        name="subTrialCheck"
                                        value={editedData.subTrialCheck || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subTrialCheck
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Voucher:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="number"
                                        name="subVoucherCheck"
                                        value={editedData.subVoucherCheck || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subVoucherCheck
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* notes section */}
                          <div className="w-full items-center px-2">
                            <div className="flex flex-col justify-normal border-gray-200">
                              <div className="flex flex-col">
                                <div className="font-bold mx-2">Notes:</div>
                                <div className="w-full">
                                  {isEditing ? (
                                    <textarea
                                      name="subNotes"
                                      value={editedData.subNotes || ''}
                                      onChange={handleTextAreaChange}
                                      className="border rounded px-2 py-1 w-full"
                                    />
                                  ) : (
                                    <div className="px-2 py-1">{user.subNotes}</div>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="font-bold mx-2">Remark:</div>
                                <div className="w-full">
                                  {isEditing ? (
                                    <textarea
                                      name="subRemark"
                                      value={editedData.subRemark || ''}
                                      onChange={handleTextAreaChange}
                                      className="border rounded px-2 py-1 w-full"
                                    />
                                  ) : (
                                    <div className="px-2 py-1">{user.subRemark}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center py-8">No user subscriptions available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Load More Button */}
      {filteredData.length < combinedData.length && (
        <div className="flex justify-center mt-2">
          <button
            onClick={handleLoadMore}
            className="px-4 py-1 bg-cyan-800 text-white rounded hover:bg-cyan-900"
          >
            Load More
          </button>
        </div>
      )}

      {/* Receipt Modal */}
      {isReceiptModalOpen && receiptUrl && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Receipt</h2>
              <button
                onClick={() => setIsReceiptModalOpen(false)}
                className="text-red-500 font-bold text-lg"
              >
                &times;
              </button>
            </div>
            <img 
              src={`${apiUrl}${receiptUrl}`} 
              alt="Payment Receipt"
              className="w-full h-auto rounded" 
            />
          </div>
        </div>
      )}

      {/* Add Data Modal */}
      <AddSubUserModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSave={handleAddNewSave}
        newData={newData}
        handleNewInputChange={handleNewInputChange}
        isPendingTable={false} 
      />
    </div>
  );
};

export default SubUserData;


// // udpate code --> 04 dec 2024 v02
// // src/components/admin/SubProductSetting/subUserData.tsx

// import React, { useState, useMemo } from 'react';
// import { RiRefreshFill, RiFilter2Line } from "react-icons/ri";
// import { FaRegEdit, FaTrashAlt, FaSave } from "react-icons/fa";
// import { MdAddToPhotos } from "react-icons/md";
// // import custom file
// import { useEnv } from '../../../context/EnvContext'; 
// import { useAuth } from '../../../context/AuthContext';   
// import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
// import { useSubUserData, SubPending } from '../../../hooks/AdminDashboard/useSubUserData';
// import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting'; 
// import AddSubUserModal from './subAddUserModal';  

// const SubUserData: React.FC = () => {
//   const { apiUrl } = useEnv();
//   const { user } = useAuth();
//   const { permissions } = useRoleAccess(user?.email || '');    
//   const { 
//     algoMatrixData, traderHubData, fetchSubProductData, addSubProduct, updateSubProduct, deleteSubProduct
//   } = useSubUserData();
//   const { products } = useSubSetting(); 
//   // state for expanded user data row
//   const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
//   // state for receipts
//   const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
//   const [receiptUrl, setReceiptUrl] = useState<string | null>(null);
//   // state for search to filter data
//   const [searchQuery, setSearchQuery] = useState("");
//   // state to add data modal
//   const [isAddModalOpen, setIsAddModalOpen] = useState(false);
//   const [newData, setNewData] = useState<Partial<any>>({});
//   // state to add filter table
//   const [selectedPlanType, setSelectedPlanType] = useState<string | "All">("All");
//   const [selectedProductId, setSelectedProductId] = useState<string | "All">("All");
//   const [selectedStatusIndex, setSelectedStatusIndex] = useState(0);
//   const statuses = ["All", "Active", "Expire"];
//   const selectedStatus = statuses[selectedStatusIndex];
//   // state to edit/modify date
//   const [isEditing, setIsEditing] = useState<string | null>(null);
//   const [editedData, setEditedData] = useState<Partial<any>>({});

//   // Function to handle refetching the data
//   const handleRefetch = () => {
//     fetchSubProductData('All'); 
//   };

//   const handleRowClick = (index: number) => {
//     setExpandedRowIndex(expandedRowIndex === index ? null : index);
//   };

//   const handleViewReceipt = (url: string) => {
//     setReceiptUrl(url);
//     setIsReceiptModalOpen(true);
//   };
  
//   const handleEditClick = (user: any) => {
//     setIsEditing(user.subOrderId);
//     setEditedData(user);
//   };

//   // Save the edited data
//   const handleSaveClick = async (user: any) => {
//     const productType = user.subOrderId.startsWith("sub-ALG") ? "AlgoMatrix" : "TraderHub";
//     await updateSubProduct(productType, user.subOrderId, editedData);
//     setIsEditing(null);
//   };

//   // delete the data
//   const handleDelete = async (orderId: string) => {
//     await deleteSubProduct(orderId); 
//     fetchSubProductData();
//   };
  
//   // Update edited data in state
//   const handleInputChange = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
//   ) => {
//     const { name, value } = e.target;
//     setEditedData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };  
  
//   const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const { name, value } = e.target;
//     setEditedData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };
  
//   const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
//     const { name, value } = e.target;
//     setEditedData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   }; 

//   const handleAddNewClick = () => {
//     setIsAddModalOpen(true);
//     setNewData({});
//   };

//   const handleAddNewSave = async () => {
//     const productType = selectedProductId === 'AlgoMatrix' ? 'AlgoMatrix' : 'TraderHub';
//     await addSubProduct(productType, newData as SubPending);
//     setIsAddModalOpen(false);
//     fetchSubProductData();
//   };

//   const handleNewInputChange = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
//   ) => {
//     const { name, value } = e.target;
//     setNewData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };  

//   // Filter data based on the selected product
//   const combinedData = useMemo(() => {
//     if (selectedProductId === 'AlgoMatrix') return algoMatrixData;
//     if (selectedProductId === 'TraderHub') return traderHubData;
//     return [...algoMatrixData, ...traderHubData];
//   }, [algoMatrixData, traderHubData, selectedProductId]);

//   // Cycle through statuses on icon click
//   const handleStatusToggle = () => {
//     setSelectedStatusIndex((prevIndex) => (prevIndex + 1) % statuses.length);
//   };

//   // Filter subscriptions based on the selected product and search query
//   const filteredData = useMemo(() => {
//     return combinedData.filter(user => {
//       const matchesSearchQuery = 
//         (user.subOrderId?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subPlanId?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subUserName?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subUserEmail?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subPlanType?.toLowerCase() || "").includes(searchQuery.toLowerCase());
  
//       const matchesProductId = selectedProductId === "All" || user.subProductId === selectedProductId;
//       const matchesPlanType = selectedPlanType === "All" || user.subPlanType === selectedPlanType;
//       const matchesStatus = selectedStatus === "All" || user.subStatus === selectedStatus;
  
//       return matchesSearchQuery && matchesProductId && matchesPlanType && matchesStatus;
//     });
//   }, [combinedData, searchQuery, selectedProductId, selectedPlanType, selectedStatus]);  

//   return (
//     <div className="p-4 border rounded">
//       <div className="flex justify-end">
//         <div className="flex items-center py-2 space-x-4">
//           {/* Dropdown to filter by product */}
//           <select
//             value={selectedProductId}
//             onChange={(e) => setSelectedProductId(e.target.value)}
//             className="border border-gray-300 rounded px-2 py-1 text-sm"
//           >
//             <option value="All">All Products</option>
//             {products.map(({ productId, productName }) => (
//               <option key={productId} value={productId}>
//                 {productName} ({productId})
//               </option>
//             ))}
//           </select>

//           {/* Reset Button */}
//           <button 
//             className="text-lg text-cyan-800"
//             onClick={handleRefetch}
//           >
//             <RiRefreshFill />
//           </button>

//           {/* Add Button */}
//           <button 
//             className="text-gray-400 text-lg cursor-not-allowed" 
//             onClick={handleAddNewClick}
//             disabled
//           >
//             <MdAddToPhotos />
//           </button>

//           {/* Status Filter Toggle Icon */}
//           <button                 
//             className="flex flex-row justify-normal items-center space-x-2 px-4 text-cyan-800 border border-cyan-800 rounded-full"
//             onClick={handleStatusToggle}
//           >
//             <RiFilter2Line/>
//             <span className={`ml-1 ${
//               selectedStatus === "Active" ? "font-bold" : selectedStatus === "Expire" ? "font-bold" : "" }`}>
//               {selectedStatus}
//             </span>
//           </button>

//           {/* Radio buttons for Plan Type filter */}
//           <div className="flex items-center space-x-2 accent-cyan-800 px-4 border-x-cyan-800 border-x">
//             <label>
//               <input
//                 type="radio"
//                 name="planType"
//                 value="All"
//                 checked={selectedPlanType === "All"}
//                 onChange={() => setSelectedPlanType("All")}
//               />
//               <span className="ml-1">All</span>
//             </label>
//             <label>
//               <input
//                 type="radio"
//                 name="planType"
//                 value="Trial"
//                 checked={selectedPlanType === "Trial"}
//                 onChange={() => setSelectedPlanType("Trial")}
//               />
//               <span className="ml-1">Trial</span>
//             </label>
//             <label>
//               <input
//                 type="radio"
//                 name="planType"
//                 value="Subscribe"
//                 checked={selectedPlanType === "Subscribe"}
//                 onChange={() => setSelectedPlanType("Subscribe")}
//               />
//               <span className="ml-1">Subscribe</span>
//             </label>
//           </div>

//           {/* Search Bar */}
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             className="border border-gray-300 rounded px-2 py-1 text-sm w-[250px]"
//           />
//         </div>
//       </div>

//       {/* Product Table */}
//       <div className="overflow-x-auto max-h-[380px] overflow-y-scroll">
//         <table className="min-w-full bg-white border text-sm">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold">
//               <th className="py-2 px-4 border-b text-nowrap">Order Id</th>
//               <th className="py-2 px-4 border-b text-nowrap">Plan</th>
//               <th className="py-2 px-4 border-b text-nowrap">User Name</th>
//               <th className="py-2 px-4 border-b text-nowrap">User Email</th>
//               <th className="py-2 px-4 border-b text-nowrap">Plan Type</th>
//               <th className="py-2 px-4 border-b text-nowrap">Account Type</th>
//               <th className="py-2 px-4 border-b text-nowrap">Plan Period</th>
//               <th className="py-2 px-4 border-b text-nowrap">Status</th>
//               <th className="py-2 px-4 border-b text-nowrap">Expire Date</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.length > 0 ? (
//               filteredData.map((user, index) => (
//                 <React.Fragment key={index}>
//                   <tr 
//                     onClick={() => handleRowClick(index)}
//                     className="cursor-pointer hover:bg-gray-100"
//                   >
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subOrderId}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanId}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subUserName}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subUserEmail}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanType}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subAccountType}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanPeriod}</td>
//                     <td className={`border-b text-center font-bold text-sm ${
//                       user.subStatus === 'Pending' ? 'text-yellow-500' :
//                       user.subStatus === 'Active' ? 'text-green-500' :
//                       user.subStatus === 'Expire' ? 'text-red-500' : ''
//                     }`}>
//                       {user.subStatus}
//                     </td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">
//                       {new Date(user.subExpiredDate).toLocaleDateString()}
//                     </td>
//                   </tr>

//                   {/* Expanded Row for More Info */}
//                   {expandedRowIndex === index && (
//                     <tr className="bg-gray-50 border border-gray-300">
//                       <td colSpan={9} className="px-8 py-4">
//                         <div className="flex flex-col space-y-2">
//                           <div className="flex justify-center items-center bg-gray-200 py-2 rounded-lg space-x-4">
//                             <div className="flex items-center">
//                               <div className="w-[80px] text-left pr-2"><strong>Status:</strong></div>
//                               <div className="flex-grow">
//                                 {isEditing ? (
//                                   <select
//                                     name="subStatus"
//                                     value={editedData.subStatus || ''}
//                                     onChange={handleSelectChange}
//                                     className="border rounded px-2 py-1 w-full"
//                                   >
//                                     <option value="Active">Active</option>
//                                     <option value="Expire">Expire</option>
//                                   </select>
//                                 ) : (
//                                   <span
//                                     className={`rounded-full text-white text-xs px-4 py-1 ${
//                                       user.subStatus === 'Pending' ? 'bg-yellow-500' :
//                                       user.subStatus === 'Active' ? 'bg-green-500' :
//                                       user.subStatus === 'Expire' ? 'bg-red-500' : ''
//                                     }`}
//                                   >
//                                     {user.subStatus}
//                                   </span>
//                                 )}
//                               </div>
//                             </div>
//                             <div className="flex justify-between items-center ">
//                               <span className="font-semibold mr-1 text-nowrap">Expired Date:</span>
//                               {isEditing ? (
//                                 <input
//                                   type="date"
//                                   name="subExpiredDate"
//                                   value={
//                                     editedData.subExpiredDate
//                                       ? new Date(editedData.subExpiredDate).toISOString().split('T')[0]
//                                       : ''
//                                   }
//                                   onChange={handleInputChange}
//                                   className="border rounded px-2 py-1 w-full mx-2"
//                                 />
//                               ) : (
//                                 <span className="mr-2">{new Date(user.subExpiredDate).toLocaleDateString()}</span>
//                               )}
//                               <span className="font-semibold text-nowrap">({user.subExpiredDays} Days)</span> 
//                             </div>
//                             {isEditing === user.subOrderId ? (
//                               <FaSave 
//                                 onClick={() => handleSaveClick(user)} 
//                                 className="cursor-pointer text-cyan-800" 
//                               />
//                             ) : (
//                               <FaRegEdit 
//                                 onClick={(e) => {
//                                   e.stopPropagation(); 
//                                   if (permissions?.productSubscription?.editSub === 'enable') {
//                                     handleEditClick(user);
//                                   }
//                                 }}
//                                 className={`${
//                                   permissions?.productSubscription?.editSub === 'enable' ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed'
//                                 }`} 
//                               />
//                             )}
//                             <FaTrashAlt 
//                               onClick={(e) => {
//                                 e.stopPropagation();
//                                 if (permissions?.productSubscription?.deleteSub === 'enable') {
//                                   handleDelete(user.subOrderId);
//                                 }
//                               }} 
//                               className={`${
//                                 permissions?.productSubscription?.editSub === 'enable' ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed' 
//                               }`}
//                             />
//                           </div>
                          
//                           {/* User info Section */}
//                           <div className="flex flex-row justify-between px-4 text-xs">
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">User Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Order Id:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subOrderId"
//                                         value={editedData.subOrderId}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subOrderId
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Plan Id:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subPlanId"
//                                         value={editedData.subPlanId}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subPlanId
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Request By:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subUserName"
//                                         value={editedData.subUserName}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subUserName
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Email:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subUserEmail"
//                                         value={editedData.subUserEmail}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subUserEmail
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Plan Section */}
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">Plan Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Type:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subPlanType"
//                                         value={editedData.subPlanType || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="Trial">Trial</option>
//                                         <option value="Subscribe">Subscribe</option>
//                                         <option value="Renew">Renew</option>
//                                       </select>
//                                     ) : (
//                                       user.subPlanType
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Amount:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subPlanAmount"
//                                         value={editedData.subPlanAmount || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subPlanAmount
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Period:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subPlanPeriod"
//                                         value={editedData.subPlanPeriod || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subPlanPeriod
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Apply Date:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="date"
//                                         name="subDateSubmit"
//                                         value={
//                                           editedData.subDateSubmit
//                                             ? new Date(editedData.subDateSubmit).toISOString().split('T')[0]
//                                             : ''
//                                         }
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       new Date(user.subDateSubmit).toLocaleDateString()
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Account Section */}
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">Account Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Type:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subAccountType"
//                                         value={editedData.subAccountType || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="DEMO">Demo</option>
//                                         <option value="LIVE">Live</option>
//                                       </select>
//                                     ) : (
//                                       user.subAccountType
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Number ID:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subAccountNumber"
//                                         value={editedData.subAccountNumber || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subAccountNumber
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Password:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="text"
//                                         name="subAccountPassword"
//                                         value={editedData.subAccountPassword || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subAccountPassword
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Server:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subAccountServer"
//                                         value={editedData.subAccountServer || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="LirunexLimited-Live">Lirunex Live</option>
//                                         <option value="LirunexLimited-Demo">Lirunex Demo</option>
//                                         <option value="FortunePrimeGlobal-Live">FPG Live</option>
//                                         <option value="FortunePrimeGlobal-Demo">FPG Demo</option>
//                                       </select>
//                                     ) : (
//                                       user.subAccountServer
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Account Section */}
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">Payment Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Method:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subPaymentMethod"
//                                         value={editedData.subPaymentMethod || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="Bank Transfer">Bank Transfer</option>
//                                         <option value="USDT">USDT</option>
//                                       </select>
//                                     ) : (
//                                       user.subPaymentMethod
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Receipts:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <span
//                                         className="text-blue-600 underline cursor-pointer"
//                                         onClick={() => handleViewReceipt(user.subPaymentReceipts)}
//                                       >
//                                         View Receipt
//                                       </span>
//                                     ) : (
//                                       <span
//                                         className="text-blue-600 underline cursor-pointer"
//                                         onClick={() => handleViewReceipt(user.subPaymentReceipts)}
//                                       >
//                                         View Receipt
//                                       </span>
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Trial:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subTrialCheck"
//                                         value={editedData.subTrialCheck || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subTrialCheck
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Voucher:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subVoucherCheck"
//                                         value={editedData.subVoucherCheck || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subVoucherCheck
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           {/* notes section */}
//                           <div className="w-full items-center px-2">
//                             <div className="flex flex-col justify-normal border-gray-200">
//                               <div className="flex flex-col">
//                                 <div className="font-bold mx-2">Notes:</div>
//                                 <div className="w-full">
//                                   {isEditing ? (
//                                     <textarea
//                                       name="subNotes"
//                                       value={editedData.subNotes || ''}
//                                       onChange={handleTextAreaChange}
//                                       className="border rounded px-2 py-1 w-full"
//                                     />
//                                   ) : (
//                                     <div className="px-2 py-1">{user.subNotes}</div>
//                                   )}
//                                 </div>
//                               </div>
//                               <div className="flex flex-col">
//                                 <div className="font-bold mx-2">Remark:</div>
//                                 <div className="w-full">
//                                   {isEditing ? (
//                                     <textarea
//                                       name="subRemark"
//                                       value={editedData.subRemark || ''}
//                                       onChange={handleTextAreaChange}
//                                       className="border rounded px-2 py-1 w-full"
//                                     />
//                                   ) : (
//                                     <div className="px-2 py-1">{user.subRemark}</div>
//                                   )}
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </td>
//                     </tr>
//                   )}
//                 </React.Fragment>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={9} className="text-center py-8">No user subscriptions available</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       {/* Receipt Modal */}
//       {isReceiptModalOpen && receiptUrl && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-lg font-semibold">Receipt</h2>
//               <button
//                 onClick={() => setIsReceiptModalOpen(false)}
//                 className="text-red-500 font-bold text-lg"
//               >
//                 &times;
//               </button>
//             </div>
//             <img 
//               src={`${apiUrl}${receiptUrl}`} 
//               alt="Payment Receipt"
//               className="w-full h-auto rounded" 
//             />
//           </div>
//         </div>
//       )}

//       {/* Add Data Modal */}
//       <AddSubUserModal
//         isOpen={isAddModalOpen}
//         onClose={() => setIsAddModalOpen(false)}
//         onSave={handleAddNewSave}
//         newData={newData}
//         handleNewInputChange={handleNewInputChange}
//         isPendingTable={false} 
//       />
//     </div>
//   );
// };

// export default SubUserData;


// // update code --> 04 dec 2024  
// // src/components/admin/SubProductSetting/subUserData.tsx

// import React, { useState, useMemo } from 'react';
// import { RiRefreshFill, RiFilter2Line } from "react-icons/ri";
// import { FaRegEdit, FaTrashAlt, FaSave } from "react-icons/fa";
// import { MdAddToPhotos } from "react-icons/md";

// import { useEnv } from '../../../context/EnvContext'; 
// import { useAuth } from '../../../context/AuthContext';   
// import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
// import { useSubUserData, SubPending } from '../../../hooks/AdminDashboard/useSubUserData';
// import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting'; 
// import AddSubUserModal from './subAddUserModal';  

// const SubUserData: React.FC = () => {
//   const { apiUrl } = useEnv();
//   const { user } = useAuth();
//   const { permissions } = useRoleAccess(user?.email || '');    
//   const { 
//     algoMatrixData, traderHubData, fetchSubProductData, addSubProduct, updateSubProduct, deleteSubProduct
//   } = useSubUserData();
//   const { products } = useSubSetting(); 
//   // state for expanded user data row
//   const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
//   // state for receipts
//   const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
//   const [receiptUrl, setReceiptUrl] = useState<string | null>(null);
//   // state for search to filter data
//   const [searchQuery, setSearchQuery] = useState("");
//   // state to add data modal
//   const [isAddModalOpen, setIsAddModalOpen] = useState(false);
//   const [newData, setNewData] = useState<Partial<any>>({});
//   // state to add filter table
//   const [selectedPlanType, setSelectedPlanType] = useState<string | "All">("All");
//   const [selectedProductId, setSelectedProductId] = useState<string | "All">("All");
//   const [selectedStatusIndex, setSelectedStatusIndex] = useState(0);
//   const statuses = ["All", "Active", "Expire"];
//   const selectedStatus = statuses[selectedStatusIndex];
//   // state to edit/modify date
//   const [isEditing, setIsEditing] = useState<string | null>(null);
//   const [editedData, setEditedData] = useState<Partial<any>>({});

//   // Function to handle refetching the data
//   const handleRefetch = () => {
//     fetchSubProductData('All'); 
//   };

//   const handleRowClick = (index: number) => {
//     setExpandedRowIndex(expandedRowIndex === index ? null : index);
//   };

//   const handleViewReceipt = (url: string) => {
//     setReceiptUrl(url);
//     setIsReceiptModalOpen(true);
//   };
  
//   const handleEditClick = (user: any) => {
//     setIsEditing(user.subOrderId);
//     setEditedData(user);
//   };

//   // Save the edited data
//   const handleSaveClick = async (user: any) => {
//     const productType = user.subOrderId.startsWith("sub-ALG") ? "AlgoMatrix" : "TraderHub";
//     await updateSubProduct(productType, user.subOrderId, editedData);
//     setIsEditing(null);
//   };

//   // delete the data
//   const handleDelete = async (orderId: string) => {
//     await deleteSubProduct(orderId); 
//     fetchSubProductData();
//   };
  
//   // Update edited data in state
//   const handleInputChange = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
//   ) => {
//     const { name, value } = e.target;
//     setEditedData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };  
  
//   const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const { name, value } = e.target;
//     setEditedData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };
  
//   const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
//     const { name, value } = e.target;
//     setEditedData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   }; 

//   const handleAddNewClick = () => {
//     setIsAddModalOpen(true);
//     setNewData({});
//   };

//   const handleAddNewSave = async () => {
//     const productType = selectedProductId === 'AlgoMatrix' ? 'AlgoMatrix' : 'TraderHub';
//     await addSubProduct(productType, newData as SubPending);
//     setIsAddModalOpen(false);
//     fetchSubProductData();
//   };

//   const handleNewInputChange = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
//   ) => {
//     const { name, value } = e.target;
//     setNewData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };  

//   // Filter data based on the selected product
//   const combinedData = useMemo(() => {
//     if (selectedProductId === 'AlgoMatrix') return algoMatrixData;
//     if (selectedProductId === 'TraderHub') return traderHubData;
//     return [...algoMatrixData, ...traderHubData];
//   }, [algoMatrixData, traderHubData, selectedProductId]);

//   // Cycle through statuses on icon click
//   const handleStatusToggle = () => {
//     setSelectedStatusIndex((prevIndex) => (prevIndex + 1) % statuses.length);
//   };

//   // Filter subscriptions based on the selected product and search query
//   const filteredData = useMemo(() => {
//     return combinedData.filter(user => {
//       const matchesSearchQuery = 
//         (user.subOrderId?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subPlanId?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subUserName?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subUserEmail?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subPlanType?.toLowerCase() || "").includes(searchQuery.toLowerCase());
  
//       const matchesProductId = selectedProductId === "All" || user.subProductId === selectedProductId;
//       const matchesPlanType = selectedPlanType === "All" || user.subPlanType === selectedPlanType;
//       const matchesStatus = selectedStatus === "All" || user.subStatus === selectedStatus;
  
//       return matchesSearchQuery && matchesProductId && matchesPlanType && matchesStatus;
//     });
//   }, [combinedData, searchQuery, selectedProductId, selectedPlanType, selectedStatus]);  

//   return (
//     <div className="p-4 border rounded">
//       <div className="flex justify-end">
//         <div className="flex items-center py-2 space-x-4">
//           {/* Dropdown to filter by product */}
//           <select
//             value={selectedProductId}
//             onChange={(e) => setSelectedProductId(e.target.value)}
//             className="border border-gray-300 rounded px-2 py-1 text-sm"
//           >
//             <option value="All">All Products</option>
//             {products.map(({ productId, productName }) => (
//               <option key={productId} value={productId}>
//                 {productName} ({productId})
//               </option>
//             ))}
//           </select>

//           {/* Reset Button */}
//           <button 
//             className="text-lg text-cyan-800"
//             onClick={handleRefetch}
//           >
//             <RiRefreshFill />
//           </button>

//           {/* Add Button */}
//           <button 
//             className="text-gray-400 text-lg cursor-not-allowed" 
//             onClick={handleAddNewClick}
//             disabled
//           >
//             <MdAddToPhotos />
//           </button>

//           {/* Status Filter Toggle Icon */}
//           <button                 
//             className="flex flex-row justify-normal items-center space-x-2 px-4 text-cyan-800 border border-cyan-800 rounded-full"
//             onClick={handleStatusToggle}
//           >
//             <RiFilter2Line/>
//             <span className={`ml-1 ${
//               selectedStatus === "Active" ? "font-bold" : selectedStatus === "Expire" ? "font-bold" : "" }`}>
//               {selectedStatus}
//             </span>
//           </button>

//           {/* Radio buttons for Plan Type filter */}
//           <div className="flex items-center space-x-2 accent-cyan-800 px-4 border-x-cyan-800 border-x">
//             <label>
//               <input
//                 type="radio"
//                 name="planType"
//                 value="All"
//                 checked={selectedPlanType === "All"}
//                 onChange={() => setSelectedPlanType("All")}
//               />
//               <span className="ml-1">All</span>
//             </label>
//             <label>
//               <input
//                 type="radio"
//                 name="planType"
//                 value="Trial"
//                 checked={selectedPlanType === "Trial"}
//                 onChange={() => setSelectedPlanType("Trial")}
//               />
//               <span className="ml-1">Trial</span>
//             </label>
//             <label>
//               <input
//                 type="radio"
//                 name="planType"
//                 value="Subscribe"
//                 checked={selectedPlanType === "Subscribe"}
//                 onChange={() => setSelectedPlanType("Subscribe")}
//               />
//               <span className="ml-1">Subscribe</span>
//             </label>
//           </div>

//           {/* Search Bar */}
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             className="border border-gray-300 rounded px-2 py-1 text-sm w-[250px]"
//           />
//         </div>
//       </div>

//       {/* Product Table */}
//       <div className="overflow-x-auto max-h-[380px] overflow-y-scroll">
//         <table className="min-w-full bg-white border text-sm">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold">
//               <th className="py-2 px-4 border-b text-nowrap">Order Id</th>
//               <th className="py-2 px-4 border-b text-nowrap">Plan</th>
//               <th className="py-2 px-4 border-b text-nowrap">User Name</th>
//               <th className="py-2 px-4 border-b text-nowrap">User Email</th>
//               <th className="py-2 px-4 border-b text-nowrap">Plan Type</th>
//               <th className="py-2 px-4 border-b text-nowrap">Account Type</th>
//               <th className="py-2 px-4 border-b text-nowrap">Plan Period</th>
//               <th className="py-2 px-4 border-b text-nowrap">Status</th>
//               <th className="py-2 px-4 border-b text-nowrap">Expire Date</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.length > 0 ? (
//               filteredData.map((user, index) => (
//                 <React.Fragment key={index}>
//                   <tr 
//                     onClick={() => handleRowClick(index)}
//                     className="cursor-pointer hover:bg-gray-100"
//                   >
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subOrderId}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanId}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subUserName}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subUserEmail}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanType}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subAccountType}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanPeriod}</td>
//                     <td className={`border-b text-center font-bold text-sm ${
//                       user.subStatus === 'Pending' ? 'text-yellow-500' :
//                       user.subStatus === 'Active' ? 'text-green-500' :
//                       user.subStatus === 'Expire' ? 'text-red-500' : ''
//                     }`}>
//                       {user.subStatus}
//                     </td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">
//                       {new Date(user.subExpiredDate).toLocaleDateString()}
//                     </td>
//                   </tr>

//                   {/* Expanded Row for More Info */}
//                   {expandedRowIndex === index && (
//                     <tr className="bg-gray-50 border border-gray-300">
//                       <td colSpan={9} className="px-8 py-4">
//                         <div className="flex flex-col space-y-2">
//                           <div className="flex justify-center items-center bg-gray-200 py-2 rounded-lg space-x-4">
//                             <div className="flex justify-normal items-center">
//                               <span className="font-semibold mr-1">Status:</span>
//                               <span className={`rounded-full text-white text-xs px-4 py-1 ${
//                                 user.subStatus === 'Pending' ? 'bg-yellow-500' :
//                                 user.subStatus === 'Active' ? 'bg-green-500' :
//                                 user.subStatus === 'Expire' ? 'bg-red-500' : ''
//                               }`}>
//                                 {user.subStatus}
//                               </span>
//                             </div>
//                             <div className="flex justify-between items-center ">
//                               <span className="font-semibold mr-1 text-nowrap">Expired Date:</span>
//                               {isEditing ? (
//                                 <input
//                                   type="date"
//                                   name="subExpiredDate"
//                                   value={
//                                     editedData.subExpiredDate
//                                       ? new Date(editedData.subExpiredDate).toISOString().split('T')[0]
//                                       : ''
//                                   }
//                                   onChange={handleInputChange}
//                                   className="border rounded px-2 py-1 w-full mx-2"
//                                 />
//                               ) : (
//                                 <span className="mr-2">{new Date(user.subExpiredDate).toLocaleDateString()}</span>
//                               )}
//                               <span className="font-semibold text-nowrap">({user.subExpiredDays} Days)</span> 
//                             </div>
//                             {isEditing === user.subOrderId ? (
//                               <FaSave 
//                                 onClick={() => handleSaveClick(user)} 
//                                 className="cursor-pointer text-cyan-800" 
//                               />
//                             ) : (
//                               <FaRegEdit 
//                                 onClick={() => handleEditClick(user)} 
//                                 className="cursor-pointer text-cyan-800" 
//                               />
//                             )}
//                             <FaTrashAlt 
//                               onClick={() => handleDelete(user.subOrderId)} 
//                               className="cursor-pointer text-cyan-800" 
//                             />
//                           </div>
                          
//                           {/* User info Section */}
//                           <div className="flex flex-row justify-between px-4 text-xs">
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">User Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Order Id:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subOrderId"
//                                         value={editedData.subOrderId}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subOrderId
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Plan Id:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subPlanId"
//                                         value={editedData.subPlanId}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subPlanId
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Request By:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subUserName"
//                                         value={editedData.subUserName}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subUserName
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Email:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subUserEmail"
//                                         value={editedData.subUserEmail}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subUserEmail
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Plan Section */}
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">Plan Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Type:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subPlanType"
//                                         value={editedData.subPlanType || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="Trial">Trial</option>
//                                         <option value="Subscribe">Subscribe</option>
//                                         <option value="Renew">Renew</option>
//                                       </select>
//                                     ) : (
//                                       user.subPlanType
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Amount:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subPlanAmount"
//                                         value={editedData.subPlanAmount || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subPlanAmount
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Period:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subPlanPeriod"
//                                         value={editedData.subPlanPeriod || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subPlanPeriod
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Apply Date:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="date"
//                                         name="subDateSubmit"
//                                         value={
//                                           editedData.subDateSubmit
//                                             ? new Date(editedData.subDateSubmit).toISOString().split('T')[0]
//                                             : ''
//                                         }
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       new Date(user.subDateSubmit).toLocaleDateString()
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Account Section */}
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">Account Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Type:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subAccountType"
//                                         value={editedData.subAccountType || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="DEMO">Demo</option>
//                                         <option value="LIVE">Live</option>
//                                       </select>
//                                     ) : (
//                                       user.subAccountType
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Number ID:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subAccountNumber"
//                                         value={editedData.subAccountNumber || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subAccountNumber
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Password:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="text"
//                                         name="subAccountPassword"
//                                         value={editedData.subAccountPassword || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subAccountPassword
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Server:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subAccountServer"
//                                         value={editedData.subAccountServer || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="LirunexLimited-Live">Lirunex Live</option>
//                                         <option value="LirunexLimited-Demo">Lirunex Demo</option>
//                                         <option value="FortunePrimeGlobal-Live">FPG Live</option>
//                                         <option value="FortunePrimeGlobal-Demo">FPG Demo</option>
//                                       </select>
//                                     ) : (
//                                       user.subAccountServer
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Account Section */}
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">Payment Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Method:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subPaymentMethod"
//                                         value={editedData.subPaymentMethod || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="Bank Transfer">Bank Transfer</option>
//                                         <option value="USDT">USDT</option>
//                                       </select>
//                                     ) : (
//                                       user.subPaymentMethod
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Receipts:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <span
//                                         className="text-blue-600 underline cursor-pointer"
//                                         onClick={() => handleViewReceipt(user.subPaymentReceipts)}
//                                       >
//                                         View Receipt
//                                       </span>
//                                     ) : (
//                                       <span
//                                         className="text-blue-600 underline cursor-pointer"
//                                         onClick={() => handleViewReceipt(user.subPaymentReceipts)}
//                                       >
//                                         View Receipt
//                                       </span>
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Trial:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subTrialCheck"
//                                         value={editedData.subTrialCheck || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subTrialCheck
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Voucher:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subVoucherCheck"
//                                         value={editedData.subVoucherCheck || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subVoucherCheck
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           {/* notes section */}
//                           <div className="w-full items-center px-2">
//                             <div className="flex flex-col justify-normal border-gray-200">
//                               <div className="flex flex-col">
//                                 <div className="font-bold mx-2">Notes:</div>
//                                 <div className="w-full">
//                                   {isEditing ? (
//                                     <textarea
//                                       name="subNotes"
//                                       value={editedData.subNotes || ''}
//                                       onChange={handleTextAreaChange}
//                                       className="border rounded px-2 py-1 w-full"
//                                     />
//                                   ) : (
//                                     <div className="px-2 py-1">{user.subNotes}</div>
//                                   )}
//                                 </div>
//                               </div>
//                               <div className="flex flex-col">
//                                 <div className="font-bold mx-2">Remark:</div>
//                                 <div className="w-full">
//                                   {isEditing ? (
//                                     <textarea
//                                       name="subRemark"
//                                       value={editedData.subRemark || ''}
//                                       onChange={handleTextAreaChange}
//                                       className="border rounded px-2 py-1 w-full"
//                                     />
//                                   ) : (
//                                     <div className="px-2 py-1">{user.subRemark}</div>
//                                   )}
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </td>
//                     </tr>
//                   )}
//                 </React.Fragment>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={9} className="text-center py-8">No user subscriptions available</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       {/* Receipt Modal */}
//       {isReceiptModalOpen && receiptUrl && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-lg font-semibold">Receipt</h2>
//               <button
//                 onClick={() => setIsReceiptModalOpen(false)}
//                 className="text-red-500 font-bold text-lg"
//               >
//                 &times;
//               </button>
//             </div>
//             <img 
//               src={`${apiUrl}${receiptUrl}`} 
//               alt="Payment Receipt"
//               className="w-full h-auto rounded" 
//             />
//           </div>
//         </div>
//       )}

//       {/* Add Data Modal */}
//       <AddSubUserModal
//         isOpen={isAddModalOpen}
//         onClose={() => setIsAddModalOpen(false)}
//         onSave={handleAddNewSave}
//         newData={newData}
//         handleNewInputChange={handleNewInputChange}
//         isPendingTable={false} 
//       />
//     </div>
//   );
// };

// export default SubUserData;