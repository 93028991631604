// src/components/admin/SubProductSetting/SubProductModal.tsx

import React, { useState, useEffect } from 'react';
import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';
import EmailList from '../SubProductSetting/subEmailModal';

interface SubTrialModalProps {
  onClose: () => void;
  onSave: (
    trial: { 
      trialId: string;
      trialLinkPlanId: string; 
      trialName: string; 
      trialPeriod: number; 
      trialMembersType: string | null; 
      trialGroupName: string | null; 
      trialGroupMember: string | null;
      trialStatus: string;
    }) => void;
    editMode?: boolean;
    initialData?: { 
      trialId: string;
      trialLinkPlanId: string; 
      trialName: string; 
      trialPeriod: number; 
      trialMembersType: string | null; 
      trialGroupName: string | null;   
      trialGroupMember: string | null; 
      trialStatus: string;
    };
  }

const SubTrialModal: React.FC<SubTrialModalProps> = ({ onClose, onSave, editMode = false, initialData }) => {
  // import custom hook
  const { products, plans } = useSubSetting();

  // custom states
  const [trialsData, setTrialsData] = useState({
    trialId: initialData?.trialId || '',
    trialLinkPlanId: initialData?.trialLinkPlanId || '',
    trialName: initialData?.trialName || '',
    trialPeriod: initialData?.trialPeriod || 0, 
    trialMembersType: initialData?.trialMembersType || '',
    trialGroupName: initialData?.trialGroupName || '',
    trialGroupMember: initialData?.trialGroupMember || '',
    trialStatus: initialData?.trialStatus || 'Active',
  });

  const [selectedPlans, setSelectedPlans] = useState<{ planId: string, displayName: string }[]>([]);
  const [selectedMemberTypes, setSelectedMemberTypes] = useState<string[]>(trialsData.trialMembersType ? trialsData.trialMembersType.split(',') : []);
  const [customGroup, setCustomGroup] = useState<{ groupName: string; groupMembers: string[] } | null>(
    initialData?.trialGroupName ? { groupName: initialData.trialGroupName, groupMembers: initialData.trialGroupMember?.split(',') || [] } : null
  );

  useEffect(() => {
    if (plans.length > 0 && initialData?.trialLinkPlanId) {
      // Initialize selectedPlans with valid display names when plans data is available
      const initialSelectedPlans = initialData.trialLinkPlanId.split(',').map(planId => {
        const plan = plans.find(p => p.planId === planId);
        const product = products.find(p => p.productId === plan?.LinkProductId);
        return {
          planId,
          displayName: product && plan ? `${product.productName} - ${plan.planName}` : 'Unknown Plan',
        };
      });
      setSelectedPlans(initialSelectedPlans);
    }
  }, [plans, products, initialData?.trialLinkPlanId]);

  const [isGroupModalOpen, setGroupModalOpen] = useState(false);

  const openGroupModal = () => setGroupModalOpen(true);
  const closeGroupModal = () => setGroupModalOpen(false);

  const handleAddGroup = (groupName: string, emails: string[]) => {
    const updatedGroupName = groupName || customGroup?.groupName || "New Group";
    setCustomGroup({ groupName: updatedGroupName, groupMembers: emails });
    setTrialsData(prevData => ({
      ...prevData,
      trialGroupName: updatedGroupName,
      trialGroupMember: emails.join(','),
    }));
    closeGroupModal();
  };

  useEffect(() => {
    if (initialData) {
      setTrialsData({
        trialId: initialData.trialId,
        trialLinkPlanId: initialData.trialLinkPlanId,
        trialName: initialData.trialName,
        trialPeriod: initialData.trialPeriod,
        trialMembersType: initialData.trialMembersType || '',
        trialGroupName: initialData.trialGroupName || '',
        trialGroupMember: initialData.trialGroupMember || '',
        trialStatus: initialData.trialStatus,
      });
    }
  }, [initialData]);

  const handlePlanSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPlanId = e.target.value;
    if (selectedPlanId && !selectedPlans.some(plan => plan.planId === selectedPlanId)) {
      const plan = plans.find(plan => plan.planId === selectedPlanId);
      const product = products.find(product => product.productId === plan?.LinkProductId);
      const displayName = product && plan ? `${product.productName} - ${plan.planName}` : 'Unknown Product';

      setSelectedPlans(prev => [...prev, { planId: selectedPlanId, displayName }]);
      setTrialsData(prevData => ({
        ...prevData,
        trialLinkPlanId: [...prevData.trialLinkPlanId.split(',').filter(Boolean), selectedPlanId].join(','),
      }));
    }
  }; 

  const handleMemberTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = e.target.value;
    if (selectedType && !selectedMemberTypes.includes(selectedType)) {
      setSelectedMemberTypes(prev => [...prev, selectedType]);
      setTrialsData(prevData => ({
        ...prevData,
        trialMembersType: [...selectedMemberTypes, selectedType].join(','),
      }));
    }
  };    

  const handlePlanRemove = (planId: string) => {
    setSelectedPlans(prev => prev.filter(plan => plan.planId !== planId));
    setTrialsData(prevData => ({
      ...prevData,
      trialLinkPlanId: prevData.trialLinkPlanId
        .split(',')
        .filter(id => id !== planId)
        .join(','),
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setTrialsData(prevData => ({
      ...prevData,
      [name]: name === "trialPeriod" ? Number(value) : value,
    }));
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setTrialsData(prevData => ({
      ...prevData,
      trialPeriod:  value === "0" ? 0: value === "30" ? 30 : value === '365' ? 365 : prevData.trialPeriod,
    }));
  };

  const handleSave = () => {
    const trialDataToSave = {
      ...trialsData,
      trialMembersType: selectedMemberTypes.length > 0 ? trialsData.trialMembersType : null,
      trialGroupName: customGroup?.groupName || null,
      trialGroupMember: customGroup?.groupMembers.join(',') || null,
    };
  
    onSave(trialDataToSave);
    onClose(); 
  };   

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-md shadow-md w-[400px]">
        <h2 className="text-xl font-semibold mb-4 text-cyan-800">
          {editMode ? "Edit Trial Sets" : "Add Trial Sets"}
        </h2>
        
        <div className="overflow-y-auto h-auto max-h-[550px]">
          {/* Trials id field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Trial Id</label>
            <input
              type="text"
              name="trialId"
              placeholder="id auto generated..."
              value={trialsData.trialId}
              onChange={handleChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
              disabled
            />
          </div>

          {/* Trials Name field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Trial Name</label>
            <input
              type="text"
              name="trialName"
              placeholder="enter trial name..."
              value={trialsData.trialName}
              onChange={handleChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
            />
          </div>

          {/* Link Plan for Trial field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Trial Link Plan</label>
            <select
              name="trialLinkPlanId"
              value=""
              onChange={handlePlanSelect}
              className="border px-4 py-1 rounded w-full text-sm"
            >
              <option value="">Select link plan</option>
              {plans.map(plan => {
                const product = products.find(product => product.productId === plan.LinkProductId);
                const productName = product ? product.productName : 'Unknown Product';

                return (
                  <option key={plan.planId} value={plan.planId}>
                    {`${productName} - ${plan.planName}`}
                  </option>
                );
              })}
            </select>

            <div className="mt-2 border-2 border-dashed text-sm p-6 rounded">
              {selectedPlans.length > 0 ? (
                selectedPlans.map(plan => (
                  <div key={plan.planId} className="flex items-center space-x-2">
                    <span>◾️{plan.displayName}</span>
                    <button 
                      onClick={() => handlePlanRemove(plan.planId)} 
                      className="text-red-500 text-xs"
                    >
                      Remove
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-gray-300 text-center">No plans selected</p>
              )}
            </div>
          </div>

          {/* Trials Period field */}
          <div className="my-6">
            <div className="flex justify-between items-center">
              <label className="mx-4 font-semibold text-cyan-800">Trial Period</label>
              <select
                name="trialPeriod"
                className="px-4 py-1 rounded w-auto text-sm text-center"
                value={trialsData.trialPeriod}
                onChange={handlePeriodChange}
              >
                <option value="0">Select a preset period</option>
                <option value="30">Monthly</option>
                <option value="365">Annually</option>
              </select>
            </div>
            <input
              type="number"
              name="trialPeriod"
              value={trialsData.trialPeriod}
              onChange={handleChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
            />
          </div>

          {/* Trial Member Type field with checkboxes */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Trial Member Type</label>
            <select
              name="trialMembersType"
              value=""
              onChange={handleMemberTypeChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
            >
              <option value="">Select a member type</option>
              <option value="R One Guest">R One Guest</option>
              <option value="R One Basic">R One Basic</option>
              <option value="R One Member">R One Member</option>
              <option value="R One Trader Hub">R One Trader Hub</option>
              <option value="R One Dealer">R One Dealer</option>
              <option value="R One Elite Trader">R One Elite Trader</option>
              <option value="R One Master Trader">R One Master Trader</option>
              <option value="R One Certified Trader">R One Certified Trader</option>
            </select>

            {/* Display selected member types below */}
            <div className="mt-2 border-2 border-dashed text-sm p-6 rounded">
              {selectedMemberTypes.length > 0 ? (
                selectedMemberTypes.map(type => (
                  <div key={type} className="flex items-center space-x-2">
                    <span>◾️{type}</span>
                    <button 
                      onClick={() => setSelectedMemberTypes(prev => prev.filter(t => t !== type))} 
                      className="text-red-500 text-xs"
                    >
                      Remove
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-gray-300 text-center">No member types selected</p>
              )}
            </div>
          </div>

          {/* Trials Custom Group Name */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Trial Custom Group</label>
            <div
              onClick={openGroupModal}
              className="border-2 border-dashed px-4 py-2 rounded text-sm mt-2 cursor-pointer"
            >
              {customGroup ? (
                <div className="p-2 rounded text-sm mt-2">
                  <div className="flex flex-col space-y-2">
                    <span className="text-rose-400">Custom Group Name:</span> {customGroup.groupName}
                    <span className="text-rose-400">Custom Email Lists</span>
                  </div>
                  <ul>
                    {customGroup.groupMembers.map(email => (
                      <li key={email}>{email}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p
                  onClick={openGroupModal}
                  className="text-gray-300 text-center cursor-pointer p-2 rounded"
                >
                  No custom group added. Click to create.
                </p>
              )}
            </div>
          </div>

          {/* Trials Status field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Trial Status</label>
            <select
              name="trialStatus"
              value={trialsData.trialStatus}
              onChange={handleChange}
              className="border px-4 py-1 rounded w-full text-sm"
            >
              <option value="">Select a status</option>
              <option value="Active">Active</option>
              <option value="Stopped">Stopped</option>
            </select>
          </div>
        </div>

        <div className="flex justify-end space-x-2 mt-4">
          <button onClick={onClose} className="bg-gray-500 text-white px-4 py-1 rounded">Cancel</button>
          <button onClick={handleSave} className="bg-cyan-800 text-white px-6 py-1 rounded">
            {editMode ? "Update" : "Add"}
          </button>
        </div>
      </div>

      <EmailList
        isOpen={isGroupModalOpen}
        onClose={closeGroupModal}
        handleAddEmail={handleAddGroup}
        initialEmails={customGroup?.groupMembers || []}
        initialGroupName={customGroup?.groupName || ''}
      />

    </div>
  );
};

export default SubTrialModal;


// // udpate code --> 01 nov 2024
// // src/components/admin/SubProductSetting/SubProductModal.tsx

// import React, { useState, useEffect } from 'react';
// import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';
// import EmailList from '../SubProductSetting/subEmailModal';

// interface SubTrialModalProps {
//   onClose: () => void;
//   onSave: (
//     trial: { 
//       trialId: string;
//       trialLinkPlanId: string; 
//       trialName: string; 
//       trialPeriod: number; 
//       trialMembersType: string | null; 
//       trialGroupName: string | null; 
//       trialGroupMember: string | null;
//       trialStatus: string;
//     }) => void;
//     editMode?: boolean;
//     initialData?: { 
//       trialId: string;
//       trialLinkPlanId: string; 
//       trialName: string; 
//       trialPeriod: number; 
//       trialMembersType: string | null; 
//       trialGroupName: string | null;   
//       trialGroupMember: string | null; 
//       trialStatus: string;
//     };
//   }

// const SubTrialModal: React.FC<SubTrialModalProps> = ({ onClose, onSave, editMode = false, initialData }) => {
//   // import custom hook
//   const { products, plans } = useSubSetting();
//   // custom states
//   const [trialsData, setTrialsData] = useState({
//     trialId: initialData?.trialId || '',
//     trialLinkPlanId: initialData?.trialLinkPlanId || '',
//     trialName: initialData?.trialName || '',
//     trialPeriod: initialData?.trialPeriod || 0, 
//     trialMembersType: initialData?.trialMembersType || '',
//     trialGroupName: initialData?.trialGroupName || '',
//     trialGroupMember: initialData?.trialGroupMember || '',
//     trialStatus: initialData?.trialStatus || 'Active',
//   });
  
//   const [selectedPlans, setSelectedPlans] = useState<{ planId: string, displayName: string }[]>([]);
//   const [selectedMemberTypes, setSelectedMemberTypes] = useState<string[]>(trialsData.trialMembersType.split(',').filter(Boolean));
//   const [customGroup, setCustomGroup] = useState<{ groupName: string; groupMembers: string[] } | null>(null);
//   const [isGroupModalOpen, setGroupModalOpen] = useState(false);

//   const openGroupModal = () => setGroupModalOpen(true);
//   const closeGroupModal = () => setGroupModalOpen(false);

//   const handleAddGroup = (groupName: string, emails: string[]) => {
//     const updatedGroupName = groupName || customGroup?.groupName || "New Group";
//     setCustomGroup({ groupName: updatedGroupName, groupMembers: emails });
//     setTrialsData(prevData => ({
//       ...prevData,
//       trialGroupName: updatedGroupName,
//       trialGroupMember: emails.join(','),
//     }));
//     closeGroupModal();
//   };

//   useEffect(() => {
//     if (initialData) {
//       setTrialsData({
//         trialId: initialData.trialId,
//         trialLinkPlanId: initialData.trialLinkPlanId,
//         trialName: initialData.trialName,
//         trialPeriod: initialData.trialPeriod,
//         trialMembersType: initialData.trialMembersType || '',
//         trialGroupName: initialData.trialGroupName || '',
//         trialGroupMember: initialData.trialGroupMember || '',
//         trialStatus: initialData.trialStatus,
//       });
//     }
//   }, [initialData]);

//   const handlePlanSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedPlanId = e.target.value;
//     if (selectedPlanId && !selectedPlans.some(plan => plan.planId === selectedPlanId)) {
//       const plan = plans.find(plan => plan.planId === selectedPlanId);
//       if (plan) {
//         // Find the matching product name based on LinkProductId in products array
//         const product = products.find(product => product.productId === plan.LinkProductId);
//         const productName = product ? product.productName : 'Unknown Product';
//         const displayName = `${productName} - ${plan.planName}`;
        
//         setSelectedPlans(prev => [...prev, { planId: plan.planId, displayName }]);
        
//         setTrialsData(prevData => ({
//           ...prevData,
//           trialLinkPlanId: [...prevData.trialLinkPlanId.split(',').filter(Boolean), selectedPlanId].join(','),
//         }));
//       }
//     }
//   };  

//   const handleMemberTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = e.target.value;
//     if (selectedType && !selectedMemberTypes.includes(selectedType)) {
//       setSelectedMemberTypes(prev => [...prev, selectedType]);
//       setTrialsData(prevData => ({
//         ...prevData,
//         trialMembersType: [...selectedMemberTypes, selectedType].join(','),
//       }));
//     }
//   };    

//   const handlePlanRemove = (planId: string) => {
//     setSelectedPlans(prev => prev.filter(plan => plan.planId !== planId));
//     setTrialsData(prevData => ({
//       ...prevData,
//       trialLinkPlanId: prevData.trialLinkPlanId
//         .split(',')
//         .filter(id => id !== planId)
//         .join(','),
//     }));
//   };

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
//     const { name, value } = e.target;
//     setTrialsData(prevData => ({
//       ...prevData,
//       [name]: name === "trialPeriod" ? Number(value) : value,
//     }));
//   };

//   const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const value = e.target.value;
//     setTrialsData(prevData => ({
//       ...prevData,
//       trialPeriod:  value === "0" ? 0: value === "30" ? 30 : value === '365' ? 365 : prevData.trialPeriod,
//     }));
//   };

//   const handleSave = () => {
//     const trialDataToSave = {
//       ...trialsData,
//       trialMembersType: selectedMemberTypes.length > 0 ? trialsData.trialMembersType : null,
//       trialGroupName: customGroup?.groupName || null,
//       trialGroupMember: customGroup?.groupMembers.join(',') || null,
//     };
  
//     onSave(trialDataToSave);
//     onClose(); 
//   };   

//   return (
//     <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
//       <div className="bg-white p-6 rounded-md shadow-md w-[400px]">
//         <h2 className="text-xl font-semibold mb-4 text-cyan-800">
//           {editMode ? "Edit Trial Sets" : "Add Trial Sets"}
//         </h2>
        
//         <div className="overflow-y-auto h-auto max-h-[550px]">
//           {/* Trials id field */}
//           <div className="my-4">
//             <label className="mx-4 font-semibold text-cyan-800">Trial Id</label>
//             <input
//               type="text"
//               name="trialId"
//               placeholder="id auto generated..."
//               value={trialsData.trialId}
//               onChange={handleChange}
//               className="border px-4 py-1 rounded mb-2 w-full text-sm"
//               disabled
//             />
//           </div>

//           {/* Trials Name field */}
//           <div className="my-4">
//             <label className="mx-4 font-semibold text-cyan-800">Trial Name</label>
//             <input
//               type="text"
//               name="trialName"
//               placeholder="enter trial name..."
//               value={trialsData.trialName}
//               onChange={handleChange}
//               className="border px-4 py-1 rounded mb-2 w-full text-sm"
//             />
//           </div>

//           {/* Link Plan for Trial field */}
//           <div className="my-4">
//             <label className="mx-4 font-semibold text-cyan-800">Trial Link Plan</label>
//             <select
//               name="trialLinkPlanId"
//               value=""
//               onChange={handlePlanSelect}
//               className="border px-4 py-1 rounded w-full text-sm"
//             >
//               <option value="">Select link plan</option>
//               {plans.map(plan => {
//                 const product = products.find(product => product.productId === plan.LinkProductId);
//                 const productName = product ? product.productName : 'Unknown Product';

//                 return (
//                   <option key={plan.planId} value={plan.planId}>
//                     {`${productName} - ${plan.planName}`}
//                   </option>
//                 );
//               })}
//             </select>

//             <div className="mt-2 border-2 border-dashed text-sm p-6 rounded">
//               {selectedPlans.length > 0 ? (
//                 selectedPlans.map(plan => (
//                   <div key={plan.planId} className="flex items-center space-x-2">
//                     <span>◾️{plan.displayName}</span>
//                     <button 
//                       onClick={() => handlePlanRemove(plan.planId)} 
//                       className="text-red-500 text-xs"
//                     >
//                       Remove
//                     </button>
//                   </div>
//                 ))
//               ) : (
//                 <p className="text-gray-300 text-center">No plans selected</p>
//               )}
//             </div>
//           </div>

//           {/* Trials Period field */}
//           <div className="my-6">
//             <div className="flex justify-between items-center">
//               <label className="mx-4 font-semibold text-cyan-800">Trial Period</label>
//               <select
//                 name="trialPeriod"
//                 className="px-4 py-1 rounded w-auto text-sm text-center"
//                 value={trialsData.trialPeriod}
//                 onChange={handlePeriodChange}
//               >
//                 <option value="0">Select a preset period</option>
//                 <option value="30">Monthly</option>
//                 <option value="365">Annually</option>
//               </select>
//             </div>
//             <input
//               type="number"
//               name="trialPeriod"
//               value={trialsData.trialPeriod}
//               onChange={handleChange}
//               className="border px-4 py-1 rounded mb-2 w-full text-sm"
//             />
//           </div>

//           {/* Trial Member Type field with checkboxes */}
//           <div className="my-4">
//             <label className="mx-4 font-semibold text-cyan-800">Trial Member Type</label>
//             <select
//               name="trialMembersType"
//               value=""
//               onChange={handleMemberTypeChange}
//               className="border px-4 py-1 rounded mb-2 w-full text-sm"
//             >
//               <option value="">Select a member type</option>
//               <option value="R One Guest">R One Guest</option>
//               <option value="R One Basic">R One Basic</option>
//               <option value="R One Member">R One Member</option>
//               <option value="R One Trader Hub">R One Trader Hub</option>
//               <option value="R One Dealer">R One Dealer</option>
//               <option value="R One Elite Trader">R One Elite Trader</option>
//               <option value="R One Master Trader">R One Master Trader</option>
//               <option value="R One Certified Trader">R One Certified Trader</option>
//             </select>

//             {/* Display selected member types below */}
//             <div className="mt-2 border-2 border-dashed text-sm p-6 rounded">
//               {selectedMemberTypes.length > 0 ? (
//                 selectedMemberTypes.map(type => (
//                   <div key={type} className="flex items-center space-x-2">
//                     <span>◾️{type}</span>
//                     <button 
//                       onClick={() => setSelectedMemberTypes(prev => prev.filter(t => t !== type))} 
//                       className="text-red-500 text-xs"
//                     >
//                       Remove
//                     </button>
//                   </div>
//                 ))
//               ) : (
//                 <p className="text-gray-300 text-center">No member types selected</p>
//               )}
//             </div>
//           </div>

//           {/* Trials Custom Group Name */}
//           <div className="my-4">
//             <label className="mx-4 font-semibold text-cyan-800">Trial Custom Group</label>
//             <div
//               onClick={openGroupModal}
//               className="border-2 border-dashed px-4 py-2 rounded text-sm mt-2 cursor-pointer"
//             >
//               {customGroup ? (
//                 <div className="p-2 rounded text-sm mt-2">
//                   <div className="flex flex-col space-y-2">
//                     <span className="text-rose-400">Custom Group Name:</span> {customGroup.groupName}
//                     <span className="text-rose-400">Custom Email Lists</span>
//                   </div>
//                   <ul>
//                     {customGroup.groupMembers.map(email => (
//                       <li key={email}>{email}</li>
//                     ))}
//                   </ul>
//                 </div>
//               ) : (
//                 <p
//                   onClick={openGroupModal}
//                   className="text-gray-300 text-center cursor-pointer p-2 rounded"
//                 >
//                   No custom group added. Click to create.
//                 </p>
//               )}
//             </div>
//           </div>

//           {/* Trials Status field */}
//           <div className="my-4">
//             <label className="mx-4 font-semibold text-cyan-800">Trial Status</label>
//             <select
//               name="trialStatus"
//               value={trialsData.trialStatus}
//               onChange={handleChange}
//               className="border px-4 py-1 rounded w-full text-sm"
//             >
//               <option value="">Select a status</option>
//               <option value="Active">Active</option>
//               <option value="Stopped">Stopped</option>
//             </select>
//           </div>
//         </div>

//         <div className="flex justify-end space-x-2 mt-4">
//           <button onClick={onClose} className="bg-gray-500 text-white px-4 py-1 rounded">Cancel</button>
//           <button onClick={handleSave} className="bg-cyan-800 text-white px-6 py-1 rounded">
//             {editMode ? "Update" : "Add"}
//           </button>
//         </div>
//       </div>

//       <EmailList
//         isOpen={isGroupModalOpen}
//         onClose={closeGroupModal}
//         handleAddEmail={handleAddGroup}
//         initialEmails={customGroup?.groupMembers || []}
//         initialGroupName={customGroup?.groupName || ''}
//       />

//     </div>
//   );
// };

// export default SubTrialModal;
