// src/components/admin/admin09_LogViewer.tsx

import React from 'react';
import LogViewer from './FetchLogs/AlgoMatrixLogViewer';

const LogViewerAdmin: React.FC = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-2 text-cyan-800 text-center">
        Algo Matrix Log Viewer
      </h1>

      {/* Log Viewer interface */}
      <div className="flex justify-center w-auto max-w-screen">
        <LogViewer />
      </div>
    </div>
  );
};

export default LogViewerAdmin;
