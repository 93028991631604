// src/components/admin/SubProductSetting/subUserPending.tsx

import React, { useState, useMemo } from 'react';
import { RiRefreshFill } from "react-icons/ri";
import { FaRegEdit, FaTrashAlt, FaSave } from "react-icons/fa";
import { MdAddToPhotos } from "react-icons/md";
// import context
import { useEnv } from '../../../context/EnvContext'; 
import { useAuth } from '../../../context/AuthContext';   
// import hook
import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
import { useSubUserData, SubPending  } from '../../../hooks/AdminDashboard/useSubUserData';
import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting'; 
// import component
import AddSubUserModal from './subAddUserModal';  
import RejectModal from './subRejectModal';

const SubUserPending: React.FC = () => {
  const { apiUrl } = useEnv();
  const { user } = useAuth();
  const { permissions } = useRoleAccess(user?.email || '');    
  const { subPending, fetchSubPending, deleteSubPending, updateSubPending, addSubPending, approveSubPending, rejectSubPending } = useSubUserData();
  const { products } = useSubSetting();
  // expanded row for more user info
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  // receipt view and modal open
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [receiptUrl, setReceiptUrl] = useState<string | null>(null);
  // search bar
  const [searchQuery, setSearchQuery] = useState("");
  // edit / update data
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState<Partial<SubPending>>({});
  // add data
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newData, setNewData] = useState<Partial<SubPending>>({});
  // show stats
  const [selectedProductId, setSelectedProductId] = useState<string | "All">("All");
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<SubPending | null>(null);

  // Function to handle refetching the data
  const handleRefetch = () => {
    fetchSubPending();
  };

  const handleRowClick = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleViewReceipt = (url: string) => {
    setReceiptUrl(url);
    setIsReceiptModalOpen(true);
  };

  const handleDelete = async (orderId: string) => {
    try {
      await deleteSubPending(orderId);
      fetchSubPending();
    } catch (error) {
      console.error(`Failed to delete row with orderId: ${orderId}`, error);
    }
  };

  const handleEditClick = (user: SubPending) => {
    setIsEditing(true);
    setEditedData(user);
  };
  
  
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };  
  
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };  

  const handleSaveClick = async () => {
    if (!editedData.subOrderId) return;
  
    try {
      await updateSubPending(editedData.subOrderId, editedData as SubPending);
      setIsEditing(false);
      fetchSubPending(); 
    } catch (error) {
      console.error("Failed to save changes:", error);
    }
  };

  const handleAddNewClick = () => {
    setIsAddModalOpen(true);
    setNewData({});
  };

  const handleAddNewSave = async () => {
    try {
      await addSubPending(newData as SubPending);
      setIsAddModalOpen(false);
      fetchSubPending();
    } catch (error) {
      console.error("Failed to add new subscription:", error);
    }
  };

  // Function to handle approval
  const handleApproveClick = (user: SubPending) => {
    approveSubPending(user);
  };

  const handleRejectClick = (user: SubPending) => {
    setSelectedUser(user);
    setRejectModalOpen(true);
  };

  const handleSendRejectReason = async (reason: string) => {
    if (!selectedUser) return;

    try {
      await rejectSubPending({ ...selectedUser, rejectReason: reason });
      fetchSubPending();
    } catch (error) {
      console.error('Error in rejecting the subscription:', error);
    } finally {
      setRejectModalOpen(false);
    }
  }; 

  const handleNewInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setNewData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };  

  // Get unique product IDs from subscribed users for the dropdown, showing only available subscribed products
  const subscribedProductIds = useMemo(() => {
    const uniqueProductMap = new Map();
    subPending.forEach(user => {
      if (!uniqueProductMap.has(user.subProductId)) {
        const productName = products.find(product => product.productId === user.subProductId)?.productName || 'Unknown Product';
        uniqueProductMap.set(user.subProductId, productName);
      }
    });
    return Array.from(uniqueProductMap.entries()).map(([id, name]) => ({ id, name }));
  }, [subPending, products]);

  // Filter subscriptions based on the selected product and search query
  const filteredSubPending = useMemo(() => {
    return subPending.filter(user => {
      const matchesSearchQuery = 
        (user.subOrderId?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
        (user.subPlanId?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
        (user.subUserName?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
        (user.subUserEmail?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
        (user.subPlanType?.toLowerCase() || "").includes(searchQuery.toLowerCase());

      const matchesProductId = selectedProductId === "All" || user.subProductId === selectedProductId;

      return matchesSearchQuery && matchesProductId;
    });
  }, [subPending, searchQuery, selectedProductId]);

  return (
    <div className="p-4 border rounded">
      <div className="flex justify-end">

        {/* table funtion section */}
        <div className="flex items-center py-2 space-x-4">

          {/* Dropdown to filter by product */}
          <select
            value={selectedProductId}
            onChange={(e) => setSelectedProductId(e.target.value)}
            className="items-end border border-gray-300 rounded px-2 py-1 text-sm"
          >
            <option value="All">All Products</option>
            {subscribedProductIds.map(({ id, name }) => (
              <option key={id} value={id}>
                {name} ({id})
              </option>
            ))}
          </select>

          {/* Reset whole table data Button */}
          <button 
            className="text-cyan-800 text-lg"
            onClick={handleRefetch}
          >
            <RiRefreshFill />
          </button>
  
          {/* Add data Button */}
          <button 
            className={`text-lg 
              ${permissions?.productSubscription?.addSub === 'enable'
                ? 'text-cyan-800' 
                : 'text-gray-400 cursor-not-allowed'
            }`}
            onClick={permissions?.productSubscription?.addSub === 'enable' ? handleAddNewClick : undefined}
            disabled={permissions?.productSubscription?.addSub === 'enable'}
          >
            <MdAddToPhotos />
          </button>

          {/* Search Bar */}
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded px-2 py-1 text-sm w-[250px]"
          />
          
        </div>
      </div>

      {/* Product Table */}
      <div className="overflow-x-auto max-h-[500px] overflow-y-auto">
        <table className="min-w-full bg-white border text-sm">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              <th className="py-2 px-4 border-b text-nowrap">Order Id</th>
              <th className="py-2 px-4 border-b text-nowrap">User Name</th>
              <th className="py-2 px-4 border-b text-nowrap">User Email</th>
              <th className="py-2 px-4 border-b text-nowrap">Account Type</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Type</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Amount</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Period</th>
              <th className="py-2 px-4 border-b text-nowrap">Status</th>
              <th className="py-2 px-4 border-b text-nowrap">Submit Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredSubPending.length > 0 ? (
              filteredSubPending.map((user, index) => (
                <React.Fragment key={index}>
                  <tr 
                    onClick={() => handleRowClick(index)} 
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subOrderId}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subUserName}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subUserEmail}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subAccountType}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanType}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanAmount}</td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanPeriod}</td>
                    {/* Conditionally styled Status cell */}
                    <td 
                      className={`border-b text-center font-bold text-sm ${
                        user.subStatus === 'Pending' ? 'text-yellow-500' :
                        user.subStatus === 'Active' ? 'text-cyan-800' :
                        user.subStatus === 'Expired' ? 'text-red-500' : ''
                      }`}
                    >
                      {user.subStatus}
                    </td>
                    <td className="py-2 px-4 border-b text-center text-nowrap">
                      {new Date(user.subDateSubmit).toLocaleDateString()}
                    </td>
                  </tr>

                  {/* Expanded Row */}
                  {expandedRowIndex === index && (
                    <tr className="bg-gray-50 border border-gray-300">
                      <td colSpan={10} className="px-8 py-4">
                        <div className="flex flex-col space-y-2">
                          <div className="flex justify-center items-center bg-gray-200 py-2 rounded-lg space-x-4">
                            <div className="flex justify-normal items-center">
                              <span className="font-semibold mr-1">Status:</span>
                              <span className={`rounded-full text-white text-xs px-4 py-1 ${
                                user.subStatus === 'Pending' ? 'bg-yellow-500' :
                                user.subStatus === 'Active' ? 'bg-cyan-800' :
                                user.subStatus === 'Expired' ? 'bg-red-500' : ''
                              }`}>
                                {user.subStatus}
                              </span>
                            </div>
                            <div className="flex justify-between items-center ">
                              <span className="font-semibold mr-1 text-nowrap">Expired Date:</span>
                              {isEditing ? (
                                <input
                                  type="date"
                                  name="subExpiredDate"
                                  value={
                                    editedData.subExpiredDate
                                      ? new Date(editedData.subExpiredDate).toISOString().split('T')[0]
                                      : ''
                                  }
                                  onChange={handleInputChange}
                                  className="border rounded px-2 py-1 w-full mx-2"
                                />
                              ) : (
                                <span className="mr-2">{new Date(user.subExpiredDate).toLocaleDateString()}</span>
                              )}
                              <span className="font-semibold text-nowrap">({user.subExpiredDays} Days)</span> 
                            </div>
                            {/* Edit and Save Icons */}
                            {isEditing ? (
                              <FaSave 
                                onClick={handleSaveClick} 
                                className="cursor-pointer text-gray-600" 
                              />
                            ) : (
                              <FaRegEdit 
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  if (permissions?.productSubscription?.editSub === 'enable') {
                                    handleEditClick(user)
                                  }
                                }}
                                className={`${
                                  permissions?.productSubscription?.editSub === 'enable' 
                                    ? 'text-cyan-800' 
                                    : 'text-gray-400 cursor-not-allowed'
                                }`} 
                              />
                            )}
                              <FaTrashAlt 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (permissions?.productSubscription?.deleteSub === 'enable') {
                                    handleDelete(user.subOrderId)
                                  }
                                }} 
                                className={`${
                                  permissions?.productSubscription?.editSub === 'enable' 
                                    ? 'text-cyan-800' 
                                    : 'text-gray-400 cursor-not-allowed'
                                }`} 
                              />
                          </div>
                          
                          {/* User info Section */}
                          <div className="flex flex-row justify-between px-4 text-xs">
                            <div className="flex flex-row justify-between">
                              <div className="flex flex-col mt-4">
                                <label className="font-bold underline mb-2">User Info</label>
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left"><strong>Order Id:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        readOnly
                                        disabled
                                        name="subOrderId"
                                        value={editedData.subOrderId}
                                        className="border rounded px-2 py-1 w-full bg-slate-100"
                                      />
                                    ) : (
                                      user.subOrderId
                                    )}
                                  </div>
                                </div>
                                
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left"><strong>Plan Id:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        readOnly
                                        disabled
                                        name="subPlanId"
                                        value={editedData.subPlanId}
                                        className="border rounded px-2 py-1 w-full bg-slate-100"
                                      />
                                    ) : (
                                      user.subPlanId
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left"><strong>Request By:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        readOnly
                                        disabled
                                        name="subUserName"
                                        value={editedData.subUserName}
                                        className="border rounded px-2 py-1 w-full bg-slate-100"
                                      />
                                    ) : (
                                      user.subUserName
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left"><strong>Email:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        readOnly
                                        disabled
                                        name="subUserEmail"
                                        value={editedData.subUserEmail}
                                        className="border rounded px-2 py-1 w-full bg-slate-100"
                                      />
                                    ) : (
                                      user.subUserEmail
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Plan Section */}
                            <div className="flex flex-row justify-between">
                              <div className="flex flex-col mt-4">
                                <label className="font-bold underline mb-2">Plan Info</label>
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Type:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <select
                                        name="subPlanType"
                                        value={editedData.subPlanType || ''}
                                        onChange={handleSelectChange}
                                        className="border rounded px-2 py-1 w-full"
                                      >
                                        <option value="Trial">Trial</option>
                                        <option value="Subscribe">Subscribe</option>
                                        <option value="Renew">Renew</option>
                                      </select>
                                    ) : (
                                      user.subPlanType
                                    )}
                                  </div>
                                </div>
                                
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Amount:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="number"
                                        name="subPlanAmount"
                                        value={editedData.subPlanAmount || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subPlanAmount
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Period:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="number"
                                        name="subPlanPeriod"
                                        value={editedData.subPlanPeriod || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subPlanPeriod
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Apply Date:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="date"
                                        name="subDateSubmit"
                                        value={
                                          editedData.subDateSubmit
                                            ? new Date(editedData.subDateSubmit).toISOString().split('T')[0]
                                            : ''
                                        }
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      new Date(user.subDateSubmit).toLocaleDateString()
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Account Section */}
                            <div className="flex flex-row justify-between">
                              <div className="flex flex-col mt-4">
                                <label className="font-bold underline mb-2">Account Info</label>
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Type:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <select
                                        name="subAccountType"
                                        value={editedData.subAccountType || ''}
                                        onChange={handleSelectChange}
                                        className="border rounded px-2 py-1 w-full"
                                      >
                                        <option value="DEMO">Demo</option>
                                        <option value="LIVE">Live</option>
                                      </select>
                                    ) : (
                                      user.subAccountType
                                    )}
                                  </div>
                                </div>
                                
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Number ID:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="number"
                                        name="subAccountNumber"
                                        value={editedData.subAccountNumber || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subAccountNumber
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Password:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="text"
                                        name="subAccountPassword"
                                        value={editedData.subAccountPassword || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subAccountPassword
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Server:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <select
                                        name="subAccountServer"
                                        value={editedData.subAccountServer || ''}
                                        onChange={handleSelectChange}
                                        className="border rounded px-2 py-1 w-full"
                                      >
                                        <option value="LirunexLimited-Live">Lirunex Live</option>
                                        <option value="LirunexLimited-Demo">Lirunex Demo</option>
                                        <option value="FortunePrimeGlobal-Live">FPG Live</option>
                                        <option value="FortunePrimeGlobal-Demo">FPG Demo</option>
                                      </select>
                                    ) : (
                                      user.subAccountServer
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Account Section */}
                            <div className="flex flex-row justify-between">
                              <div className="flex flex-col mt-4">
                                <label className="font-bold underline mb-2">Payment Info</label>
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Method:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <select
                                        name="subPaymentMethod"
                                        value={editedData.subPaymentMethod || ''}
                                        onChange={handleSelectChange}
                                        className="border rounded px-2 py-1 w-full"
                                      >
                                        <option value="Bank Transfer">Bank Transfer</option>
                                        <option value="USDT">USDT</option>
                                      </select>
                                    ) : (
                                      user.subPaymentMethod
                                    )}
                                  </div>
                                </div>
                                
                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Receipts:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <span
                                        className="text-blue-600 underline cursor-pointer"
                                        onClick={() => handleViewReceipt(user.subPaymentReceipts)}
                                      >
                                        View Receipt
                                      </span>
                                    ) : (
                                      <span
                                        className="text-blue-600 underline cursor-pointer"
                                        onClick={() => handleViewReceipt(user.subPaymentReceipts)}
                                      >
                                        View Receipt
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Trial:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="number"
                                        name="subTrialCheck"
                                        value={editedData.subTrialCheck || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subTrialCheck
                                    )}
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  <div className="w-[80px] text-left pr-2"><strong>Voucher:</strong></div>
                                  <div className="flex-grow">
                                    {isEditing ? (
                                      <input
                                        type="number"
                                        name="subVoucherCheck"
                                        value={editedData.subVoucherCheck || ''}
                                        onChange={handleInputChange}
                                        className="border rounded px-2 py-1 w-full"
                                      />
                                    ) : (
                                      user.subVoucherCheck
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* notes section */}
                          <div className="w-full items-center px-2">
                            <div className="flex flex-col justify-normal border-gray-200">
                              <div className="flex flex-col">
                                <div className="font-bold mx-2">Notes:</div>
                                <div className="w-full">
                                  {isEditing ? (
                                    <textarea
                                      name="subNotes"
                                      value={editedData.subNotes || ''}
                                      onChange={handleTextAreaChange}
                                      className="border rounded px-2 py-1 w-full"
                                    />
                                  ) : (
                                    <div className="px-2 py-1">{user.subNotes}</div>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="font-bold mx-2">Remark:</div>
                                <div className="w-full">
                                  {isEditing ? (
                                    <textarea
                                      name="subRemark"
                                      value={editedData.subRemark || ''}
                                      onChange={handleTextAreaChange}
                                      className="border rounded px-2 py-1 w-full"
                                    />
                                  ) : (
                                    <div className="px-2 py-1">{user.subRemark}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Approve/Reject section */}
                        <div className="flex justify-center space-x-2 mt-2 mb-4">
                          <button 
                            className={`px-6 py-1 rounded 
                              ${permissions?.productSubscription.rejectSub !== 'enable'
                                ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
                                : 'bg-gray-200 text-gray-600'
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (permissions?.productSubscription?.rejectSub === 'enable') { 
                                handleRejectClick(user)
                              }
                            }}
                            disabled={permissions?.productSubscription.rejectSub !== 'enable'}
                          >
                            Reject
                          </button>

                          <button 
                            className={`px-4 py-1 rounded
                              ${permissions?.productSubscription.approveSub !== 'enable'
                                ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
                                : 'bg-cyan-800 text-white'
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (permissions?.productSubscription?.approveSub === 'enable') {
                                handleApproveClick(user)
                              }
                            }}
                            disabled={permissions?.productSubscription.approveSub !== 'enable'}
                          >
                            Approve
                          </button>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={10} className="text-center py-8">No user subscribe available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Receipt Modal */}
      {isReceiptModalOpen && receiptUrl && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Receipt</h2>
              <button
                onClick={() => setIsReceiptModalOpen(false)}
                className="text-red-500 font-bold text-lg"
              >
                &times;
              </button>
            </div>
            <img 
              src={`${apiUrl}${receiptUrl}`} 
              alt="Payment Receipt"
              className="w-full h-auto rounded" 
            />
          </div>
        </div>
      )}

      {/* add data Modal */}
      <AddSubUserModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSave={handleAddNewSave}
        newData={newData}
        handleNewInputChange={handleNewInputChange}
        isPendingTable={true}
      />

      {/* Reject Modal */}
      <RejectModal
        isOpen={rejectModalOpen}
        onClose={() => setRejectModalOpen(false)}
        onSend={handleSendRejectReason}
      />
    </div>
  );
};

export default SubUserPending;

// // udpaet code --> 22 nov 2024
// // src/components/admin/SubProductSetting/subUserPending.tsx

// import React, { useState, useMemo } from 'react';
// import { RiRefreshFill } from "react-icons/ri";
// import { FaRegEdit, FaTrashAlt, FaSave } from "react-icons/fa";
// import { MdAddToPhotos } from "react-icons/md";
// // import context
// import { useEnv } from '../../../context/EnvContext'; 
// // import hook
// import { useSubUserData, SubPending  } from '../../../hooks/AdminDashboard/useSubUserData';
// import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting'; 
// // import component
// import AddSubUserModal from './subAddUserModal';  

// const SubUserPending: React.FC = () => {
//   const { apiUrl } = useEnv();
//   const { subPending, fetchSubPending, deleteSubPending, updateSubPending, addSubPending, approveSubPending, rejectSubPending } = useSubUserData();
//   const { products } = useSubSetting(); 
//   // expanded row for more user info
//   const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
//   // receipt view and modal open
//   const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
//   const [receiptUrl, setReceiptUrl] = useState<string | null>(null);
//   // search bar
//   const [searchQuery, setSearchQuery] = useState("");
//   // edit / update data
//   const [isEditing, setIsEditing] = useState(false);
//   const [editedData, setEditedData] = useState<Partial<SubPending>>({});
//   // add data
//   const [isAddModalOpen, setIsAddModalOpen] = useState(false);
//   const [newData, setNewData] = useState<Partial<SubPending>>({});
//   // show stats
//   const [selectedProductId, setSelectedProductId] = useState<string | "All">("All");

//   // Function to handle refetching the data
//   const handleRefetch = () => {
//     fetchSubPending();
//   };

//   const handleRowClick = (index: number) => {
//     setExpandedRowIndex(expandedRowIndex === index ? null : index);
//   };

//   const handleViewReceipt = (url: string) => {
//     setReceiptUrl(url);
//     setIsReceiptModalOpen(true);
//   };

//   const handleDelete = async (orderId: string) => {
//     try {
//       await deleteSubPending(orderId);
//       fetchSubPending();
//     } catch (error) {
//       console.error(`Failed to delete row with orderId: ${orderId}`, error);
//     }
//   };

//   const handleEditClick = (user: SubPending) => {
//     setIsEditing(true);
//     setEditedData(user);
//   };
  
  
//   const handleInputChange = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
//   ) => {
//     const { name, value } = e.target;
//     setEditedData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };  
  
//   const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const { name, value } = e.target;
//     setEditedData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };
  
//   const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
//     const { name, value } = e.target;
//     setEditedData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };  

//   const handleSaveClick = async () => {
//     if (!editedData.subOrderId) return;
  
//     try {
//       await updateSubPending(editedData.subOrderId, editedData as SubPending);
//       setIsEditing(false);
//       fetchSubPending(); 
//     } catch (error) {
//       console.error("Failed to save changes:", error);
//     }
//   };

//   const handleAddNewClick = () => {
//     setIsAddModalOpen(true);
//     setNewData({});
//   };

//   const handleAddNewSave = async () => {
//     try {
//       await addSubPending(newData as SubPending);
//       setIsAddModalOpen(false);
//       fetchSubPending();
//     } catch (error) {
//       console.error("Failed to add new subscription:", error);
//     }
//   };

//   // Function to handle approval
//   const handleApproveClick = (user: SubPending) => {
//     approveSubPending(user);
//   };

//   const handleRejectClick = async (user: SubPending) => {
//     try {
//       await rejectSubPending(user); 
//     } catch (error) {
//       console.error("Error in rejecting the subscription:", error);
//     }
//   };  

//   const handleNewInputChange = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
//   ) => {
//     const { name, value } = e.target;
//     setNewData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };  

//   // Get unique product IDs from subscribed users for the dropdown, showing only available subscribed products
//   const subscribedProductIds = useMemo(() => {
//     const uniqueProductMap = new Map();
//     subPending.forEach(user => {
//       if (!uniqueProductMap.has(user.subProductId)) {
//         const productName = products.find(product => product.productId === user.subProductId)?.productName || 'Unknown Product';
//         uniqueProductMap.set(user.subProductId, productName);
//       }
//     });
//     return Array.from(uniqueProductMap.entries()).map(([id, name]) => ({ id, name }));
//   }, [subPending, products]);

//   // Filter subscriptions based on the selected product and search query
//   const filteredSubPending = useMemo(() => {
//     return subPending.filter(user => {
//       const matchesSearchQuery = 
//         (user.subOrderId?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subPlanId?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subUserName?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subUserEmail?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
//         (user.subPlanType?.toLowerCase() || "").includes(searchQuery.toLowerCase());

//       const matchesProductId = selectedProductId === "All" || user.subProductId === selectedProductId;

//       return matchesSearchQuery && matchesProductId;
//     });
//   }, [subPending, searchQuery, selectedProductId]);

//   return (
//     <div className="p-4 border rounded">
//       <div className="flex justify-end">

//         {/* table funtion section */}
//         <div className="flex items-center py-2 space-x-4">

//           {/* Dropdown to filter by product */}
//           <select
//             value={selectedProductId}
//             onChange={(e) => setSelectedProductId(e.target.value)}
//             className="items-end border border-gray-300 rounded px-2 py-1 text-sm"
//           >
//             <option value="All">All Products</option>
//             {subscribedProductIds.map(({ id, name }) => (
//               <option key={id} value={id}>
//                 {name} ({id})
//               </option>
//             ))}
//           </select>

//           {/* Reset whole table data Button */}
//           <button 
//             className="text-cyan-800 text-lg"
//             onClick={handleRefetch}
//           >
//             <RiRefreshFill />
//           </button>
  
//           {/* Add data Button */}
//           <button 
//             className=" text-cyan-800 text-lg"
//             onClick={handleAddNewClick}
//           >
//             <MdAddToPhotos />
//           </button>

//           {/* Search Bar */}
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             className="border border-gray-300 rounded px-2 py-1 text-sm w-[250px]"
//           />
          
//         </div>
//       </div>

//       {/* Product Table */}
//       <div className="overflow-x-auto max-h-[500px] overflow-y-scroll">
//         <table className="min-w-full bg-white border text-sm">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold">
//               <th className="py-2 px-4 border-b text-nowrap">Order Id</th>
//               <th className="py-2 px-4 border-b text-nowrap">User Name</th>
//               <th className="py-2 px-4 border-b text-nowrap">User Email</th>
//               <th className="py-2 px-4 border-b text-nowrap">Account Type</th>
//               <th className="py-2 px-4 border-b text-nowrap">Plan Type</th>
//               <th className="py-2 px-4 border-b text-nowrap">Plan Amount</th>
//               <th className="py-2 px-4 border-b text-nowrap">Plan Period</th>
//               <th className="py-2 px-4 border-b text-nowrap">Status</th>
//               <th className="py-2 px-4 border-b text-nowrap">Submit Date</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredSubPending.length > 0 ? (
//               filteredSubPending.map((user, index) => (
//                 <React.Fragment key={index}>
//                   <tr 
//                     onClick={() => handleRowClick(index)} 
//                     className="cursor-pointer hover:bg-gray-100"
//                   >
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subOrderId}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subUserName}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subUserEmail}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subAccountType}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanType}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanAmount}</td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">{user.subPlanPeriod}</td>
//                     {/* Conditionally styled Status cell */}
//                     <td 
//                       className={`border-b text-center font-bold text-sm ${
//                         user.subStatus === 'Pending' ? 'text-yellow-500' :
//                         user.subStatus === 'Active' ? 'text-cyan-800' :
//                         user.subStatus === 'Expired' ? 'text-red-500' : ''
//                       }`}
//                     >
//                       {user.subStatus}
//                     </td>
//                     <td className="py-2 px-4 border-b text-center text-nowrap">
//                       {new Date(user.subDateSubmit).toLocaleDateString()}
//                     </td>
//                   </tr>

//                   {/* Expanded Row */}
//                   {expandedRowIndex === index && (
//                     <tr className="bg-gray-50 border border-gray-300">
//                       <td colSpan={10} className="px-8 py-4">
//                         <div className="flex flex-col space-y-2">
//                           <div className="flex justify-center items-center bg-gray-200 py-2 rounded-lg space-x-4">
//                             <div className="flex justify-normal items-center">
//                               <span className="font-semibold mr-1">Status:</span>
//                               <span className={`rounded-full text-white text-xs px-4 py-1 ${
//                                 user.subStatus === 'Pending' ? 'bg-yellow-500' :
//                                 user.subStatus === 'Active' ? 'bg-cyan-800' :
//                                 user.subStatus === 'Expired' ? 'bg-red-500' : ''
//                               }`}>
//                                 {user.subStatus}
//                               </span>
//                             </div>
//                             <div className="flex justify-normal items-center ">
//                               <span className="font-semibold mr-1">Expired Date:</span>
//                               <span className="mr-1">{new Date(user.subExpiredDate).toLocaleDateString()}</span> 
//                               <span className="font-semibold">({user.subExpiredDays} Days)</span> 
//                             </div>
//                             {/* Edit and Save Icons */}
//                             {isEditing ? (
//                               <FaSave onClick={handleSaveClick} className="cursor-pointer text-gray-600" />
//                             ) : (
//                               <FaRegEdit onClick={() => handleEditClick(user)} className="cursor-pointer text-gray-600" />
//                             )}
//                               <FaTrashAlt 
//                                 onClick={() => handleDelete(user.subOrderId)} 
//                                 className="cursor-pointer text-gray-600" 
//                               />
//                           </div>
                          
//                           {/* User info Section */}
//                           <div className="flex flex-row justify-between px-4 text-xs">
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">User Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Order Id:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subOrderId"
//                                         value={editedData.subOrderId}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subOrderId
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Plan Id:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subPlanId"
//                                         value={editedData.subPlanId}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subPlanId
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Request By:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subUserName"
//                                         value={editedData.subUserName}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subUserName
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left"><strong>Email:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         readOnly
//                                         disabled
//                                         name="subUserEmail"
//                                         value={editedData.subUserEmail}
//                                         className="border rounded px-2 py-1 w-full bg-slate-100"
//                                       />
//                                     ) : (
//                                       user.subUserEmail
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Plan Section */}
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">Plan Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Type:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subPlanType"
//                                         value={editedData.subPlanType || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="Trial">Trial</option>
//                                         <option value="Subscribe">Subscribe</option>
//                                         <option value="Renew">Renew</option>
//                                       </select>
//                                     ) : (
//                                       user.subPlanType
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Amount:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subPlanAmount"
//                                         value={editedData.subPlanAmount || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subPlanAmount
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Period:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subPlanPeriod"
//                                         value={editedData.subPlanPeriod || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subPlanPeriod
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Apply Date:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="date"
//                                         name="subDateSubmit"
//                                         value={
//                                           editedData.subDateSubmit
//                                             ? new Date(editedData.subDateSubmit).toISOString().split('T')[0]
//                                             : ''
//                                         }
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       new Date(user.subDateSubmit).toLocaleDateString()
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Account Section */}
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">Account Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Type:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subAccountType"
//                                         value={editedData.subAccountType || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="Demo">Demo</option>
//                                         <option value="Live">Live</option>
//                                       </select>
//                                     ) : (
//                                       user.subAccountType
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Number ID:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subAccountNumber"
//                                         value={editedData.subAccountNumber || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subAccountNumber
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Password:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="text"
//                                         name="subAccountPassword"
//                                         value={editedData.subAccountPassword || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subAccountPassword
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Server:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subAccountServer"
//                                         value={editedData.subAccountServer || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="Lirunex">Lirunex</option>
//                                         <option value="ForturePrimalGlobal">ForturePrimalGlobal</option>
//                                       </select>
//                                     ) : (
//                                       user.subAccountServer
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>

//                             {/* Account Section */}
//                             <div className="flex flex-row justify-between">
//                               <div className="flex flex-col mt-4">
//                                 <label className="font-bold underline mb-2">Payment Info</label>
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Method:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <select
//                                         name="subPaymentMethod"
//                                         value={editedData.subPaymentMethod || ''}
//                                         onChange={handleSelectChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       >
//                                         <option value="Bank Transfer">Bank Transfer</option>
//                                         <option value="USDT">USDT</option>
//                                       </select>
//                                     ) : (
//                                       user.subPaymentMethod
//                                     )}
//                                   </div>
//                                 </div>
                                
//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Receipts:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <span
//                                         className="text-blue-600 underline cursor-pointer"
//                                         onClick={() => handleViewReceipt(user.subPaymentReceipts)}
//                                       >
//                                         View Receipt
//                                       </span>
//                                     ) : (
//                                       <span
//                                         className="text-blue-600 underline cursor-pointer"
//                                         onClick={() => handleViewReceipt(user.subPaymentReceipts)}
//                                       >
//                                         View Receipt
//                                       </span>
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Trial:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subTrialCheck"
//                                         value={editedData.subTrialCheck || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subTrialCheck
//                                     )}
//                                   </div>
//                                 </div>

//                                 <div className="flex items-center">
//                                   <div className="w-[80px] text-left pr-2"><strong>Voucher:</strong></div>
//                                   <div className="flex-grow">
//                                     {isEditing ? (
//                                       <input
//                                         type="number"
//                                         name="subVoucherCheck"
//                                         value={editedData.subVoucherCheck || ''}
//                                         onChange={handleInputChange}
//                                         className="border rounded px-2 py-1 w-full"
//                                       />
//                                     ) : (
//                                       user.subVoucherCheck
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           {/* notes section */}
//                           <div className="w-full items-center px-2">
//                             <div className="flex flex-col justify-normal border-gray-200">
//                               <div className="flex flex-col">
//                                 <div className="font-bold mx-2">Notes:</div>
//                                 <div className="w-full">
//                                   {isEditing ? (
//                                     <textarea
//                                       name="subNotes"
//                                       value={editedData.subNotes || ''}
//                                       onChange={handleTextAreaChange}
//                                       className="border rounded px-2 py-1 w-full"
//                                     />
//                                   ) : (
//                                     <div className="px-2 py-1">{user.subNotes}</div>
//                                   )}
//                                 </div>
//                               </div>
//                               <div className="flex flex-col">
//                                 <div className="font-bold mx-2">Remark:</div>
//                                 <div className="w-full">
//                                   {isEditing ? (
//                                     <textarea
//                                       name="subRemark"
//                                       value={editedData.subRemark || ''}
//                                       onChange={handleTextAreaChange}
//                                       className="border rounded px-2 py-1 w-full"
//                                     />
//                                   ) : (
//                                     <div className="px-2 py-1">{user.subRemark}</div>
//                                   )}
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>

//                         {/* Approve/Reject section */}
//                         <div className="flex justify-center space-x-2 mt-2 mb-4">
//                           <button 
//                             className="bg-gray-200 text-gray-600 px-6 py-1 rounded"
//                             onClick={() => handleRejectClick(user)}
//                           >
//                             Reject
//                           </button>
//                           <button 
//                             className="bg-cyan-800 text-white px-4 py-1 rounded"
//                             onClick={() => handleApproveClick(user)}
//                           >
//                             Approve
//                           </button>
//                         </div>
//                       </td>
//                     </tr>
//                   )}
//                 </React.Fragment>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={10} className="text-center py-8">No user subscribe available</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       {/* Receipt Modal */}
//       {isReceiptModalOpen && receiptUrl && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-lg font-semibold">Receipt</h2>
//               <button
//                 onClick={() => setIsReceiptModalOpen(false)}
//                 className="text-red-500 font-bold text-lg"
//               >
//                 &times;
//               </button>
//             </div>
//             <img 
//               src={`${apiUrl}${receiptUrl}`} 
//               alt="Payment Receipt"
//               className="w-full h-auto rounded" 
//             />
//           </div>
//         </div>
//       )}

//       {/* add data Modal */}
//       <AddSubUserModal
//         isOpen={isAddModalOpen}
//         onClose={() => setIsAddModalOpen(false)}
//         onSave={handleAddNewSave}
//         newData={newData}
//         handleNewInputChange={handleNewInputChange}
//         isPendingTable={true}
//       />
//     </div>
//   );
// };

// export default SubUserPending;