// src/hooks/Dashboard/useUserDashboard.tsx

import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useEnv } from '../../context/EnvContext';
interface UserDashboard {
  username: string;
  email: string;
  icNo: string; 
  gender: string;
  profile_image: string;
  points: number;
  stages: string;
  badges: string;
  program_stats: string;
  phone: string;
  address: string;
  state: string;
  telegram_id: string;
  discord_id: string;
  password: string;
  // user table data
  dob: Date;
  country: string;
  termsAndConditions: string;
  loginCount: number;
}

const useUserDashboard = () => {
  const { isAuthenticated, triggerSessionExpired, logout } = useAuth();
  const { apiUrl } = useEnv();
  const [user, setUser] = useState<UserDashboard | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [showExpireAlert, setShowExpireAlert] = useState(false);
  const [showStateModal, setShowStateModal] = useState(false);
  const [needsPasswordReset, setNeedsPasswordReset] = useState(false);
  const [showUpdateUserData, setShowUpdateUserData] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem('token');
      if (!token) {
        setError(new Error('No token found'));
        return;
      }

      fetch(`${apiUrl}/api/user-dashboard`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          if (!response.ok) {
            if (response.status === 401) {
              setShowExpireAlert(true);
              triggerSessionExpired();
              throw new Error('Session expired');
            }
            const errorText = await response.text();
            throw new Error(`Error fetching data: ${response.status} ${response.statusText} - ${errorText}`);
          }
          return response.json();
        })

        .then((data: UserDashboard) => {
          setUser(data);
          // Show the update user data modal if loginCount is 0
          if (data.loginCount === 0) {
            setShowUpdateUserData(true);
          }
        })
        
        .catch((error: Error) => {
          setError(error);
          console.error('Error fetching user data:', error);
        });
      }
  }, [isAuthenticated, apiUrl, triggerSessionExpired]);

  // udpate users data
  const updateUser = async (formData: FormData) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError(new Error('No token found'));
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/user-dashboard`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error updating profile: ${response.status} ${response.statusText} - ${errorText}`);
      }

      const data: UserDashboard = await response.json();
      setUser(data);
    } catch (error: any) {
      setError(error);
      console.error('Error updating profile:', error);
    }
  };

  const resetPassword = async (newPassword: string) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError(new Error('No token found'));
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/user-dashboard/reset-password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ newPassword }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error updating password: ${response.status} ${response.statusText} - ${errorText}`);
      }

      // Reset the password reset requirement upon successful update
      setNeedsPasswordReset(false);
      alert("Password reset successful!");
    } catch (error: any) {
      setError(error);
      console.error('Error updating password:', error);
    }
  };

  const updateUserState = async (state: string, icNo: string) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError(new Error('No token found'));
      return;
    }
  
    try {
      const response = await fetch(`${apiUrl}/api/user-dashboard/update-state`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ state, icNo }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error updating state: ${response.status} ${response.statusText} - ${errorText}`);
      }
  
      // Fetch the updated data from the server to ensure consistency
      const updatedUser: UserDashboard = await response.json();
      setUser((prev) => (prev ? { ...prev, state: updatedUser.state, icNo: updatedUser.icNo } : null));
      setShowStateModal(false);
    } catch (error: any) {
      setError(error);
      console.error('Error updating state:', error);
    }
  };  

  const handleExpireAlertConfirm = () => {
    setShowExpireAlert(false);
    logout();
  };

  return { 
    user, error, 
    updateUser, showUpdateUserData, setShowUpdateUserData, 
    updateUserState, resetPassword,
    showStateModal, setShowStateModal, logout, 
    showExpireAlert, handleExpireAlertConfirm,
    needsPasswordReset, setNeedsPasswordReset,
  };
};

export default useUserDashboard;

// // udpate copde --> 09 dec 2024
// // src/hooks/Dashboard/useUserDashboard.tsx

// import { useState, useEffect } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import { useEnv } from '../../context/EnvContext';

// interface UserDashboard {
//   username: string;
//   email: string;
//   icNo: string; 
//   gender: string;
//   profile_image: string;
//   points: number;
//   stages: string;
//   badges: string;
//   program_stats: string;
//   phone: string;
//   address: string;
//   state: string;
//   telegram_id: string;
//   discord_id: string;
//   password: string;
// }

// const useUserDashboard = () => {
//   const { isAuthenticated, triggerSessionExpired, logout  } = useAuth();
//   const { apiUrl } = useEnv();
//   const [user, setUser] = useState<UserDashboard | null>(null);
//   const [error, setError] = useState<Error | null>(null);
//   const [showExpireAlert, setShowExpireAlert] = useState(false);
//   const [showStateModal, setShowStateModal] = useState(false);
//   const [needsPasswordReset, setNeedsPasswordReset] = useState(false);

//   useEffect(() => {
//     if (isAuthenticated) {
//       const token = localStorage.getItem('token');
//       if (!token) {
//         setError(new Error('No token found'));
//         return;
//       }

//       fetch(`${apiUrl}/api/user-dashboard`, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//       })
//         .then(async (response) => {
//           if (!response.ok) {
//             if (response.status === 401) {
//               setShowExpireAlert(true);
//               triggerSessionExpired();
//               throw new Error('Session expired');
//             }
//             const errorText = await response.text();
//             throw new Error(`Error fetching data: ${response.status} ${response.statusText} - ${errorText}`);
//           }
//           return response.json();
//         })
//         .then((data: UserDashboard) => {
//           setUser(data);

//           // Check if the state is empty and show the modal
//           if (!data.state || !data.icNo) {
//             setShowStateModal(true);  
//           }

//           // Check if user needs to reset password
//           if (data.password === '$2b$10$D9C6/qJ/6kK8XBk2t/FG/.hT43QJLfKRojQ9bMQyQVBigWfBGLwY2') { 
//             setNeedsPasswordReset(true);
//           }
//         })
        
//         .catch((error: Error) => {
//           setError(error);
//           console.error('Error fetching user data:', error);
//         });
//       }
//   }, [isAuthenticated, apiUrl, triggerSessionExpired]);


//   // udpate users data
//   const updateUser = async (formData: FormData) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       setError(new Error('No token found'));
//       return;
//     }

//     try {
//       const response = await fetch(`${apiUrl}/api/user-dashboard`, {
//         method: 'PUT',
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         body: formData,
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`Error updating profile: ${response.status} ${response.statusText} - ${errorText}`);
//       }

//       const data: UserDashboard = await response.json();
//       setUser(data);
//     } catch (error: any) {
//       setError(error);
//       console.error('Error updating profile:', error);
//     }
//   };

//   const resetPassword = async (newPassword: string) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       setError(new Error('No token found'));
//       return;
//     }

//     try {
//       const response = await fetch(`${apiUrl}/api/user-dashboard/reset-password`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({ newPassword }),
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`Error updating password: ${response.status} ${response.statusText} - ${errorText}`);
//       }

//       // Reset the password reset requirement upon successful update
//       setNeedsPasswordReset(false);
//       alert("Password reset successful!");
//     } catch (error: any) {
//       setError(error);
//       console.error('Error updating password:', error);
//     }
//   };

//   const updateUserState = async (state: string, icNo: string) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       setError(new Error('No token found'));
//       return;
//     }
  
//     try {
//       const response = await fetch(`${apiUrl}/api/user-dashboard/update-state`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({ state, icNo }),
//       });
  
//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`Error updating state: ${response.status} ${response.statusText} - ${errorText}`);
//       }
  
//       // Fetch the updated data from the server to ensure consistency
//       const updatedUser: UserDashboard = await response.json();
//       setUser((prev) => (prev ? { ...prev, state: updatedUser.state, icNo: updatedUser.icNo } : null));
//       setShowStateModal(false);
//     } catch (error: any) {
//       setError(error);
//       console.error('Error updating state:', error);
//     }
//   };  

//   const handleExpireAlertConfirm = () => {
//     setShowExpireAlert(false);
//     logout();
//   };

//   return { 
//     user, error, 
//     updateUser, updateUserState, resetPassword,
//     showStateModal, setShowStateModal, logout, 
//     showExpireAlert, handleExpireAlertConfirm,
//     needsPasswordReset, setNeedsPasswordReset,
//   };
// };

// export default useUserDashboard;

// // udpate code --> 18 oct 2024
// // src/hooks/Dashboard/useUserDashboard.tsx

// import { useState, useEffect } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import { useEnv } from '../../context/EnvContext';

// interface UserDashboard {
//   username: string;
//   email: string;
//   icNo: string; 
//   gender: string;
//   profile_image: string;
//   points: number;
//   stages: string;
//   badges: string;
//   program_stats: string;
//   phone: string;
//   address: string;
//   state: string;
//   telegram_id: string;
//   discord_id: string;
// }

// const useUserDashboard = () => {
//   const { isAuthenticated, triggerSessionExpired, logout  } = useAuth();
//   const { apiUrl } = useEnv();
//   const [user, setUser] = useState<UserDashboard | null>(null);
//   const [error, setError] = useState<Error | null>(null);
//   const [showExpireAlert, setShowExpireAlert] = useState(false);
//   const [showStateModal, setShowStateModal] = useState(false);

//   useEffect(() => {
//     if (isAuthenticated) {
//       const token = localStorage.getItem('token');
//       if (!token) {
//         setError(new Error('No token found'));
//         return;
//       }

//       fetch(`${apiUrl}/api/user-dashboard`, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//       })
//         .then(async (response) => {
//           if (!response.ok) {
//             if (response.status === 401) {
//               setShowExpireAlert(true);
//               triggerSessionExpired();
//               throw new Error('Session expired');
//             }
//             const errorText = await response.text();
//             throw new Error(`Error fetching data: ${response.status} ${response.statusText} - ${errorText}`);
//           }
//           return response.json();
//         })
//         .then((data: UserDashboard) => {
//           setUser(data);

//         // Check if the state is empty and show the modal
//         if (!data.state) {
//           setShowStateModal(true);
//           }
//         })
        
//         .catch((error: Error) => {
//           setError(error);
//           console.error('Error fetching user data:', error);
//         });
//     }
//   }, [isAuthenticated, apiUrl, triggerSessionExpired]);


//   // udpate users data
//   const updateUser = async (formData: FormData) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       setError(new Error('No token found'));
//       return;
//     }

//     try {
//       const response = await fetch(`${apiUrl}/api/user-dashboard`, {
//         method: 'PUT',
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         body: formData,
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`Error updating profile: ${response.status} ${response.statusText} - ${errorText}`);
//       }

//       const data: UserDashboard = await response.json();
//       setUser(data);
//     } catch (error: any) {
//       setError(error);
//       console.error('Error updating profile:', error);
//     }
//   };

//   const updateUserState = async (state: string) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       setError(new Error('No token found'));
//       return;
//     }
  
//     try {
//       const response = await fetch(`${apiUrl}/api/user-dashboard/update-state`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({ state }),
//       });
  
//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`Error updating state: ${response.status} ${response.statusText} - ${errorText}`);
//       }
  
//       // Fetch the updated data from the server to ensure consistency
//       const updatedUser: UserDashboard = await response.json();
//       setUser((prev) => (prev ? { ...prev, state: updatedUser.state } : null));
//       setShowStateModal(false);
//     } catch (error: any) {
//       setError(error);
//       console.error('Error updating state:', error);
//     }
//   };  

//   const handleExpireAlertConfirm = () => {
//     setShowExpireAlert(false);
//     logout();
//   };

//   return { user, error, updateUser, updateUserState, showStateModal, logout, showExpireAlert, handleExpireAlertConfirm };
// };

// export default useUserDashboard;