// src/hooks/MiniApp/useSubValidation.tsx

import { useEnv } from '../context/EnvContext'; 
import useUserDashboard from '../hooks/Dashboard/useUserDashboard';

const useSubValidation = () => {
  const { user } = useUserDashboard();
  const { apiUrl } = useEnv();

  // Function to validate voucher entitlement
  const validateVoucherEntitlement = async (voucherCode: string) => {
    if (!user?.email) {
      throw new Error('User email is required to validate voucher entitlement');
    }
  
    const endpoint = `${apiUrl}/validate/voucher-entitlement?email=${user.email}&voucherCode=${voucherCode}`;
  
    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        const errorData = await response.json();
  
        if (errorData.expired) {
          return { entitled: false };
        }
  
        if (errorData.redeemable === false) {
          return { entitled: false };
        }
  
        throw new Error('Failed to validate voucher entitlement');
      }
  
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error validating voucher entitlement:', error);
      throw error;
    }
  };
  
  // Function to validate trial entitlement
  const validateTrialEntitlement = async (planId: string) => {
    if (!user?.email) {
      throw new Error('User email is required to validate trial entitlement');
    }

    const endpoint = `${apiUrl}/validate/trial-entitlement?email=${user.email}&planId=${planId}`;

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to validate trial entitlement');
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error validating trial entitlement:', error);
      throw error;
    }
  };

  // Validation function for subscription form
  const validateSubscriptionForm = ({
    isRenew,
    planType,
    liveAccountNumber,
    liveAccountPassword,
    demoAccountNumber,
    demoAccountPassword,
    paymentReceipt,
    termsAccepted,
    isTradingSectionEnabled,
  }: {
    isRenew: boolean;
    planType: string;
    liveAccountNumber: string | null;
    liveAccountPassword: string | null;
    demoAccountNumber: string | null;
    demoAccountPassword: string | null;
    paymentReceipt: File | null;
    termsAccepted: boolean;
    isTradingSectionEnabled: boolean;
  }) => {
    const missingFields: string[] = [];

    if (!planType) missingFields.push("Plan Type");

    if (!isRenew) { // Skip these checks if it's a renewal
      if (isTradingSectionEnabled && planType !== "Demo" && !liveAccountNumber)
        missingFields.push("Live Account Number");
      if (isTradingSectionEnabled && planType !== "Demo" && !liveAccountPassword)
        missingFields.push("Live Account Password");
      if (isTradingSectionEnabled && planType !== "Live" && !demoAccountNumber)
        missingFields.push("Demo Account Number");
      if (isTradingSectionEnabled && planType !== "Live" && !demoAccountPassword)
        missingFields.push("Demo Account Password");
    }

    if (!paymentReceipt) missingFields.push("Payment Receipt");
    if (!termsAccepted) missingFields.push("Agreement to Terms and Conditions");

    return missingFields;
  };

  return { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm };
};

export default useSubValidation;

// // update code --> 19 nov 2024
// // src/hooks/MiniApp/useSubValidation.tsx

// import { useEnv } from '../context/EnvContext'; 
// import useUserDashboard from '../hooks/Dashboard/useUserDashboard';

// const useSubValidation = () => {
//   const { user } = useUserDashboard();
//   const { apiUrl } = useEnv();

//   // Function to validate voucher entitlement
//   const validateVoucherEntitlement = async (voucherCode: string) => {
//     if (!user?.email) {
//       throw new Error('User email is required to validate voucher entitlement');
//     }
  
//     const endpoint = `${apiUrl}/validate/voucher-entitlement?email=${user.email}&voucherCode=${voucherCode}`;
  
//     try {
//       const response = await fetch(endpoint);
//       if (!response.ok) {
//         const errorData = await response.json();
  
//         if (errorData.expired) {
//           return { entitled: false };
//         }
  
//         if (errorData.redeemable === false) {
//           return { entitled: false };
//         }
  
//         throw new Error('Failed to validate voucher entitlement');
//       }
  
//       const data = await response.json();

//       return data;
//     } catch (error) {
//       console.error('Error validating voucher entitlement:', error);
//       throw error;
//     }
//   };
  
//   // Function to validate trial entitlement
//   const validateTrialEntitlement = async (planId: string) => {
//     if (!user?.email) {
//       throw new Error('User email is required to validate trial entitlement');
//     }

//     const endpoint = `${apiUrl}/validate/trial-entitlement?email=${user.email}&planId=${planId}`;

//     try {
//       const response = await fetch(endpoint);
//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || 'Failed to validate trial entitlement');
//       }

//       const data = await response.json();

//       return data;
//     } catch (error) {
//       console.error('Error validating trial entitlement:', error);
//       throw error;
//     }
//   };

//   // Validation function for subscription form
//   const validateSubscriptionForm = ({
//     planType,
//     liveAccountNumber,
//     liveAccountPassword,
//     demoAccountNumber,
//     demoAccountPassword,
//     paymentReceipt,
//     termsAccepted,
//     isTradingSectionEnabled,
//   }: {
//     planType: string;
//     liveAccountNumber: string | null;
//     liveAccountPassword: string | null;
//     demoAccountNumber: string | null;
//     demoAccountPassword: string | null;
//     paymentReceipt: File | null;
//     termsAccepted: boolean;
//     isTradingSectionEnabled: boolean;
//   }) => {
//     const missingFields: string[] = [];

//     if (!planType) missingFields.push("Plan Type");
//     if (isTradingSectionEnabled && planType !== "Demo" && !liveAccountNumber)
//       missingFields.push("Live Account Number");
//     if (isTradingSectionEnabled && planType !== "Demo" && !liveAccountPassword)
//       missingFields.push("Live Account Password");
//     if (isTradingSectionEnabled && planType !== "Live" && !demoAccountNumber)
//       missingFields.push("Demo Account Number");
//     if (isTradingSectionEnabled && planType !== "Live" && !demoAccountPassword)
//       missingFields.push("Demo Account Password");
//     if (!paymentReceipt) missingFields.push("Payment Receipt");
//     if (!termsAccepted) missingFields.push("Agreement to Terms and Conditions");

//     return missingFields;
//   };

//   return { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm };
// };

// export default useSubValidation;


// // udpoate cdoe --> 18 nov 2024
// // src/hooks/MiniApp/useSubValidation.tsx

// import { useEnv } from '../context/EnvContext'; 
// import useUserDashboard from '../hooks/Dashboard/useUserDashboard';

// const useSubValidation = () => {
//   const { user } = useUserDashboard();
//   const { apiUrl } = useEnv();

//   // Function to validate voucher entitlement
//   const validateVoucherEntitlement = async (voucherCode: string) => {
//     if (!user?.email) {
//       throw new Error('User email is required to validate voucher entitlement');
//     }
  
//     const endpoint = `${apiUrl}/validate/voucher-entitlement?email=${user.email}&voucherCode=${voucherCode}`;
  
//     try {
//       const response = await fetch(endpoint);
//       if (!response.ok) {
//         const errorData = await response.json();
  
//         if (errorData.expired) {
//           return { entitled: false };
//         }
  
//         if (errorData.redeemable === false) {
//           return { entitled: false };
//         }
  
//         throw new Error('Failed to validate voucher entitlement');
//       }
  
//       const data = await response.json();

//       return data;
//     } catch (error) {
//       console.error('Error validating voucher entitlement:', error);
//       throw error;
//     }
//   };
  
//   // Function to validate trial entitlement
//   const validateTrialEntitlement = async (planId: string) => {
//     if (!user?.email) {
//       throw new Error('User email is required to validate trial entitlement');
//     }

//     const endpoint = `${apiUrl}/validate/trial-entitlement?email=${user.email}&planId=${planId}`;

//     try {
//       const response = await fetch(endpoint);
//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || 'Failed to validate trial entitlement');
//       }

//       const data = await response.json();

//       return data;
//     } catch (error) {
//       console.error('Error validating trial entitlement:', error);
//       throw error;
//     }
//   };

//   // Validation function for subscription form
//   const validateSubscriptionForm = ({
//     planType,
//     liveAccountNumber,
//     liveAccountPassword,
//     demoAccountNumber,
//     demoAccountPassword,
//     paymentReceipt,
//     termsAccepted,
//     isTradingSectionEnabled,
//   }: {
//     planType: string;
//     liveAccountNumber: string;
//     liveAccountPassword: string;
//     demoAccountNumber: string;
//     demoAccountPassword: string;
//     paymentReceipt: File | null;
//     termsAccepted: boolean;
//     isTradingSectionEnabled: boolean;
//   }) => {
//     const missingFields: string[] = [];

//     if (!planType) missingFields.push("Plan Type");
//     if (isTradingSectionEnabled && !liveAccountNumber) missingFields.push("Live Account Number");
//     if (isTradingSectionEnabled && !liveAccountPassword) missingFields.push("Live Account Password");
//     if (isTradingSectionEnabled && !demoAccountNumber) missingFields.push("Demo Account Number");
//     if (isTradingSectionEnabled && !demoAccountPassword) missingFields.push("Demo Account Password");
//     if (!paymentReceipt) missingFields.push("Payment Receipt");
//     if (!termsAccepted) missingFields.push("Agreement to Terms and Conditions");

//     return missingFields;
//   };

//   return { validateVoucherEntitlement, validateTrialEntitlement, validateSubscriptionForm };
// };

// export default useSubValidation;