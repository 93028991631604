// src/hooks/AdminDashboard/useFundProgramSetting.ts

import { useState } from 'react';
import { useEnv } from '../../context/EnvContext';

interface Program {
  fundSetId?: string;
  fundNameSet: string;
  fundStageSet: string;
  fundSizeSet: string;
  fundFeatureSet: string;
  fundStatusSet: string;
}

export const useFundProgramSetting = () => {
  const { apiUrl } = useEnv();
  const [programs, setPrograms] = useState<Program[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPrograms = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/fund/settings`);
      if (!response.ok) throw new Error('Failed to fetch programs');
      const data = await response.json();
      setPrograms(data);
    } catch (error) {
      console.error('Failed to fetch programs:', error);
    } finally {
      setLoading(false);
    }
  };

  const addProgram = async (program: Program) => {
    try {
      const response = await fetch(`${apiUrl}/api/fund/settings`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(program),
      });
      if (!response.ok) throw new Error('Failed to add program');
      await fetchPrograms(); 
    } catch (error) {
      console.error('Failed to add program:', error);
    }
  };

  return { programs, loading, fetchPrograms, addProgram };
};
