// src/components/admin/admin05_Notification.tsx

import React, { useState } from 'react';
import { FaSearch, FaSync, FaStar, FaTrashAlt, FaFilter, FaArchive } from 'react-icons/fa';
import { useEnv } from '../../context/EnvContext';
import { useAuth } from '../../context/AuthContext';   
import { useTheme } from '../../context/ThemeContext';
import { useAdminNotifications } from '../../hooks/AdminDashboard/useAdminNotification';
import { useRoleAccess } from '../../hooks/AdminDashboard/useRoleAccess';
import { useNotificationFilters } from './Notification/NotificationFilter';
import AdminNoticeAlertMessage from './Notification/NotificationAlertMessage';

interface Notification {
  ticketId: string;
  eventTitle: string;
  username: string;
  status: string;
  createdAt: string;
  isImportant: boolean;
  isRead: boolean;
  receiptPath?: string;
}

const Admin05_Notification: React.FC = () => {
  const { apiUrl } = useEnv();
  const { user } = useAuth();
  const { theme } = useTheme();
  const { permissions } = useRoleAccess(user?.email || '');   
  const { notifications, loading, error, fetchTicketDetails, updateTicketStatus, deleteNotification, archiveNotification, refetchNotifications } = useAdminNotifications();
  const { searchTerm, setSearchTerm, activeCategory, setActiveCategory, filteredNotifications, setFilteredNotifications, statusFilter, toggleStatusFilter } = useNotificationFilters(notifications);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  const [archiveSubcategories, setArchiveSubcategories] = useState<string[]>([]);

  // Define the type for the alert state
  type AlertState = {
    visible: boolean;
    type: 'success' | 'error' | 'confirm' | 'warning' | 'check';
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    onClose: () => void;
  };

  // Initialize the alert state
  const [alert, setAlert] = useState<AlertState>({
    visible: false,
    type: 'confirm',
    message: '',
    onConfirm: () => {},
    onCancel: () => {},
    onClose: () => {},
  });

  const sanitizeTableName = (name: string) => {
    return name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  };

  const handleTicketClick = async (ticketId: string) => {
    try {
      const details = await fetchTicketDetails(ticketId);
      setSelectedTicket(details);
      setFilteredNotifications(prev =>
        prev.map((n: Notification) => n.ticketId === ticketId ? { ...n, isRead: true } : n)
      );
    } catch (error) {
      console.error('Error fetching ticket details:', error);
    }
  };

  const handleStatusUpdate = async (status: 'approved' | 'rejected') => {
    if (selectedTicket) {
      await updateTicketStatus(selectedTicket.ticketId, status);
      setActiveCategory(status);
      setSelectedTicket({ ...selectedTicket, status });
      setAlert({
        visible: true,
        type: 'success',
        message: `Ticket has been ${status}.`,
        onConfirm: () => setAlert({ ...alert, visible: false }),
        onCancel: () => setAlert({ ...alert, visible: false }),
        onClose: () => setAlert({ ...alert, visible: false }),
      });
    }
  };

  const handleImageClick = (imagePath: string) => {
    setEnlargedImage(imagePath);
  };

  const handleStarClick = (notification: Notification) => {
    const updatedNotification = { ...notification, isImportant: !notification.isImportant };
    const updatedNotifications = notifications.map((n: Notification) =>
      n.ticketId === notification.ticketId ? updatedNotification : n
    );
    setFilteredNotifications(updatedNotifications);
  };

  const handleDeleteClick = (ticketId: string) => {
    setAlert({
      visible: true,
      type: 'confirm',
      message: 'Are you sure you want to delete this notification?',
      onConfirm: async () => {
        try {
          await deleteNotification(ticketId);
          setFilteredNotifications(filteredNotifications.filter(n => n.ticketId !== ticketId));
          setAlert({ ...alert, visible: false });
        } catch (error) {
          console.error('Error deleting notification:', error);
        }
      },
      onCancel: () => setAlert({ ...alert, visible: false }),
      onClose: () => setAlert({ ...alert, visible: false }),
    });
  };

  const handleArchiveClick = async (eventCategory: string) => {
    try {
      await archiveNotification(eventCategory);
      refetchNotifications(); 
    } catch (error) {
      console.error('Error archiving notifications:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="px-4 py-10 border-2 ">
      <h1 className="text-3xl font-semibold py-4 px-4 text-cyan-800">
          R One Admin Notification
      </h1>
      <div className="flex h-auto w-auto justify-center lg:flex-col md:flex-col md:w-[620px] sm:flex-col xs:flex-col">
        <div className="flex lg:flex-row">
          {/* Left Section */}
          <div className="w-auto py-8 px-4 border-2 lg:w-[300px] md:w-[220px] md:text-sm bg-gray-200 text-cyan-800">
            <h2 className="text-2xl font-semibold mb-4 md:text-lg text-cyan-800" >
              Categories
            </h2>
            <ul>
              {['inbox', 'approved', 'rejected', 'sent', 'trash', 'archive'].map((category) => (
                <li
                  key={category}
                  className={`cursor-pointer p-2 rounded-r-3xl  ${activeCategory === category ? 'bg-cyan-800 text-white' : ''}`}
                  onClick={() => {
                    setActiveCategory(category);
                    if (category === 'archive') {
                      setArchiveSubcategories(['event_1', 'event_2', 'event_3']); 
                    }
                  }}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                  {category === 'inbox' && (
                    <span className="ml-[185px] bg-red-500 text-white rounded-full px-2 py-1 text-xs md:ml-[100px]">
                      {notifications.length}
                    </span>
                  )}
                </li>
              ))}
            </ul>
            {activeCategory === 'archive' && (
              <ul className="pl-4">
                {archiveSubcategories.map((subcategory) => (
                  <li
                    key={subcategory}
                    className="cursor-pointer p-2 rounded-r-3xl flex justify-between item"
                    onClick={() => setActiveCategory(subcategory)}
                  >
                    {subcategory}
                    <FaArchive
                      className="ml-2 cursor-pointer text-gray-500"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleArchiveClick(subcategory);
                      }}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Middle Section */}
          <div className="w-auto h-auto max-h-[780px] border border-gray-300 p-4 lg:w-[580px]">
            <div className="p-4">
              <h2 className="text-2xl font-semibold mb-4 md:text-lg text-cyan-800">
                Notifications
              </h2>
              <div className="flex items-center mb-4 relative">
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search notifications..."
                  className="flex-grow p-2 pl-10 border rounded-lg md:text-sm"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button
                  className="ml-2 p-2 rounded md:text-sm"
                  style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}
                  onClick={() => refetchNotifications()}
                >
                  <FaSync />
                </button>
                <button
                  className="ml-2 p-2 rounded md:text-sm"
                  style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}
                  onClick={toggleStatusFilter}
                >
                  <FaFilter />
                </button>
                <span className="ml-2 p-2 rounded bg-gray-200 md:text-sm">{statusFilter}</span>
              </div>
            </div>
            <div className="overflow-y-auto h-[470px]">
              {filteredNotifications.map((notification: Notification) => (
                <div
                  key={notification.ticketId}
                  className={`shadow-md rounded-lg border p-4 cursor-pointer relative md:text-sm
                    ${selectedTicket?.ticketId === notification.ticketId ? 'border-2 border-cyan-700' : ''}`}
                  onClick={() => handleTicketClick(notification.ticketId)}
                >
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-bold">Ticket #{notification.ticketId}</span>
                    <span className="px-4 py-2 rounded-full" 
                      style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}>{notification.username}</span>
                  </div>
                  <p>{notification.eventTitle}</p>
                  <p>
                    Status:
                    <span className={`ml-2 px-2 py-1 rounded text-white ${
                      notification.status === 'approved' ? 'bg-green-500' :
                        notification.status === 'rejected' ? 'bg-red-500' :
                          'bg-yellow-500'
                    }`}>
                      {notification.status}
                    </span>
                  </p>
                  <p>Send on: {new Date(notification.createdAt).toLocaleString()}</p>
                  <div className="absolute bottom-2 right-2 flex items-center">
                    <FaStar
                      className={`mr-2 cursor-pointer ${notification.isImportant ? 'text-yellow-500' : 'text-gray-500'}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleStarClick(notification);
                      }}
                    />
                    <FaTrashAlt
                      className={`cursor-pointer
                        ${
                          permissions?.eventNotification?.deleteEventTicket !== 'enable'
                            ? 'text-gray-400 cursor-not-allowed pointer-events-none'
                            : 'text-rose-600'
                        }`}
                      onClick={(e) => {
                        if (permissions?.eventNotification?.deleteEventTicket === 'enable') {
                          e.stopPropagation();
                          handleDeleteClick(notification.ticketId);
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-auto h-auto p-4 mt-2 border rounded-lg overflow-y-auto md:h-[500px]">
          <h2 className="text-2xl font-bold mb-4 text-cyan-800">
            Ticket Details
          </h2>
          {selectedTicket ? (
            <div className="flex">
              {/* Details Table */}
              <div className="w-2/3 pr-4">
                <table className="min-w-full bg-white md:text-sm">
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Ticket ID:</td>
                      <td className="border px-4 py-2">{selectedTicket.ticketId}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Event:</td>
                      <td className="border px-4 py-2">{selectedTicket.eventTitle}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Username:</td>
                      <td className="border px-4 py-2">{selectedTicket.username}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Email:</td>
                      <td className="border px-4 py-2">{selectedTicket.email}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Appointment Date:</td>
                      <td className="border px-4 py-2">{new Date(selectedTicket.appointmentDate).toLocaleString()}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Payment Method:</td>
                      <td className="border px-4 py-2">{selectedTicket.paymentMethod}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Amount:</td>
                      <td className="border px-4 py-2">${selectedTicket.amount}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Lot Size:</td>
                      <td className="border px-4 py-2">{selectedTicket.lotSize}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Account ID:</td>
                      <td className="border px-4 py-2">{selectedTicket.accountId}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Account Password:</td>
                      <td className="border px-4 py-2">{selectedTicket.accountPassword}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Broker Server:</td>
                      <td className="border px-4 py-2">{selectedTicket.accountServer}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Points:</td>
                      <td className="border px-4 py-2">{selectedTicket.points}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Notes:</td>
                      <td className="border px-4 py-2">{selectedTicket.notes}</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 font-bold">Status:</td>
                      <td className="border px-4 py-2">{selectedTicket.status}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="mt-4 flex justify-between">

                  {/* Approve Button */}
                  <button
                    className={`px-4 py-2 rounded ${
                      selectedTicket.status === 'approved' || 
                      selectedTicket.status === 'rejected' ||
                      permissions?.eventNotification?.approveEventTicket !== 'enable'
                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                        : 'bg-green-500 text-white'
                    }`}
                    onClick={() => handleStatusUpdate('approved')}
                    disabled={
                      selectedTicket.status === 'approved' || 
                      selectedTicket.status === 'rejected' ||
                      permissions?.eventNotification?.approveEventTicket !== 'enable'
                    }
                  >
                    Approve
                  </button>

                  {/* Reject Button */}
                  <button
                    className={`px-4 py-2 rounded ${
                      selectedTicket.status === 'approved' || 
                      selectedTicket.status === 'rejected' ||
                      permissions?.eventNotification?.rejectEventTicket !== 'enable'
                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                        : 'bg-red-500 text-white'
                    }`}
                    onClick={() => handleStatusUpdate('rejected')}
                    disabled={
                      selectedTicket.status === 'approved' ||
                      selectedTicket.status === 'rejected' ||
                      permissions?.eventNotification?.rejectEventTicket !== 'enable'
                    }
                  >
                    Reject
                  </button>
                </div>
              </div>
              {/* Receipt Image */}
              <div className="w-1/3 pl-4">
                {selectedTicket.receiptPath && (
                  <div className="mt-4">
                    <img
                      src={`${apiUrl}/uploads/forEventReceipts/${sanitizeTableName(selectedTicket.eventTitle)}/${selectedTicket.receiptPath}`}
                      alt="Receipt"
                      className="w-full cursor-pointer"
                      onClick={() => handleImageClick(`${apiUrl}/uploads/forEventReceipts/${sanitizeTableName(selectedTicket.eventTitle)}/${selectedTicket.receiptPath}`)}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <p>Select a ticket to view details</p>
          )}
        </div>

        {/* Enlarged Image Modal */}
        {enlargedImage && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={() => setEnlargedImage(null)}>
            <img src={enlargedImage} alt="Enlarged Receipt" className="max-w-3xl max-h-3xl" />
          </div>
        )}
      </div>
      {/* Alert Modal */}
      <AdminNoticeAlertMessage
        type={alert.type}
        message={alert.message}
        visible={alert.visible}
        onClose={alert.onClose}
        onConfirm={alert.onConfirm}
        onCancel={alert.onCancel}
      />
    </div>
  );
};

export default Admin05_Notification;


// // udpate code --> 03 dec 2024
// // src/components/admin/admin05_Notification.tsx

// import React, { useState } from 'react';
// import { FaSearch, FaSync, FaStar, FaTrashAlt, FaFilter, FaArchive } from 'react-icons/fa';
// import { useEnv } from '../../context/EnvContext';
// import { useAuth } from '../../context/AuthContext';   
// import { useTheme } from '../../context/ThemeContext';
// import { useAdminNotifications } from '../../hooks/AdminDashboard/useAdminNotification';
// import { useRoleAccess } from '../../hooks/AdminDashboard/useRoleAccess';
// import { useNotificationFilters } from './Notification/NotificationFilter';
// import AdminNoticeAlertMessage from './Notification/NotificationAlertMessage';

// interface Notification {
//   ticketId: string;
//   eventTitle: string;
//   username: string;
//   status: string;
//   createdAt: string;
//   isImportant: boolean;
//   isRead: boolean;
//   receiptPath?: string;
// }

// const Admin05_Notification: React.FC = () => {
//   const { apiUrl } = useEnv();
//   const { user } = useAuth();
//   const { theme } = useTheme();
//   const { permissions } = useRoleAccess(user?.email || '');   
//   const { notifications, loading, error, fetchTicketDetails, updateTicketStatus, deleteNotification, archiveNotification, refetchNotifications } = useAdminNotifications();
//   const { searchTerm, setSearchTerm, activeCategory, setActiveCategory, filteredNotifications, setFilteredNotifications, statusFilter, toggleStatusFilter } = useNotificationFilters(notifications);
//   const [selectedTicket, setSelectedTicket] = useState<any>(null);
//   const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
//   const [archiveSubcategories, setArchiveSubcategories] = useState<string[]>([]);

//   // Define the type for the alert state
//   type AlertState = {
//     visible: boolean;
//     type: 'success' | 'error' | 'confirm' | 'warning' | 'check';
//     message: string;
//     onConfirm: () => void;
//     onCancel: () => void;
//     onClose: () => void;
//   };

//   // Initialize the alert state
//   const [alert, setAlert] = useState<AlertState>({
//     visible: false,
//     type: 'confirm',
//     message: '',
//     onConfirm: () => {},
//     onCancel: () => {},
//     onClose: () => {},
//   });

//   const sanitizeTableName = (name: string) => {
//     return name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
//   };

//   const handleTicketClick = async (ticketId: string) => {
//     try {
//       const details = await fetchTicketDetails(ticketId);
//       setSelectedTicket(details);
//       setFilteredNotifications(prev =>
//         prev.map((n: Notification) => n.ticketId === ticketId ? { ...n, isRead: true } : n)
//       );
//     } catch (error) {
//       console.error('Error fetching ticket details:', error);
//     }
//   };

//   const handleStatusUpdate = async (status: 'approved' | 'rejected') => {
//     if (selectedTicket) {
//       await updateTicketStatus(selectedTicket.ticketId, status);
//       setActiveCategory(status);
//       setSelectedTicket({ ...selectedTicket, status });
//       setAlert({
//         visible: true,
//         type: 'success',
//         message: `Ticket has been ${status}.`,
//         onConfirm: () => setAlert({ ...alert, visible: false }),
//         onCancel: () => setAlert({ ...alert, visible: false }),
//         onClose: () => setAlert({ ...alert, visible: false }),
//       });
//     }
//   };

//   const handleImageClick = (imagePath: string) => {
//     setEnlargedImage(imagePath);
//   };

//   const handleStarClick = (notification: Notification) => {
//     const updatedNotification = { ...notification, isImportant: !notification.isImportant };
//     const updatedNotifications = notifications.map((n: Notification) =>
//       n.ticketId === notification.ticketId ? updatedNotification : n
//     );
//     setFilteredNotifications(updatedNotifications);
//   };

//   const handleDeleteClick = (ticketId: string) => {
//     setAlert({
//       visible: true,
//       type: 'confirm',
//       message: 'Are you sure you want to delete this notification?',
//       onConfirm: async () => {
//         try {
//           await deleteNotification(ticketId);
//           setFilteredNotifications(filteredNotifications.filter(n => n.ticketId !== ticketId));
//           setAlert({ ...alert, visible: false });
//         } catch (error) {
//           console.error('Error deleting notification:', error);
//         }
//       },
//       onCancel: () => setAlert({ ...alert, visible: false }),
//       onClose: () => setAlert({ ...alert, visible: false }),
//     });
//   };

//   const handleArchiveClick = async (eventCategory: string) => {
//     try {
//       await archiveNotification(eventCategory);
//       refetchNotifications(); 
//     } catch (error) {
//       console.error('Error archiving notifications:', error);
//     }
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;

//   return (
//     <div className="px-4 py-10 border-2 ">
//       <h1 className="text-3xl font-semibold py-4 px-4 text-cyan-800">
//           R One Admin Notification
//       </h1>
//       <div className="flex h-auto w-auto justify-center lg:flex-col md:flex-col md:w-[620px] sm:flex-col xs:flex-col">
//         <div className="flex lg:flex-row">
//           {/* Left Section */}
//           <div className="w-auto py-8 px-4 border-2 lg:w-[300px] md:w-[220px] md:text-sm bg-gray-200 text-cyan-800">
//             <h2 className="text-2xl font-semibold mb-4 md:text-lg text-cyan-800" >
//               Categories
//             </h2>
//             <ul>
//               {['inbox', 'approved', 'rejected', 'sent', 'trash', 'archive'].map((category) => (
//                 <li
//                   key={category}
//                   className={`cursor-pointer p-2 rounded-r-3xl  ${activeCategory === category ? 'bg-cyan-800 text-white' : ''}`}
//                   onClick={() => {
//                     setActiveCategory(category);
//                     if (category === 'archive') {
//                       setArchiveSubcategories(['event_1', 'event_2', 'event_3']); 
//                     }
//                   }}
//                 >
//                   {category.charAt(0).toUpperCase() + category.slice(1)}
//                   {category === 'inbox' && (
//                     <span className="ml-[185px] bg-red-500 text-white rounded-full px-2 py-1 text-xs md:ml-[100px]">
//                       {notifications.length}
//                     </span>
//                   )}
//                 </li>
//               ))}
//             </ul>
//             {activeCategory === 'archive' && (
//               <ul className="pl-4">
//                 {archiveSubcategories.map((subcategory) => (
//                   <li
//                     key={subcategory}
//                     className="cursor-pointer p-2 rounded-r-3xl flex justify-between item"
//                     onClick={() => setActiveCategory(subcategory)}
//                   >
//                     {subcategory}
//                     <FaArchive
//                       className="ml-2 cursor-pointer text-gray-500"
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleArchiveClick(subcategory);
//                       }}
//                     />
//                   </li>
//                 ))}
//               </ul>
//             )}
//           </div>

//           {/* Middle Section */}
//           <div className="w-auto h-auto max-h-[780px] border border-gray-300 p-4 lg:w-[580px]">
//             <div className="p-4">
//               <h2 className="text-2xl font-semibold mb-4 md:text-lg text-cyan-800">
//                 Notifications
//               </h2>
//               <div className="flex items-center mb-4 relative">
//                 <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
//                 <input
//                   type="text"
//                   placeholder="Search notifications..."
//                   className="flex-grow p-2 pl-10 border rounded-lg md:text-sm"
//                   value={searchTerm}
//                   onChange={(e) => setSearchTerm(e.target.value)}
//                 />
//                 <button
//                   className="ml-2 p-2 rounded md:text-sm"
//                   style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}
//                   onClick={() => refetchNotifications()}
//                 >
//                   <FaSync />
//                 </button>
//                 <button
//                   className="ml-2 p-2 rounded md:text-sm"
//                   style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}
//                   onClick={toggleStatusFilter}
//                 >
//                   <FaFilter />
//                 </button>
//                 <span className="ml-2 p-2 rounded bg-gray-200 md:text-sm">{statusFilter}</span>
//               </div>
//             </div>
//             <div className="overflow-y-auto h-[470px]">
//               {filteredNotifications.map((notification: Notification) => (
//                 <div
//                   key={notification.ticketId}
//                   className={`shadow-md rounded-lg border p-4 cursor-pointer relative md:text-sm
//                     ${selectedTicket?.ticketId === notification.ticketId ? 'border-2 border-cyan-700' : ''}`}
//                   onClick={() => handleTicketClick(notification.ticketId)}
//                 >
//                   <div className="flex justify-between items-center mb-2">
//                     <span className="font-bold">Ticket #{notification.ticketId}</span>
//                     <span className="px-4 py-2 rounded-full" 
//                       style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}>{notification.username}</span>
//                   </div>
//                   <p>{notification.eventTitle}</p>
//                   <p>
//                     Status:
//                     <span className={`ml-2 px-2 py-1 rounded text-white ${
//                       notification.status === 'approved' ? 'bg-green-500' :
//                         notification.status === 'rejected' ? 'bg-red-500' :
//                           'bg-yellow-500'
//                     }`}>
//                       {notification.status}
//                     </span>
//                   </p>
//                   <p>Send on: {new Date(notification.createdAt).toLocaleString()}</p>
//                   <div className="absolute bottom-2 right-2 flex items-center">
//                     <FaStar
//                       className={`mr-2 cursor-pointer ${notification.isImportant ? 'text-yellow-500' : 'text-gray-500'}`}
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleStarClick(notification);
//                       }}
//                     />
//                     <FaTrashAlt
//                       className={`cursor-pointer
//                         ${permissions?.eventNotification?.deleteEventTicket !== 'enable'
//                           ? 'text-gray-400 cursor-not-allowed' : 'text-rose-600'
//                         }`}
//                       onClick={(e) => {
//                         if (permissions?.eventNotification?.deleteEventTicket === 'enable')
//                           e.stopPropagation();
//                           handleDeleteClick(notification.ticketId);
//                         }
//                       }
//                     />
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>

//         {/* Right Section */}
//         <div className="w-auto h-auto p-4 mt-2 border rounded-lg overflow-y-auto md:h-[500px]">
//           <h2 className="text-2xl font-bold mb-4"
//             style={{ color:theme.primaryColor, fontFamily:theme.fontFamily }}>Ticket Details</h2>
//           {selectedTicket ? (
//             <div className="flex">
//               {/* Details Table */}
//               <div className="w-2/3 pr-4">
//                 <table className="min-w-full bg-white md:text-sm">
//                   <tbody>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Ticket ID:</td>
//                       <td className="border px-4 py-2">{selectedTicket.ticketId}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Event:</td>
//                       <td className="border px-4 py-2">{selectedTicket.eventTitle}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Username:</td>
//                       <td className="border px-4 py-2">{selectedTicket.username}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Email:</td>
//                       <td className="border px-4 py-2">{selectedTicket.email}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Appointment Date:</td>
//                       <td className="border px-4 py-2">{new Date(selectedTicket.appointmentDate).toLocaleString()}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Payment Method:</td>
//                       <td className="border px-4 py-2">{selectedTicket.paymentMethod}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Amount:</td>
//                       <td className="border px-4 py-2">${selectedTicket.amount}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Lot Size:</td>
//                       <td className="border px-4 py-2">{selectedTicket.lotSize}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Account ID:</td>
//                       <td className="border px-4 py-2">{selectedTicket.accountId}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Account Password:</td>
//                       <td className="border px-4 py-2">{selectedTicket.accountPassword}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Broker Server:</td>
//                       <td className="border px-4 py-2">{selectedTicket.accountServer}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Points:</td>
//                       <td className="border px-4 py-2">{selectedTicket.points}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Notes:</td>
//                       <td className="border px-4 py-2">{selectedTicket.notes}</td>
//                     </tr>
//                     <tr>
//                       <td className="border px-4 py-2 font-bold">Status:</td>
//                       <td className="border px-4 py-2">{selectedTicket.status}</td>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="mt-4 flex justify-between">
//                   <button
//                     className={`px-4 py-2 rounded ${
//                       selectedTicket.status === 'approved' || selectedTicket.status === 'rejected'
//                         ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
//                         : 'bg-green-500 text-white'
//                     }`}
//                     onClick={() => handleStatusUpdate('approved')}
//                     disabled={selectedTicket.status === 'approved' || selectedTicket.status === 'rejected'}
//                   >
//                     Approve
//                   </button>
//                   <button
//                     className={`px-4 py-2 rounded ${
//                       selectedTicket.status === 'approved' || selectedTicket.status === 'rejected'
//                         ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
//                         : 'bg-red-500 text-white'
//                     }`}
//                     onClick={() => handleStatusUpdate('rejected')}
//                     disabled={selectedTicket.status === 'approved' || selectedTicket.status === 'rejected'}
//                   >
//                     Reject
//                   </button>
//                 </div>
//               </div>
//               {/* Receipt Image */}
//               <div className="w-1/3 pl-4">
//                 {selectedTicket.receiptPath && (
//                   <div className="mt-4">
//                     <img
//                       src={`${apiUrl}/uploads/forEventReceipts/${sanitizeTableName(selectedTicket.eventTitle)}/${selectedTicket.receiptPath}`}
//                       alt="Receipt"
//                       className="w-full cursor-pointer"
//                       onClick={() => handleImageClick(`${apiUrl}/uploads/forEventReceipts/${sanitizeTableName(selectedTicket.eventTitle)}/${selectedTicket.receiptPath}`)}
//                     />
//                   </div>
//                 )}
//               </div>
//             </div>
//           ) : (
//             <p>Select a ticket to view details</p>
//           )}
//         </div>

//         {/* Enlarged Image Modal */}
//         {enlargedImage && (
//           <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={() => setEnlargedImage(null)}>
//             <img src={enlargedImage} alt="Enlarged Receipt" className="max-w-3xl max-h-3xl" />
//           </div>
//         )}
//       </div>
//       {/* Alert Modal */}
//       <AdminNoticeAlertMessage
//         type={alert.type}
//         message={alert.message}
//         visible={alert.visible}
//         onClose={alert.onClose}
//         onConfirm={alert.onConfirm}
//         onCancel={alert.onCancel}
//       />
//     </div>
//   );
// };

// export default Admin05_Notification;