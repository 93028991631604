// src/components/admin/ClientData/UsersTableEdit.tsx

import React, { useState, useEffect } from 'react';
// import react icon
import { FaRegIdCard, FaRegEdit, FaRegSave, FaRegUserCircle, FaTelegramPlane, FaDiscord } from "react-icons/fa";
import { 
  MdOutlineMailOutline, MdOutlineDateRange, MdLocalPhone, 
  MdTypeSpecimen, MdQueryStats, MdOutlinePassword, 
  MdPayment, MdReceiptLong
} from "react-icons/md";
import { TbMailbox } from "react-icons/tb";
import { IoMdGlobe } from "react-icons/io";
import { PiGenderIntersex, PiRanking, PiPasswordFill } from "react-icons/pi";
import { FaUserTag } from "react-icons/fa6";
// import hook file
import { ClientDataProps } from '../../../hooks/AdminDashboard/useDataTable';
import { countryOptions, stateOptions, phoneOptions } from '../../../hooks/Login/useFormValidation';

interface UserTableEditModalProps {
  user: ClientDataProps | null;
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedUser: ClientDataProps) => void;
}

const UsersTableEditModal: React.FC<UserTableEditModalProps> = ({ user, isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState<ClientDataProps | null>(null);
  const [editableFields, setEditableFields] = useState<{ [key: string]: boolean }>({});
  const [paymentReceipts, setPaymentReceipts] = useState<{ receipts: File | null }>({ receipts: null });

  useEffect(() => {
    setFormData(user);
    setEditableFields({}); 
  }, [user]);

  useEffect(() => {
    if (user) {
      setFormData(user); 
    } else {
      setFormData({
        id: '',
        name: '',
        icNo: '',
        email: '',
        gender: '',
        phone: '',
        dob: '',
        country: '',
        state: '',
        address: '',
        amount: 0,
        receipt: null,
        telegram_id: '',
        discord_id: '',
        stages: '',
        badges: '',
        programStats: '',
        password: '', 
        memberType: '',
        dateJoined: null,
      });
    }
    setEditableFields({});
  }, [user]);  

  // Handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (formData) {
      const { name, value } = e.target;
  
      if (name === "phone") {
        const phoneValue = value;
        const matchedPhoneOption = phoneOptions.find(option => phoneValue.startsWith(option.value));
  
        setFormData({
          ...formData,
          countryCode: matchedPhoneOption ? matchedPhoneOption.value : formData.countryCode, 
          phone: matchedPhoneOption ? phoneValue.substring(matchedPhoneOption.value.length) : phoneValue,
        });
      } else if (name === "countryCode") {
        setFormData({
          ...formData,
          countryCode: value,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  };
  
  useEffect(() => {
    if (formData?.phone) {
      const matchedPhoneOption = phoneOptions.find(option => formData.phone.startsWith(option.value));
      
      if (matchedPhoneOption) {
        setFormData(prevFormData => {
          if (!prevFormData) return prevFormData;
          
          return {
            ...prevFormData,
            countryCode: matchedPhoneOption.value, 
            phone: formData.phone.substring(matchedPhoneOption.value.length),
          };
        });
      }
    }
  }, [formData?.phone]);          

  // Handle save operation
  const handleSave = () => {
    if (formData) {
      const combinedPhone = `${formData.countryCode}${formData.phone}`;
      const updatedFormData = {
        ...formData,
        phone: combinedPhone,
      };
  
      // Build a plain object that matches ClientDataProps
      const plainData = {
        ...updatedFormData,
        receipt: paymentReceipts.receipts || null, 
      };
  
      onSave(plainData as ClientDataProps); 
      onClose();
    }
  };

  // Toggle editability of a field
  const toggleEditableField = (fieldName: string) => {
    setEditableFields((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  if (!isOpen || !formData) return null; 

  // Function to generate password with exactly one symbol from a specific set
  const generatePassword = (): string => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const symbols = '#@$!'; 
    let password = '';

    // Randomly insert one symbol into the password
    const randomSymbol = symbols[Math.floor(Math.random() * symbols.length)];
      for (let i = 0; i < 7; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        password += chars[randomIndex];
      }

    // Randomly insert the symbol into the password
    const insertPosition = Math.floor(Math.random() * password.length);
    password = password.slice(0, insertPosition) + randomSymbol + password.slice(insertPosition);

    return password;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    // console.log("File selected for upload:", file);
    setPaymentReceipts({ receipts: file });
  };
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-100 p-6 rounded-lg shadow-lg max-w-md w-full max-h-screen h-auto">
        <h2 className="text-xl font-semibold mb-4 text-cyan-800 p-2 rounded-sm">
            {user ? "Edit User Data" : "Add User Data"}
        </h2>

      <div className="border-b-2 mb-2 border-gray-800 border-opacity-10"></div>

      {/* form field section */}
      <div className="space-y-2 overflow-y-auto h-[500px]">

        {/* user name id*/}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center">
            <div className="flex justify-start items-center mb-1">
              <FaRegIdCard className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">id</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="text"
              name="id"
              value={formData.id}
              disabled
              className="border py-1 px-4 text-sm rounded w-full"
            />
          </div>
        </div>

        {/* Member Type field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <FaUserTag className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Account Type:</span>
            </div>
          </div>
          <div className="flex justify-between items-center space-x-4">
            {/* Dropdown for account type */}
            <select
              name="memberType"
              value={formData?.memberType || ""}
              onChange={handleInputChange}
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['memberType'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
              disabled={!editableFields['memberType']}
            >
              <option value="">Select a Member type</option>
              <option value="exisiting">Exisiting Member</option>
              <option value="new">New Member</option>
            </select>
            <button 
                onClick={() => toggleEditableField('memberType')} 
                className="flex justify-normal items-center"
            >
              {editableFields['memberType'] ? (
                  <FaRegSave className="text-cyan-800"/>
              ) : (
                  <FaRegEdit className="text-cyan-800"/>
              )}
            </button>
          </div>
        </div>

        {/* date of joined field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <MdOutlineDateRange className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Date of Joined</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="date"
              name="dateJoined"
              value={formData.dateJoined ? new Date(formData.dateJoined).toISOString().slice(0, 10) : ""}
              onChange={handleInputChange}
              placeholder="dateJoined"
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['dateJoined'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
              disabled={!editableFields['dateJoined']}
            />
            <button 
              onClick={() => toggleEditableField('dateJoined')} 
              className="flex justify-normal items-center"
            >
              {editableFields['dateJoined'] ? (
                  <FaRegSave className="text-cyan-800"/>
              ) : (
                  <FaRegEdit className="text-cyan-800"/>
              )}
            </button>
          </div>
        </div>

        {/* user name field*/}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center">
            <div className="flex justify-start items-center mb-1">
              <FaRegUserCircle className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Name</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Name"
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['name'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
              disabled={!editableFields['name']}
            />
            <button 
              onClick={() => toggleEditableField('name')} 
              className="flex justify-normal items-center"
            >
              {editableFields['name'] ? (
                <FaRegSave className="text-cyan-800"/>
              ) : (
                <FaRegEdit className="text-cyan-800"/>
              )}
            </button>
          </div>
        </div>

        {/* user ic field*/}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center">
            <div className="flex justify-start items-center mb-1">
              <FaRegUserCircle className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Identity Card No.</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="text"
              name="icNo"
              value={formData.icNo}
              onChange={handleInputChange}
              placeholder="identity"
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['icNo'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
              disabled={!editableFields['icNo']}
            />
            <button 
              onClick={() => toggleEditableField('icNo')} 
              className="flex justify-normal items-center"
            >
              {editableFields['icNo'] ? (
                  <FaRegSave className="text-cyan-800"/>
              ) : (
                  <FaRegEdit className="text-cyan-800"/>
              )}
            </button>
          </div>
        </div>

        {/* email field*/}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <MdOutlineMailOutline className="text-cyan-800"/>
              <span className="font-semibold ml-2 text-sm text-cyan-800">Email</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['email'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
              disabled={!editableFields['email']}
            />
            <button 
              onClick={() => toggleEditableField('email')} 
              className="flex justify-normal items-center"
            >
              {editableFields['email'] ? (
                  <FaRegSave className="text-cyan-800"/>
              ) : (
                  <FaRegEdit className="text-cyan-800"/>
              )}
            </button>
          </div>
        </div>

        {/* Contact No. field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <MdLocalPhone className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Contact No.</span>
            </div>
          </div>
          <div className="flex justify-between items-center space-x-4">
            <div className="flex justify-between items-center">
              {/* Dropdown for phone code */}
              <select
                name="countryCode"
                value={formData?.countryCode || ""}
                onChange={handleInputChange}
                className={`border py-1 px-2 text-sm rounded w-[170px] ${editableFields['phone'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
                disabled={!editableFields['phone']}
              >
                <option value="">Select a phone code</option>
                {phoneOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                      {option.label}
                  </option>
                ))}
              </select>

              {/* Input for phone number */}
              <input
                type="text"
                name="phone"
                value={formData.phone || ""}
                onChange={handleInputChange}
                placeholder="Phone"
                className={`border py-1 px-4 text-sm rounded w-full ${editableFields['phone'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
                disabled={!editableFields['phone']}
              />
            </div>
              <button 
                onClick={() => toggleEditableField('phone')} 
                className="flex justify-normal items-center"
              >
                {editableFields['phone'] ? (
                  <FaRegSave className="text-cyan-800"/>
                ) : (
                  <FaRegEdit className="text-cyan-800"/>
                )}
              </button>
          </div>
        </div>

        {/* gender field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <PiGenderIntersex className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Gender</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <select
              name="gender"
              value={formData.gender || ""}
              onChange={handleInputChange}
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['gender'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
              disabled={!editableFields['gender']}
            >
              <option value="">Select a gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            <button 
              onClick={() => toggleEditableField('gender')} 
              className="flex justify-normal items-center"
            >
              {editableFields['gender'] ? (
                <FaRegSave className="text-cyan-800"/>
              ) : (
                <FaRegEdit className="text-cyan-800"/>
              )}
            </button>
          </div>
        </div>

        {/* date of birth field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <MdOutlineDateRange className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Date of Birth</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="date"
              name="dob"
              value={formData.dob ? new Date(formData.dob).toISOString().slice(0, 10) : ""}
              onChange={handleInputChange}
              placeholder="dob"
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['date'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
              disabled={!editableFields['date']}
            />
            <button 
              onClick={() => toggleEditableField('date')} 
              className="flex justify-normal items-center"
            >
              {editableFields['date'] ? (
                <FaRegSave className="text-cyan-800"/>
              ) : (
                <FaRegEdit className="text-cyan-800"/>
              )}
            </button>
          </div>
        </div>

        {/* Country Field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <IoMdGlobe className="text-cyan-800" />
              <span className="font-semibold text-sm ml-2 text-cyan-800">Country</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <select
              name="country"
              value={formData.country || ""}
              onChange={handleInputChange}
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['country'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
              disabled={!editableFields['country']}
            >
              <option value="">Select a country</option>
              {countryOptions.map((option) => (
                <option key={option.value} value={option.label}>
                  {option.label}
                </option>
              ))}
            </select>
            <button
              onClick={() => toggleEditableField('country')}
              className="flex justify-normal items-center"
            >
              {editableFields['country'] ? (
                <FaRegSave className="text-cyan-800" />
              ) : (
                <FaRegEdit className="text-cyan-800" />
              )}
            </button>
          </div>
        </div>

        {/* State Field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <TbMailbox className="text-cyan-800" />
              <span className="font-semibold text-sm ml-2 text-cyan-800">State</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <select
              name="state"
              value={formData.state || ""}
              onChange={handleInputChange}
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['state'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
              disabled={!editableFields['state']}
            >
              <option value="">Select a state</option>
              {stateOptions.map((option) => (
                <option key={option.value} value={option.label}>
                  {option.label}
                </option>
              ))}
            </select>
            <button
              onClick={() => toggleEditableField('state')}
              className="flex justify-normal items-center"
            >
              {editableFields['state'] ? (
                <FaRegSave className="text-cyan-800" />
              ) : (
                <FaRegEdit className="text-cyan-800" />
              )}
            </button>
          </div>
        </div>

        {/* address field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <TbMailbox className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Address</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="textarea"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="address"
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['address'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
              disabled={!editableFields['address']}
            />
            <button
              onClick={() => toggleEditableField('address')}
              className="flex justify-normal items-center"
            >
              {editableFields['address'] ? (
                <FaRegSave className="text-cyan-800" />
              ) : (
                <FaRegEdit className="text-cyan-800" />
              )}
            </button>
          </div>
        </div>

        {/* payment amount field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <MdPayment className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Payment Amount</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              placeholder="payment amount"
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['amount'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
              disabled={!editableFields['amount']}
            />
            <button
              onClick={() => toggleEditableField('amount')}
              className="flex justify-normal items-center"
            >
              {editableFields['amount'] ? (
                <FaRegSave className="text-cyan-800" />
              ) : (
                <FaRegEdit className="text-cyan-800" />
              )}
            </button>
          </div>
        </div>

        {/* payment receipts field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <MdReceiptLong className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Payment Receipts</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="file"
              name="receipt"
              onChange={handleFileChange}
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['receipt'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
              disabled={!editableFields['receipt']}
            />
            <button
              onClick={() => toggleEditableField('receipt')}
              className="flex justify-normal items-center"
            >
              {editableFields['receipt'] ? (
                <FaRegSave className="text-cyan-800" />
              ) : (
                <FaRegEdit className="text-cyan-800" />
              )}
            </button>
          </div>
          <div className="flex">
            {paymentReceipts.receipts && (
              <p className="text-sm text-gray-600 mt-4 mx-2">
                <span className="font-semibold">Uploaded file:</span> 
                <span className="text-rose-600">{paymentReceipts.receipts.name}</span>
              </p>
            )}
          </div>
        </div>

        {/* telegram id field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <FaTelegramPlane className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Telegram id</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="text"
              name="telegram_id"
              value={formData.telegram_id || ""}
              onChange={handleInputChange}
              placeholder="enter users telegram id..."
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['telegram_id'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
              disabled={!editableFields['telegram_id']}
            />
            <button
              onClick={() => toggleEditableField('telegram_id')}
              className="flex justify-normal items-center"
            >
              {editableFields['telegram_id'] ? (
                <FaRegSave className="text-cyan-800" />
              ) : (
                <FaRegEdit className="text-cyan-800" />
              )}
            </button>
          </div>
        </div>

        {/* discord id field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <FaDiscord className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Discord id</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="text"
              name="discord_id"
              value={formData.discord_id}
              onChange={handleInputChange}
              placeholder="enter users discord id..."
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['discord_id'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
              disabled={!editableFields['discord_id']}
            />
            <button
              onClick={() => toggleEditableField('discord_id')}
              className="flex justify-normal items-center"
            >
              {editableFields['discord_id'] ? (
                <FaRegSave className="text-cyan-800" />
              ) : (
                <FaRegEdit className="text-cyan-800" />
              )}
            </button>
          </div>
        </div>

        {/* member type field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <MdTypeSpecimen className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Member Type</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <select
              name="stages"
              value={formData.stages || ""}
              onChange={handleInputChange}
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['stages'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
              disabled={!editableFields['stages']}
            >
              <option value="">Select a member type</option>
              <option value="R One Guest">R One Guest</option>
              <option value="R One Basic">R One Basic</option>
              <option value="R One Member">R One Member</option>
              <option value="R One Dealer">R One Dealer</option>
              <option value="R One Trader Hub">R One Trader Hub</option>
              <option value="R One Elite">R One Elite</option>
              <option value="R One Master Trader">R One Master Trader</option>
              <option value="R One Certified Trader">R One Certified Trader</option>
            </select>
            <button
              onClick={() => toggleEditableField('stages')}
              className="flex justify-normal items-center"
            >
              {editableFields['stages'] ? (
                <FaRegSave className="text-cyan-800" />
              ) : (
                <FaRegEdit className="text-cyan-800" />
              )}
            </button>
          </div>
        </div>

        {/* ranking field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <PiRanking className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Ranking</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <select
              name="badges"
              value={formData.badges || ""}
              onChange={handleInputChange}
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['badges'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
              disabled={!editableFields['badges']}
            >
              <option value="">Select a Ranking</option>
              <option value="Copper">Copper(R One Guest)</option>
              <option value="Amber">Amber(R One Basic)</option>
              <option value="Bronze">Bronze(R One Member)</option>
              <option value="Silver">Silver</option>
              <option value="Gold">Gold</option>
              <option value="Diamond">Diamond</option>
            </select>
            <button
              onClick={() => toggleEditableField('badges')}
              className="flex justify-normal items-center"
            >
              {editableFields['badges'] ? (
                <FaRegSave className="text-cyan-800" />
              ) : (
                <FaRegEdit className="text-cyan-800" />
              )}
            </button>
          </div>
        </div>

        {/* program stats field */}
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <MdQueryStats className="text-cyan-800"/>
              <span className="font-semibold text-sm ml-2 text-cyan-800">Program Status</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="text"
              name="programStats"
              value={formData.programStats}
              onChange={handleInputChange}
              placeholder="enter users program stats..."
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['programStats'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
              disabled={!editableFields['programStats']}
            />
            <button
              onClick={() => toggleEditableField('programStats')}
              className="flex justify-normal items-center"
            >
              {editableFields['programStats'] ? (
                <FaRegSave className="text-cyan-800" />
              ) : (
                <FaRegEdit className="text-cyan-800" />
              )}
            </button>
          </div>
        </div>

        {/* Password field with Generate button */ }
        <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
          <div className="flex justify-between items-center mb-1">
            <div className="flex justify-start items-center">
              <MdOutlinePassword className="text-cyan-800" />
              <span className="font-semibold text-sm ml-2 text-cyan-800">Password</span>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <input
              type="text"
              name="password"
              value={formData.password || ""}
              onChange={handleInputChange}
              placeholder="Generate a new password..."
              className={`border py-1 px-4 text-sm rounded w-full ${editableFields['password'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
              disabled={!editableFields['password']}
            />
            {editableFields['password'] && (
              <button
                onClick={() => {
                    const newPassword = generatePassword();
                    setFormData((prevData) => prevData ? { ...prevData, password: newPassword } : prevData);
                }}
                className="flex justify-normal items-center text-cyan-800 cursor-pointer "
              >
                <PiPasswordFill />
              </button>
            )}
            <button
              onClick={() => toggleEditableField('password')}  
              className="flex justify-normal items-center"
            >
              {editableFields['password'] ? (
                  <FaRegSave className="text-cyan-800" />
              ) : (
                  <FaRegEdit className="text-cyan-800" />
              )}
            </button>
          </div>
        </div>
        </div>
        <div className="mt-6 flex justify-end space-x-2 text-sm">
          <button 
            onClick={onClose} 
            className="bg-gray-400 text-white px-4 py-1 rounded"
          >
            Cancel
          </button>
          <button 
            onClick={handleSave} 
            className="bg-cyan-800 text-white px-6 py-1 rounded"
          >
            {user ? "Save" : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UsersTableEditModal;

// // udpaet code --> 07 dec 2024
// // src/components/admin/ClientData/UsersTableEdit.tsx

// import React, { useState, useEffect } from 'react';
// // import react icon
// import { FaRegIdCard, FaRegEdit, FaRegSave, FaRegUserCircle, FaTelegramPlane, FaDiscord } from "react-icons/fa";
// import { 
//   MdOutlineMailOutline, MdOutlineDateRange, MdLocalPhone, 
//   MdTypeSpecimen, MdQueryStats, MdOutlinePassword, 
//   MdPayment, MdReceiptLong
// } from "react-icons/md";
// import { TbMailbox } from "react-icons/tb";
// import { IoMdGlobe } from "react-icons/io";
// import { PiGenderIntersex, PiRanking, PiPasswordFill } from "react-icons/pi";
// import { FaUserTag } from "react-icons/fa6";
// // import hook file
// import { ClientDataProps } from '../../../hooks/AdminDashboard/useDataTable';
// import { countryOptions, stateOptions, phoneOptions } from '../../../hooks/Login/useFormValidation';

// interface UserTableEditModalProps {
//   user: ClientDataProps | null;
//   isOpen: boolean;
//   onClose: () => void;
//   onSave: (updatedUser: ClientDataProps) => void;
// }

// const UsersTableEditModal: React.FC<UserTableEditModalProps> = ({ user, isOpen, onClose, onSave }) => {
//   const [formData, setFormData] = useState<ClientDataProps | null>(null);
//   const [editableFields, setEditableFields] = useState<{ [key: string]: boolean }>({});
//   const [paymentReceipts, setPaymentReceipts] = useState<{ receipts: File | null }>({ receipts: null });

//   useEffect(() => {
//     setFormData(user);
//     setEditableFields({}); 
//   }, [user]);

//   useEffect(() => {
//     if (user) {
//       setFormData(user); 
//     } else {
//       setFormData({
//         id: '',
//         name: '',
//         icNo: '',
//         email: '',
//         gender: '',
//         phone: '',
//         dob: '',
//         country: '',
//         state: '',
//         address: '',
//         amount: 0,
//         receipt: null,
//         telegram_id: '',
//         discord_id: '',
//         stages: '',
//         badges: '',
//         programStats: '',
//         password: '', 
//         memberType: '',
//         dateJoined: null,
//       });
//     }
//     setEditableFields({});
//   }, [user]);  

//   // Handle form input changes
//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//     if (formData) {
//       const { name, value } = e.target;
  
//       if (name === "phone") {
//         const phoneValue = value;
//         const matchedPhoneOption = phoneOptions.find(option => phoneValue.startsWith(option.value));
  
//         setFormData({
//           ...formData,
//           countryCode: matchedPhoneOption ? matchedPhoneOption.value : formData.countryCode, 
//           phone: matchedPhoneOption ? phoneValue.substring(matchedPhoneOption.value.length) : phoneValue,
//         });
//       } else if (name === "countryCode") {
//         setFormData({
//           ...formData,
//           countryCode: value,
//         });
//       } else {
//         setFormData({
//           ...formData,
//           [name]: value,
//         });
//       }
//     }
//   };
  
//   useEffect(() => {
//     if (formData?.phone) {
//       const matchedPhoneOption = phoneOptions.find(option => formData.phone.startsWith(option.value));
      
//       if (matchedPhoneOption) {
//         setFormData(prevFormData => {
//           if (!prevFormData) return prevFormData;
          
//           return {
//             ...prevFormData,
//             countryCode: matchedPhoneOption.value, 
//             phone: formData.phone.substring(matchedPhoneOption.value.length),
//           };
//         });
//       }
//     }
//   }, [formData?.phone]);          

//   // Handle save operation
//   const handleSave = () => {
//     if (formData) {
//       const combinedPhone = `${formData.countryCode}${formData.phone}`;
//       const updatedFormData = {
//         ...formData,
//         phone: combinedPhone,
//       };
  
//       // Build a plain object that matches ClientDataProps
//       const plainData = {
//         ...updatedFormData,
//         receipt: paymentReceipts.receipts || null, 
//       };
  
//       onSave(plainData as ClientDataProps); 
//       onClose();
//     }
//   };

//   // Toggle editability of a field
//   const toggleEditableField = (fieldName: string) => {
//     setEditableFields((prev) => ({
//       ...prev,
//       [fieldName]: !prev[fieldName],
//     }));
//   };

//   if (!isOpen || !formData) return null; 

//   // Function to generate password with exactly one symbol from a specific set
//   const generatePassword = (): string => {
//     const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//     const symbols = '#@$!'; 
//     let password = '';

//     // Randomly insert one symbol into the password
//     const randomSymbol = symbols[Math.floor(Math.random() * symbols.length)];
//     for (let i = 0; i < 7; i++) {
//       const randomIndex = Math.floor(Math.random() * chars.length);
//       password += chars[randomIndex];
//     }

//     // Randomly insert the symbol into the password
//     const insertPosition = Math.floor(Math.random() * password.length);
//     password = password.slice(0, insertPosition) + randomSymbol + password.slice(insertPosition);

//     return password;
//   };

//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0] || null;
//     // console.log("File selected for upload:", file);
//     setPaymentReceipts({ receipts: file });
//   };
  
//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="bg-gray-100 p-6 rounded-lg shadow-lg max-w-md w-full max-h-screen h-auto">
//         <h2 className="text-xl font-semibold mb-4 text-cyan-800 p-2 rounded-sm">
//             {user ? "Edit User Data" : "Add User Data"}
//         </h2>

//       <div className="border-b-2 mb-2 border-gray-800 border-opacity-10"></div>

//       {/* form field section */}
//       <div className="space-y-2 overflow-y-auto h-[500px]">

//         {/* user name id*/}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center">
//             <div className="flex justify-start items-center mb-1">
//               <FaRegIdCard className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">id</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="text"
//               name="id"
//               value={formData.id}
//               disabled
//               className="border py-1 px-4 text-sm rounded w-full"
//             />
//           </div>
//         </div>

//         {/* Member Type field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <FaUserTag className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Account Type:</span>
//             </div>
//           </div>
//           <div className="flex justify-between items-center space-x-4">
//             {/* Dropdown for account type */}
//             <select
//               name="memberType"
//               value={formData?.memberType || ""}
//               onChange={handleInputChange}
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['memberType'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//               disabled={!editableFields['memberType']}
//             >
//               <option value="">Select a Member type</option>
//               <option value="exisiting">Exisiting Member</option>
//               <option value="new">New Member</option>
//             </select>
//             <button 
//                 onClick={() => toggleEditableField('memberType')} 
//                 className="flex justify-normal items-center"
//             >
//               {editableFields['memberType'] ? (
//                   <FaRegSave className="text-cyan-800"/>
//               ) : (
//                   <FaRegEdit className="text-cyan-800"/>
//               )}
//             </button>
//           </div>
//         </div>

//         {/* date of joined field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <MdOutlineDateRange className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Date of Joined</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="date"
//               name="dateJoined"
//               value={formData.dateJoined ? new Date(formData.dateJoined).toISOString().slice(0, 10) : ""}
//               onChange={handleInputChange}
//               placeholder="dateJoined"
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['dateJoined'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//               disabled={!editableFields['dateJoined']}
//             />
//             <button 
//               onClick={() => toggleEditableField('dateJoined')} 
//               className="flex justify-normal items-center"
//             >
//               {editableFields['dateJoined'] ? (
//                   <FaRegSave className="text-cyan-800"/>
//               ) : (
//                   <FaRegEdit className="text-cyan-800"/>
//               )}
//             </button>
//           </div>
//         </div>

//         {/* user name field*/}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center">
//             <div className="flex justify-start items-center mb-1">
//               <FaRegUserCircle className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Name</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="text"
//               name="name"
//               value={formData.name}
//               onChange={handleInputChange}
//               placeholder="Name"
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['name'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
//               disabled={!editableFields['name']}
//             />
//             <button 
//               onClick={() => toggleEditableField('name')} 
//               className="flex justify-normal items-center"
//             >
//               {editableFields['name'] ? (
//                 <FaRegSave className="text-cyan-800"/>
//               ) : (
//                 <FaRegEdit className="text-cyan-800"/>
//               )}
//             </button>
//           </div>
//         </div>

//         {/* user ic field*/}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center">
//             <div className="flex justify-start items-center mb-1">
//               <FaRegUserCircle className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Identity Card No.</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="text"
//               name="icNo"
//               value={formData.icNo}
//               onChange={handleInputChange}
//               placeholder="identity"
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['icNo'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
//               disabled={!editableFields['icNo']}
//             />
//             <button 
//               onClick={() => toggleEditableField('icNo')} 
//               className="flex justify-normal items-center"
//             >
//               {editableFields['icNo'] ? (
//                   <FaRegSave className="text-cyan-800"/>
//               ) : (
//                   <FaRegEdit className="text-cyan-800"/>
//               )}
//             </button>
//           </div>
//         </div>

//         {/* email field*/}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <MdOutlineMailOutline className="text-cyan-800"/>
//               <span className="font-semibold ml-2 text-sm text-cyan-800">Email</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               placeholder="Email"
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['email'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//               disabled={!editableFields['email']}
//             />
//             <button 
//               onClick={() => toggleEditableField('email')} 
//               className="flex justify-normal items-center"
//             >
//               {editableFields['email'] ? (
//                   <FaRegSave className="text-cyan-800"/>
//               ) : (
//                   <FaRegEdit className="text-cyan-800"/>
//               )}
//             </button>
//           </div>
//         </div>

//         {/* Contact No. field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <MdLocalPhone className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Contact No.</span>
//             </div>
//           </div>
//           <div className="flex justify-between items-center space-x-4">
//             <div className="flex justify-between items-center">
//               {/* Dropdown for phone code */}
//               <select
//                 name="countryCode"
//                 value={formData?.countryCode || ""}
//                 onChange={handleInputChange}
//                 className={`border py-1 px-2 text-sm rounded w-[170px] ${editableFields['phone'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//                 disabled={!editableFields['phone']}
//               >
//                 <option value="">Select a phone code</option>
//                 {phoneOptions.map((option) => (
//                   <option key={option.value} value={option.value}>
//                       {option.label}
//                   </option>
//                 ))}
//               </select>

//               {/* Input for phone number */}
//               <input
//                 type="text"
//                 name="phone"
//                 value={formData.phone || ""}
//                 onChange={handleInputChange}
//                 placeholder="Phone"
//                 className={`border py-1 px-4 text-sm rounded w-full ${editableFields['phone'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//                 disabled={!editableFields['phone']}
//               />
//             </div>
//               <button 
//                 onClick={() => toggleEditableField('phone')} 
//                 className="flex justify-normal items-center"
//               >
//                 {editableFields['phone'] ? (
//                   <FaRegSave className="text-cyan-800"/>
//                 ) : (
//                   <FaRegEdit className="text-cyan-800"/>
//                 )}
//               </button>
//           </div>
//         </div>

//         {/* gender field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <PiGenderIntersex className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Gender</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <select
//               name="gender"
//               value={formData.gender || ""}
//               onChange={handleInputChange}
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['gender'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//               disabled={!editableFields['gender']}
//             >
//               <option value="">Select a gender</option>
//               <option value="male">Male</option>
//               <option value="female">Female</option>
//             </select>
//             <button 
//               onClick={() => toggleEditableField('gender')} 
//               className="flex justify-normal items-center"
//             >
//               {editableFields['gender'] ? (
//                 <FaRegSave className="text-cyan-800"/>
//               ) : (
//                 <FaRegEdit className="text-cyan-800"/>
//               )}
//             </button>
//           </div>
//         </div>

//         {/* date of birth field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <MdOutlineDateRange className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Date of Birth</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="date"
//               name="dob"
//               value={formData.dob ? new Date(formData.dob).toISOString().slice(0, 10) : ""}
//               onChange={handleInputChange}
//               placeholder="dob"
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['date'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//               disabled={!editableFields['date']}
//             />
//             <button 
//               onClick={() => toggleEditableField('date')} 
//               className="flex justify-normal items-center"
//             >
//               {editableFields['date'] ? (
//                 <FaRegSave className="text-cyan-800"/>
//               ) : (
//                 <FaRegEdit className="text-cyan-800"/>
//               )}
//             </button>
//           </div>
//         </div>

//         {/* Country Field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <IoMdGlobe className="text-cyan-800" />
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Country</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <select
//               name="country"
//               value={formData.country || ""}
//               onChange={handleInputChange}
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['country'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
//               disabled={!editableFields['country']}
//             >
//               <option value="">Select a country</option>
//               {countryOptions.map((option) => (
//                 <option key={option.value} value={option.label}>
//                   {option.label}
//                 </option>
//               ))}
//             </select>
//             <button
//               onClick={() => toggleEditableField('country')}
//               className="flex justify-normal items-center"
//             >
//               {editableFields['country'] ? (
//                 <FaRegSave className="text-cyan-800" />
//               ) : (
//                 <FaRegEdit className="text-cyan-800" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* State Field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <TbMailbox className="text-cyan-800" />
//               <span className="font-semibold text-sm ml-2 text-cyan-800">State</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <select
//               name="state"
//               value={formData.state || ""}
//               onChange={handleInputChange}
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['state'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
//               disabled={!editableFields['state']}
//             >
//               <option value="">Select a state</option>
//               {stateOptions.map((option) => (
//                 <option key={option.value} value={option.label}>
//                   {option.label}
//                 </option>
//               ))}
//             </select>
//             <button
//               onClick={() => toggleEditableField('state')}
//               className="flex justify-normal items-center"
//             >
//               {editableFields['state'] ? (
//                 <FaRegSave className="text-cyan-800" />
//               ) : (
//                 <FaRegEdit className="text-cyan-800" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* address field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <TbMailbox className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Address</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="textarea"
//               name="address"
//               value={formData.address}
//               onChange={handleInputChange}
//               placeholder="address"
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['address'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
//               disabled={!editableFields['address']}
//             />
//             <button
//               onClick={() => toggleEditableField('address')}
//               className="flex justify-normal items-center"
//             >
//               {editableFields['address'] ? (
//                 <FaRegSave className="text-cyan-800" />
//               ) : (
//                 <FaRegEdit className="text-cyan-800" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* payment amount field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <MdPayment className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Payment Amount</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="number"
//               name="amount"
//               value={formData.amount}
//               onChange={handleInputChange}
//               placeholder="payment amount"
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['amount'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
//               disabled={!editableFields['amount']}
//             />
//             <button
//               onClick={() => toggleEditableField('amount')}
//               className="flex justify-normal items-center"
//             >
//               {editableFields['amount'] ? (
//                 <FaRegSave className="text-cyan-800" />
//               ) : (
//                 <FaRegEdit className="text-cyan-800" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* payment receipts field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <MdReceiptLong className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Payment Receipts</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="file"
//               name="receipt"
//               onChange={handleFileChange}
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['receipt'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`} 
//               disabled={!editableFields['receipt']}
//             />
//             <button
//               onClick={() => toggleEditableField('receipt')}
//               className="flex justify-normal items-center"
//             >
//               {editableFields['receipt'] ? (
//                 <FaRegSave className="text-cyan-800" />
//               ) : (
//                 <FaRegEdit className="text-cyan-800" />
//               )}
//             </button>
//           </div>
//           <div className="flex">
//             {paymentReceipts.receipts && (
//               <p className="text-sm text-gray-600 mt-4 mx-2">
//                 <span className="font-semibold">Uploaded file:</span> 
//                 <span className="text-rose-600">{paymentReceipts.receipts.name}</span>
//               </p>
//             )}
//           </div>
//         </div>

//         {/* telegram id field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <FaTelegramPlane className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Telegram id</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="text"
//               name="telegram_id"
//               value={formData.telegram_id || ""}
//               onChange={handleInputChange}
//               placeholder="enter users telegram id..."
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['telegram_id'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//               disabled={!editableFields['telegram_id']}
//             />
//             <button
//               onClick={() => toggleEditableField('telegram_id')}
//               className="flex justify-normal items-center"
//             >
//               {editableFields['telegram_id'] ? (
//                 <FaRegSave className="text-cyan-800" />
//               ) : (
//                 <FaRegEdit className="text-cyan-800" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* discord id field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <FaDiscord className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Discord id</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="text"
//               name="discord_id"
//               value={formData.discord_id}
//               onChange={handleInputChange}
//               placeholder="enter users discord id..."
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['discord_id'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//               disabled={!editableFields['discord_id']}
//             />
//             <button
//               onClick={() => toggleEditableField('discord_id')}
//               className="flex justify-normal items-center"
//             >
//               {editableFields['discord_id'] ? (
//                 <FaRegSave className="text-cyan-800" />
//               ) : (
//                 <FaRegEdit className="text-cyan-800" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* member type field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <MdTypeSpecimen className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Member Type</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <select
//               name="stages"
//               value={formData.stages || ""}
//               onChange={handleInputChange}
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['stages'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//               disabled={!editableFields['stages']}
//             >
//               <option value="">Select a member type</option>
//               <option value="R One Guest">R One Guest</option>
//               <option value="R One Basic">R One Basic</option>
//               <option value="R One Member">R One Member</option>
//               <option value="R One Dealer">R One Dealer</option>
//               <option value="R One Trader Hub">R One Trader Hub</option>
//               <option value="R One Elite">R One Elite</option>
//               <option value="R One Master Trader">R One Master Trader</option>
//               <option value="R One Certified Trader">R One Certified Trader</option>
//             </select>
//             <button
//               onClick={() => toggleEditableField('stages')}
//               className="flex justify-normal items-center"
//             >
//               {editableFields['stages'] ? (
//                 <FaRegSave className="text-cyan-800" />
//               ) : (
//                 <FaRegEdit className="text-cyan-800" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* ranking field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//               <div className="flex justify-start items-center">
//                   <PiRanking className="text-cyan-800"/>
//                   <span className="font-semibold text-sm ml-2 text-cyan-800">Ranking</span>
//               </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <select
//               name="badges"
//               value={formData.badges || ""}
//               onChange={handleInputChange}
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['badges'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//               disabled={!editableFields['badges']}
//             >
//               <option value="">Select a Ranking</option>
//               <option value="Copper">Copper(R One Guest)</option>
//               <option value="Amber">Amber(R One Basic)</option>
//               <option value="Bronze">Bronze(R One Member)</option>
//               <option value="Silver">Silver</option>
//               <option value="Gold">Gold</option>
//               <option value="Diamond">Diamond</option>
//             </select>
//             <button
//               onClick={() => toggleEditableField('badges')}
//               className="flex justify-normal items-center"
//             >
//               {editableFields['badges'] ? (
//                 <FaRegSave className="text-cyan-800" />
//               ) : (
//                 <FaRegEdit className="text-cyan-800" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* program stats field */}
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <MdQueryStats className="text-cyan-800"/>
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Program Status</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="text"
//               name="programStats"
//               value={formData.programStats}
//               onChange={handleInputChange}
//               placeholder="enter users program stats..."
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['programStats'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//               disabled={!editableFields['programStats']}
//             />
//             <button
//               onClick={() => toggleEditableField('programStats')}
//               className="flex justify-normal items-center"
//             >
//               {editableFields['programStats'] ? (
//                 <FaRegSave className="text-cyan-800" />
//               ) : (
//                 <FaRegEdit className="text-cyan-800" />
//               )}
//             </button>
//           </div>
//         </div>

//         {/* Password field with Generate button */ }
//         <div className="flex flex-col border bg-white p-4 rounded hover:border hover:border-gray-400">
//           <div className="flex justify-between items-center mb-1">
//             <div className="flex justify-start items-center">
//               <MdOutlinePassword className="text-cyan-800" />
//               <span className="font-semibold text-sm ml-2 text-cyan-800">Password</span>
//             </div>
//           </div>
//           <div className="flex justify-end items-center space-x-4">
//             <input
//               type="text"
//               name="password"
//               value={formData.password || ""}
//               onChange={handleInputChange}
//               placeholder="Generate a new password..."
//               className={`border py-1 px-4 text-sm rounded w-full ${editableFields['password'] ? "text-black bg-white" : "text-gray-300 bg-gray-50"}`}
//               disabled={!editableFields['password']}
//             />
//             {editableFields['password'] && (
//               <button
//                 onClick={() => {
//                     const newPassword = generatePassword();
//                     setFormData((prevData) => prevData ? { ...prevData, password: newPassword } : prevData);
//                 }}
//                 className="flex justify-normal items-center text-cyan-800 cursor-pointer "
//               >
//                 <PiPasswordFill />
//               </button>
//             )}
//             <button
//               onClick={() => toggleEditableField('password')}  
//               className="flex justify-normal items-center"
//             >
//               {editableFields['password'] ? (
//                   <FaRegSave className="text-cyan-800" />
//               ) : (
//                   <FaRegEdit className="text-cyan-800" />
//               )}
//             </button>
//           </div>
//         </div>
//         </div>
//         <div className="mt-6 flex justify-end space-x-2 text-sm">
//           <button 
//             onClick={onClose} 
//             className="bg-gray-400 text-white px-4 py-1 rounded"
//           >
//             Cancel
//           </button>
//           <button 
//             onClick={handleSave} 
//             className="bg-cyan-800 text-white px-6 py-1 rounded"
//           >
//             {user ? "Save" : "Add"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UsersTableEditModal;