// src/components/admin/EventSetting/ProgramSetting.tsx

import React, { useState, useEffect } from 'react';
// import react icon
import { FaInfoCircle, FaCheckCircle } from 'react-icons/fa';
import { TbLayoutBottombarCollapseFilled, TbLayoutNavbarCollapse } from "react-icons/tb";
import { Tooltip } from 'react-tooltip';
// import custom file 
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';   
import useProgram from '../../../hooks/AdminDashboard/useProgram';
import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
import ProgramAlert from './ProgramCreatedAlert'; 

const ProgramSetting: React.FC = () => {
  // handle custom states
  const { theme } = useTheme();
  const { user } = useAuth();
  const { permissions } = useRoleAccess(user?.email || '');
  const { createProgram } = useProgram();   
  // handle event setting states
  const [isFormExpanded, setIsFormExpanded] = useState(false); 
  const [isUnlimited, setIsUnlimited] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState('rone office');
  const [daysCount, setDaysCount] = useState(0);
  const [showAlert, setShowAlert] = useState(false); 
  const [showSuccess, setShowSuccess] = useState(false); 
  const [onlineConferenceText, setOnlineConferenceText] = useState('');
  const [isBrokerRequired, setIsBrokerRequired] = useState(true);
  const [formValues, setFormValues] = useState({
    programName: '',
    amount: '',
    currency: 'USD',
    lotSize: '0',
    deposit: '0',
    broker: '',
    startDate: '',
    endDate: '',
    time:'',
    points: '',
    description: '',
    redeemNumber: '',
    location: '',
    image: null as File | null,
    slot: '',
    slotType: 'unlimited', 
    slotCount: '', 
    memberType: [] as string[], 
  });

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormValues({ ...formValues, image: e.target.files[0] });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowAlert(true);
  };

  const handleConfirm = async () => {
    const formData = new FormData();
    formData.append('name', formValues.programName);
    formData.append('amount', formValues.amount.toString());
    formData.append('currency', formValues.currency);
    formData.append('lotSize', formValues.lotSize === 'No Lot Size Required' ? '0' : formValues.lotSize);
    formData.append('deposit', formValues.deposit === 'No Deposit Required' ? '0' : formValues.deposit);
    formData.append('broker', isBrokerRequired ? formValues.broker : 'No Broker Required');
    formData.append('startDate', formValues.startDate);
    formData.append('endDate', formValues.endDate);
    formData.append('time', formValues.time);
    formData.append('points', formValues.points.toString());
    formData.append('description', formValues.description);
    formData.append('redeemNumber', isUnlimited ? '0' : formValues.redeemNumber);
    formData.append('status', 'stopped');
    formData.append('location', selectedLocation === 'specify location' ? formValues.location : selectedLocation);
    formData.append('memberType', JSON.stringify(formValues.memberType)); 
    formData.append('slot', formValues.slotType === 'unlimited' ? '-1' : formValues.slotCount);  
    if (formValues.slotType === 'specify') {
      formData.append('slotCount', formValues.slotCount.toString());
    }
    if (formValues.image) {
      formData.append('image', formValues.image);
    }
    if (selectedLocation === 'Online Conference') {
      formData.append('onlineConferenceText', onlineConferenceText);
    }
    await createProgram(formData);
    setShowAlert(false); 
    setShowSuccess(true);
  };

  const calculateDays = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const timeDiff = endDate.getTime() - startDate.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);
    return daysDiff;
  };

  useEffect(() => {
    if (formValues.startDate && formValues.endDate) {
      const days = calculateDays(formValues.startDate, formValues.endDate);
      setDaysCount(days);
    }
  }, [formValues.startDate, formValues.endDate]);

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const currency = e.target.value;
    setFormValues({ ...formValues, currency });
    if (currency === 'FREE') {
      setFormValues({ ...formValues, amount: '0', points: '0' });
    }
  };

  const handleMemberTypeChange = (memberType: string) => {
    setFormValues((prevValues) => {
      const newMemberTypes = prevValues.memberType.includes(memberType)
        ? prevValues.memberType.filter((type) => type !== memberType)
        : [...prevValues.memberType, memberType];
      return { ...prevValues, memberType: newMemberTypes };
    });
  };

  return (
    <div className="w-[850px] ml-4 py-10">
      <div className="flex justify-normal items-center">
        <h2 className={`text-2xl font-bold text-cyan-800
          ${permissions?.eventSetting?.addEvent !== 'enable'
              ? 'text-gray-400 cursor-not-allowed' : ''
          }`}
        >
          Event Setting
        </h2>
        <button
          onClick={() => setIsFormExpanded(!isFormExpanded)}
          className={`text-cyan-800 flex items-center mx-2 
            ${permissions?.eventSetting?.addEvent !== 'enable'
              ? 'text-gray-400 cursor-not-allowed' : ''
            }`}
          disabled={permissions?.eventSetting?.addEvent !== 'enable'} 
        >
          {isFormExpanded ? 
            <TbLayoutNavbarCollapse className="text-2xl" /> :
            <TbLayoutBottombarCollapseFilled className="text-2xl" />
          }
        </button>
      </div>

      {/* event setting form */}
      {isFormExpanded && (
        <div className="p-10 bg-white rounded-lg shadow-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">

              {/* Event Title Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-program-title" place="top">
                  Enter the program title
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-program-title" className="ml-2 mr-2 inline-block" />
                Program Title
              </label>
              <input
                type="text"
                value={formValues.programName}
                onChange={(e) => setFormValues({ ...formValues, programName: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded"
                required
                placeholder="Enter a event title..."
                style={{ width: '100%' }}
              />

              {/* Event Start & End Date Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-event-date" place="top">
                  Select the event date
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-event-date" className="ml-2 mr-2 inline-block" />
                Event Date
              </label>
              <div className="flex space-x-4 w-full">
                <div>
                  <label className="block text-md font-medium mb-1">Start Date</label>
                  <input
                    type="date"
                    value={formValues.startDate}
                    onChange={(e) => setFormValues({ ...formValues, startDate: e.target.value })}
                    className="w-full p-2 border border-gray-300 rounded"
                    required
                    style={{ width: '100%' }}
                  />
                </div>
                <div>
                  <label className="block text-md font-medium mb-1">End Date</label>
                  <input
                    type="date"
                    value={formValues.endDate}
                    onChange={(e) => setFormValues({ ...formValues, endDate: e.target.value })}
                    className="w-full p-2 border border-gray-300 rounded"
                    required
                    style={{ width: '100%' }}
                  />
                  <p className="mt-2 text-center p-1 rounded-full" 
                    style={{ 
                      color: theme.text2Color, 
                      backgroundColor: theme.primaryColor, 
                      fontFamily: theme.fontFamily 
                    }}>{daysCount} days</p>
                </div>
              </div>

              {/* Event Time Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-event-time" place="top">
                  Enter the event time in 24-hour format (Malaysia/KL time zone)
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-event-time" className="ml-2 mr-2 inline-block" />
                Event Time
              </label>
              <input
                type="time"
                value={formValues.time}
                onChange={(e) => setFormValues({ ...formValues, time: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />

              {/* Event Location Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-location" place="top">
                  Select the location
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-location" className="ml-2 mr-2 inline-block" />
                Location
              </label>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="noahs-space"
                    value="Noah's Space"
                    checked={selectedLocation === 'Noah\'s Space'}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="noahs-space">Noah's Space</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="online-meeting"
                    value="Online Conference"
                    checked={selectedLocation === 'Online Conference'}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="online-meeting">Online Conference</label>
                </div>
                {selectedLocation === 'Online Conference' && (
                  <textarea
                    value={onlineConferenceText}
                    onChange={(e) => setOnlineConferenceText(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mt-2"
                    placeholder="Enter online conference or link here..."
                  />
                )}
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="specify-location"
                      value="specify location"
                      checked={selectedLocation === 'specify location'}
                      onChange={(e) => setSelectedLocation(e.target.value)}
                      className="mr-2"
                      style={{ accentColor: theme.primaryColor }}
                    />
                    <label htmlFor="specify-location">Specify Location</label>
                  </div>
                  {selectedLocation === 'specify location' && (
                    <textarea
                      value={formValues.location}
                      onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
                      className="w-full p-2 border border-gray-300 rounded mt-2"
                      placeholder="Enter text or link here..."
                      required
                    />
                  )}
                </div>
              </div>

              {/* Event Fee Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-amount" place="top">
                  Enter the amount
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-amount" className="ml-2 mr-2 inline-block" />
                Event Fee
              </label>
              <div className="flex space-x-2">
                <input
                  type="number"
                  value={formValues.amount}
                  onChange={(e) => setFormValues({ ...formValues, amount: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                  placeholder="Enter a ticket amount..."
                />
                <select
                  value={formValues.currency}
                  onChange={handleCurrencyChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                >
                  <option value="USD">USD</option>
                  <option value="MYR">MYR</option>
                  <option value="FREE">Free</option>
                </select>
              </div>

              {/* Event Requirement Field */}

              {/* Requirement Lotsize Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-requirement" place="top">
                  Specify the Lot Size
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-requirement" className="ml-2 mr-2 inline-block" />
                Lot Size
              </label>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="no-lotsize"
                    value="0"
                    checked={formValues.lotSize === '0'}
                    onChange={() => setFormValues({ ...formValues, lotSize: '0' })}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="no-lotsize">No Lot Size Required</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="specify-lotsize"
                    value="specify"
                    checked={formValues.lotSize !== '0'}
                    onChange={() => setFormValues({ ...formValues, lotSize: '' })}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="specify-lotsize">Specify Lot Size</label>
                  {formValues.lotSize !== '0' && (
                    <input
                      type="number"
                      value={formValues.lotSize}
                      onChange={(e) => setFormValues({ ...formValues, lotSize: e.target.value })}
                      className="w-full p-2 border border-gray-300 rounded ml-4"
                      required
                      placeholder="Enter a lot size..."
                    />
                  )}
                </div>
              </div>

              {/* Requirement Deposit Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-deposit" place="top">
                  Specify the deposit amount
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-deposit" className="ml-2 mr-2 inline-block" />
                Deposit
              </label>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="no-deposit"
                    value="0"
                    checked={formValues.deposit === '0'}
                    onChange={(e) => setFormValues({ ...formValues, deposit: '0' })}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="no-deposit">No Deposit Required</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="specify-deposit"
                    value="specify"
                    checked={formValues.deposit !== '0'}
                    onChange={(e) => setFormValues({ ...formValues, deposit: '' })}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="specify-deposit">Specify Deposit</label>
                  {formValues.deposit !== '0' && (
                    <>
                      <input
                        type="number"
                        value={formValues.deposit}
                        onChange={(e) => setFormValues({ ...formValues, deposit: e.target.value })}
                        className="w-full p-2 border border-gray-300 rounded ml-4"
                        placeholder="Enter a deposit amount..."
                        required
                      />
                      <span className="ml-4">USD</span>
                    </>
                  )}
                </div>
              </div>

              {/* Requirement Broker Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-broker" place="top">
                  Specify the broker requirement
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-broker" className="ml-2 mr-2 inline-block" />
                Broker
              </label>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="no-broker"
                    value="No Broker Required"
                    checked={!isBrokerRequired}
                    onChange={() => {
                      setIsBrokerRequired(false);
                      setFormValues({ ...formValues, broker: 'No Broker Required' });
                    }}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="no-broker">No Broker Required</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="specify-broker"
                    value="Specify Broker"
                    checked={isBrokerRequired}
                    onChange={() => {
                      setIsBrokerRequired(true);
                      setFormValues({ ...formValues, broker: '' });
                    }}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="specify-broker">Specify Broker</label>
                  {isBrokerRequired && (
                    <input
                      type="text"
                      value={formValues.broker}
                      onChange={(e) => setFormValues({ ...formValues, broker: e.target.value })}
                      className="w-full p-2 border border-gray-300 rounded ml-4"
                      required
                      placeholder="Enter a broker name..."
                    />
                  )}
                </div>
              </div>

              {/* Event Redeem Point Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-points" place="top">
                  Enter the points
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-points" className="ml-2 mr-2 inline-block" />
                Points
              </label>
              <input
                type="number"
                value={formValues.points}
                onChange={(e) => setFormValues({ ...formValues, points: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Enter a redeem points..."
                required
              />

              {/* Event Redeem Count Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-redeem" place="top">
                  Specify the number of times a user can redeem
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-redeem" className="ml-2 mr-2 inline-block" />
                Redeem Counts of Times
              </label>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="unlimited"
                    checked={isUnlimited}
                    onChange={() => {
                      setIsUnlimited(true);
                      setFormValues({ ...formValues, redeemNumber: '0' }); 
                    }}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="unlimited">Unlimited</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="specify"
                    checked={!isUnlimited}
                    onChange={() => {
                      setIsUnlimited(false);
                      setFormValues({ ...formValues, redeemNumber: '' }); 
                    }}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="specify">Specify Count</label>
                  {!isUnlimited && (
                    <input
                      type="number"
                      value={formValues.redeemNumber}
                      onChange={(e) => setFormValues({ ...formValues, redeemNumber: e.target.value })}
                      className="w-full p-2 border border-gray-300 rounded ml-4"
                      placeholder="Enter a specify redeem count..."
                      required
                    />
                  )}
                </div>
              </div>

              {/* Event member allowed Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-member-type" place="top">
                  Select the member type allowed
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-member-type" className="ml-2 mr-2 inline-block" />
                Allow Member Type
              </label>
              <div className="flex flex-col space-y-2">
                {[
                  "R One Guest",
                  "R One Basic",
                  "R One Member",
                  "R One Dealer",
                  "R One Trader Hub",
                  "R One Elite Trader",
                  "R One Master Trader",
                  "R One Certified Trader"
                ].map((type) => (
                  <div className="flex items-center" key={type}>
                    <input
                      type="checkbox"
                      id={type}
                      value={type}
                      checked={formValues.memberType.includes(type)}
                      onChange={() => handleMemberTypeChange(type)}
                      className="mr-2"
                      style={{ accentColor: theme.primaryColor }}
                    />
                    <label htmlFor={type}>{type}</label>
                  </div>
                ))}
              </div>

              {/* Event slot Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-slot" place="top">
                  Enter the slot limit
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-slot" className="ml-2 mr-2 inline-block" />
                Slot
              </label>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="unlimited-slot"
                    value="unlimited"
                    checked={formValues.slotType === 'unlimited'}
                    onChange={(e) => setFormValues({ ...formValues, slotType: e.target.value })}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="unlimited-slot">Unlimited</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="specify-slot"
                    value="specify"
                    checked={formValues.slotType === 'specify'}
                    onChange={(e) => setFormValues({ ...formValues, slotType: e.target.value })}
                    className="mr-2"
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <label htmlFor="specify-slot">Specify Count</label>
                  {formValues.slotType === 'specify' && (
                    <input
                      type="number"
                      value={formValues.slotCount}
                      onChange={(e) => setFormValues({ ...formValues, slotCount: e.target.value })}
                      placeholder="Enter a slot count..."
                      className="w-full p-2 border border-gray-300 rounded ml-4"
                      required
                    />
                  )}
                </div>
              </div>

              {/* Event description Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-description" place="top">
                  Enter the description
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-description" className="ml-2 mr-2 inline-block" />
                Description
              </label>
              <textarea
                value={formValues.description}
                onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Enter the description..."
                required
              />

              {/* Event Image upload Field */}
              <label className="block text-md font-medium mb-1">
                <Tooltip id="tooltip-image" place="top">
                  Upload the event image
                </Tooltip>
                <FaInfoCircle data-tooltip-id="tooltip-image" className="ml-2 mr-2 inline-block" />
                Event Image
              </label>
              <input
                type="file"
                onChange={handleImageChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>

            {/* Create Event Button */}
            <div className="mt-14 text-right">
              <button
                type="submit"
                className={`px-6 py-2 rounded 
                  ${permissions?.eventSetting?.addEvent !== 'enable'
                    ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-cyan-800 text-white'
                  }`}
                disabled={permissions?.eventSetting?.addEvent !== 'enable'} 
              >
                Create
              </button>
            </div>
          </form>
        </div>
      )}
      {showAlert && (
        <ProgramAlert
          programDetails={formValues}
          selectedLocation={selectedLocation}  
          onConfirm={handleConfirm}
          onCancel={() => setShowAlert(false)}
        />
      )}
      {showSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <FaCheckCircle className="text-green-500 text-4xl mb-2" />
            <h3 className="text-lg font-bold mb-2" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
              Event Created Successfully
            </h3>
            <p className="text-sm text-gray-600">Your event has been created and added to the program list.</p>
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
              onClick={() => setShowSuccess(false)}
              style={{ color: theme.text2Color, fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgramSetting;

// // update code --> 03 july 2024
// // src/components/admin/EventSetting/ProgramSetting.tsx

// import React, { useState, useEffect } from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import useProgram from '../../../hooks/AdminDashboard/useProgram';
// import { FaInfoCircle } from 'react-icons/fa';
// import { Tooltip } from 'react-tooltip';
// import ProgramAlert from './ProgramCreatedAlert';  // Import the new component
// import { FaCheckCircle } from 'react-icons/fa'; // Import the new icon

// const ProgramSetting: React.FC = () => {
//   const { theme } = useTheme();
//   const { createProgram } = useProgram();
//   const [isUnlimited, setIsUnlimited] = useState(true);
//   const [selectedLocation, setSelectedLocation] = useState('rone office');
//   const [daysCount, setDaysCount] = useState(0);
//   const [showAlert, setShowAlert] = useState(false); // Add state for alert modal
//   const [showSuccess, setShowSuccess] = useState(false); // Add state for success message
//   const [onlineConferenceText, setOnlineConferenceText] = useState('');
//   const [formValues, setFormValues] = useState({
//     programName: '',
//     amount: '',
//     currency: 'USD',
//     startDate: '',
//     endDate: '',
//     time:'',
//     points: '',
//     description: '',
//     redeemNumber: '',
//     location: '',
//     image: null as File | null,
//     slot: '',
//     slotType: 'unlimited', 
//     slotCount: '', 
//     memberType: [] as string[], 
//   });

//   const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.files && e.target.files[0]) {
//       setFormValues({ ...formValues, image: e.target.files[0] });
//     }
//   };

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     setShowAlert(true); // Show the alert modal
//   };

//   const handleConfirm = async () => {
//     const formData = new FormData();
//     formData.append('name', formValues.programName);
//     formData.append('amount', formValues.amount.toString());
//     formData.append('currency', formValues.currency);
//     formData.append('startDate', formValues.startDate);
//     formData.append('endDate', formValues.endDate);
//     formData.append('time', formValues.time);
//     formData.append('points', formValues.points.toString());
//     formData.append('description', formValues.description);
//     formData.append('redeemNumber', isUnlimited ? '0' : formValues.redeemNumber); // Updated
//     formData.append('status', 'stopped');
//     formData.append('location', selectedLocation === 'specify location' ? formValues.location : selectedLocation);
//     formData.append('memberType', JSON.stringify(formValues.memberType)); // Convert to JSON string
//     formData.append('slot', formValues.slotType === 'unlimited' ? '-1' : formValues.slotCount);  // Ensure slot is set correctly
//     if (formValues.slotType === 'specify') {
//       formData.append('slotCount', formValues.slotCount.toString());  // Add this line
//     }
//     if (formValues.image) {
//       formData.append('image', formValues.image);
//     }
//     if (selectedLocation === 'Online Conference') {
//       formData.append('onlineConferenceText', onlineConferenceText);
//     }
//     await createProgram(formData);
//     setShowAlert(false); // Close the alert modal
//     setShowSuccess(true); // Show success message
//   };

//   const calculateDays = (start: string, end: string) => {
//     const startDate = new Date(start);
//     const endDate = new Date(end);
//     const timeDiff = endDate.getTime() - startDate.getTime();
//     const daysDiff = timeDiff / (1000 * 3600 * 24);
//     return daysDiff;
//   };

//   useEffect(() => {
//     if (formValues.startDate && formValues.endDate) {
//       const days = calculateDays(formValues.startDate, formValues.endDate);
//       setDaysCount(days);
//     }
//   }, [formValues.startDate, formValues.endDate]);

//   const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const currency = e.target.value;
//     setFormValues({ ...formValues, currency });
//     if (currency === 'FREE') {
//       setFormValues({ ...formValues, amount: '0', points: '0' });
//     }
//   };

//   const handleMemberTypeChange = (memberType: string) => {
//     setFormValues((prevValues) => {
//       const newMemberTypes = prevValues.memberType.includes(memberType)
//         ? prevValues.memberType.filter((type) => type !== memberType)
//         : [...prevValues.memberType, memberType];
//       return { ...prevValues, memberType: newMemberTypes };
//     });
//   };

//   return (
//     <div className="w-[850px] ml-4 py-10">
//       <h2 className="text-2xl font-bold mb-4" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//         Program Setting
//       </h2>
//       <div className="p-10 bg-white rounded-lg shadow-md">
//         <form onSubmit={handleSubmit}>
//           <div className="grid grid-cols-2 gap-4">
//             <label className="block text-md font-medium mb-1">
//               <Tooltip id="tooltip-program-title" place="top">
//                 Enter the program title
//               </Tooltip>
//               <FaInfoCircle data-tooltip-id="tooltip-program-title" className="ml-2 mr-2 inline-block" />
//               Program Title
//             </label>
//             <input
//               type="text"
//               value={formValues.programName}
//               onChange={(e) => setFormValues({ ...formValues, programName: e.target.value })}
//               className="w-full p-2 border border-gray-300 rounded"
//               required
//               style={{ width: '90%' }}
//             />
//             <label className="block text-md font-medium mb-1">
//               <Tooltip id="tooltip-event-date" place="top">
//                 Select the event date
//               </Tooltip>
//               <FaInfoCircle data-tooltip-id="tooltip-event-date" className="ml-2 mr-2 inline-block" />
//               Event Date
//             </label>
//             <div className="flex space-x-4">
//               <div>
//                 <label className="block text-md font-medium mb-1">Start Date</label>
//                 <input
//                   type="date"
//                   value={formValues.startDate}
//                   onChange={(e) => setFormValues({ ...formValues, startDate: e.target.value })}
//                   className="w-full p-2 border border-gray-300 rounded"
//                   required
//                 />
//               </div>
//               <div>
//                 <label className="block text-md font-medium mb-1">End Date</label>
//                 <input
//                   type="date"
//                   value={formValues.endDate}
//                   onChange={(e) => setFormValues({ ...formValues, endDate: e.target.value })}
//                   className="w-full p-2 border border-gray-300 rounded"
//                   required
//                 />
//                 <p className="mt-2 text-center p-1 rounded-full" 
//                   style={{ 
//                     color: theme.text2Color, 
//                     backgroundColor: theme.primaryColor, 
//                     fontFamily: theme.fontFamily 
//                   }}>{daysCount} days</p>
//               </div>
//             </div>
//             <label className="block text-md font-medium mb-1">
//               <Tooltip id="tooltip-event-time" place="top">
//                 Enter the event time in 24-hour format (Malaysia/KL time zone)
//               </Tooltip>
//               <FaInfoCircle data-tooltip-id="tooltip-event-time" className="ml-2 mr-2 inline-block" />
//               Event Time
//             </label>
//             <input
//               type="time"
//               value={formValues.time}
//               onChange={(e) => setFormValues({ ...formValues, time: e.target.value })}
//               className="w-full p-2 border border-gray-300 rounded"
//               required
//             />
//             <label className="block text-md font-medium mb-1">
//               <Tooltip id="tooltip-location" place="top">
//                 Select the location
//               </Tooltip>
//               <FaInfoCircle data-tooltip-id="tooltip-location" className="ml-2 mr-2 inline-block" />
//               Location
//             </label>
//             <div className="flex flex-col space-y-2">
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="noahs-space"
//                   value="Noah's Space"
//                   checked={selectedLocation === 'Noah\'s Space'}
//                   onChange={(e) => setSelectedLocation(e.target.value)}
//                   className="mr-2"
//                   style={{ accentColor: theme.primaryColor }}
//                 />
//                 <label htmlFor="noahs-space">Noah's Space</label>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="online-meeting"
//                   value="Online Conference"
//                   checked={selectedLocation === 'Online Conference'}
//                   onChange={(e) => setSelectedLocation(e.target.value)}
//                   className="mr-2"
//                   style={{ accentColor: theme.primaryColor }}
//                 />
//                 <label htmlFor="online-meeting">Online Conference</label>
//               </div>
//               {selectedLocation === 'Online Conference' && (
//                 <textarea
//                   value={onlineConferenceText}
//                   onChange={(e) => setOnlineConferenceText(e.target.value)}
//                   className="w-full p-2 border border-gray-300 rounded mt-2"
//                   placeholder="Enter online conference or link here..."
//                 />
//               )}
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="specify-location"
//                   value="specify location"
//                   checked={selectedLocation === 'specify location'}
//                   onChange={(e) => setSelectedLocation(e.target.value)}
//                   className="mr-2"
//                   style={{ accentColor: theme.primaryColor }}
//                 />
//                 <label htmlFor="specify-location">Specify Location</label>
//                 {selectedLocation === 'specify location' && (
//                   <textarea
//                     value={formValues.location}
//                     onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
//                     className="w-full p-2 border border-gray-300 rounded mt-2"
//                     placeholder="Enter text or link here..."
//                     required
//                   />
//                 )}
//               </div>
//             </div>
//             <label className="block text-md font-medium mb-1">
//               <Tooltip id="tooltip-amount" place="top">
//                 Enter the amount
//               </Tooltip>
//               <FaInfoCircle data-tooltip-id="tooltip-amount" className="ml-2 mr-2 inline-block" />
//               Amount
//             </label>
//             <div className="flex space-x-2">
//               <input
//                 type="number"
//                 value={formValues.amount}
//                 onChange={(e) => setFormValues({ ...formValues, amount: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               />
//               <select
//                 value={formValues.currency}
//                 onChange={handleCurrencyChange}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               >
//                 <option value="USD">USD</option>
//                 <option value="MYR">MYR</option>
//                 <option value="FREE">Free</option>
//               </select>
//             </div>
//             <label className="block text-md font-medium mb-1">
//               <Tooltip id="tooltip-points" place="top">
//                 Enter the points
//               </Tooltip>
//               <FaInfoCircle data-tooltip-id="tooltip-points" className="ml-2 mr-2 inline-block" />
//               Points
//             </label>
//             <input
//               type="number"
//               value={formValues.points}
//               onChange={(e) => setFormValues({ ...formValues, points: e.target.value })}
//               className="w-full p-2 border border-gray-300 rounded"
//               required
//             />
//             <label className="block text-md font-medium mb-1">
//               <Tooltip id="tooltip-redeem" place="top">
//                 Specify the number of times a user can redeem
//               </Tooltip>
//               <FaInfoCircle data-tooltip-id="tooltip-redeem" className="ml-2 mr-2 inline-block" />
//               Redeem Counts of Times
//             </label>
//             <div className="flex flex-col space-y-2">
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="unlimited"
//                   checked={isUnlimited}
//                   onChange={() => {
//                     setIsUnlimited(true);
//                     setFormValues({ ...formValues, redeemNumber: '0' }); // Set redeemNumber to '0' for unlimited
//                   }}
//                   className="mr-2"
//                   style={{ accentColor: theme.primaryColor }}
//                 />
//                 <label htmlFor="unlimited">Unlimited</label>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="specify"
//                   checked={!isUnlimited}
//                   onChange={() => {
//                     setIsUnlimited(false);
//                     setFormValues({ ...formValues, redeemNumber: '' }); // Clear redeemNumber for specify count
//                   }}
//                   className="mr-2"
//                   style={{ accentColor: theme.primaryColor }}
//                 />
//                 <label htmlFor="specify">Specify Count</label>
//                 {!isUnlimited && (
//                   <input
//                     type="number"
//                     value={formValues.redeemNumber}
//                     onChange={(e) => setFormValues({ ...formValues, redeemNumber: e.target.value })}
//                     className="w-full p-2 border border-gray-300 rounded ml-4"
//                     required
//                   />
//                 )}
//               </div>
//             </div>
//             <label className="block text-md font-medium mb-1">
//               <Tooltip id="tooltip-member-type" place="top">
//                 Select the member type allowed
//               </Tooltip>
//               <FaInfoCircle data-tooltip-id="tooltip-member-type" className="ml-2 mr-2 inline-block" />
//               Allow Member Type
//             </label>
//             <div className="flex flex-col space-y-2">
//               {[
//                 "Trader Hub Member",
//                 "R One Member",
//                 "R One Dealer",
//                 "R One Elite Trader",
//                 "R One Master Trader",
//                 "R One Certified Trader"
//               ].map((type) => (
//                 <div className="flex items-center" key={type}>
//                   <input
//                     type="checkbox"
//                     id={type}
//                     value={type}
//                     checked={formValues.memberType.includes(type)}
//                     onChange={() => handleMemberTypeChange(type)}
//                     className="mr-2"
//                     style={{ accentColor: theme.primaryColor }}
//                   />
//                   <label htmlFor={type}>{type}</label>
//                 </div>
//               ))}
//             </div>
//             <label className="block text-md font-medium mb-1">
//               <Tooltip id="tooltip-slot" place="top">
//                 Enter the slot limit
//               </Tooltip>
//               <FaInfoCircle data-tooltip-id="tooltip-slot" className="ml-2 mr-2 inline-block" />
//               Slot
//             </label>
//             <div className="flex flex-col space-y-2">
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="unlimited-slot"
//                   value="unlimited"
//                   checked={formValues.slotType === 'unlimited'}
//                   onChange={(e) => setFormValues({ ...formValues, slotType: e.target.value })}
//                   className="mr-2"
//                   style={{ accentColor: theme.primaryColor }}
//                 />
//                 <label htmlFor="unlimited-slot">Unlimited</label>
//               </div>
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="specify-slot"
//                   value="specify"
//                   checked={formValues.slotType === 'specify'}
//                   onChange={(e) => setFormValues({ ...formValues, slotType: e.target.value })}
//                   className="mr-2"
//                   style={{ accentColor: theme.primaryColor }}
//                 />
//                 <label htmlFor="specify-slot">Specify Count</label>
//                 {formValues.slotType === 'specify' && (
//                   <input
//                     type="number"
//                     value={formValues.slotCount}
//                     onChange={(e) => setFormValues({ ...formValues, slotCount: e.target.value })}
//                     className="w-full p-2 border border-gray-300 rounded ml-4"
//                     required
//                   />
//                 )}
//               </div>
//             </div>
//             <label className="block text-md font-medium mb-1">
//               <Tooltip id="tooltip-description" place="top">
//                 Enter the description
//               </Tooltip>
//               <FaInfoCircle data-tooltip-id="tooltip-description" className="ml-2 mr-2 inline-block" />
//               Description
//             </label>
//             <textarea
//               value={formValues.description}
//               onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
//               className="w-full p-2 border border-gray-300 rounded"
//               required
//             />
//             <label className="block text-md font-medium mb-1">
//               <Tooltip id="tooltip-image" place="top">
//                 Upload the event image
//               </Tooltip>
//               <FaInfoCircle data-tooltip-id="tooltip-image" className="ml-2 mr-2 inline-block" />
//               Event Image
//             </label>
//             <input
//               type="file"
//               onChange={handleImageChange}
//               className="w-full p-2 border border-gray-300 rounded"
//               required
//             />
//           </div>
//           <div className="mt-14 text-right">
//             <button
//               type="submit"
//               className="px-6 py-2 bg-blue-500 text-white rounded"
//               style={{ 
//                 color: theme.text2Color, 
//                 fontFamily: theme.fontFamily, 
//                 backgroundColor: theme.primaryColor 
//               }}
//             >
//               Create
//             </button>
//           </div>
//         </form>
//       </div>
//       {showAlert && (
//         <ProgramAlert
//           programDetails={formValues}
//           selectedLocation={selectedLocation}  // Pass selectedLocation as prop
//           onConfirm={handleConfirm}
//           onCancel={() => setShowAlert(false)}
//         />
//       )}
//       {showSuccess && (
//         <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
//           <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
//             <FaCheckCircle className="text-green-500 text-4xl mb-2" /> {/* Add success icon */}
//             <h3 className="text-lg font-bold mb-2" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//               Event Created Successfully
//             </h3>
//             <p className="text-sm text-gray-600">Your event has been created and added to the program list.</p>
//             <button
//               className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
//               onClick={() => setShowSuccess(false)}
//               style={{ color: theme.text2Color, fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ProgramSetting;