// src/hooks/useGeoIPCheck.tsx

import { useState, useEffect } from 'react';
import { useEnv } from '../context/EnvContext';

interface GeoData {
  country_code: string;
  ip?: string;
}

const useGeoIPCheck = () => {
  const [geoData, setGeoData] = useState<GeoData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { apiUrl } = useEnv();

  useEffect(() => {
    const fetchGeoData = async () => {
      try {
        const response = await fetch(`${apiUrl}/geoip`);
        if (response.ok) {
          const data = await response.json();
          // console.log('GeoIP Data Received:', data); 
          setGeoData(data);
        } else {
          console.error('Failed to fetch geo IP data');
        }
      } catch (error) {
        console.error('Error during geo IP fetch:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGeoData();
  }, [apiUrl]);

  return { geoData, isLoading };
};

export default useGeoIPCheck;


