// src/components/client/MiniApp/App01_MyTicketRegisteration.tsx

import React, { useState, useEffect } from 'react';
import { FaClipboard, FaArrowCircleLeft, FaTimes, FaMapMarkerAlt, FaEye, FaEyeSlash, FaExclamationCircle } from 'react-icons/fa';
import { IoTicketSharp } from "react-icons/io5";

// import hooks
import { Event } from '../../../hooks/MiniApp/useEventData';
import useEventData, { formatAmount } from '../../../hooks/MiniApp/useEventData';
import useEventRegistrationSubmit from '../../../hooks/MiniApp/useEventRegistrationSubmit';
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
import useEventSlot from '../../../hooks/MiniApp/useEventSlot';

// import context 
import { useEventRegTerms } from '../../../context/EventRegTermContext';
import { useTheme } from '../../../context/ThemeContext';
import MyTicketAlert from './App_AlertMessage';
import officeImg from '../../../assets/miniApp/NoahSpace_img.png';
import ServerNameImg from '../../../assets/miniApp/broker_server_name.png'
interface RegistrationProps {
  event: Event;
  onBack: () => void;
}

interface AlertState {
  type: 'success' | 'error' | 'confirm';
  message: string;
  visible: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const MyTicketRegistration: React.FC<RegistrationProps> = ({ event, onBack }) => {
  // handle import custom state
  const { theme } = useTheme();
  const { user } = useUserDashboard();
  const terms = useEventRegTerms();
  const { getSlotMessage } = useEventData();
  const { submitRegistration, loading } = useEventRegistrationSubmit();
  const { slot, fetchSlot } = useEventSlot(event.name);
  // handle custom state
  const [ticketId, setTicketId] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [receipts, setReceipts] = useState<File[]>([]);
  const [notes, setNotes] = useState('');
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [fileInputs] = useState([0]);
  // const [fileInputs, setFileInputs] = useState([0]);
  const [accountId, setAccountId] = useState('');
  const [accountPassword, setAccountPassword] = useState('');
  const [accountServer, setAccountServer] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [isTradeAccountIdTouched, setIsTradeAccountIdTouched] = useState(false);
  const [isAccountServerTouched, setIsAccountServerTouched] = useState(false);

  // Disabled attribute value based on conditions
  const allRequirementsEmpty = event.lotSize === 0 && event.deposit === 0 && event.broker === "No Broker Required";
  // Remove isFreeTicker condition
  const isButtonDisabled = !termsAccepted || (!allRequirementsEmpty && (!paymentMethod || receipts.length === 0));
  const isFieldDisabled = allRequirementsEmpty;
  
  const [alert, setAlert] = useState<AlertState>({
    type: 'success',
    message: '',
    visible: false,
    onClose: () => {},
  });

  useEffect(() => {
    const generateTicketId = () => {
      const currentDate = new Date();
      const year = currentDate.getFullYear().toString().slice(-2);
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      const randomPart = Math.random().toString(36).substr(2, 6).toUpperCase();
      return `${randomPart}-${year}${month}${day}`;
    };
    setTicketId(generateTicketId());
  }, []);

  const handleBuyTicketClick = async () => {
    if (slot === 0) {
      alertUser('error', 'Ticket is not available for purchase.');
      return;
    }
  
    if (!allRequirementsEmpty && (!paymentMethod || receipts.length === 0)) {
      alertUser('error', 'Please fill all required fields.');
      return;
    }
  
    setAlert({
      type: 'confirm',
      message: allRequirementsEmpty ? 'Are you sure you want to register for this event?' : 'Are you sure you want to buy the ticket?',
      visible: true,
      onClose: () => setAlert((prevState) => ({ ...prevState, visible: false })),
      onConfirm: async () => {
        const result = await submitRegistration({
          ticketId,
          eventTitle: event.name,
          location: event.location,
          email: user?.email || '',
          username: user?.username || '',
          appointmentDate: event.startDate,
          time: event.time,
          paymentMethod: allRequirementsEmpty ? 'Free' : paymentMethod,
          amount: event.amount,
          points: event.points,
          accountId,
          accountPassword,
          accountServer,
          receipts: receipts,
          termsAccepted,
          notes,
          image: event.image,
        });
  
        if (result.success) {
          alertUser('success', 'Registration submitted successfully. Please wait for approval.');
          fetchSlot();
        } else {
          alertUser('error', `Failed to submit ticket registration: ${result.message}`);
        }
      },
      onCancel: () => setAlert((prevState) => ({ ...prevState, visible: false })),
    });
  };
  
  const handleShowLocation = () => {
    setLocationModalVisible(true);
  };
  
  const handleCloseLocationModal = () => {
    setLocationModalVisible(false);
  };  

  const handleReceiptUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newReceipts = [...receipts];
    if (e.target.files && e.target.files[0]) {
      newReceipts[index] = e.target.files[0];
      setReceipts(newReceipts);
    }
  }; 

  // const addFileInput = () => {
  //   if (fileInputs.length < 5) {
  //     setFileInputs([...fileInputs, fileInputs.length]);
  //   }
  // };  

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    alertUser('success', 'Copied to clipboard!');
  };

  const handleTermsCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };
  
  const handleTermsClick = () => {
    setTermsModalVisible(true);
  };
  
  const handleCloseTermsModal = () => {
    setTermsModalVisible(false);
  };

  const alertUser = (type: 'success' | 'error', message: string) => {
    setAlert({
      type,
      message,
      visible: true,
      onClose: () => setAlert((prevState) => ({ ...prevState, visible: false })),
    });
  };

  const parseDescription = (description: string) => {
    const regex = /\[(.*?)\]/g;
    const items = [];
    let match;
    while ((match = regex.exec(description)) !== null) {
      items.push(match[1]);
    }
    return items;
  };

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    <div>
      {alert.visible && <MyTicketAlert {...alert} />}
      <div className="w-auto h-auto max-h-[600px] px-2 overflow-y-auto 
            xl:h-[560px] 
            lg:h-[520px] 
            md:text-md md:h-[560px]
            tablet-landscape-max:h-[600px]
            sm:h-[550px] sm:p-1 sm:text-sm 
            xs:h-[500px] xs:px-1 xs:text-xs"
      >
        <p className="mb-6 font-semibold">{event.description.split('[')[0]}</p>
        <div className="mb-6">
          <ul className="list-disc list-inside">
            {parseDescription(event.description).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <form>
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="ticket-id">Ticket ID</label>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="ticket-id" type="text" value={ticketId} disabled />
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="event-title">Event Title</label>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="event-title" type="text" value={event.name} disabled />
          </div>
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <label className="block mb-2 font-semibold" htmlFor="event-location">Venue</label>
              {event.location === "Noah's Space" && (
                <button
                  type="button"
                  className="flex items-center text-blue-500"
                  onClick={handleShowLocation}
                  style={styles.title}
                >
                  <FaMapMarkerAlt className="mr-2" />
                  Show Location
                </button>
              )}
            </div>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="event-location" type="text" value={event.location} disabled />
          </div>
          {locationModalVisible && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
              <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Location Details</h2>
                  <button onClick={handleCloseLocationModal} className="text-gray-500 hover:text-gray-800">
                    <FaTimes className="w-6 h-6" />
                  </button>
                </div>
                <div>
                  <div className="mb-4">
                    <h3 className="font-semibold">Address:</h3>
                    <p>Unit 3-2-2, Kompleks Kantonmen Prima, 698, Jln Ipoh, Taman Bamboo, 51200 Kuala Lumpur, Federal Territory of Kuala Lumpur</p>
                  </div>
                  <div className="mb-4">
                    <h3 className="font-semibold">Google Maps:</h3>
                    <div className="flex items-center">
                      <a 
                        href="https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-blue-500 underline"
                        style={{ color: theme.primaryColor }}
                      >
                        https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7
                      </a>
                    </div>
                  </div>
                  <div>
                    <img src={officeImg} alt="Noah's Space location" className="w-full h-auto" />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="user-name">Name</label>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="user-name" type="text" value={user?.username || ''} disabled />
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="user-email">Email</label>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="user-email" type="email" value={user?.email || ''} disabled />
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="event-date">Event Date</label>
            <input
              type="text"
              id="event-date"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
              value={`${new Date(event.startDate).toLocaleDateString()}`}
              disabled
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="event-time">Event Time</label>
            <input
              type="text"
              id="event-time"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
              value={`${new Date(`1970-01-01T${event.time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`}
              disabled
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="slot">Current Slot</label>
            <div id="slot">{getSlotMessage(event.slot)}</div>
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="amount">Amount</label>
            <input 
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
              id="amount" 
              type="text" 
              value={event.amount === 0 ? 'Free of Charges' : `${event.currency} ${formatAmount(event.amount)}`} 
              disabled 
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="points">Points</label>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="points" type="text" value={event.points} disabled />
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="requirements">Register Requirement</label>
            <ul className="list-disc list-inside">
              <li>Lot Size: {event.lotSize === 0 ? 'No lot size required' : event.lotSize}</li>
              <li>Deposit: {event.deposit === 0 ? 'No deposit required' : `${formatAmount(event.deposit)} USD`}</li>
              <li>Broker: {event.broker}</li>
            </ul>
          </div>

          <div className="mb-4">
            <div className="flex items-center justify-normal">
              <div className="flex items-center justify-normal">
                <label 
                  className="block mb-2 font-semibold" 
                  htmlFor="trade-account-id"
                >
                  Account Number
                </label>
                <FaExclamationCircle className="mb-2 mx-2"/>
                <div className="text-xs text-rose-600 mb-2">
                  ( This is trading account number )
                </div>
              </div>
            </div>
            <input
              type="text"
              id="trade-account-id"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 "
              value={accountId}
              placeholder="Enter your trading account number..."
              onChange={(e) => setAccountId(e.target.value)}
              onBlur={() => setIsTradeAccountIdTouched(true)}
              required={isTradeAccountIdTouched}
              disabled={isFieldDisabled}
            />
          </div>

          <div className="mb-4">
            <div className="flex items-center justify-normal">
              <label 
                className="block mb-2 font-semibold" 
                htmlFor="account-password"
              >
                Account Password (Read-Only)
              </label>
              <FaExclamationCircle className="mb-2 mx-2"/>
              <div className="text-xs text-rose-600 mb-2">
                ( This is trading account investor "read-only" password )
              </div>
            </div>
            <div className="relative">
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                id="account-password"
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
                value={accountPassword}
                placeholder="Enter your trading account investor (read-only) password..."
                onChange={(e) => setAccountPassword(e.target.value)}
                required
                disabled={isFieldDisabled}
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div className="flex items-center justify-normal">
              <label 
                className="block mb-2 font-semibold" 
                htmlFor="account-server"
              >
                Account Server
              </label>
              <div className="relative group mx-2">
                <div className="flex justify-between items-center">
                  <FaExclamationCircle 
                    className="text-xl cursor-pointer w-4 h-4 mb-2"
                    style={styles.title}
                    aria-hidden="true"
                  />
                </div>
                {/* Tooltip */}
                <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs"
                  style={styles.button}>
                  You can find your account server details in your trading platform under the account information section.
                  <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
                </div>
              </div>
              <div className="text-xs text-rose-600 mb-2">
                ( This is registered trading account server. Hover over "<span className="font-bold">!</span>" for instructions. )
              </div>
            </div>
            <input
              type="text"
              id="account-server"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
              value={accountServer}
              onChange={(e) => setAccountServer(e.target.value)}
              onBlur={() => setIsAccountServerTouched(true)}
              required={isAccountServerTouched}
              disabled={isFieldDisabled}
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2 font-semibold">
              Pay Method
            </label>
            <div 
              className="flex items-center mb-2"
              onClick={() => setPaymentMethod('')}
            >
              <input
                type="radio"
                id="bank-transfer"
                name="payment-method"
                value="Bank Transfer"
                checked={paymentMethod === 'Bank Transfer'}
                onChange={(e) => setPaymentMethod(e.target.value)}
                style={{ accentColor: theme.primaryColor }}
                disabled={isFieldDisabled}
              />
              <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
            </div>
            {paymentMethod === 'Bank Transfer' && (
              <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
                <span>Bank Account</span><br/> 
                <div className="flex justify-between items-center">
                  <div>
                    <li>Please proceed with the payment through USDT (cryptocurrency).</li>
                    {/* <li>FINOX TRADE MARKET SDN. BHD.</li>
                    <li>Hong leong bank</li>
                    <li>23600590742</li> */}
                  </div>
                  <FaClipboard 
                    className="ml-2 cursor-pointer w-6 h-6"
                    style={{ color: theme.primaryColor }} 
                    onClick={() => copyToClipboard("23600590742")} 
                  />
                </div>
              </div>
            )}
            <div 
              className="flex items-center"
              onClick={() => setPaymentMethod('')}
            >
              <input
                type="radio"
                id="usdt"
                name="payment-method"
                value="USDT"
                checked={paymentMethod === 'USDT'}
                onChange={(e) => setPaymentMethod(e.target.value)}
                style={{ accentColor: theme.primaryColor }}
                disabled={isFieldDisabled}
              />
              <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
            </div>
            {paymentMethod === 'USDT' && (
              <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
                <span>Wallet Address:</span><br/> 
                <div className="flex justify-between items-center">
                  <div>
                    <li>Tron (TRC20) Network</li>
                    <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
                  </div>
                  <FaClipboard 
                    className="ml-2 cursor-pointer w-6 h-6"
                    style={{ color: theme.primaryColor }} 
                    onClick={() => copyToClipboard("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")}
                     
                  />
                </div>
              </div>
            )}
          </div>
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <div className="flex justify-normal items-center">
                <label 
                  className="block font-semibold" 
                  htmlFor="payment-receipts"
                >
                  Payment Receipts
                </label>
                <FaExclamationCircle className="mx-2"/>
                <div className="text-xs text-rose-600">
                  ( Please upload your payment receipt in "png" or "jpg" format only. )
                </div>
              </div>
            </div>
            {fileInputs.map((input, index) => (
              <div key={index} className="mb-2">
                <input
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
                  type="file"
                  onChange={(e) => handleReceiptUpload(e, index)}
                  disabled={isFieldDisabled}
                />
              </div>
            ))}
          </div>
          {termsModalVisible && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
              <div className="bg-white p-10 rounded-lg max-w-lg w-full h-[700px] md:h-[600px] sm:h-[500px]">
                <div className="flex justify-between items-center mb-4 sticky top-0 bg-white z-10">
                  <h2 className="text-xl font-semibold">Terms and Conditions</h2>
                  <button onClick={handleCloseTermsModal} className="text-gray-500 hover:text-gray-800">
                    <FaTimes className="w-6 h-6" />
                  </button>
                </div>
                <div className="overflow-y-auto border-2 h-[calc(800px-48px)] md:h-[calc(500px-48px)] sm:h-[calc(500px-48px)]">
                  {terms}
                </div>
              </div>
            </div>
          )}
          <div className="mb-6">
            <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
            <textarea className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
              id="notes" 
              placeholder="Leave any notes here (optional)..." 
              value={notes} 
              onChange={(e) => setNotes(e.target.value)}
              disabled={isFieldDisabled}
            />
          </div>
          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={handleTermsCheckboxChange}
                className="mr-2 h-6 w-6 md:w-4 sm:w-4 xs:w-4"
                style={{ accentColor: theme.primaryColor, color: theme.textColor}}
              />
              <span 
                onClick={handleTermsClick} 
                className="cursor-pointer text-blue-500 underline"
                style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
                I have read and understand the terms and conditions
              </span>
            </label>
          </div>
        </form>
      </div>
      <div className="flex justify-between items-center mt-4 space-x-4">
        <div className="flex justify-between items-center w-full sm:text-sm xs:text-xs">
          <button 
            onClick={onBack} 
            className="px-4 py-2 rounded-md flex items-center justify-center"
            style={styles.button}>
            <FaArrowCircleLeft />
            <div className="ml-2">Back to Details</div>
          </button>
          <button 
            type="button" 
            className="px-4 py-2 rounded-md flex items-center justify-center"
            onClick={handleBuyTicketClick}
            disabled={loading || isButtonDisabled}
            style={{ 
              backgroundColor: isButtonDisabled ? 'gray' : theme.primaryColor, 
              color: theme.text2Color, 
              fontFamily: theme.fontFamily 
            }}
          >
            <IoTicketSharp />
            <div className="ml-2">Buy Ticket</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyTicketRegistration;


// // udpate code --> 17 Aug 2024
// // src/components/client/MiniApp/App01_MyTicketRegisteration.tsx

// import React, { useState, useEffect } from 'react';
// import { FaClipboard, FaArrowCircleLeft, FaTimes, FaMapMarkerAlt, FaEye, FaEyeSlash, FaExclamationCircle } from 'react-icons/fa';
// import { IoTicketSharp } from "react-icons/io5";

// // import hooks
// import { Event } from '../../../hooks/MiniApp/useEventData';
// import useEventData, { formatAmount } from '../../../hooks/MiniApp/useEventData';
// import useEventRegistrationSubmit from '../../../hooks/MiniApp/useEventRegistrationSubmit';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useEventSlot from '../../../hooks/MiniApp/useEventSlot';

// // import context 
// import { useEventRegTerms } from '../../../context/EventRegTermContext';
// import { useTheme } from '../../../context/ThemeContext';
// import MyTicketAlert from './App_AlertMessage';
// import officeImg from '../../../assets/miniApp/NoahSpace_img.png';
// import ServerNameImg from '../../../assets/miniApp/broker_server_name.png'
// interface RegistrationProps {
//   event: Event;
//   onBack: () => void;
// }

// interface AlertState {
//   type: 'success' | 'error' | 'confirm';
//   message: string;
//   visible: boolean;
//   onClose: () => void;
//   onConfirm?: () => void;
//   onCancel?: () => void;
// }

// const MyTicketRegistration: React.FC<RegistrationProps> = ({ event, onBack }) => {
//   const { theme } = useTheme();
//   const { user } = useUserDashboard();
//   const terms = useEventRegTerms();
//   const [ticketId, setTicketId] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [receipts, setReceipts] = useState<File[]>([]);
//   const [notes, setNotes] = useState('');
//   const { getSlotMessage } = useEventData();
//   const [termsModalVisible, setTermsModalVisible] = useState(false);
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const { submitRegistration, loading } = useEventRegistrationSubmit();
//   const { slot, fetchSlot } = useEventSlot(event.name);
//   const [fileInputs] = useState([0]);
//   // const [fileInputs, setFileInputs] = useState([0]);
//   const [accountId, setAccountId] = useState('');
//   const [accountPassword, setAccountPassword] = useState('');
//   const [accountServer, setAccountServer] = useState('');
//   const [isPasswordVisible, setIsPasswordVisible] = useState(false);
//   const [locationModalVisible, setLocationModalVisible] = useState(false);
//   const [isTradeAccountIdTouched, setIsTradeAccountIdTouched] = useState(false);
//   const [isAccountServerTouched, setIsAccountServerTouched] = useState(false);

//   // Disabled attribute value based on conditions
//   const allRequirementsEmpty = event.lotSize === 0 && event.deposit === 0 && event.broker === "No Broker Required";
//   // Remove isFreeTicker condition
//   const isButtonDisabled = !termsAccepted || (!allRequirementsEmpty && (!paymentMethod || receipts.length === 0));
//   const isFieldDisabled = allRequirementsEmpty;
  
//   const [alert, setAlert] = useState<AlertState>({
//     type: 'success',
//     message: '',
//     visible: false,
//     onClose: () => {},
//   });

//   useEffect(() => {
//     const generateTicketId = () => {
//       const currentDate = new Date();
//       const year = currentDate.getFullYear().toString().slice(-2);
//       const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
//       const day = currentDate.getDate().toString().padStart(2, '0');
//       const randomPart = Math.random().toString(36).substr(2, 6).toUpperCase();
//       return `${randomPart}-${year}${month}${day}`;
//     };
//     setTicketId(generateTicketId());
//   }, []);

//   const handleBuyTicketClick = async () => {
//     if (slot === 0) {
//       alertUser('error', 'Ticket is not available for purchase.');
//       return;
//     }
  
//     if (!allRequirementsEmpty && (!paymentMethod || receipts.length === 0)) {
//       alertUser('error', 'Please fill all required fields.');
//       return;
//     }
  
//     setAlert({
//       type: 'confirm',
//       message: allRequirementsEmpty ? 'Are you sure you want to register for this event?' : 'Are you sure you want to buy the ticket?',
//       visible: true,
//       onClose: () => setAlert((prevState) => ({ ...prevState, visible: false })),
//       onConfirm: async () => {
//         const result = await submitRegistration({
//           ticketId,
//           eventTitle: event.name,
//           location: event.location,
//           email: user?.email || '',
//           username: user?.username || '',
//           appointmentDate: event.startDate,
//           time: event.time,
//           paymentMethod: allRequirementsEmpty ? 'Free' : paymentMethod,
//           amount: event.amount,
//           points: event.points,
//           accountId,
//           accountPassword,
//           accountServer,
//           receipts: receipts,
//           termsAccepted,
//           notes,
//           image: event.image,
//         });
  
//         if (result.success) {
//           alertUser('success', 'Registration submitted successfully. Please wait for approval.');
//           fetchSlot();
//         } else {
//           alertUser('error', `Failed to submit ticket registration: ${result.message}`);
//         }
//       },
//       onCancel: () => setAlert((prevState) => ({ ...prevState, visible: false })),
//     });
//   };
  
//   const handleShowLocation = () => {
//     setLocationModalVisible(true);
//   };
  
//   const handleCloseLocationModal = () => {
//     setLocationModalVisible(false);
//   };  

//   const handleReceiptUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
//     const newReceipts = [...receipts];
//     if (e.target.files && e.target.files[0]) {
//       newReceipts[index] = e.target.files[0];
//       setReceipts(newReceipts);
//     }
//   }; 

//   // const addFileInput = () => {
//   //   if (fileInputs.length < 5) {
//   //     setFileInputs([...fileInputs, fileInputs.length]);
//   //   }
//   // };  

//   const copyToClipboard = (text: string) => {
//     navigator.clipboard.writeText(text);
//     alertUser('success', 'Copied to clipboard!');
//   };

//   const handleTermsCheckboxChange = () => {
//     setTermsAccepted(!termsAccepted);
//   };
  
//   const handleTermsClick = () => {
//     setTermsModalVisible(true);
//   };
  
//   const handleCloseTermsModal = () => {
//     setTermsModalVisible(false);
//   };

//   const alertUser = (type: 'success' | 'error', message: string) => {
//     setAlert({
//       type,
//       message,
//       visible: true,
//       onClose: () => setAlert((prevState) => ({ ...prevState, visible: false })),
//     });
//   };

//   const parseDescription = (description: string) => {
//     const regex = /\[(.*?)\]/g;
//     const items = [];
//     let match;
//     while ((match = regex.exec(description)) !== null) {
//       items.push(match[1]);
//     }
//     return items;
//   };

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   return (
//     <div>
//       {alert.visible && <MyTicketAlert {...alert} />}
//       <div className="w-auto h-auto max-h-screen px-2 overflow-y-auto xl:h-[560px] lg:h-[520px] md:text-md md:h-[560px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         <p className="mb-6 font-semibold">{event.description.split('[')[0]}</p>
//         <div className="mb-6">
//           <ul className="list-disc list-inside">
//             {parseDescription(event.description).map((item, index) => (
//               <li key={index}>{item}</li>
//             ))}
//           </ul>
//         </div>
//         <form>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="ticket-id">Ticket ID</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="ticket-id" type="text" value={ticketId} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="event-title">Event Title</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="event-title" type="text" value={event.name} disabled />
//           </div>
//           <div className="mb-4">
//             <div className="flex justify-between items-center mb-2">
//               <label className="block mb-2 font-semibold" htmlFor="event-location">Venue</label>
//               {event.location === "Noah's Space" && (
//                 <button
//                   type="button"
//                   className="flex items-center text-blue-500"
//                   onClick={handleShowLocation}
//                   style={styles.title}
//                 >
//                   <FaMapMarkerAlt className="mr-2" />
//                   Show Location
//                 </button>
//               )}
//             </div>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="event-location" type="text" value={event.location} disabled />
//           </div>
//           {locationModalVisible && (
//             <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
//               <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto">
//                 <div className="flex justify-between items-center mb-4">
//                   <h2 className="text-xl font-semibold">Location Details</h2>
//                   <button onClick={handleCloseLocationModal} className="text-gray-500 hover:text-gray-800">
//                     <FaTimes className="w-6 h-6" />
//                   </button>
//                 </div>
//                 <div>
//                   <div className="mb-4">
//                     <h3 className="font-semibold">Address:</h3>
//                     <p>Unit 3-2-2, Kompleks Kantonmen Prima, 698, Jln Ipoh, Taman Bamboo, 51200 Kuala Lumpur, Federal Territory of Kuala Lumpur</p>
//                   </div>
//                   <div className="mb-4">
//                     <h3 className="font-semibold">Google Maps:</h3>
//                     <div className="flex items-center">
//                       <a 
//                         href="https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7" 
//                         target="_blank" 
//                         rel="noopener noreferrer" 
//                         className="text-blue-500 underline"
//                         style={{ color: theme.primaryColor }}
//                       >
//                         https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7
//                       </a>
//                     </div>
//                   </div>
//                   <div>
//                     <img src={officeImg} alt="Noah's Space location" className="w-full h-auto" />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="user-name">Name</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="user-name" type="text" value={user?.username || ''} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="user-email">Email</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="user-email" type="email" value={user?.email || ''} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="event-date">Event Date</label>
//             <input
//               type="text"
//               id="event-date"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               value={`${new Date(event.startDate).toLocaleDateString()}`}
//               disabled
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="event-time">Event Time</label>
//             <input
//               type="text"
//               id="event-time"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               value={`${new Date(`1970-01-01T${event.time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`}
//               disabled
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="slot">Current Slot</label>
//             <div id="slot">{getSlotMessage(event.slot)}</div>
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="amount">Amount</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="amount" 
//               type="text" 
//               value={event.amount === 0 ? 'Free of Charges' : `${event.currency} ${formatAmount(event.amount)}`} 
//               disabled 
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="points">Points</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="points" type="text" value={event.points} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="requirements">Register Requirement</label>
//             <ul className="list-disc list-inside">
//               <li>Lot Size: {event.lotSize === 0 ? 'No lot size required' : event.lotSize}</li>
//               <li>Deposit: {event.deposit === 0 ? 'No deposit required' : `${formatAmount(event.deposit)} USD`}</li>
//               <li>Broker: {event.broker}</li>
//             </ul>
//           </div>

//           <div className="mb-4">
//             <div className="flex items-center justify-normal">
//               <div className="flex items-center justify-normal">
//                 <label 
//                   className="block mb-2 font-semibold" 
//                   htmlFor="trade-account-id"
//                 >
//                   Account Number
//                 </label>
//                 <div className="relative group mx-2 mb-2">
//                   <div className="flex justify-between items-center">
//                     <FaExclamationCircle 
//                       className="text-xl cursor-pointer w-4 h-4"
//                       style={styles.title}
//                       aria-hidden="true"
//                     /> 
//                   </div>
//                   {/* Tooltip */}
//                   <div className="absolute top-full right-0 z-50 mt-2 hidden w-[400px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block sm:w-[300px] sm:text-xs"
//                     style={styles.button}>
//                     We require your investor trading Account ID and Investor Password to verify your account and ensure you have met the necessary requirements for this event. 
//                     Your information will be kept secure and confidential.
//                   </div>
//                 </div>
//                 <div className="text-xs text-rose-600 mb-2">
//                   ( This is your registered trading account number )
//                 </div>
//               </div>
//             </div>
//             <input
//               type="text"
//               id="trade-account-id"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 "
//               value={accountId}
//               onChange={(e) => setAccountId(e.target.value)}
//               onBlur={() => setIsTradeAccountIdTouched(true)}
//               required={isTradeAccountIdTouched}
//               disabled={isFieldDisabled}
//             />
//           </div>

//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="account-password">Account Password (Investor)</label>
//             <div className="relative">
//               <input
//                 type={isPasswordVisible ? 'text' : 'password'}
//                 id="account-password"
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//                 value={accountPassword}
//                 onChange={(e) => setAccountPassword(e.target.value)}
//                 required
//                 disabled={isFieldDisabled}
//               />
//               <div
//                 className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
//                 onClick={() => setIsPasswordVisible(!isPasswordVisible)}
//               >
//                 {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
//               </div>
//             </div>
//           </div>

//           <div className="mb-4">
//             <div className="flex items-center justify-between">
//               <label className="block mb-2 font-semibold" htmlFor="account-server">Account Server</label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaExclamationCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mr-2"
//                     style={styles.title}
//                     aria-hidden="true"
//                   />
//                   <span>Where?</span>
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full right-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs"
//                   style={styles.button}>
//                   You can find your account server details in your trading platform under the account information section.
//                   <img src={ServerNameImg} alt="Account Server Example" className="mt-6 cursor-pointer" />
//                 </div>
//               </div>
//             </div>
//             <input
//               type="text"
//               id="account-server"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               value={accountServer}
//               onChange={(e) => setAccountServer(e.target.value)}
//               onBlur={() => setIsAccountServerTouched(true)}
//               required={isAccountServerTouched}
//               disabled={isFieldDisabled}
//             />
//           </div>

//           <div className="mb-4">
//             <label className="block mb-2 font-semibold">Pay By</label>
//             <div 
//               className="flex items-center mb-2"
//               onClick={() => setPaymentMethod('')}
//             >
//               <input
//                 type="radio"
//                 id="bank-transfer"
//                 name="payment-method"
//                 value="Bank Transfer"
//                 checked={paymentMethod === 'Bank Transfer'}
//                 onChange={(e) => setPaymentMethod(e.target.value)}
//                 style={{ accentColor: theme.primaryColor }}
//                 disabled={isFieldDisabled}
//               />
//               <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
//             </div>
//             {paymentMethod === 'Bank Transfer' && (
//               <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                 <span>Bank Account</span><br/> 
//                 <div className="flex justify-between items-center">
//                   <div>
//                     <li>FINOX TRADE MARKET SDN. BHD.</li>
//                     <li>Hong leong bank</li>
//                     <li>23600590742</li>
//                   </div>
//                   <FaClipboard 
//                     className="ml-2 cursor-pointer w-6 h-6"
//                     style={{ color: theme.primaryColor }} 
//                     onClick={() => copyToClipboard("23600590742")} 
//                   />
//                 </div>
//               </div>
//             )}
//             <div 
//               className="flex items-center"
//               onClick={() => setPaymentMethod('')}
//             >
//               <input
//                 type="radio"
//                 id="usdt"
//                 name="payment-method"
//                 value="USDT"
//                 checked={paymentMethod === 'USDT'}
//                 onChange={(e) => setPaymentMethod(e.target.value)}
//                 style={{ accentColor: theme.primaryColor }}
//                 disabled={isFieldDisabled}
//               />
//               <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
//             </div>
//             {paymentMethod === 'USDT' && (
//               <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                 <span>Wallet Address:</span><br/> 
//                 <div className="flex justify-between items-center">
//                   <div>
//                     <li>Tron (TRC20) Network</li>
//                     <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                   </div>
//                   <FaClipboard 
//                     className="ml-2 cursor-pointer w-6 h-6"
//                     style={{ color: theme.primaryColor }} 
//                     onClick={() => copyToClipboard("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")}
                     
//                   />
//                 </div>
//               </div>
//             )}
//           </div>
//           <div className="mb-4">
//             <div className="flex justify-between items-center mb-2">
//               <label className="block font-semibold" htmlFor="payment-receipts">Payment Receipts</label>
//               {/* {fileInputs.length < 5 && (
//                 <button
//                   type="button"
//                   className="flex items-center text-blue-500"
//                   onClick={addFileInput}
//                   style={{ color: theme.primaryColor }}
//                 >
//                   <span className="mr-2">Add file</span>
//                   <FaPlus />
//                 </button>
//               )} */}
//             </div>
//             {fileInputs.map((input, index) => (
//               <div key={index} className="mb-2">
//                 <input
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//                   type="file"
//                   onChange={(e) => handleReceiptUpload(e, index)}
//                   disabled={isFieldDisabled}
//                 />
//               </div>
//             ))}
//           </div>
//           {termsModalVisible && (
//             <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
//               <div className="bg-white p-10 rounded-lg max-w-lg w-full h-[700px] md:h-[600px] sm:h-[500px]">
//                 <div className="flex justify-between items-center mb-4 sticky top-0 bg-white z-10">
//                   <h2 className="text-xl font-semibold">Terms and Conditions</h2>
//                   <button onClick={handleCloseTermsModal} className="text-gray-500 hover:text-gray-800">
//                     <FaTimes className="w-6 h-6" />
//                   </button>
//                 </div>
//                 <div className="overflow-y-auto border-2 h-[calc(800px-48px)] md:h-[calc(500px-48px)] sm:h-[calc(500px-48px)]">
//                   {terms}
//                 </div>
//               </div>
//             </div>
//           )}
//           <div className="mb-6">
//             <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
//             <textarea className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="notes" 
//               placeholder="Leave any notes here (optional)..." 
//               value={notes} 
//               onChange={(e) => setNotes(e.target.value)}
//               disabled={isFieldDisabled}
//             />
//           </div>
//           <div className="mb-4">
//             <label className="flex items-center">
//               <input
//                 type="checkbox"
//                 checked={termsAccepted}
//                 onChange={handleTermsCheckboxChange}
//                 className="mr-2 h-6 w-6 md:w-4 sm:w-4 xs:w-4"
//                 style={{ accentColor: theme.primaryColor, color: theme.textColor}}
//               />
//               <span 
//                 onClick={handleTermsClick} 
//                 className="cursor-pointer text-blue-500 underline"
//                 style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                 I have read and understand the terms and conditions
//               </span>
//             </label>
//           </div>
//         </form>
//       </div>
//       <div className="flex justify-between items-center mt-4 space-x-4">
//         <div className="flex justify-between items-center w-full sm:text-sm xs:text-xs">
//           <button 
//             onClick={onBack} 
//             className="px-4 py-2 rounded-md flex items-center justify-center"
//             style={styles.button}>
//             <FaArrowCircleLeft />
//             <div className="ml-2">Back to Details</div>
//           </button>
//           <button 
//             type="button" 
//             className="px-4 py-2 rounded-md flex items-center justify-center"
//             onClick={handleBuyTicketClick}
//             disabled={loading || isButtonDisabled}
//             style={{ 
//               backgroundColor: isButtonDisabled ? 'gray' : theme.primaryColor, 
//               color: theme.text2Color, 
//               fontFamily: theme.fontFamily 
//             }}
//           >
//             <IoTicketSharp />
//             <div className="ml-2">Buy Ticket</div>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MyTicketRegistration;


// // udpate code --> 19 july 2024 
// // src/components/client/MiniApp/App01_MyTicketRegisteration.tsx

// import React, { useState, useEffect } from 'react';
// import { FaClipboard, FaArrowCircleLeft, FaTimes, FaMapMarkerAlt, FaEye, FaEyeSlash, FaExclamationCircle } from 'react-icons/fa';
// import { IoTicketSharp } from "react-icons/io5";

// // import hooks
// import { Event } from '../../../hooks/MiniApp/useEventData';
// import useEventData, { formatAmount } from '../../../hooks/MiniApp/useEventData';
// import useEventRegistrationSubmit from '../../../hooks/MiniApp/useEventRegistrationSubmit';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useEventSlot from '../../../hooks/MiniApp/useEventSlot';

// // import context 
// import { useEventRegTerms } from '../../../context/EventRegTermContext';
// import { useTheme } from '../../../context/ThemeContext';
// import MyTicketAlert from './App_AlertMessage';
// import officeImg from '../../../assets/miniApp/NoahSpace_img.png';
// import ServerNameImg from '../../../assets/miniApp/broker_server_name.png'
// interface RegistrationProps {
//   event: Event;
//   onBack: () => void;
// }

// interface AlertState {
//   type: 'success' | 'error' | 'confirm';
//   message: string;
//   visible: boolean;
//   onClose: () => void;
//   onConfirm?: () => void;
//   onCancel?: () => void;
// }

// const MyTicketRegistration: React.FC<RegistrationProps> = ({ event, onBack }) => {
//   const { theme } = useTheme();
//   const { user } = useUserDashboard();
//   const terms = useEventRegTerms();
//   const [ticketId, setTicketId] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [receipts, setReceipts] = useState<File[]>([]);
//   const [notes, setNotes] = useState('');
//   const { getSlotMessage } = useEventData();
//   const [termsModalVisible, setTermsModalVisible] = useState(false);
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const { submitRegistration, loading } = useEventRegistrationSubmit();
//   const { slot, fetchSlot } = useEventSlot(event.name);
//   const [fileInputs] = useState([0]);
//   // const [fileInputs, setFileInputs] = useState([0]);
//   const [accountId, setAccountId] = useState('');
//   const [accountPassword, setAccountPassword] = useState('');
//   const [accountServer, setAccountServer] = useState('');
//   const [isPasswordVisible, setIsPasswordVisible] = useState(false);
//   const [locationModalVisible, setLocationModalVisible] = useState(false);
//   const [isTradeAccountIdTouched, setIsTradeAccountIdTouched] = useState(false);
//   const [isAccountServerTouched, setIsAccountServerTouched] = useState(false);

//   // Disabled attribute value based on conditions
//   const isFreeTicker = event.amount === 0;
//   const allRequirementsEmpty = event.lotSize === 0 && event.deposit === 0 && event.broker === "No Broker";
//   const isButtonDisabled = !termsAccepted || (!isFreeTicker && !allRequirementsEmpty && (!paymentMethod || receipts.length === 0));
//   const isFieldDisabled = isFreeTicker || allRequirementsEmpty;

//   const [alert, setAlert] = useState<AlertState>({
//     type: 'success',
//     message: '',
//     visible: false,
//     onClose: () => {},
//   });

//   useEffect(() => {
//     const generateTicketId = () => {
//       const currentDate = new Date();
//       const year = currentDate.getFullYear().toString().slice(-2);
//       const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
//       const day = currentDate.getDate().toString().padStart(2, '0');
//       const randomPart = Math.random().toString(36).substr(2, 6).toUpperCase();
//       return `${randomPart}-${year}${month}${day}`;
//     };
//     setTicketId(generateTicketId());
//   }, []);

//   const handleBuyTicketClick = async () => {
//     if (slot === 0) {
//       alertUser('error', 'Ticket is not available for purchase.');
//       return;
//     }
  
//     if (!isFreeTicker && (!paymentMethod || receipts.length === 0)) {
//       alertUser('error', 'Please fill all required fields.');
//       return;
//     }
  
//     setAlert({
//       type: 'confirm',
//       message: isFreeTicker ? 'Are you sure you want to register for this free ticket?' : 'Are you sure you want to buy the ticket?',
//       visible: true,
//       onClose: () => setAlert((prevState) => ({ ...prevState, visible: false })),
//       onConfirm: async () => {
//         const result = await submitRegistration({
//           ticketId,
//           eventTitle: event.name,
//           location: event.location,
//           email: user?.email || '',
//           username: user?.username || '',
//           appointmentDate: event.startDate,
//           time: event.time,
//           paymentMethod: isFreeTicker ? 'Free' : paymentMethod,
//           amount: event.amount,
//           points: event.points,
//           accountId,
//           accountPassword,
//           accountServer,
//           receipts: receipts,
//           termsAccepted,
//           notes,
//           image: event.image,
//         });
  
//         if (result.success) {
//           alertUser('success', 'Registration submitted successfully. Please wait for approval.');
//           fetchSlot();
//         } else {
//           alertUser('error', `Failed to submit ticket registration: ${result.message}`);
//         }
//       },
//       onCancel: () => setAlert((prevState) => ({ ...prevState, visible: false })),
//     });
//   };
  
//   const handleShowLocation = () => {
//     setLocationModalVisible(true);
//   };
  
//   const handleCloseLocationModal = () => {
//     setLocationModalVisible(false);
//   };  

//   const handleReceiptUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
//     const newReceipts = [...receipts];
//     if (e.target.files && e.target.files[0]) {
//       newReceipts[index] = e.target.files[0];
//       setReceipts(newReceipts);
//     }
//   }; 

//   // const addFileInput = () => {
//   //   if (fileInputs.length < 5) {
//   //     setFileInputs([...fileInputs, fileInputs.length]);
//   //   }
//   // };  

//   const copyToClipboard = (text: string) => {
//     navigator.clipboard.writeText(text);
//     alertUser('success', 'Copied to clipboard!');
//   };

//   const handleTermsCheckboxChange = () => {
//     setTermsAccepted(!termsAccepted);
//   };
  
//   const handleTermsClick = () => {
//     setTermsModalVisible(true);
//   };
  
//   const handleCloseTermsModal = () => {
//     setTermsModalVisible(false);
//   };

//   const alertUser = (type: 'success' | 'error', message: string) => {
//     setAlert({
//       type,
//       message,
//       visible: true,
//       onClose: () => setAlert((prevState) => ({ ...prevState, visible: false })),
//     });
//   };

//   const parseDescription = (description: string) => {
//     const regex = /\[(.*?)\]/g;
//     const items = [];
//     let match;
//     while ((match = regex.exec(description)) !== null) {
//       items.push(match[1]);
//     }
//     return items;
//   };

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   return (
//     <div>
//       {alert.visible && <MyTicketAlert {...alert} />}
//       <div className="w-auto h-auto max-h-[800px] px-2 overflow-y-auto md:text-md md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         <p className="mb-6 font-semibold">{event.description.split('[')[0]}</p>
//         <div className="mb-6">
//           <ul className="list-disc list-inside">
//             {parseDescription(event.description).map((item, index) => (
//               <li key={index}>{item}</li>
//             ))}
//           </ul>
//         </div>
//         <form>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="ticket-id">Ticket ID</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="ticket-id" type="text" value={ticketId} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="event-title">Event Title</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="event-title" type="text" value={event.name} disabled />
//           </div>
//           <div className="mb-4">
//             <div className="flex justify-between items-center mb-2">
//               <label className="block mb-2 font-semibold" htmlFor="event-location">Venue</label>
//               {event.location === "Noah's Space" && (
//                 <button
//                   type="button"
//                   className="flex items-center text-blue-500"
//                   onClick={handleShowLocation}
//                   style={styles.title}
//                 >
//                   <FaMapMarkerAlt className="mr-2" />
//                   Show Location
//                 </button>
//               )}
//             </div>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="event-location" type="text" value={event.location} disabled />
//           </div>
//           {locationModalVisible && (
//             <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
//               <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto">
//                 <div className="flex justify-between items-center mb-4">
//                   <h2 className="text-xl font-semibold">Location Details</h2>
//                   <button onClick={handleCloseLocationModal} className="text-gray-500 hover:text-gray-800">
//                     <FaTimes className="w-6 h-6" />
//                   </button>
//                 </div>
//                 <div>
//                   <div className="mb-4">
//                     <h3 className="font-semibold">Address:</h3>
//                     <p>Unit 3-2-2, Kompleks Kantonmen Prima, 698, Jln Ipoh, Taman Bamboo, 51200 Kuala Lumpur, Federal Territory of Kuala Lumpur</p>
//                   </div>
//                   <div className="mb-4">
//                     <h3 className="font-semibold">Google Maps:</h3>
//                     <div className="flex items-center">
//                       <a 
//                         href="https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7" 
//                         target="_blank" 
//                         rel="noopener noreferrer" 
//                         className="text-blue-500 underline"
//                         style={{ color: theme.primaryColor }}
//                       >
//                         https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7
//                       </a>
//                     </div>
//                   </div>
//                   <div>
//                     <img src={officeImg} alt="Noah's Space location" className="w-full h-auto" />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="user-name">Name</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="user-name" type="text" value={user?.username || ''} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="user-email">Email</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="user-email" type="email" value={user?.email || ''} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="event-date">Event Date</label>
//             <input
//               type="text"
//               id="event-date"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               value={`${new Date(event.startDate).toLocaleDateString()}`}
//               disabled
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="event-time">Event Time</label>
//             <input
//               type="text"
//               id="event-time"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               value={`${new Date(`1970-01-01T${event.time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`}
//               disabled
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="slot">Current Slot</label>
//             <div id="slot">{getSlotMessage(event.slot)}</div>
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="amount">Amount</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="amount" 
//               type="text" 
//               value={event.amount === 0 ? 'Free of Charges' : `${event.currency} ${formatAmount(event.amount)}`} 
//               disabled 
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="points">Points</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="points" type="text" value={event.points} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="requirements">Requirement</label>
//             <ul className="list-disc list-inside">
//               <li>Lot Size: {event.lotSize === 0 ? 'No lot size required' : event.lotSize}</li>
//               <li>Deposit: {event.deposit === 0 ? 'No deposit required' : `${formatAmount(event.deposit)} USD`}</li>
//               <li>Broker: {event.broker}</li>
//             </ul>
//           </div>

//           <div className="mb-4">
//             <div className="flex items-center justify-between">
//               <label className="block mb-2 font-semibold" htmlFor="trade-account-id">Trade Account ID (Investor)</label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaExclamationCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mr-2"
//                     style={styles.title}
//                     aria-hidden="true"
//                   /> 
//                   <span>Why?</span>
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full right-0 z-50 mt-2 hidden w-[400px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block sm:w-[300px] sm:text-xs"
//                   style={styles.button}>
//                   We require your investor trading Account ID and Investor Password to verify your account and ensure you have met the necessary requirements for this event. 
//                   Your information will be kept secure and confidential.
//                 </div>
//               </div>
//             </div>
//             <input
//               type="text"
//               id="trade-account-id"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 "
//               value={accountId}
//               onChange={(e) => setAccountId(e.target.value)}
//               onBlur={() => setIsTradeAccountIdTouched(true)}
//               required={isTradeAccountIdTouched}
//               disabled={isFieldDisabled}
//             />
//           </div>

//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="account-password">Account Password (Investor)</label>
//             <div className="relative">
//               <input
//                 type={isPasswordVisible ? 'text' : 'password'}
//                 id="account-password"
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//                 value={accountPassword}
//                 onChange={(e) => setAccountPassword(e.target.value)}
//                 required
//                 disabled={isFieldDisabled}
//               />
//               <div
//                 className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
//                 onClick={() => setIsPasswordVisible(!isPasswordVisible)}
//               >
//                 {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
//               </div>
//             </div>
//           </div>

//           <div className="mb-4">
//             <div className="flex items-center justify-between">
//               <label className="block mb-2 font-semibold" htmlFor="account-server">Account Server</label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaExclamationCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mr-2"
//                     style={styles.title}
//                     aria-hidden="true"
//                   />
//                   <span>Where?</span>
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full right-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs"
//                   style={styles.button}>
//                   You can find your account server details in your trading platform under the account information section.
//                   <img src={ServerNameImg} alt="Account Server Example" className="mt-6 cursor-pointer" />
//                 </div>
//               </div>
//             </div>
//             <input
//               type="text"
//               id="account-server"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               value={accountServer}
//               onChange={(e) => setAccountServer(e.target.value)}
//               onBlur={() => setIsAccountServerTouched(true)}
//               required={isAccountServerTouched}
//               disabled={isFieldDisabled}
//             />
//           </div>

//           <div className="mb-4">
//             <label className="block mb-2 font-semibold">Pay By</label>
//             <div 
//               className="flex items-center mb-2"
//               onClick={() => setPaymentMethod('')}
//             >
//               <input
//                 type="radio"
//                 id="bank-transfer"
//                 name="payment-method"
//                 value="Bank Transfer"
//                 checked={paymentMethod === 'Bank Transfer'}
//                 onChange={(e) => setPaymentMethod(e.target.value)}
//                 style={{ accentColor: theme.primaryColor }}
//                 disabled={isFieldDisabled}
//               />
//               <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
//             </div>
//             {paymentMethod === 'Bank Transfer' && (
//               <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                 <span>Bank Account</span><br/> 
//                 <div className="flex justify-between items-center">
//                   <div>
//                     <li>FINOX TRADE MARKET SDN. BHD.</li>
//                     <li>Hong leong bank</li>
//                     <li>23600590742</li>
//                   </div>
//                   <FaClipboard 
//                     className="ml-2 cursor-pointer w-6 h-6"
//                     style={{ color: theme.primaryColor }} 
//                     onClick={() => copyToClipboard("23600590742")} 
//                   />
//                 </div>
//               </div>
//             )}
//             <div 
//               className="flex items-center"
//               onClick={() => setPaymentMethod('')}
//             >
//               <input
//                 type="radio"
//                 id="usdt"
//                 name="payment-method"
//                 value="USDT"
//                 checked={paymentMethod === 'USDT'}
//                 onChange={(e) => setPaymentMethod(e.target.value)}
//                 style={{ accentColor: theme.primaryColor }}
//                 disabled={isFieldDisabled}
//               />
//               <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
//             </div>
//             {paymentMethod === 'USDT' && (
//               <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                 <span>Wallet Address:</span><br/> 
//                 <div className="flex justify-between items-center">
//                   <div>
//                     <li>Tron (TRC20) Network</li>
//                     <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                   </div>
//                   <FaClipboard 
//                     className="ml-2 cursor-pointer w-6 h-6"
//                     style={{ color: theme.primaryColor }} 
//                     onClick={() => copyToClipboard("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")}
                     
//                   />
//                 </div>
//               </div>
//             )}
//           </div>
//           <div className="mb-4">
//             <div className="flex justify-between items-center mb-2">
//               <label className="block font-semibold" htmlFor="payment-receipts">Payment Receipts</label>
//               {/* {fileInputs.length < 5 && (
//                 <button
//                   type="button"
//                   className="flex items-center text-blue-500"
//                   onClick={addFileInput}
//                   style={{ color: theme.primaryColor }}
//                 >
//                   <span className="mr-2">Add file</span>
//                   <FaPlus />
//                 </button>
//               )} */}
//             </div>
//             {fileInputs.map((input, index) => (
//               <div key={index} className="mb-2">
//                 <input
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//                   type="file"
//                   onChange={(e) => handleReceiptUpload(e, index)}
//                   disabled={isFieldDisabled}
//                 />
//               </div>
//             ))}
//           </div>
//           {termsModalVisible && (
//             <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
//               <div className="bg-white p-10 rounded-lg max-w-lg w-full h-[700px] md:h-[600px] sm:h-[500px]">
//                 <div className="flex justify-between items-center mb-4 sticky top-0 bg-white z-10">
//                   <h2 className="text-xl font-semibold">Terms and Conditions</h2>
//                   <button onClick={handleCloseTermsModal} className="text-gray-500 hover:text-gray-800">
//                     <FaTimes className="w-6 h-6" />
//                   </button>
//                 </div>
//                 <div className="overflow-y-auto border-2 h-[calc(800px-48px)] md:h-[calc(500px-48px)] sm:h-[calc(500px-48px)]">
//                   {terms}
//                 </div>
//               </div>
//             </div>
//           )}
//           <div className="mb-6">
//             <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
//             <textarea className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="notes" 
//               placeholder="Leave any notes here (optional)..." 
//               value={notes} 
//               onChange={(e) => setNotes(e.target.value)}
//               disabled={isFieldDisabled}
//             />
//           </div>
//           <div className="mb-4">
//             <label className="flex items-center">
//               <input
//                 type="checkbox"
//                 checked={termsAccepted}
//                 onChange={handleTermsCheckboxChange}
//                 className="mr-2 h-6 w-6 md:w-4 sm:w-4 xs:w-4"
//                 style={{ accentColor: theme.primaryColor, color: theme.textColor}}
//               />
//               <span 
//                 onClick={handleTermsClick} 
//                 className="cursor-pointer text-blue-500 underline"
//                 style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                 I have read and understand the terms and conditions
//               </span>
//             </label>
//           </div>
//         </form>
//       </div>
//       <div className="flex justify-between items-center mt-4 space-x-4">
//         <div className="flex justify-between items-center w-full sm:text-sm xs:text-xs">
//           <button 
//             onClick={onBack} 
//             className="px-4 py-2 rounded-md flex items-center justify-center"
//             style={styles.button}>
//             <FaArrowCircleLeft />
//             <div className="ml-2">Back to Details</div>
//           </button>
//           <button 
//             type="button" 
//             className="px-4 py-2 rounded-md flex items-center justify-center"
//             onClick={handleBuyTicketClick}
//             disabled={loading || isButtonDisabled}
//             style={{ 
//               backgroundColor: isButtonDisabled ? 'gray' : theme.primaryColor, 
//               color: theme.text2Color, 
//               fontFamily: theme.fontFamily 
//             }}
//           >
//             <IoTicketSharp />
//             <div className="ml-2">Buy Ticket</div>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MyTicketRegistration;

// // update code --> 17 july 2024
// // src/components/client/MiniApp/App01_MyTicketRegisteration.tsx

// import React, { useState, useEffect } from 'react';
// import { FaClipboard, FaArrowCircleLeft, FaTimes, FaMapMarkerAlt, FaEye, FaEyeSlash, FaExclamationCircle } from 'react-icons/fa';
// import { IoTicketSharp } from "react-icons/io5";

// // import hooks
// import { Event } from '../../../hooks/MiniApp/useEventData';
// import useEventData, { formatAmount } from '../../../hooks/MiniApp/useEventData';
// import useEventRegistrationSubmit from '../../../hooks/MiniApp/useEventRegistrationSubmit';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useEventSlot from '../../../hooks/MiniApp/useEventSlot';

// // import context 
// import { useEventRegTerms } from '../../../context/EventRegTermContext';
// import { useTheme } from '../../../context/ThemeContext';
// import MyTicketAlert from './App_AlertMessage';
// import officeImg from '../../../assets/miniApp/NoahSpace_img.png';
// import ServerNameImg from '../../../assets/miniApp/broker_server_name.png'
// interface RegistrationProps {
//   event: Event;
//   onBack: () => void;
// }

// interface AlertState {
//   type: 'success' | 'error' | 'confirm';
//   message: string;
//   visible: boolean;
//   onClose: () => void;
//   onConfirm?: () => void;
//   onCancel?: () => void;
// }

// const MyTicketRegistration: React.FC<RegistrationProps> = ({ event, onBack }) => {
//   const { theme } = useTheme();
//   const { user } = useUserDashboard();
//   const terms = useEventRegTerms();
//   const [ticketId, setTicketId] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [receipts, setReceipts] = useState<File[]>([]);
//   const [notes, setNotes] = useState('');
//   const { getSlotMessage } = useEventData();
//   const [termsModalVisible, setTermsModalVisible] = useState(false);
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const { submitRegistration, loading } = useEventRegistrationSubmit();
//   const { slot, fetchSlot } = useEventSlot(event.name);
//   const [fileInputs] = useState([0]);
//   // const [fileInputs, setFileInputs] = useState([0]);
//   const [accountId, setAccountId] = useState('');
//   const [accountPassword, setAccountPassword] = useState('');
//   const [accountServer, setAccountServer] = useState('');
//   const [isPasswordVisible, setIsPasswordVisible] = useState(false);
//   const [locationModalVisible, setLocationModalVisible] = useState(false);
//   const [isTradeAccountIdTouched, setIsTradeAccountIdTouched] = useState(false);
//   const [isAccountServerTouched, setIsAccountServerTouched] = useState(false);

//   const isFreeTicker = event.amount === 0;
//   const allRequirementsEmpty = event.lotSize === 0 && event.deposit === 0 && event.broker === "No Broker";
//   const isButtonDisabled = !termsAccepted || (!isFreeTicker && (!paymentMethod || receipts.length === 0));

//   // Disabled attribute value based on conditions
//   const isFieldDisabled = isFreeTicker && allRequirementsEmpty;

//   const [alert, setAlert] = useState<AlertState>({
//     type: 'success',
//     message: '',
//     visible: false,
//     onClose: () => {},
//   });

//   useEffect(() => {
//     const generateTicketId = () => {
//       const currentDate = new Date();
//       const year = currentDate.getFullYear().toString().slice(-2);
//       const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
//       const day = currentDate.getDate().toString().padStart(2, '0');
//       const randomPart = Math.random().toString(36).substr(2, 6).toUpperCase();
//       return `${randomPart}-${year}${month}${day}`;
//     };
//     setTicketId(generateTicketId());
//   }, []);

//   const handleBuyTicketClick = async () => {
//     if (slot === 0) {
//       alertUser('error', 'Ticket is not available for purchase.');
//       return;
//     }
  
//     if (!isFreeTicker && (!paymentMethod || receipts.length === 0)) {
//       alertUser('error', 'Please fill all required fields.');
//       return;
//     }
  
//     setAlert({
//       type: 'confirm',
//       message: isFreeTicker ? 'Are you sure you want to register for this free ticket?' : 'Are you sure you want to buy the ticket?',
//       visible: true,
//       onClose: () => setAlert((prevState) => ({ ...prevState, visible: false })),
//       onConfirm: async () => {
//         const result = await submitRegistration({
//           ticketId,
//           eventTitle: event.name,
//           location: event.location,
//           email: user?.email || '',
//           username: user?.username || '',
//           appointmentDate: event.startDate,
//           time: event.time,
//           paymentMethod: isFreeTicker ? 'Free' : paymentMethod,
//           amount: event.amount,
//           points: event.points,
//           accountId,
//           accountPassword,
//           accountServer,
//           receipts: receipts,
//           termsAccepted,
//           notes,
//           image: event.image,
//         });
  
//         if (result.success) {
//           alertUser('success', 'Registration submitted successfully. Please wait for approval.');
//           fetchSlot();
//         } else {
//           alertUser('error', `Failed to submit ticket registration: ${result.message}`);
//         }
//       },
//       onCancel: () => setAlert((prevState) => ({ ...prevState, visible: false })),
//     });
//   };
  
//   const handleShowLocation = () => {
//     setLocationModalVisible(true);
//   };
  
//   const handleCloseLocationModal = () => {
//     setLocationModalVisible(false);
//   };  

//   const handleReceiptUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
//     const newReceipts = [...receipts];
//     if (e.target.files && e.target.files[0]) {
//       newReceipts[index] = e.target.files[0];
//       setReceipts(newReceipts);
//     }
//   }; 

//   // const addFileInput = () => {
//   //   if (fileInputs.length < 5) {
//   //     setFileInputs([...fileInputs, fileInputs.length]);
//   //   }
//   // };  

//   const copyToClipboard = (text: string) => {
//     navigator.clipboard.writeText(text);
//     alertUser('success', 'Copied to clipboard!');
//   };

//   const handleTermsCheckboxChange = () => {
//     setTermsAccepted(!termsAccepted);
//   };
  
//   const handleTermsClick = () => {
//     setTermsModalVisible(true);
//   };
  
//   const handleCloseTermsModal = () => {
//     setTermsModalVisible(false);
//   };

//   const alertUser = (type: 'success' | 'error', message: string) => {
//     setAlert({
//       type,
//       message,
//       visible: true,
//       onClose: () => setAlert((prevState) => ({ ...prevState, visible: false })),
//     });
//   };

//   const parseDescription = (description: string) => {
//     const regex = /\[(.*?)\]/g;
//     const items = [];
//     let match;
//     while ((match = regex.exec(description)) !== null) {
//       items.push(match[1]);
//     }
//     return items;
//   };

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   return (
//     <div>
//       {alert.visible && <MyTicketAlert {...alert} />}
//       <div className="w-auto h-auto max-h-[800px] px-2 overflow-y-auto md:text-md md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         <p className="mb-6 font-semibold">{event.description.split('[')[0]}</p>
//         <div className="mb-6">
//           <ul className="list-disc list-inside">
//             {parseDescription(event.description).map((item, index) => (
//               <li key={index}>{item}</li>
//             ))}
//           </ul>
//         </div>
//         <form>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="ticket-id">Ticket ID</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="ticket-id" type="text" value={ticketId} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="event-title">Event Title</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="event-title" type="text" value={event.name} disabled />
//           </div>
//           <div className="mb-4">
//             <div className="flex justify-between items-center mb-2">
//               <label className="block mb-2 font-semibold" htmlFor="event-location">Venue</label>
//               {event.location === "Noah's Space" && (
//                 <button
//                   type="button"
//                   className="flex items-center text-blue-500"
//                   onClick={handleShowLocation}
//                   style={styles.title}
//                 >
//                   <FaMapMarkerAlt className="mr-2" />
//                   Show Location
//                 </button>
//               )}
//             </div>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="event-location" type="text" value={event.location} disabled />
//           </div>
//           {locationModalVisible && (
//             <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
//               <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto">
//                 <div className="flex justify-between items-center mb-4">
//                   <h2 className="text-xl font-semibold">Location Details</h2>
//                   <button onClick={handleCloseLocationModal} className="text-gray-500 hover:text-gray-800">
//                     <FaTimes className="w-6 h-6" />
//                   </button>
//                 </div>
//                 <div>
//                   <div className="mb-4">
//                     <h3 className="font-semibold">Address:</h3>
//                     <p>Unit 3-2-2, Kompleks Kantonmen Prima, 698, Jln Ipoh, Taman Bamboo, 51200 Kuala Lumpur, Federal Territory of Kuala Lumpur</p>
//                   </div>
//                   <div className="mb-4">
//                     <h3 className="font-semibold">Google Maps:</h3>
//                     <div className="flex items-center">
//                       <a 
//                         href="https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7" 
//                         target="_blank" 
//                         rel="noopener noreferrer" 
//                         className="text-blue-500 underline"
//                         style={{ color: theme.primaryColor }}
//                       >
//                         https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7
//                       </a>
//                     </div>
//                   </div>
//                   <div>
//                     <img src={officeImg} alt="Noah's Space location" className="w-full h-auto" />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="user-name">Name</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="user-name" type="text" value={user?.username || ''} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="user-email">Email</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="user-email" type="email" value={user?.email || ''} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="event-date">Event Date</label>
//             <input
//               type="text"
//               id="event-date"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               value={`${new Date(event.startDate).toLocaleDateString()}`}
//               disabled
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="event-time">Event Time</label>
//             <input
//               type="text"
//               id="event-time"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               value={`${new Date(`1970-01-01T${event.time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`}
//               disabled
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="slot">Current Slot</label>
//             <div id="slot">{getSlotMessage(event.slot)}</div>
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="amount">Amount</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="amount" 
//               type="text" 
//               value={event.amount === 0 ? 'Free of Charges' : `${event.currency} ${formatAmount(event.amount)}`} 
//               disabled 
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="points">Points</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="points" type="text" value={event.points} disabled />
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="requirements">Requirement</label>
//             <ul className="list-disc list-inside">
//               <li>Lot Size: {event.lotSize === 0 ? 'No lot size required' : event.lotSize}</li>
//               <li>Deposit: {event.deposit === 0 ? 'No deposit required' : `${formatAmount(event.deposit)} USD`}</li>
//               <li>Broker: {event.broker}</li>
//             </ul>
//           </div>

//           <div className="mb-4">
//             <div className="flex items-center justify-between">
//               <label className="block mb-2 font-semibold" htmlFor="trade-account-id">Trade Account ID (Investor)</label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaExclamationCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mr-2"
//                     style={styles.title}
//                     aria-hidden="true"
//                   /> 
//                   <span>Why?</span>
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full right-0 z-50 mt-2 hidden w-[400px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block sm:w-[300px] sm:text-xs"
//                   style={styles.button}>
//                   We require your investor trading Account ID and Investor Password to verify your account and ensure you have met the necessary requirements for this event. 
//                   Your information will be kept secure and confidential.
//                 </div>
//               </div>
//             </div>
//             <input
//               type="text"
//               id="trade-account-id"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               value={accountId}
//               onChange={(e) => setAccountId(e.target.value)}
//               onBlur={() => setIsTradeAccountIdTouched(true)}
//               required={isTradeAccountIdTouched}
//               disabled={isFieldDisabled}
//             />
//           </div>

//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="account-password">Account Password (Investor)</label>
//             <div className="relative">
//               <input
//                 type={isPasswordVisible ? 'text' : 'password'}
//                 id="account-password"
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//                 value={accountPassword}
//                 onChange={(e) => setAccountPassword(e.target.value)}
//                 required
//                 disabled={isFieldDisabled}
//               />
//               <div
//                 className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
//                 onClick={() => setIsPasswordVisible(!isPasswordVisible)}
//               >
//                 {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
//               </div>
//             </div>
//           </div>

//           <div className="mb-4">
//             <div className="flex items-center justify-between">
//               <label className="block mb-2 font-semibold" htmlFor="account-server">Account Server</label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaExclamationCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mr-2"
//                     style={styles.title}
//                     aria-hidden="true"
//                   />
//                   <span>Where?</span>
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full right-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block sm:w-[300px] sm:text-xs"
//                   style={styles.button}>
//                   You can find your account server details in your trading platform under the account information section.
//                   <img src={ServerNameImg} alt="Account Server Example" className="mt-6 cursor-pointer" />
//                 </div>
//               </div>
//             </div>
//             <input
//               type="text"
//               id="account-server"
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               value={accountServer}
//               onChange={(e) => setAccountServer(e.target.value)}
//               onBlur={() => setIsAccountServerTouched(true)}
//               required={isAccountServerTouched}
//               disabled={isFieldDisabled}
//             />
//           </div>

//           <div className="mb-4">
//             <label className="block mb-2 font-semibold">Pay By</label>
//             <div 
//               className="flex items-center mb-2"
//               onClick={() => setPaymentMethod('')}
//             >
//               <input
//                 type="radio"
//                 id="bank-transfer"
//                 name="payment-method"
//                 value="Bank Transfer"
//                 checked={paymentMethod === 'Bank Transfer'}
//                 onChange={(e) => setPaymentMethod(e.target.value)}
//                 style={{ accentColor: theme.primaryColor }}
//                 disabled={isFieldDisabled}
//               />
//               <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
//             </div>
//             {paymentMethod === 'Bank Transfer' && (
//               <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                 <span>Bank Account</span><br/> 
//                 <div className="flex justify-between items-center">
//                   <div>
//                     <li>FINOX TRADE MARKET SDN. BHD.</li>
//                     <li>Hong leong bank</li>
//                     <li>23600590742</li>
//                   </div>
//                   <FaClipboard 
//                     className="ml-2 cursor-pointer w-6 h-6"
//                     style={{ color: theme.primaryColor }} 
//                     onClick={() => copyToClipboard("23600590742")} 
//                   />
//                 </div>
//               </div>
//             )}
//             <div 
//               className="flex items-center"
//               onClick={() => setPaymentMethod('')}
//             >
//               <input
//                 type="radio"
//                 id="usdt"
//                 name="payment-method"
//                 value="USDT"
//                 checked={paymentMethod === 'USDT'}
//                 onChange={(e) => setPaymentMethod(e.target.value)}
//                 style={{ accentColor: theme.primaryColor }}
//                 disabled={isFieldDisabled}
//               />
//               <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
//             </div>
//             {paymentMethod === 'USDT' && (
//               <div className="ml-6 my-4 border border-slate-400 rounded-2xl p-4">
//                 <span>Wallet Address:</span><br/> 
//                 <div className="flex justify-between items-center">
//                   <div>
//                     <li>Tron (TRC20) Network</li>
//                     <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                   </div>
//                   <FaClipboard 
//                     className="ml-2 cursor-pointer w-6 h-6"
//                     style={{ color: theme.primaryColor }} 
//                     onClick={() => copyToClipboard("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")}
                     
//                   />
//                 </div>
//               </div>
//             )}
//           </div>
//           <div className="mb-4">
//             <div className="flex justify-between items-center mb-2">
//               <label className="block font-semibold" htmlFor="payment-receipts">Payment Receipts</label>
//               {/* {fileInputs.length < 5 && (
//                 <button
//                   type="button"
//                   className="flex items-center text-blue-500"
//                   onClick={addFileInput}
//                   style={{ color: theme.primaryColor }}
//                 >
//                   <span className="mr-2">Add file</span>
//                   <FaPlus />
//                 </button>
//               )} */}
//             </div>
//             {fileInputs.map((input, index) => (
//               <div key={index} className="mb-2">
//                 <input
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//                   type="file"
//                   onChange={(e) => handleReceiptUpload(e, index)}
//                   disabled={isFieldDisabled}
//                 />
//               </div>
//             ))}
//           </div>
//           {termsModalVisible && (
//             <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
//               <div className="bg-white p-10 rounded-lg max-w-lg w-full h-[700px] md:h-[600px] sm:h-[500px]">
//                 <div className="flex justify-between items-center mb-4 sticky top-0 bg-white z-10">
//                   <h2 className="text-xl font-semibold">Terms and Conditions</h2>
//                   <button onClick={handleCloseTermsModal} className="text-gray-500 hover:text-gray-800">
//                     <FaTimes className="w-6 h-6" />
//                   </button>
//                 </div>
//                 <div className="overflow-y-auto border-2 h-[calc(800px-48px)] md:h-[calc(500px-48px)] sm:h-[calc(500px-48px)]">
//                   {terms}
//                 </div>
//               </div>
//             </div>
//           )}
//           <div className="mb-6">
//             <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
//             <textarea className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="notes" 
//               placeholder="Leave any notes here (optional)..." 
//               value={notes} 
//               onChange={(e) => setNotes(e.target.value)}
//               disabled={isFieldDisabled}
//             />
//           </div>
//           <div className="mb-4">
//             <label className="flex items-center">
//               <input
//                 type="checkbox"
//                 checked={termsAccepted}
//                 onChange={handleTermsCheckboxChange}
//                 className="mr-2 h-6 w-6 md:w-4 sm:w-4 xs:w-4"
//                 style={{ accentColor: theme.primaryColor, color: theme.textColor}}
//               />
//               <span 
//                 onClick={handleTermsClick} 
//                 className="cursor-pointer text-blue-500 underline"
//                 style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                 I have read and understand the terms and conditions
//               </span>
//             </label>
//           </div>
//         </form>
//       </div>
//       <div className="flex justify-between items-center mt-4 space-x-4">
//         <div className="flex justify-between items-center w-full sm:text-sm xs:text-xs">
//           <button 
//             onClick={onBack} 
//             className="px-4 py-2 rounded-md flex items-center justify-center"
//             style={styles.button}>
//             <FaArrowCircleLeft />
//             <div className="ml-2">Back to Details</div>
//           </button>
//           <button 
//             type="button" 
//             className="px-4 py-2 rounded-md flex items-center justify-center"
//             onClick={handleBuyTicketClick}
//             disabled={loading || isButtonDisabled}
//             style={{ 
//               backgroundColor: isButtonDisabled ? 'gray' : theme.primaryColor, 
//               color: theme.text2Color, 
//               fontFamily: theme.fontFamily 
//             }}
//           >
//             <IoTicketSharp />
//             <div className="ml-2">Buy Ticket</div>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MyTicketRegistration;
