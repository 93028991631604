// src/hooks/AdminDashboard/useSubProductSetting.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

interface Product {
  id?: string;
  productId: string;
  productName: string;
  productDescription: string;
  productImg: string | File | null; 
  productStatus: string;
}

interface Plan {
  id?: string;
  planId: string;
  LinkProductId: string;
  planName: string;
  planDescription: string;
  planFeatures: string;
  planAmount: number;
  planCurrency: string;
  planPeriod: number;
  planAccount: string;
  planStatus: string;
}

interface Trial {
  id?: string;
  trialId: string;
  trialLinkPlanId: string;
  trialName: string;
  trialPeriod: number;
  trialMembersType: string | null;  
  trialGroupName: string | null;  
  trialGroupMember: string | null; 
  trialStatus: string;
}

interface Voucher {
  id?: string;
  voucherId: string;
  VoucherLinkPlanID: string; 
  voucherName: string; 
  voucherCode: string; 
  voucherType: string;    
  voucherAmount?: number;     
  voucherPercentage?: number; 
  voucherPeriod: Date;
  voucherDays: number;
  voucherRedeemCount: number;
  voucherMemberType: string;
  voucherGroupName: string;
  voucherGroupMember: string;
  voucherStatus: string;
}

export const useSubSetting = () => {
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [trials, setTrials] = useState<Trial[]>([]);
  const [vouchers, setVouchers] = useState<Voucher[]>([]);
  const [loading, setLoading] = useState(true);
  const { apiUrl } = useEnv();

  //------------------------ Product -------------------------//
  const fetchProducts = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/products`);
      const data = await response.json();
      setProducts(data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const addProduct = async (product: Product) => {
    const formData = new FormData();
    formData.append('productName', product.productName);
    formData.append('productDescription', product.productDescription);
    if (product.productImg) {
      formData.append('productImg', product.productImg); 
    }
  
    await fetch(`${apiUrl}/products`, {
      method: 'POST',
      body: formData,
    });
    fetchProducts();
  };

  const updateProduct = async (productId: string, product: Product) => {
    await fetch(`${apiUrl}/products/${productId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(product),
    });
    fetchProducts();
  };

  const deleteProduct = async (productId: string) => {
    await fetch(`${apiUrl}/products/${productId}`, { 
      method: 'DELETE' 
    });
    fetchProducts();
  };

  //------------------------ Plan -------------------------//
  const fetchPlan = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/plans`);
      const data = await response.json();
      setPlans(data);

      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch plans', error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchPlan();
  }, [fetchPlan]);

  const addPlan = async (plan: Plan) => {
    try {
      const response = await fetch(`${apiUrl}/plans`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(plan),
      });
      if (!response.ok) {
        throw new Error('Error adding new plan');
      }
      const newPlan = await response.json(); 

      setPlans((prevPlans) => [...prevPlans, newPlan]); 
    } catch (error) {
      console.error('Failed to add plan:', error);
    }
  };  

  const updatePlan = async (planId: string, plan: Plan) => {
    await fetch(`${apiUrl}/plans/${planId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(plan),
    });
    fetchPlan();
  };

  const deletePlan = async (planId: string) => {
    await fetch(`${apiUrl}/plans/${planId}`, { 
      method: 'DELETE' 
    });
    fetchPlan();
  };

  //------------------------ Trial set -------------------------//
  const fetchTrial = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/trials`);
      const data = await response.json();
      setTrials(data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch trials', error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchTrial();
  }, [fetchTrial]);

  const addTrial = async (trial: Trial) => {
    try {
      const response = await fetch(`${apiUrl}/trials`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(trial),
      });
      if (!response.ok) {
        throw new Error('Error adding new plan');
      }
      const newTrial = await response.json(); 

      setTrials((prevTrials) => [...prevTrials, newTrial]); 
    } catch (error) {
      console.error('Failed to add plan:', error);
    }
  };  

  const updateTrial = async (trialId: string, trial: Trial) => {
    await fetch(`${apiUrl}/trials/${trialId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(trial),
    });
    fetchTrial();
  };

  const deleteTrial = async (trialId: string) => {
    await fetch(`${apiUrl}/trials/${trialId}`, { 
      method: 'DELETE' 
    });
    fetchTrial();
  };

  //------------------------ Product -------------------------//
  const fetchVoucher = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/vouchers`);
      const data = await response.json();
      setVouchers(data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch voucher', error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchVoucher();
  }, [fetchVoucher]);

  const addVoucher = async (voucher: Voucher) => {
    try {
      const response = await fetch(`${apiUrl}/vouchers`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(voucher),
      });
      if (!response.ok) {
        throw new Error('Error adding new voucher');
      }
      const newVoucher = await response.json();
      setVouchers((prevVouchers) => [...prevVouchers, newVoucher]);
    } catch (error) {
      console.error('Failed to add voucher:', error);
    }
  }; 

  const updateVoucher = async (voucherId: string, voucher: Voucher) => {
    try {
      await fetch(`${apiUrl}/vouchers/${voucherId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(voucher),
      });
      fetchVoucher();
    } catch (error) {
      console.error('Failed to update voucher:', error);
    }
  };

  const deleteVoucher = async (voucherId: string) => {
    await fetch(`${apiUrl}/vouchers/${voucherId}`, { 
      method: 'DELETE' 
    });
    fetchVoucher();
  };

  // -------------------------- show email list ----------------------------- //
  // Fetch user emails for the custom group modal
  const fetchUserEmails = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/users`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch user emails');
      }

      const data = await response.json();

      if (data.emails && Array.isArray(data.emails)) {
        setUserEmails(data.emails);
      } else {
        throw new Error('Invalid email data format');
      }
    } catch (error) {
      console.error('Error fetching user emails:', error);
    }
  }, [apiUrl]);  

  useEffect(() => {
    fetchUserEmails();
  }, [fetchUserEmails]);  

  return { 
    products, plans, trials, vouchers, userEmails, loading, 
    addProduct, updateProduct, deleteProduct,
    addPlan, updatePlan, deletePlan,
    addTrial, updateTrial, deleteTrial,
    addVoucher, updateVoucher, deleteVoucher,
    fetchUserEmails
  };
};


// // udpate code --> 29 oct 2024
// // src/hooks/AdminDashboard/useSubProductSetting.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface Product {
//   id?: string;
//   productId: string;
//   productName: string;
//   productDescription: string;
//   productImg: string;
// }

// export const useSubSetting = () => {
//   const [products, setProducts] = useState<Product[]>([]);
//   const [loading, setLoading] = useState(true);
//   const { apiUrl } = useEnv();

//   const fetchProducts = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/products`);
//       const data = await response.json();
//       setProducts(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch products', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   const addProduct = async (product: Product) => {
//     await fetch(`${apiUrl}/products`, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(product),
//     });
//     fetchProducts();
//   };

//   const updateProduct = async (id: string, product: Product) => {
//     await fetch(`${apiUrl}/products/${id}`, {
//       method: 'PUT',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(product),
//     });
//     fetchProducts();
//   };

//   const deleteProduct = async (id: string) => {
//     await fetch(`${apiUrl}/products/${id}`, { method: 'DELETE' });
//     fetchProducts();
//   };

//   return { products, loading, addProduct, updateProduct, deleteProduct };
// };
