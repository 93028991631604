// src/components/admin/AdminSetting/AdminAssignMemberModal.tsx

import React, { useState, useEffect } from 'react';
import { RiMailAddLine } from 'react-icons/ri';
import { FaExclamationCircle } from "react-icons/fa";
import AssignAdminEmail from './AdminUserAssignEmail';
import { useAdminUsers, AssignUser } from '../../../hooks/AdminDashboard/useAdminRoles';
import { stateOptions } from '../../../hooks/Login/useFormValidation';

interface AdminAssignMemberModalProps {
  onClose: () => void;
  handleAssignMembers: (formData: {
    assignId?: string;
    assignAccount: string;
    assignedEmails: string[];
    assignGroup: string;
    assignPermission: string;
    assignStatus: string;
  }) => void;
  initialAssignedEmails?: string[];
  initialData?: AssignUser; 
  isEdit?: boolean;
}

const AdminAssignMemberModal: React.FC<AdminAssignMemberModalProps> = ({
  onClose,
  handleAssignMembers,
  initialAssignedEmails = [],
  initialData,
  isEdit = false,
}) => {
  const { adminUsers, fetchAdminUsers } = useAdminUsers();
  const [assignedEmails, setAssignedEmails] = useState<string[]>(initialAssignedEmails);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [assignAccount, setAssignAccount] = useState<string>(initialData?.assignAccount || '');
  const [assignGroup, setAssignGroup] = useState<string>(initialData?.assignGroup || '');
  const [assignStatus, setAssignStatus] = useState<string>(initialData?.assignStatus || 'Active');

  // Fetch the admin users when the component loads
  useEffect(() => {
    fetchAdminUsers(); 
  }, [fetchAdminUsers]);

  useEffect(() => {
  
    if (initialData) {
      setAssignAccount(initialData.assignAccount || "");
      setAssignGroup(initialData.assignGroup || "");
      setAssignStatus(initialData.assignStatus || "Active");
  
      // Split assignMember string into array if available
      const emails = initialData.assignMember ? initialData.assignMember.split(", ") : [];
      setAssignedEmails(emails);
    }
  }, [initialData]);  

  const handleOpenEmailModal = () => {
    setIsEmailModalOpen(true);
  };

  const handleCloseEmailModal = () => {
    setIsEmailModalOpen(false);
  };

  const handleAddEmail = (emailList: string[]) => {
    setAssignedEmails(emailList.filter(email => email));
    setIsEmailModalOpen(false);
  };

  const handleAccountEmailChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedEmail = e.target.value;
    setAssignAccount(selectedEmail);
  };

  const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedGroup = e.target.value;
    setAssignGroup(selectedGroup);
  };

  const handleSubmit = () => {
    const formData = {
      assignId: initialData?.assignId,
      assignAccount,
      assignedEmails,
      assignGroup, 
      assignPermission: '',
      assignStatus: assignStatus || '',
    };

    handleAssignMembers(formData); 
    onClose(); 
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full h-auto max-h-screen">
        <h2 className="text-xl font-bold mb-4 text-cyan-800">
          {isEdit ? "Edit Assign Member" : "Assign Members"}
        </h2>

        {/* assign id field */}
        <div className="mb-4">
          <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
            Assign id
          </label>
          <input
            type="text"
            name="assignId"
            className="text-xs w-full px-4 py-2 border rounded-md"
            disabled
            placeholder="Auto generate assignId..."
          />
        </div>

        {/* assign admin account field */}
        <div className="mb-4">
          <div className="flex justify-between items-center mb-1">
            <label className="block text-sm text-cyan-800 font-semibold ml-2">
              Assign Account Email
            </label>
            <select 
              className="text-xs"
              name="assignAccount"
              value={assignAccount}
              onChange={handleAccountEmailChange}
              required
            >
              <option value="">Select an admin account</option>
              {adminUsers.map((user) => (
                <option key={user.roleId} value={user.email}>
                  {user.email}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-between items-center">
            <input
              type="email"
              name="assignAccount"
              className="text-xs w-full px-4 py-2 border rounded-md"
              value={assignAccount}
              readOnly
              required
              disabled
            />
          </div>
        </div>

        {/* assign member to admin account field */}
        <div className="mb-4">
          <div className="flex justify-between items-center">
            <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
              Assign to Members
            </label>
            <div
              className="flex justify-normal items-center text-sm text-cyan-800 cursor-pointer"
              onClick={handleOpenEmailModal}
            >
              <RiMailAddLine />
              <span className="text-xs ml-2">Add Emails</span>
            </div>
          </div>
          <input
            type="text"
            value={assignedEmails.length > 0 ? `${assignedEmails.length} emails assigned` : 'No emails assigned'}
            onClick={handleOpenEmailModal}
            className="text-xs w-full px-4 py-2 border rounded-md"
            readOnly
          />
        </div>

        {/* assign group field */}
        <div className="mb-4">
          <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
            Assign to Group 
          </label>
          <select
            name="assignGroup"
            value={assignGroup}
            onChange={handleGroupChange}
            className="text-xs w-full px-4 py-2 border rounded-md"
          >
            <option value="">Select a Group</option>
            <option value="All State">All States</option>
            {stateOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* assign permission field */}
        <div className="mb-4">
          <div className="flex justify-normal items-center">
            <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
              Assign Permission
            </label>
            <div className="flex justify-normal items-center ml-2 text-rose-600 text-xs mb-1">
              <FaExclamationCircle className="mr-1"/>
              <span className="font-normal">
                (leave blank if no permission) 
              </span>
            </div>
          </div>
          <input
            type="text"
            name="assignPermission"
            className="text-xs w-full px-4 py-2 border rounded-md"
            placeholder="Default assign permission..."
            disabled
          />
        </div>

        {/* assign status field */}
        <div className="mb-4">
          <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
            Assign Status
          </label>
          <select
            name="assignStatus"
            value={assignStatus}
            onChange={(e) => setAssignStatus(e.target.value)}
            className="text-xs w-full px-4 py-2 border rounded-md"
          >
            <option value="">Select a Status</option>
            <option value="Active">Active</option>
            <option value="Deactive">Deactive</option>
          </select>
        </div>

        {/* function buttons */}
        <div className="flex justify-end mt-4">
          <button
            type="button"
            className="mr-4 bg-gray-400 text-white rounded-md px-8 py-1"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="text-sm bg-cyan-800 text-white rounded-md px-4 py-1"
            onClick={handleSubmit}
          >
            {isEdit ? "Update Member" : "Assign Members"}
          </button>
        </div>

        {isEmailModalOpen && (
          <AssignAdminEmail
            isOpen={isEmailModalOpen}
            onClose={handleCloseEmailModal}
            handleAddEmail={handleAddEmail}
            initialEmails={assignedEmails}
          />
        )}
      </div>
    </div>
  );
};

export default AdminAssignMemberModal;

// // udpatre code --> 28 oct 2024
// // src/components/admin/AdminSetting/AdminAssignMemberModal.tsx

// import React, { useState, useEffect } from 'react';
// import { RiMailAddLine } from 'react-icons/ri';
// import { FaExclamationCircle } from "react-icons/fa";
// import AssignAdminEmail from './AdminUserAssignEmail';
// import { useAdminUsers } from '../../../hooks/AdminDashboard/useAdminRoles';
// import { stateOptions } from '../../../hooks/Login/useFormValidation';

// interface AdminAssignMemberModalProps {
//   onClose: () => void;
//   handleAssignMembers: (formData: { 
//     assignId: string; 
//     assignAccount: string; 
//     assignedEmails: string[]; 
//     assignGroup: string; 
//     assignPermission: string; 
//     assignStatus: string; 
//   }) => void;
//   initialAssignedEmails?: string[];
// }

// const AdminAssignMemberModal: React.FC<AdminAssignMemberModalProps> = ({
//   onClose,
//   handleAssignMembers,
//   initialAssignedEmails = [],
// }) => {
//   const { adminUsers, fetchAdminUsers } = useAdminUsers();
//   const [assignedEmails, setAssignedEmails] = useState<string[]>(initialAssignedEmails);
//   const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
//   const [assignAccount, setAssignAccount] = useState<string>('');
//   const [assignId, setAssignId] = useState<string>(''); 
//   const [assignGroup, setAssignGroup] = useState<string>('');
//   const [assignStatus, setAssignStatus] = useState<string>('Active');

//   // Fetch the admin users when the component loads
//   useEffect(() => {
//     fetchAdminUsers(); 
//   }, [fetchAdminUsers]);

//   // Set the initial assign ID (fetch or calculate from the backend)
//   useEffect(() => {
//     if (adminUsers.length > 0) {
//       // Fetch the last assign ID or use a method to determine the next available ID
//       const fetchAssignId = async () => {
//         try {
//           // Assuming you have an endpoint to get the next available ID or fetch all existing IDs
//           const lastAssignId = adminUsers.length > 0 ? adminUsers[adminUsers.length - 1].roleId : 'as-000';
//           const lastIdNumber = parseInt(lastAssignId.split('-')[1]) + 1;
//           setAssignId(`as-${String(lastIdNumber).padStart(3, '0')}`);
//         } catch (error) {
//           console.error('Error fetching assign ID:', error);
//         }
//       };
      
//       fetchAssignId();
//     }
//   }, [adminUsers]);

//   const handleOpenEmailModal = () => {
//     setIsEmailModalOpen(true);
//   };

//   const handleCloseEmailModal = () => {
//     setIsEmailModalOpen(false);
//   };

//   const handleAddEmail = (emailList: string[]) => {
//     setAssignedEmails(emailList.filter(email => email));
//     setIsEmailModalOpen(false);
//   };

//   const handleAccountEmailChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedEmail = e.target.value;
//     setAssignAccount(selectedEmail);
//   };

//   const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedGroup = e.target.value;
//     setAssignGroup(selectedGroup);
//   };

//   const handleSubmit = () => {
//     const formData = {
//       assignId,
//       assignAccount,
//       assignedEmails,
//       assignGroup, 
//       assignPermission: '',
//       assignStatus: assignStatus || '',
//     };

//     handleAssignMembers(formData); 
//     onClose(); 
//   };

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full h-auto max-h-screen">
//         <h2 className="text-xl font-bold mb-4 text-cyan-800">Assign Members</h2>

//         {/* assign id field */}
//         <div className="mb-4">
//           <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//             Assign id
//           </label>
//           <input
//             type="text"
//             name="assignId"
//             className="text-xs w-full px-4 py-2 border rounded-md"
//             disabled
//             placeholder="Auto generate assignId..."
//           />
//         </div>

//         {/* assign admin account field */}
//         <div className="mb-4">
//           <div className="flex justify-between items-center mb-1">
//             <label className="block text-sm text-cyan-800 font-semibold ml-2">
//               Assign Account Email
//             </label>
//             <select 
//               className="text-xs"
//               name="assignAccount"
//               value={assignAccount}
//               onChange={handleAccountEmailChange}
//               required
//             >
//               <option value="">Select an admin account</option>
//               {adminUsers.map((user) => (
//                 <option key={user.roleId} value={user.email}>
//                   {user.email}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className="flex justify-between items-center">
//             <input
//               type="email"
//               name="assignAccount"
//               className="text-xs w-full px-4 py-2 border rounded-md"
//               value={assignAccount}
//               readOnly
//               required
//               disabled
//             />
//           </div>
//         </div>

//         {/* assign member to admin account field */}
//         <div className="mb-4">
//           <div className="flex justify-between items-center">
//             <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//               Assign to Members
//             </label>
//             <div
//               className="flex justify-normal items-center text-sm text-cyan-800 cursor-pointer"
//               onClick={handleOpenEmailModal}
//             >
//               <RiMailAddLine />
//               <span className="text-xs ml-2">Add Emails</span>
//             </div>
//           </div>
//           <input
//             type="text"
//             value={assignedEmails.length > 0 ? `${assignedEmails.length} emails assigned` : 'No emails assigned'}
//             onClick={handleOpenEmailModal}
//             className="text-xs w-full px-4 py-2 border rounded-md"
//             readOnly
//           />
//         </div>

//         {/* assign group field */}
//         <div className="mb-4">
//           <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//             Assign to Group 
//           </label>
//           <select
//             name="assignGroup"
//             value={assignGroup}
//             onChange={handleGroupChange}
//             className="text-xs w-full px-4 py-2 border rounded-md"
//           >
//             <option value="">Select a Group</option>
//             {stateOptions.map(option => (
//               <option key={option.value} value={option.value}>
//                 {option.label}
//               </option>
//             ))}
//           </select>
//         </div>

//         {/* assign permission field */}
//         <div className="mb-4">
//           <div className="flex justify-normal items-center">
//             <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//               Assign Permission
//             </label>
//             <div className="flex justify-normal items-center ml-2 text-rose-600 text-xs mb-1">
//               <FaExclamationCircle className="mr-1"/>
//               <span className="font-normal">
//                 (leave blank if no permission) 
//               </span>
//             </div>
//           </div>
//           <input
//             type="text"
//             name="assignPermission"
//             className="text-xs w-full px-4 py-2 border rounded-md"
//             placeholder="Default assign permission..."
//             disabled
//           />
//         </div>

//         {/* assign status field */}
//         <div className="mb-4">
//           <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//             Assign Status
//           </label>
//           <select
//             name="assignStatus"
//             value={assignStatus}
//             onChange={(e) => setAssignStatus(e.target.value)}
//             className="text-xs w-full px-4 py-2 border rounded-md"
//           >
//             <option value="">Select a Status</option>
//             <option value="Active">Active</option>
//             <option value="Deactive">Deactive</option>
//           </select>
//         </div>

//         {/* function buttons */}
//         <div className="flex justify-end mt-4">
//           <button
//             type="button"
//             className="mr-4 bg-gray-400 text-white rounded-md px-8 py-1"
//             onClick={onClose}
//           >
//             Cancel
//           </button>
//           <button
//             type="button"
//             className="text-sm bg-cyan-800 text-white rounded-md px-4 py-1"
//             onClick={handleSubmit}
//           >
//             Assign Members
//           </button>
//         </div>

//         {isEmailModalOpen && (
//           <AssignAdminEmail
//             isOpen={isEmailModalOpen}
//             onClose={handleCloseEmailModal}
//             handleAddEmail={handleAddEmail}
//             initialEmails={assignedEmails}
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default AdminAssignMemberModal;
