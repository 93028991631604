// src/components/admin/admin07_FundProgram.tsx

import React, { useState } from 'react';
import FundProgramPending from './FundedProgram/FundProgramPending';
import FundProgramData from './FundedProgram/FundProgramData';
import FundProgramSetting from './FundedProgram/FundProgramSetting';

const FundProgram: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'pending' | 'participants'>('participants');

  return (
    <div className="px-4 py-8">
      <div className="font-bold text-xl text-cyan-800">
        Funded Program Management Center
      </div>

      {/* Tab Navigation */}
      <div className="flex justify-center border-b mb-4 mt-8">
        <button
          className={`px-4 py-2 font-semibold ${
            activeTab === 
            'pending' ? 'text-white border-cyan-800 bg-cyan-800 rounded-tl-md' : 
            'text-cyan-800 bg-gray-200 rounded-tl-lg'
          }`}
          onClick={() => setActiveTab('pending')}
        >
          Fund Pending
        </button>
        <button
          className={`px-4 py-2 font-semibold ${
            activeTab === 
            'participants' ? 'text-white border-cyan-800 bg-cyan-800 rounded-tr-md' : 
            'text-cyan-800 bg-gray-200 rounded-tr-md'
          }`}
          onClick={() => setActiveTab('participants')}
        >
          Fund Participants
        </button>
      </div>

      {/* Render Active Tab */}
      {activeTab === 'pending' && <FundProgramPending />}
      {activeTab === 'participants' && <FundProgramData />}
      
      {/* Funded Program Setting*/}
      <FundProgramSetting />
    </div>
  );
};

export default FundProgram;
