// src/components/admin/SubProductSetting/SubProductModal.tsx

import React, { useState, useEffect } from 'react';
import { useEnv } from '../../../context/EnvContext'; 

interface SubProductModalProps {
  onClose: () => void;
  onSave: (product: 
    { 
      productId: string; 
      productName: string; 
      productDescription: string;
      productStatus: string; 
      productImg: File | null | string;
    }) => void;
    editMode?: boolean;
    initialData?: { 
      productId: string; 
      productName: string; 
      productDescription: string;
      productStatus: string; 
      productImg: File | null | string;
    };
  }

const SubProductModal: React.FC<SubProductModalProps> = ({ onClose, onSave, editMode = false, initialData }) => {
  const { apiUrl } = useEnv();
  const [productData, setProductData] = useState({
    productId: initialData?.productId || '',
    productName: initialData?.productName || '',
    productDescription: initialData?.productDescription || '',
    productStatus: initialData?.productStatus || 'Active',
    productImg: initialData?.productImg || null,
  });

  useEffect(() => {
    if (initialData) {
      setProductData({
        productId: initialData.productId,
        productName: initialData.productName,
        productDescription: initialData.productDescription,
        productStatus: initialData.productStatus,
        productImg: initialData.productImg || null,
      });
    }
  }, [initialData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setProductData((prevData) => ({ ...prevData, productImg: file }));
  };

  const handleSave = () => {
    onSave(productData);
    onClose(); 
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-md shadow-md w-[400px]">
        <h2 className="text-xl font-semibold mb-4 text-cyan-800">
          {editMode ? "Edit Product" : "Add New Product"}
        </h2>
        
        {/* Product id field */}
        <div className="my-4">
          <label className="mx-4 font-semibold text-cyan-800">Product Id</label>
          <input
            type="text"
            name="productId"
            placeholder="id auto generated..."
            value={productData.productId}
            onChange={handleChange}
            className="border px-4 py-1 rounded mb-2 w-full text-sm"
            disabled
          />
        </div>

        {/* Product Name field */}
        <div className="my-4">
          <label className="mx-4 font-semibold text-cyan-800">Product Name</label>
          <input
            type="text"
            name="productName"
            placeholder="enter product name..."
            value={productData.productName}
            onChange={handleChange}
            className="border px-4 py-1 rounded mb-2 w-full text-sm"
          />
        </div>

        {/* Product Description field */}
        <div className="my-2">
          <label className="mx-4 font-semibold text-cyan-800">Product Description</label>
          <textarea
            name="productDescription"
            placeholder="enter product description..."
            value={productData.productDescription}
            onChange={handleChange}
            className="border px-4 py-1 rounded w-full text-sm"
          />
        </div>

        {/* Product Status field */}
        <div className="my-2">
          <label className="mx-4 font-semibold text-cyan-800">Product Status</label>
          <select
            name="productStatus"
            value={productData.productStatus}
            onChange={handleChange}
            className="border px-4 py-1 rounded w-full text-sm"
          >
            <option value="">Select a status</option>
            <option value="Active">Active</option>
            <option value="Stopped">Stopped</option>
          </select>
        </div>

        {/* Product Image field */}
        <div className="my-4">
          <label className="mx-4 font-semibold text-cyan-800">Product Image</label>
          {typeof productData.productImg === 'string' && productData.productImg ? (
            <img 
              src={`${apiUrl}${productData.productImg}`} 
              alt="Current Product" 
              className="w-20 h-20 mb-2" 
            />
          ) : null}
          <input
            type="file"
            name="productImg"
            onChange={handleFileChange} 
            className="border px-4 py-2 rounded w-full text-sm"
          />
        </div>

        <div className="flex justify-end space-x-2">
          <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
          <button onClick={handleSave} className="bg-cyan-800 text-white px-4 py-2 rounded">
            {editMode ? "Update" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubProductModal;
