// src/components/client/MiniApp/MyProgram.tsx

import React from 'react';
import { RiFundsFill } from "react-icons/ri";

const MyProgram: React.FC = () => {

  return (
    <div className="max-w-xl mx-auto p-4 rounded-lg w-auto h-auto max-h-screen 
          md:p-6">
      <h2 className="flex justify-center items-center text-2xl font-bold mb-4 text-center 
          sm:text-xl 
          xs:text-lg"
      >
        <RiFundsFill className="mr-2 w-6 h-6"/>
          Funded Trader Program
      </h2>
      <div className="overflow-y-auto w-auto h-auto 
            xl:h-[500px] 
            lg:h-[500px] 
            md:h-[600px] 
            sm:h-[500px] 
            xs:h-[300px]"
      >
        <p className="mb-6 
            xs:text-sm"
        >
          Welcome to "My Program," your gateway to two exceptional funded trader programs designed to elevate your trading career. 
          Explore the opportunities offered by our funded programs and find the perfect fit for your trading aspirations.
        </p>

        <h3 className="text-2xl font-semibold mb-4 
              sm:text-lg 
              xs:text-base"
        >
          180 Funded Trader Program
        </h3>
        <p className="mb-6 
            xs:text-sm"
        >
          The 180 Funded Trader Program is tailored for traders looking to scale their trading operations with the backing of substantial capital. This program offers:
        </p>
        <ul className="list-disc list-inside space-y-2 mb-6 
              xs:text-sm"
        >
          <li><span className="font-semibold">Generous Capital Allocation:</span> Receive up to $180,000 in trading capital based on your performance.</li>
          <li><span className="font-semibold">Comprehensive Support:</span> Benefit from professional guidance and resources to optimize your trading strategies.</li>
          <li><span className="font-semibold">Performance-Based Scaling:</span> Increase your capital allocation as you demonstrate consistent profitability and risk management.</li>
          <li><span className="font-semibold">Flexible Trading Conditions:</span> Enjoy the freedom to trade various markets and instruments.</li>
        </ul>
      </div>
      <div className="flex justify-evenly mt-4">
        <button className="font-semi py-2 px-6 rounded-full xs:text-xs">
          180 Program
        </button>
        <button className="font-semi py-2 px-6 rounded-full xs:text-xs">
          Go4Funds Program
        </button>
      </div>
    </div>
  );
};

export default MyProgram;


