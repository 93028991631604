// src/components/admin/FundedProgram/FundProgramSetting.tsx

import React, { useState, useEffect } from 'react';
import { IoMdTrash } from 'react-icons/io';
import { useFundProgramSetting } from '../../../hooks/AdminDashboard/useFundProgramSetting';
import FundProgramSettingModal from './FundProgramSettingModal';

const FundProgramSetting: React.FC = () => {
  const { fetchPrograms, addProgram, programs, loading } = useFundProgramSetting();
  const [newProgram, setNewProgram] = useState({
    fundNameSet: '',
    fundStageSet: '',
    fundSizeSet: '',
    fundFeatureSet: '',
    fundStatusSet: 'Running',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewProgram((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddProgram = async () => {
    await addProgram(newProgram);
    setNewProgram({
      fundNameSet: '',
      fundStageSet: '',
      fundSizeSet: '',
      fundFeatureSet: '',
      fundStatusSet: 'Running',
    });
    setIsModalOpen(false);
  };

  return (
    <div className="p-4 border rounded">
      <div className="font-bold text-cyan-800 text-xl">Evaluation Program Setting</div>
      <div className="flex justify-end mb-4 space-x-2">
        <button
          className="bg-cyan-800 text-white px-6 py-1 rounded"
          onClick={() => setIsModalOpen(true)}
        >
          Add Evaluation
        </button>
      </div>

      {/* Modal */}
      <FundProgramSettingModal
        isOpen={isModalOpen}
        newProgram={newProgram}
        onClose={() => setIsModalOpen(false)}
        onSave={handleAddProgram}
        onChange={handleInputChange}
      />

      {/* Program Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border text-sm">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              <th className="py-2 px-4 border-b">ID</th>
              <th className="py-2 px-4 border-b">Fund Name</th>
              <th className="py-2 px-4 border-b">Fund Stage</th>
              <th className="py-2 px-4 border-b">Fund Features</th>
              <th className="py-2 px-4 border-b">Fund Status</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={6} className="text-center py-8">Loading...</td>
              </tr>
            ) : programs && programs.length > 0 ? (
              programs.map((program) => (
                <tr key={program.fundSetId}>
                  <td className="py-2 px-4">{program.fundSetId}</td>
                  <td className="py-2 px-4">{program.fundNameSet}</td>
                  <td className="py-2 px-4">{program.fundStageSet}</td>
                  <td className="py-2 px-4">{program.fundFeatureSet}</td>
                  <td className="py-2 px-4">{program.fundStatusSet}</td>
                  <td className="py-2 px-4">
                    <button className="text-red-600 hover:text-red-800">
                      <IoMdTrash size={20} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center py-8">No Programs Available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FundProgramSetting;

