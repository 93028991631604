// src/hooks/Dashboard/useUserNotification.tsx

import { useState, useEffect } from 'react';
import { useEnv } from '../../context/EnvContext';
import { useAuth } from '../../context/AuthContext';

interface Notification {
  // Event Data
  ticketId: string;
  eventTitle: string;
  username: string;
  status: string;
  createdAt: string;
  email: string; 
  type: string;
  read: boolean;
  expiredDate: string;
  // Subscription Data
  subOrderId: string;
  subProductId: string;
  subPlanId: string;
  subUserName: string;
  subUserEmail: string;
  subStatus: string;
  subDateSubmit: string;
  subExpiredDate: string;
}

const useUserNotification = () => {
  const { apiUrl } = useEnv();
  const { user } = useAuth(); 
  const [ notifications, setNotifications ] = useState<Notification[]>([]);
  const [ loading, setLoading ] = useState<boolean>(true);
  const [ error, setError ] = useState<string | null>(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setLoading(true);

        const response = await fetch(`${apiUrl}/api/notifications`);
        const data = await response.json();

        const userNotifications = data
          .filter((notification: Notification) => notification.email === user?.email)
          .map((notification: Notification) => {

            if (notification.type === 'subscription') {
              const currentDate = new Date();
              const expiredDate = new Date(notification.subExpiredDate);
              const daysLeft = (expiredDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);
              
              if (daysLeft <= 14) {
                notification.subPlanId = `Your subscription will expire in ${Math.ceil(daysLeft)} days. Please renew your subscription.`;
              }
            }
            return notification;
          });

        setNotifications(userNotifications);
      } catch (err) {
        console.error('Failed to fetch notifications:', err);
        setError('Failed to fetch notifications');
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [apiUrl, user]);

  // Function to mark a notification as read in the backend
  const markNotificationAsRead = async (item: string, isSubscription: boolean) => {
    try {
      const userEmail = user?.email;
  
      if (!userEmail) throw new Error("User email is not available");
  
      const notificationItem = isSubscription ? item : item;
  
      await fetch(`${apiUrl}/api/notifications/${notificationItem}/read`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userEmail }),
      });
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };   

  return { notifications, loading, error, setNotifications, markNotificationAsRead };
};

export default useUserNotification;


// // udpate code --> 25 nov 2024
// // src/hooks/Dashboard/useUserNotification.tsx

// import { useState, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import { useAuth } from '../../context/AuthContext';

// interface Notification {
//   // Event Data
//   ticketId: string;
//   eventTitle: string;
//   username: string;
//   status: string;
//   createdAt: string;
//   email: string; 
//   type: string;
//   read: boolean;
//   expiredDate: string;
//   // Subscription Data
//   subOrderId: string;
//   subProductId: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subStatus: string;
//   subDateSubmit: string;
//   subExpiredDate: string;
// }

// const useUserNotification = () => {
//   const { apiUrl } = useEnv();
//   const { user } = useAuth(); 
//   const [ notifications, setNotifications ] = useState<Notification[]>([]);
//   const [ loading, setLoading ] = useState<boolean>(true);
//   const [ error, setError ] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         setLoading(true);

//         const response = await fetch(`${apiUrl}/api/notifications`);
//         const data = await response.json();

//         const userNotifications = data
//           .filter((notification: Notification) => notification.email === user?.email)
//           .map((notification: Notification) => {

//             if (notification.type === 'subscription') {
//               const currentDate = new Date();
//               const expiredDate = new Date(notification.subExpiredDate);
//               const daysLeft = (expiredDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);
              
//               if (daysLeft <= 14) {
//                 notification.subPlanId = `Your subscription will expire in ${Math.ceil(daysLeft)} days. Please renew your subscription.`;
//               }
//             }
//             return notification;
//           });

//         setNotifications(userNotifications);
//       } catch (err) {
//         console.error('Failed to fetch notifications:', err);
//         setError('Failed to fetch notifications');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchNotifications();
//   }, [apiUrl, user]);

//   // Function to mark a notification as read in the backend
//   const markNotificationAsRead = async (item: string, isSubscription: boolean) => {
//     try {
//       const userEmail = user?.email;
  
//       if (!userEmail) throw new Error("User email is not available");
  
//       const notificationItem = isSubscription ? item : item;
  
//       await fetch(`${apiUrl}/api/notifications/${notificationItem}/read`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ userEmail }),
//       });
//     } catch (error) {
//       console.error('Failed to mark notification as read:', error);
//     }
//   };   

//   return { notifications, loading, error, setNotifications, markNotificationAsRead };
// };

// export default useUserNotification;


// // update code --> 14 Aug 2024
// // src/hooks/Dashboard/useUserNotification.tsx

// import { useState, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import { useAuth } from '../../context/AuthContext';

// interface Notification {
//   ticketId: string;
//   eventTitle: string;
//   username: string;
//   status: string;
//   createdAt: string;
//   email: string; 
//   type: string;
//   read: boolean;
// }

// const useUserNotification = () => {
//   const { apiUrl } = useEnv();
//   const { user } = useAuth(); 
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(`${apiUrl}/api/notifications`);
//         const data = await response.json();

//         // Filter notifications based on user's email
//         const userNotifications = data.filter((notification: Notification) => notification.email === user?.email);
//         setNotifications(userNotifications);
//       } catch (err) {
//         setError('Failed to fetch notifications');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchNotifications();
//   }, [apiUrl, user]);

//   return { notifications, loading, error, setNotifications };
// };

// export default useUserNotification;



