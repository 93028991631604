// src/components/admin/FundProgramPending/FundProgramPending.tsx

import React from 'react';

const FundProgramPending: React.FC = () => {
  return (
    <div className="p-4 border rounded">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border text-sm">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              <th className="py-2 px-4 border-b">User ID</th>
              <th className="py-2 px-4 border-b">User Name</th>
              <th className="py-2 px-4 border-b">Requested Amount</th>
              <th className="py-2 px-4 border-b">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={4} className="text-center py-8">No pending requests available</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FundProgramPending;
