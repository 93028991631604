// src/components/admin/FundedProgram/FundProgramSettingModal.tsx

import React from 'react';
import { IoMdClose } from 'react-icons/io';

interface FundProgramSettingModalProps {
  newProgram: {
    fundNameSet: string;
    fundStageSet: string;
    fundSizeSet: string;
    fundFeatureSet: string;
    fundStatusSet: string;
  };
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const FundProgramSettingModal: React.FC<FundProgramSettingModalProps> = ({
  newProgram,
  isOpen,
  onClose,
  onSave,
  onChange,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[400px] relative">
        <button
          className="absolute top-2 right-2 text-gray-500"
          onClick={onClose}
        >
          <IoMdClose size={20} />
        </button>
        <div>
          <h2 className="text-lg font-bold">Add New Program</h2>
          <div className="flex flex-col ">
            {/* Fund Name */}
            <div className="mb-4 mt-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Name</label>
              <input
                type="text"
                name="fundNameSet"
                value={newProgram.fundNameSet}
                placeholder="set funded name..."
                onChange={onChange}
                className="border px-2 py-1 rounded w-full"
              />
            </div>

            {/* Fund Stage */}
            <div className="mb-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Stage</label>
              <input
                type="text"
                name="fundStageSet"
                value={newProgram.fundStageSet}
                placeholder="set funded stage..."
                onChange={onChange}
                className="border px-2 py-1 rounded w-full"
              />
            </div>

            {/* Fund Size */}
            <div className="mb-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Size</label>
              <input
                type="number"
                name="fundSizeSet"
                value={newProgram.fundSizeSet}
                placeholder="set funded size..."
                onChange={onChange}
                className="border px-2 py-1 rounded w-full"
              />
            </div>

            {/* Fund Feature */}
            <div className="mb-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Feature</label>
              <input
                type="text"
                name="fundFeatureSet"
                value={newProgram.fundFeatureSet}
                placeholder="set funded feature..."
                onChange={onChange}
                className="border px-2 py-1 rounded w-full"
              />
            </div>

            {/* Fund Status */}
            <div className="mb-4">
              <label className="mx-2 font-semibold text-cyan-800">Fund Status</label>
              <select
                name="fundStatusSet"
                value={newProgram.fundStatusSet}
                onChange={onChange}
                className="border px-2 py-1 rounded w-full"
              >
                <option value="Running">Running</option>
                <option value="Stopped">Stopped</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <button
              className="bg-cyan-800 text-white px-6 py-1 rounded"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundProgramSettingModal;
