// src/components/admin/SubProductSetting/SubProductModal.tsx

import React, { useState, useEffect } from 'react';
import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';
import EmailList from '../SubProductSetting/subEmailModal';

interface SubVoucherModalProps {
  onClose: () => void;
  onSave: (
    voucher: { 
      voucherId: string;
      VoucherLinkPlanID: string; 
      voucherName: string; 
      voucherCode: string; 
      voucherType: string;
      voucherAmount?: number;
      voucherPercentage?: number;
      voucherPeriod: Date;
      voucherDays: number;
      voucherRedeemCount: number;
      voucherMemberType: string; 
      voucherGroupName: string; 
      voucherGroupMember: string;
      voucherStatus: string;
    }) => void;
    editMode?: boolean;
    initialData?: { 
      voucherId: string;
      VoucherLinkPlanID: string; 
      voucherName: string; 
      voucherCode: string; 
      voucherType: string;
      voucherAmount?: number;
      voucherPercentage?: number;
      voucherPeriod: Date;
      voucherDays: number;
      voucherRedeemCount: number;
      voucherMemberType: string; 
      voucherGroupName: string; 
      voucherGroupMember: string;
      voucherStatus: string;
    };
  }

const SubVoucherModal: React.FC<SubVoucherModalProps> = ({ onClose, onSave, editMode = false, initialData }) => {
  // import custom hook
  const { products, plans } = useSubSetting();
  // custom states
  const [vouchersData, setVouchersData] = useState({
    voucherId: initialData?.voucherId || '',
    VoucherLinkPlanID: initialData?.VoucherLinkPlanID || '',
    voucherName: initialData?.voucherName || '',
    voucherCode: initialData?.voucherCode || '', 
    voucherType: initialData?.voucherType || '',
    voucherAmount: initialData?.voucherAmount ?? 0,
    voucherPercentage: initialData?.voucherPercentage ?? 0,
    voucherPeriod: initialData?.voucherPeriod instanceof Date ? initialData.voucherPeriod : new Date(),
    voucherDays: initialData?.voucherDays || 0,
    voucherRedeemCount: initialData?.voucherRedeemCount || 0,
    voucherMemberType: initialData?.voucherMemberType || '',
    voucherGroupName: initialData?.voucherGroupName || '',
    voucherGroupMember: initialData?.voucherGroupMember || '',
    voucherStatus: initialData?.voucherStatus || 'Active',
  });

  const [selectedPlans, setSelectedPlans] = useState<{ voucherId: string; displayName: string }[]>([]);
  const [selectedMemberTypes, setSelectedMemberTypes] = useState<string[]>(vouchersData.voucherMemberType ? vouchersData.voucherMemberType.split(',') : []);
  const [customGroup, setCustomGroup] = useState<{ groupName: string; groupMembers: string[] } | null>(
    initialData?.voucherGroupName ? { groupName: initialData.voucherGroupName, groupMembers: initialData.voucherGroupMember?.split(',') || [] } : null
  );

  useEffect(() => {
    if (plans.length > 0 && initialData?.VoucherLinkPlanID) {
      const initialSelectedPlans = initialData.VoucherLinkPlanID.split(',').map(planId => {
        const plan = plans.find(p => p.planId === planId);
        const product = products.find(p => p.productId === plan?.LinkProductId);
        return {
          voucherId: planId,  // Keeping voucherId here
          displayName: product && plan ? `${product.productName} - ${plan.planName}` : 'Unknown Plan',
        };
      });
      setSelectedPlans(initialSelectedPlans);
    }
  }, [plans, products, initialData?.VoucherLinkPlanID]);  

  const [isGroupModalOpen, setGroupModalOpen] = useState(false);

  const openGroupModal = () => setGroupModalOpen(true);
  const closeGroupModal = () => setGroupModalOpen(false);

  const handleAddGroup = (groupName: string, emails: string[]) => {
    const updatedGroupName = groupName || customGroup?.groupName || "New Group";
    setCustomGroup({ groupName: updatedGroupName, groupMembers: emails });
    setVouchersData(prevData => ({
      ...prevData,
      voucherGroupName: updatedGroupName,
      voucherGroupMember: emails.join(','),
    }));
    closeGroupModal();
  };

  useEffect(() => {
    if (initialData) {
      setVouchersData({
        voucherId: initialData.voucherId,
        VoucherLinkPlanID: initialData.VoucherLinkPlanID,
        voucherName: initialData.voucherName,
        voucherCode: initialData.voucherCode,
        voucherType: initialData.voucherType,
        voucherAmount: initialData.voucherAmount ?? 0,
        voucherPercentage: initialData.voucherPercentage ?? 0,
        voucherPeriod: initialData.voucherPeriod,
        voucherDays: initialData.voucherDays,
        voucherRedeemCount: initialData.voucherRedeemCount,
        voucherMemberType: initialData.voucherMemberType,
        voucherGroupName: initialData.voucherGroupName,
        voucherGroupMember: initialData.voucherGroupMember,
        voucherStatus: initialData.voucherStatus,
      });
    }
  }, [initialData]);

  const handlePlanSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPlanId = e.target.value;
    if (selectedPlanId && !selectedPlans.some(plan => plan.voucherId === selectedPlanId)) {
      const plan = plans.find(plan => plan.planId === selectedPlanId);
      const product = products.find(product => product.productId === plan?.LinkProductId);
      const displayName = product && plan ? `${product.productName} - ${plan.planName}` : 'Unknown Product';
  
      setSelectedPlans(prev => [...prev, { voucherId: selectedPlanId, displayName }]);
      setVouchersData(prevData => ({
        ...prevData,
        VoucherLinkPlanID: [...prevData.VoucherLinkPlanID.split(',').filter(Boolean), selectedPlanId].join(','),
      }));
    }
  };   

  const handleMemberTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = e.target.value;
    if (selectedType && !selectedMemberTypes.includes(selectedType)) {
      setSelectedMemberTypes(prev => [...prev, selectedType]);
      setVouchersData(prevData => ({
        ...prevData,
        trialMembersType: [...selectedMemberTypes, selectedType].join(','),
      }));
    }
  };    

  const handlePlanRemove = (voucherId: string) => {
    setSelectedPlans(prev => prev.filter(plan => plan.voucherId !== voucherId));
    setVouchersData(prevData => ({
      ...prevData,
      VoucherLinkPlanID: prevData.VoucherLinkPlanID
        .split(',')
        .filter(id => id !== voucherId)
        .join(','),
    }));
  };  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setVouchersData(prevData => ({
      ...prevData,
      [name]: name === "voucherPeriod" ? new Date(value) : value,
    }));
  };  

  const handleVoucherTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setVouchersData(prevData => ({
      ...prevData,
      voucherType: value,
      voucherAmount: value === "By Amount" ? prevData.voucherAmount : 0,
      voucherPercentage: value === "By Percentage" ? prevData.voucherPercentage : 0,
    }));
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();
    const dayDifference = Math.ceil((selectedDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24));

    setVouchersData(prevData => ({
      ...prevData,
      voucherPeriod: selectedDate,
      voucherDays: dayDifference >= 0 ? dayDifference : 0,
    }));
  };

  const handleAmountOrPercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setVouchersData(prevData => ({
      ...prevData,
      voucherAmount: vouchersData.voucherType === "By Amount" ? value : 0,
      voucherPercentage: vouchersData.voucherType === "By Percentage" ? value : 0,
    }));
  };

  const handleSave = () => {
    // Prepare the object to save based on voucher type
    const voucherDataToSave = {
      ...vouchersData,
      voucherMemberType: selectedMemberTypes.length > 0 ? selectedMemberTypes.join(',') : '', 
      voucherGroupName: customGroup?.groupName || '',
      voucherGroupMember: customGroup?.groupMembers.join(',') || '',
      voucherAmount: vouchersData.voucherType === "By Amount" ? vouchersData.voucherAmount : undefined,
      voucherPercentage: vouchersData.voucherType === "By Percentage" ? vouchersData.voucherPercentage : undefined,
    };
  
    // Call onSave with the updated voucher data
    onSave(voucherDataToSave);
    onClose();
  };      

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-md shadow-md w-[400px]">
        <h2 className="text-xl font-semibold mb-4 text-cyan-800">
          {editMode ? "Edit Voucher Sets" : "Add Voucher Sets"}
        </h2>
        
        <div className="overflow-y-auto h-auto max-h-[550px]">
          {/* Voucher id field */}

          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Voucher Id</label>
            <input
              type="text"
              name="voucherId"
              placeholder="id auto generated..."
              value={vouchersData.voucherId}
              onChange={handleChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
              disabled
            />
          </div>

          {/* Voucher Name field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Voucher Name</label>
            <input
              type="text"
              name="voucherName"
              placeholder="enter voucher name..."
              value={vouchersData.voucherName}
              onChange={handleChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
            />
          </div>

          {/* Link Plan for Voucher field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Voucher Link Plan</label>
            <select
              name="VoucherLinkPlanID"
              value=""
              onChange={handlePlanSelect}
              className="border px-4 py-1 rounded w-full text-sm"
            >
              <option value="">Select link plan</option>
              {plans.map(plan => {
                const product = products.find(product => product.productId === plan.LinkProductId);
                const productName = product ? product.productName : 'Unknown Product';

                return (
                  <option key={plan.planId} value={plan.planId}>
                    {`${productName} - ${plan.planName}`}
                  </option>
                );
              })}
            </select>

            <div className="mt-2 border-2 border-dashed text-sm p-6 rounded">
              {selectedPlans.length > 0 ? (
                selectedPlans.map(plan => (
                  <div key={plan.voucherId} className="flex items-center space-x-2">
                    <span>◾️{plan.displayName}</span>
                    <button 
                      onClick={() => handlePlanRemove(plan.voucherId)} 
                      className="text-red-500 text-xs"
                    >
                      Remove
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-gray-300 text-center">No plans selected</p>
              )}
            </div>
          </div>

          {/* Voucher Code field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Voucher Code</label>
            <input
              type="text"
              name="voucherCode"
              placeholder="enter voucher code..."
              value={vouchersData.voucherCode}
              onChange={handleChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
            />
          </div>

          {/* Voucher Type and Amount/Percentage field */}
          <div className="my-6">
            <div className="flex justify-between items-center">
              <label className="mx-4 font-semibold text-cyan-800">Voucher Type</label>
              <select
                name="voucherType"
                className="px-4 py-1 rounded w-auto text-sm text-center"
                value={vouchersData.voucherType}
                onChange={handleVoucherTypeChange}
              >
                <option value="0">Select a voucher type</option>
                <option value="By Amount">Amount</option>
                <option value="By Percentage">Percentage</option>
              </select>
            </div>
            <div className="flex items-center">
              {vouchersData.voucherType === "By Amount" && <span className="text-cyan-800 mr-2">$</span>}
              {vouchersData.voucherType === "By Percentage" && <span className="text-cyan-800 mr-2">%</span>}
              
              <input
                type="number"
                name="voucherValue" // Use a generic name here
                value={vouchersData.voucherType === "By Amount" ? vouchersData.voucherAmount : vouchersData.voucherPercentage}
                onChange={(e) => handleAmountOrPercentageChange(e)}
                className="border px-4 py-1 rounded w-full text-sm"
              />
            </div>
          </div>

          {/* Voucher Period and Days field */}
          <div className="my-6">
            <label className="mx-4 font-semibold text-cyan-800 text-nowrap">Voucher Period</label>
            <input
              type="date"
              name="voucherPeriod"
              value={vouchersData.voucherPeriod instanceof Date ? vouchersData.voucherPeriod.toISOString().substring(0, 10) : ''}
              onChange={handlePeriodChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
            />
            <div className="flex justify-end items-center">
              <label className="mx-4 font-semibold text-cyan-800">Voucher Days</label>
              <span className="font-semibold text-rose-500 text-sm">{vouchersData.voucherDays}</span>
            </div>
          </div>

          {/* Voucher redeem count field */}
          <div className="my-6">
            <label className="mx-4 font-semibold text-cyan-800">Voucher Redeem Count</label>
            <input
              type="number"
              name="voucherRedeemCount"
              value={vouchersData.voucherRedeemCount}
              onChange={handleChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
            />
          </div>

          {/* voucher Member Type field with mutiple selection */}
          <div className="my-6">
            <label className="mx-4 font-semibold text-cyan-800">Voucher Member Type</label>
            <select
              name="voucherMemberType"
              value=""
              onChange={handleMemberTypeChange}
              className="border px-4 py-1 rounded mb-2 w-full text-sm"
            >
              <option value="">Select a member type</option>
              <option value="R One Guest">R One Guest</option>
              <option value="R One Basic">R One Basic</option>
              <option value="R One Member">R One Member</option>
              <option value="R One Trader Hub">R One Trader Hub</option>
              <option value="R One Dealer">R One Dealer</option>
              <option value="R One Elite Trader">R One Elite Trader</option>
              <option value="R One Master Trader">R One Master Trader</option>
              <option value="R One Certified Trader">R One Certified Trader</option>
            </select>

            {/* Display selected member types below */}
            <div className="mt-2 border-2 border-dashed text-sm p-6 rounded">
              {selectedMemberTypes.length > 0 ? (
                selectedMemberTypes.map(type => (
                  <div key={type} className="flex items-center space-x-2">
                    <span>◾️{type}</span>
                    <button 
                      onClick={() => setSelectedMemberTypes(prev => prev.filter(t => t !== type))} 
                      className="text-red-500 text-xs"
                    >
                      Remove
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-gray-300 text-center">No member types selected</p>
              )}
            </div>
          </div>

          {/* Voucher Custom Group Name */}
          <div className="my-6">
            <label className="mx-4 font-semibold text-cyan-800">Voucher Custom Group</label>
            <div
              onClick={openGroupModal}
              className="border-2 border-dashed px-4 py-2 rounded text-sm mt-2 cursor-pointer"
            >
              {customGroup ? (
                <div className="p-2 rounded text-sm mt-2">
                  <div className="flex flex-col space-y-2">
                    <span className="text-rose-400">Custom Group Name:</span> {customGroup.groupName}
                    <span className="text-rose-400">Custom Email Lists</span>
                  </div>
                  <ul>
                    {customGroup.groupMembers.map(email => (
                      <li key={email}>{email}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p
                  onClick={openGroupModal}
                  className="text-gray-300 text-center cursor-pointer p-5 rounded"
                >
                  No custom group added. Click to create.
                </p>
              )}
            </div>
          </div>

          {/* Voucher Status field */}
          <div className="my-4">
            <label className="mx-4 font-semibold text-cyan-800">Voucher Status</label>
            <select
              name="voucherStatus"
              value={vouchersData.voucherStatus}
              onChange={handleChange}
              className="border px-4 py-1 rounded w-full text-sm"
            >
              <option value="">Select a status</option>
              <option value="Active">Active</option>
              <option value="Stopped">Stopped</option>
            </select>
          </div>
        </div>

        <div className="flex justify-end space-x-2 mt-4">
          <button onClick={onClose} className="bg-gray-500 text-white px-4 py-1 rounded">Cancel</button>
          <button onClick={handleSave} className="bg-cyan-800 text-white px-6 py-1 rounded">
            {editMode ? "Update" : "Add"}
          </button>
        </div>
      </div>

      <EmailList
        isOpen={isGroupModalOpen}
        onClose={closeGroupModal}
        handleAddEmail={handleAddGroup}
        initialEmails={customGroup?.groupMembers || []}
        initialGroupName={customGroup?.groupName || ''}
      />

    </div>
  );
};

export default SubVoucherModal;
