// src/components/admin/SubProductSetting/subPlanSetting.tsx

// import react component
import React, { useState } from 'react';
// import react icon
import { IoIosCheckbox, IoIosCheckboxOutline  } from "react-icons/io";
// import custom components
import { useSubSetting } from '../../../hooks/AdminDashboard/useSubProductSetting';
import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
import { useAuth } from '../../../context/AuthContext';   
import SubProductModal from './subPlanModal';

const SubPlanSetting: React.FC = () => {
  // import custom hook
  const { user } = useAuth();
  const { plans, products, loading, addPlan, updatePlan, deletePlan } = useSubSetting();
  const { permissions } = useRoleAccess(user?.email || '');
  // add/edit product modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isEditMode, setIsEditMode] = useState(false); 
  const [currentPlan, setCurrentPlan] = useState<any>(null);

  const handleAddPlan = async (plan: { 
    planId: string;
    LinkProductId: string; 
    planName: string; 
    planDescription: string; 
    planFeatures: string;
    planAmount: number;
    planCurrency: string;
    planPeriod: number;
    planAccount: string;
    planStatus: string;
  }) => {
    try {
      if (isEditMode && currentPlan) {
        // Update the existing plan
        await updatePlan(currentPlan.planId, plan);
      } else {
        // Add a new plan
        await addPlan(plan);
      }
    } catch (error) {
      console.error("Failed to save plan:", error);
    } finally {
      // Reset modal state and editing state
      setIsModalOpen(false);
      setCurrentPlan(null);
      setIsEditMode(false);
    }
  };
  
  const handleSelectRow = (planId: string) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(planId)
        ? prevSelectedRows.filter(id => id !== planId) 
        : [...prevSelectedRows, planId]
    );
  };

  const handleDeleteSelected = async () => {
    for (const planId of selectedRows) {
      await deletePlan(planId); 
    }
    setSelectedRows([]); 
  };

  // Handle row double-click to enter edit mode
  const handleEditPlan = (plan: any) => {
    setCurrentPlan(plan);
    setIsEditMode(true); 
    setIsModalOpen(true); 
  };

  return (
    <div className="p-4 border rounded">

      <div className="flex justify-end mb-2 space-x-2">
        {/* Toggle Multi-Select Mode */}
        <button 
          className={`text-xl mr-2 
            ${permissions?.productSubscription?.editSub !== 'enable'
              ? 'text-gray-400 cursor-not-allowed' 
              : 'text-cyan-800 '
            }`}
          onClick={() => setIsMultiSelectEnabled(!isMultiSelectEnabled)}
          disabled={permissions?.productSubscription?.editSub !== 'enable'}
        >
          {isMultiSelectEnabled ? <IoIosCheckbox /> : <IoIosCheckboxOutline />}
        </button>

        {/* Add Product Button */}
        <button 
          className={`px-6 py-1 rounded 
            ${permissions?.productSubscription?.addSub !== 'enable'
              ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
              : 'bg-cyan-800 text-white'
            }`}
          onClick={() => {
            setIsEditMode(false);
            setCurrentPlan(null);
            setIsModalOpen(true);
          }}
          disabled={permissions?.productSubscription?.addSub !== 'enable'}
        >
          Add Plan
        </button>

        {/* Remove Selected Products Button */}
        <button
          className={`px-6 py-1 rounded 
            ${permissions?.productSubscription?.deleteSub !== 'enable'
              ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
              : 'bg-cyan-800 text-white'
            }`}
          onClick={handleDeleteSelected}
          disabled={!selectedRows.length || permissions?.productSubscription?.deleteSub !== 'enable'}
        >
          Remove Plan
        </button>
      </div>

      {/* Plan Table */}
      <div className="overflow-x-auto w-auto">
        <table className="min-w-full bg-white border text-sm">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              {isMultiSelectEnabled && <th className="py-2 px-4 border-b">Select</th>}
              <th className="py-2 px-4 border-b text-nowrap">Plan Id</th>
              <th className="py-2 px-4 border-b text-nowrap">Link Product Id</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Name</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Description</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Features</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Amount</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Period</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Account</th>
              <th className="py-2 px-4 border-b text-nowrap">Plan Status</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} className="text-center py-8">Loading...</td>
              </tr>
            ) : plans.length > 0 ? (
              plans.map((plan) => (
                <tr 
                  key={plan.planId} 
                  className={`text-center border-b hover:bg-gray-50 ${
                    permissions?.productSubscription?.editSub === 'enable' ? '' : 'cursor-not-allowed pointer-events-none'
                  }`}
                  onDoubleClick={() => {
                    if (permissions?.productSubscription?.editSub === 'enable') {
                      handleEditPlan(plan);
                    }
                  }}
                >
                  {isMultiSelectEnabled && (
                    <td className="py-2 px-4">
                      <input
                        type="checkbox"
                        className="accent-cyan-800"
                        checked={selectedRows.includes(plan.planId)}
                        onChange={() => handleSelectRow(plan.planId)}
                      />
                    </td>
                  )}
                  <td className="py-2 px-4 text-nowrap">{plan.planId}</td>
                  <td className="py-2 px-4 text-nowrap">
                    {
                      products.find(product => product.productId === plan.LinkProductId)?.productName || plan.LinkProductId
                    }
                  </td>
                  <td className="py-2 px-4 text-nowrap">{plan.planName}</td>
                  <td className="py-2 px-4 text-wrap min-w-[400px]">{plan.planDescription}</td>
                  <td className="py-2 px-4 text-wrap min-w-[200px]">
                    {plan.planFeatures.split(',').map((feature, index) => (
                      <div key={index}>{feature.trim()}</div>
                    ))}
                  </td>
                  <td className="py-2 px-4 text-wrap">{plan.planAmount} {plan.planCurrency}</td>
                  <td className="py-2 px-4 text-wrap">{plan.planPeriod}</td>
                  <td className="py-2 px-4 text-wrap">{plan.planAccount}</td>
                  <td className={`py-2 px-4 text-wrap ${
                    plan.planStatus === 'Active' ? 'text-green-500 font-bold' : 'text-rose-500 font-bold'
                  }`}>
                    {plan.planStatus}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center py-8">No plan available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal for Adding Product */}
      {isModalOpen && (
        <SubProductModal
          onClose={() => setIsModalOpen(false)}
          onSave={handleAddPlan}
          editMode={isEditMode}
          initialData={currentPlan}
        />
      )}
    </div>
  );
};

export default SubPlanSetting;