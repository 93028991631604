// src/hooks/AdminDashboard/useSubUserData.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

export interface SubPending {
  subOrderId: string;
  subProductId: string;
  subProductName: string;
  subPlanId: string;
  subUserName: string;
  subUserEmail: string;
  subPlanType: string;
  subPlanAmount: number;
  subPlanPeriod: string;
  subDateSubmit: Date;
  subExpiredDate: Date;
  subExpiredDays: number;
  subStatus: string;
  subAccountType: string;
  subAccountServer: string;
  subAccountNumber: string;
  subAccountPassword: string;
  subTrialCheck: number;
  subVoucherCheck: number;
  subDemoCheck: number;
  subPaymentMethod: string;
  subPaymentReceipts: string;
  subNotes: string;
  subRemark: string;
  subTermAgreed: string;
}

export const useSubUserData = () => {
  const { apiUrl } = useEnv();
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [subPending, setSubPending] = useState<SubPending[]>([]);
  const [algoMatrixData, setAlgoMatrixData] = useState<SubPending[]>([]);
  const [traderHubData, setTraderHubData] = useState<SubPending[]>([]);
  const [pendingUserCount, setPendingUserCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  // state handle subscription statistics
  const [statistics, setStatistics] = useState({
    totalSubscriptions: 0,
    totalActive: 0,
    totalPending: 0,
    totalExpired: 0,
    productName: '',
  });
  const [loadingStats, setLoadingStats] = useState(false);

  //------------------------ Subscrption Stats -------------------------//
  // Fetch subscription statistics
  const fetchStatistics = useCallback(
    async (productId: string | 'All' = 'All') => {
      setLoadingStats(true);
      try {
        const response = await fetch(
          `${apiUrl}/Sub-Statistics${productId !== 'All' ? `?productId=${productId}` : ''}`
        );
        const data = await response.json();
        setStatistics(data);
      } catch (error) {
        console.error('Failed to fetch subscription statistics:', error);
      } finally {
        setLoadingStats(false);
      }
    },
    [apiUrl]
  );

  useEffect(() => {
    fetchStatistics(); 
  }, [fetchStatistics]);

  //------------------------ Plan -------------------------//
  const fetchSubPending = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/Sub-Pending`);
      const data = await response.json();
      setSubPending(data);

      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch subPending', error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchSubPending();
  }, [fetchSubPending]);

  // Fetch count of pending users
  const fetchPendingUserCount = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/Sub-Pending/Count`);
      const data = await response.json();
      setPendingUserCount(data.count);
    } catch (error) {
      console.error('Failed to fetch pending user count:', error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchPendingUserCount(); 
  }, [fetchPendingUserCount]);

  const addSubPending = async (subPending: SubPending, paymentReceipt?: File) => {
    try {
      const formData = new FormData();

      // Append all subPending data to formData
      for (const key in subPending) {
        if (Object.prototype.hasOwnProperty.call(subPending, key)) {
          formData.append(key, (subPending as any)[key]);
        }
      }

      // Append the file if it exists
      if (paymentReceipt) {
        formData.append("subPaymentReceipts", paymentReceipt);
      }

      const response = await fetch(`${apiUrl}/Sub-Pending`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error adding new pending user');
      }

      const newPendingUser = await response.json();
      setSubPending((prevPendingUsers) => [...prevPendingUsers, newPendingUser]);
    } catch (error) {
      console.error('Failed to add pending user:', error);
    }
  }; 

  const updateSubPending = async (orderId: string, subPending: SubPending) => {
    try {
      await fetch(`${apiUrl}/Sub-Pending/${orderId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(subPending),
      });
      fetchSubPending();
    } catch (error) {
      console.error('Failed to update pending user:', error);
    }
  };

  const deleteSubPending= async (orderId: string) => {
    try {
      const response = await fetch(`${apiUrl}/Sub-Pending/${orderId}`, { 
        method: 'DELETE' 
      });

      if (!response.ok) {
        throw new Error(`Failed to delete subscription with orderId: ${orderId}`);
      }

      await fetchSubPending(); 
    } catch (error) {
      console.error(`Error deleting subscription with orderId: ${orderId}`, error);
      throw error;
    }
  }

  // ------------------------------- approve/reject pending user ------------------------------ //
  // approve a pending subscription
  const approveSubPending = async (user: SubPending) => {
    try {
      const response = await fetch(`${apiUrl}/Sub-Pending/approve/${user.subOrderId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
      });

      if (!response.ok) {
        throw new Error('Failed to approve user');
      }

      // Refetch the pending users to refresh the list after approval
      fetchSubPending();
    } catch (error) {
      console.error('Failed to approve pending user:', error);
    }
  };

  // Reject a pending subscription
  const rejectSubPending = async (user: SubPending & { rejectReason?: string }) => {
    try {
       // Check if subOrderId starts with "sub-TRA"
      const isTraderHub = user.subOrderId.startsWith("sub-TRA");

      if (!isTraderHub && !user.subAccountType) {
        console.error("Error: subAccountType is required for non-TraderHub subscriptions.");
        throw new Error("subAccountType cannot be null or undefined for this subscription.");
      }

      const response = await fetch(`${apiUrl}/Sub-Pending/reject/${user.subOrderId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          subUserEmail: user.subUserEmail,
          subUserName: user.subUserName,
          subOrderId: user.subOrderId,
          subAccountType: user.subAccountType,
          rejectReason: user.rejectReason,
        }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API error (${response.status}):`, errorText);
        throw new Error(`Failed to reject subscription: ${errorText}`);
      }
  
      // Refetch the data to update the table
      fetchSubPending();
    } catch (error) {
      console.error('Error rejecting subscription:', error);
    }
  };    

  // ----------------  fetch and dispaly user data into subscription product table ------------------- //
  const fetchSubProductData = useCallback(
    async (type: 'AlgoMatrix' | 'TraderHub' | 'All' = 'All') => {
      setLoading(true);
      try {
        if (type === 'AlgoMatrix' || type === 'All') {
          const response = await fetch(`${apiUrl}/Sub-AlgoMatrix`);
          const data = await response.json();
          setAlgoMatrixData(data);
        }

        if (type === 'TraderHub' || type === 'All') {
          const response = await fetch(`${apiUrl}/Sub-TraderHub`);
          const data = await response.json();
          setTraderHubData(data);
        }
      } catch (error) {
        console.error(`Failed to fetch data for ${type}`, error);
      } finally {
        setLoading(false);
      }
    },
    [apiUrl]
  );

  const addSubProduct = async (type: 'AlgoMatrix' | 'TraderHub', subPendingData: SubPending) => {
    try {
      console.log("Submitting to Sub-Pending:", subPending);
      
      const response = await fetch(`${apiUrl}/Sub-${type}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(subPendingData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Failed to add ${type} data: ${errorText}`);
        throw new Error(`Failed to add ${type} data: ${response.status} - ${errorText}`);
      }
      
      await fetchSubProductData(type);
      console.log(`Successfully added ${type} data and refreshed.`);
    } catch (error) {
      console.error(`Failed to add ${type} data`, error);
    }
  };

  const updateSubProduct = async (
    type: 'AlgoMatrix' | 'TraderHub',
    orderId: string,
    updatedData: Partial<SubPending>
  ) => {
    try {
      await fetch(`${apiUrl}/Sub-${type}/${orderId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedData),
      });
      await fetchSubProductData(type);
    } catch (error) {
      console.error(`Failed to update ${type} data with orderId: ${orderId}`, error);
    }
  };

  const deleteSubProduct = async (orderId: string) => {
    try {
      const response = await fetch(`${apiUrl}/Sub-Delete/${orderId}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) throw new Error(`Failed to delete data with orderId: ${orderId}`);

      await fetchSubProductData(); 
    } catch (error) {
      console.error(`Failed to delete data with orderId: ${orderId}`, error);
    }
  };  

  useEffect(() => {
    fetchSubProductData(); 
  }, [fetchSubProductData]);


  // -------------------------- show email list ----------------------------- //
  // Fetch user emails for the custom group modal
  const fetchUserEmails = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/users`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch user emails');
      }

      const data = await response.json();

      if (data.emails && Array.isArray(data.emails)) {
        setUserEmails(data.emails);
      } else {
        throw new Error('Invalid email data format');
      }
    } catch (error) {
      console.error('Error fetching user emails:', error);
    }
  }, [apiUrl]);  

  useEffect(() => {
    fetchUserEmails();
  }, [fetchUserEmails]);  

  return { 
    subPending, algoMatrixData, pendingUserCount, traderHubData, userEmails, loading, statistics, loadingStats,
    addSubPending, updateSubPending, deleteSubPending, approveSubPending, rejectSubPending,
    fetchUserEmails, fetchSubPending, fetchSubProductData,
    addSubProduct, updateSubProduct, deleteSubProduct,
    fetchStatistics
  };
};

// // udpate code --> 24 nov 2024
// // src/hooks/AdminDashboard/useSubUserData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface SubPending {
//   subOrderId: string;
//   subProductId: string;
//   subProductName: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: string;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType: string;
//   subAccountServer: string;
//   subAccountNumber: string;
//   subAccountPassword: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// export const useSubUserData = () => {
//   const [userEmails, setUserEmails] = useState<string[]>([]);
//   const [subPending, setSubPending] = useState<SubPending[]>([]);
//   const [algoMatrixData, setAlgoMatrixData] = useState<SubPending[]>([]);
//   const [traderHubData, setTraderHubData] = useState<SubPending[]>([]);
//   const [pendingUserCount, setPendingUserCount] = useState<number>(0);
//   const [loading, setLoading] = useState(true);
//   const { apiUrl } = useEnv();

//   //------------------------ Plan -------------------------//
//   const fetchSubPending = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/Sub-Pending`);
//       const data = await response.json();
//       setSubPending(data);

//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch subPending', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchSubPending();
//   }, [fetchSubPending]);

//   // Fetch count of pending users
//   const fetchPendingUserCount = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/Sub-Pending/Count`);
//       const data = await response.json();
//       setPendingUserCount(data.count);
//     } catch (error) {
//       console.error('Failed to fetch pending user count:', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchPendingUserCount(); 
//   }, [fetchPendingUserCount]);

//   const addSubPending = async (subPending: SubPending) => {
//     try {
//       const response = await fetch(`${apiUrl}/Sub-Pending`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(subPending),
//       });
//       if (!response.ok) {
//         throw new Error('Error adding new pending user');
//       }
//       const newPendingUser = await response.json();
//       setSubPending((prevPendingUsers) => [...prevPendingUsers, newPendingUser]);
//     } catch (error) {
//       console.error('Failed to add pending user:', error);
//     }
//   }; 

//   const updateSubPending = async (orderId: string, subPending: SubPending) => {
//     try {
//       await fetch(`${apiUrl}/Sub-Pending/${orderId}`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(subPending),
//       });
//       fetchSubPending();
//     } catch (error) {
//       console.error('Failed to update pending user:', error);
//     }
//   };

//   const deleteSubPending= async (orderId: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/Sub-Pending/${orderId}`, { 
//         method: 'DELETE' 
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to delete subscription with orderId: ${orderId}`);
//       }

//       await fetchSubPending(); 
//     } catch (error) {
//       console.error(`Error deleting subscription with orderId: ${orderId}`, error);
//       throw error;
//     }
//   }

//   // ------------------------------- approve/reject pending user ------------------------------ //
//   // approve a pending subscription
//   const approveSubPending = async (user: SubPending) => {
//     try {
//       const response = await fetch(`${apiUrl}/Sub-Pending/approve/${user.subOrderId}`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(user),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to approve user');
//       }

//       // Refetch the pending users to refresh the list after approval
//       fetchSubPending();
//     } catch (error) {
//       console.error('Failed to approve pending user:', error);
//     }
//   };

//   // Reject a pending subscription
//   const rejectSubPending = async (user: SubPending & { rejectReason?: string }) => {
//     try {
//        // Check if subOrderId starts with "sub-TRA"
//       const isTraderHub = user.subOrderId.startsWith("sub-TRA");

//       if (!isTraderHub && !user.subAccountType) {
//         console.error("Error: subAccountType is required for non-TraderHub subscriptions.");
//         throw new Error("subAccountType cannot be null or undefined for this subscription.");
//       }

//       const response = await fetch(`${apiUrl}/Sub-Pending/reject/${user.subOrderId}`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           subUserEmail: user.subUserEmail,
//           subUserName: user.subUserName,
//           subOrderId: user.subOrderId,
//           subAccountType: user.subAccountType,
//           rejectReason: user.rejectReason,
//         }),
//       });
  
//       if (!response.ok) {
//         const errorText = await response.text();
//         console.error(`API error (${response.status}):`, errorText);
//         throw new Error(`Failed to reject subscription: ${errorText}`);
//       }
  
//       // Refetch the data to update the table
//       fetchSubPending();
//     } catch (error) {
//       console.error('Error rejecting subscription:', error);
//     }
//   };    

//   // ----------------  fetch and dispaly user data into subscription product table ------------------- //
//   const fetchSubProductData = useCallback(
//     async (type: 'AlgoMatrix' | 'TraderHub' | 'All' = 'All') => {
//       setLoading(true);
//       try {
//         if (type === 'AlgoMatrix' || type === 'All') {
//           const response = await fetch(`${apiUrl}/Sub-AlgoMatrix`);
//           const data = await response.json();
//           setAlgoMatrixData(data);
//         }

//         if (type === 'TraderHub' || type === 'All') {
//           const response = await fetch(`${apiUrl}/Sub-TraderHub`);
//           const data = await response.json();
//           setTraderHubData(data);
//         }
//       } catch (error) {
//         console.error(`Failed to fetch data for ${type}`, error);
//       } finally {
//         setLoading(false);
//       }
//     },
//     [apiUrl]
//   );

//   const addSubProduct = async (type: 'AlgoMatrix' | 'TraderHub', subPendingData: SubPending) => {
//     try {
//       const response = await fetch(`${apiUrl}/Sub-${type}`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(subPendingData),
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         console.error(`Failed to add ${type} data: ${errorText}`);
//         throw new Error(`Failed to add ${type} data`);
//       }
      
//       await fetchSubProductData(type);
//     } catch (error) {
//       console.error(`Failed to add ${type} data`, error);
//     }
//   };

//   const updateSubProduct = async (
//     type: 'AlgoMatrix' | 'TraderHub',
//     orderId: string,
//     updatedData: Partial<SubPending>
//   ) => {
//     try {
//       await fetch(`${apiUrl}/Sub-${type}/${orderId}`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(updatedData),
//       });
//       await fetchSubProductData(type);
//     } catch (error) {
//       console.error(`Failed to update ${type} data with orderId: ${orderId}`, error);
//     }
//   };

//   const deleteSubProduct = async (orderId: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/Sub-Delete/${orderId}`, {
//         method: 'DELETE',
//       });
  
//       if (!response.ok) throw new Error(`Failed to delete data with orderId: ${orderId}`);

//       await fetchSubProductData(); 
//     } catch (error) {
//       console.error(`Failed to delete data with orderId: ${orderId}`, error);
//     }
//   };  

//   useEffect(() => {
//     fetchSubProductData(); 
//   }, [fetchSubProductData]);


//   // -------------------------- show email list ----------------------------- //
//   // Fetch user emails for the custom group modal
//   const fetchUserEmails = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/users`);
      
//       if (!response.ok) {
//         throw new Error('Failed to fetch user emails');
//       }

//       const data = await response.json();

//       if (data.emails && Array.isArray(data.emails)) {
//         setUserEmails(data.emails);
//       } else {
//         throw new Error('Invalid email data format');
//       }
//     } catch (error) {
//       console.error('Error fetching user emails:', error);
//     }
//   }, [apiUrl]);  

//   useEffect(() => {
//     fetchUserEmails();
//   }, [fetchUserEmails]);  

//   return { 
//     subPending, algoMatrixData, pendingUserCount, traderHubData, userEmails, loading, 
//     addSubPending, updateSubPending, deleteSubPending, approveSubPending, rejectSubPending,
//     fetchUserEmails, fetchSubPending, fetchSubProductData,
//     addSubProduct, updateSubProduct, deleteSubProduct
//   };
// };




// // udpate code --> 10 nov 2024
// // src/hooks/AdminDashboard/useSubUserData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface SubPending {
//   subOrderId: string;
//   subProductId: string;
//   subProductName: string;
//   subPlanId: string;
//   subUserName: string;
//   subUserEmail: string;
//   subPlanType: string;
//   subPlanAmount: number;
//   subPlanPeriod: string;
//   subDateSubmit: Date;
//   subExpiredDate: Date;
//   subExpiredDays: number;
//   subStatus: string;
//   subAccountType: string;
//   subAccountServer: string;
//   subAccountNumber: string;
//   subAccountPassword: string;
//   subTrialCheck: number;
//   subVoucherCheck: number;
//   subPaymentMethod: string;
//   subPaymentReceipts: string;
//   subNotes: string;
//   subRemark: string;
//   subTermAgreed: string;
// }

// export const useSubPending = () => {
//   const [userEmails, setUserEmails] = useState<string[]>([]);
//   const [subPending, setSubPending] = useState<SubPending[]>([]);
//   const [algoMatrixData, setAlgoMatrixData] = useState<SubPending[]>([]);
//   const [traderHubData, setTraderHubData] = useState<SubPending[]>([]);
//   const [pendingUserCount, setPendingUserCount] = useState<number>(0);
//   const [loading, setLoading] = useState(true);
//   const { apiUrl } = useEnv();

//   //------------------------ Plan -------------------------//
//   const fetchSubPending = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/Get-Sub-Pending`);
//       const data = await response.json();
//       setSubPending(data);

//       setLoading(false);
//     } catch (error) {
//       console.error('Failed to fetch subPending', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchSubPending();
//   }, [fetchSubPending]);

//   // Fetch count of pending users
//   const fetchPendingUserCount = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/Sub-Pending/Count`);
//       const data = await response.json();
//       setPendingUserCount(data.count);
//     } catch (error) {
//       console.error('Failed to fetch pending user count:', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchPendingUserCount(); 
//   }, [fetchPendingUserCount]);

//   const addSubPending = async (subPending: SubPending) => {
//     try {
//       const response = await fetch(`${apiUrl}/Sub-Pending`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(subPending),
//       });
//       if (!response.ok) {
//         throw new Error('Error adding new pending user');
//       }
//       const newPendingUser = await response.json();
//       setSubPending((prevPendingUsers) => [...prevPendingUsers, newPendingUser]);
//     } catch (error) {
//       console.error('Failed to add pending user:', error);
//     }
//   }; 

//   const updateSubPending = async (orderId: string, subPending: SubPending) => {
//     try {
//       await fetch(`${apiUrl}/Sub-Pending/${orderId}`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(subPending),
//       });
//       fetchSubPending();
//     } catch (error) {
//       console.error('Failed to update pending user:', error);
//     }
//   };

//   const deleteSubPending= async (orderId: string) => {
//     await fetch(`${apiUrl}/Sub-Pending/${orderId}`, { 
//       method: 'DELETE' 
//     });
//     fetchSubPending();
//   };

//   // ------------------------------- approve/reject pending user ------------------------------ //
//   // approve a pending user
//   const approveSubPending = async (user: SubPending) => {
//     try {
//       const response = await fetch(`${apiUrl}/Sub-Pending/approve/${user.subOrderId}`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(user),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to approve user');
//       }

//       // Refetch the pending users to refresh the list after approval
//       fetchSubPending();
//     } catch (error) {
//       console.error('Failed to approve pending user:', error);
//     }
//   };

//   // ----------------  fetch and dispaly user data into subscription product table ------------------- //
//   const fetchSubProductData = useCallback(
//     async (type: 'AlgoMatrix' | 'TraderHub' | 'All' = 'All') => {
//       setLoading(true);
//       try {
//         if (type === 'AlgoMatrix' || type === 'All') {
//           const response = await fetch(`${apiUrl}/Sub-AlgoMatrix`);
//           const data = await response.json();
//           setAlgoMatrixData(data);
//         }

//         if (type === 'TraderHub' || type === 'All') {
//           const response = await fetch(`${apiUrl}/Sub-TraderHub`);
//           const data = await response.json();
//           setTraderHubData(data);
//         }
//       } catch (error) {
//         console.error(`Failed to fetch data for ${type}`, error);
//       } finally {
//         setLoading(false);
//       }
//     },
//     [apiUrl]
//   );

//   const addSubProduct = async (type: 'AlgoMatrix' | 'TraderHub', subPendingData: SubPending) => {
//     try {
//       const response = await fetch(`${apiUrl}/Sub-${type}`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(subPendingData),
//       });
//       if (!response.ok) throw new Error(`Failed to add ${type} data`);
//       await fetchSubProductData(type);
//     } catch (error) {
//       console.error(`Failed to add ${type} data`, error);
//     }
//   };

//   const updateSubProduct = async (
//     type: 'AlgoMatrix' | 'TraderHub',
//     orderId: string,
//     updatedData: Partial<SubPending>
//   ) => {
//     try {
//       await fetch(`${apiUrl}/Sub-${type}/${orderId}`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(updatedData),
//       });
//       await fetchSubProductData(type);
//     } catch (error) {
//       console.error(`Failed to update ${type} data with orderId: ${orderId}`, error);
//     }
//   };

//   const deleteSubProduct = async (type: 'AlgoMatrix' | 'TraderHub', orderId: string) => {
//     try {
//       await fetch(`${apiUrl}/Sub-${type}/${orderId}`, {
//         method: 'DELETE',
//       });
//       await fetchSubProductData(type);
//     } catch (error) {
//       console.error(`Failed to delete ${type} data with orderId: ${orderId}`, error);
//     }
//   };

//   useEffect(() => {
//     fetchSubProductData(); 
//   }, [fetchSubProductData]);



//   // -------------------------- show email list ----------------------------- //
//   // Fetch user emails for the custom group modal
//   const fetchUserEmails = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/users`);
      
//       if (!response.ok) {
//         throw new Error('Failed to fetch user emails');
//       }

//       const data = await response.json();

//       if (data.emails && Array.isArray(data.emails)) {
//         setUserEmails(data.emails);
//       } else {
//         throw new Error('Invalid email data format');
//       }
//     } catch (error) {
//       console.error('Error fetching user emails:', error);
//     }
//   }, [apiUrl]);  

//   useEffect(() => {
//     fetchUserEmails();
//   }, [fetchUserEmails]);  

//   return { 
//     subPending, algoMatrixData,pendingUserCount, traderHubData, userEmails, loading, 
//     addSubPending, updateSubPending, deleteSubPending, approveSubPending, 
//     fetchUserEmails, fetchSubPending, fetchSubProductData,
//     addSubProduct, updateSubProduct, deleteSubProduct
//   };
// };


